import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Layout, Row, Button, Form, Menu, Modal, Select, Input, notification } from 'antd'
import InviteGroupUser from './components/InviteGroupUser'
import { PageLoader, NoDataFound, openNotification } from '../common/components/Helper'
import { MoreOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { languageInfo } from '../../otherLanguages'
import { Service } from '../../services/Service'

const { Content } = Layout
const { confirm } = Modal
const { Option } = Select
const { SubMenu } = Menu

const AdminGroups = (props) => {
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [popupMode, setPopupMode] = useState('Add')
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [editGroupInfo, setEditGroupInfo] = useState([])
  const [activeLanguages, setActiveLanguages] = useState([])
  const [groupList, setGroupList] = useState([])

  useEffect(() => {
    setLoading(true)
    getGroupList()
  }, [])

  const getGroupList = () => {
    Service.get({
      url: '/groups',
    })
      .then((response) => {
        setLoading(false)
        if (response.hasOwnProperty('error')) {
          return
        }
        setGroupList(response)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const [form] = Form.useForm()

  const showEditModal = (group) => {
    if (group.defaultLanguage) {
      group.name = group.translations[group.defaultLanguage].name
    }

    setPopupMode('Edit')
    form.resetFields()
    setEditModalVisible(true)
    setActiveLanguages(group.language)
    setEditGroupInfo(group)
    form.setFieldsValue(group)
  }

  const handleOk = () => {
    setEditModalVisible(false)
  }

  const closeEditModal = () => {
    setEditModalVisible(false)
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  }

  if (props.reload === true) {
    getGroupList()
    props.stopreload()
  }

  const validateMessages = {
    required: '${label} ' + t('errors.is required!'),
    types: {
      email: '${label} ' + t('errors.is not a valid email!'),
      number: '${label} ' + t('errors.is not a valid number!'),
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  const submitForm = (values) => {
    var params = {
      name: values.name,
      language: values.language,
      defaultLanguage: values.defaultLanguage,
      translations: values.translations ? values.translations : {},
    }

    if (typeof values.translations[values.defaultLanguage] == 'undefined') {
      openNotification(
        'warning',
        t('content.Missing default lanugage content'),
        t('content.Please add Name in default language - ') + languageInfo(values.defaultLanguage).name,
      )
    } else {
      var defLngContent = values.translations[values.defaultLanguage]
      params.name = defLngContent.name
    }

    setLoading(true)
    if (popupMode == 'Edit') {
      // EDIT Api call
      Service.patch({
        url: '/groups/' + editGroupInfo._id,
        body: JSON.stringify(params),
      })
        .then((response) => {
          apiSuccess(apiSuccess)
        })
        .catch((err) => {
          setLoading(false)
          openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
        })
    } else {
      // Add Api Call
      Service.post({
        url: '/groups',
        body: JSON.stringify(params),
      })
        .then((response) => {
          apiSuccess(apiSuccess)
        })
        .catch((err) => {
          setLoading(false)
          openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
        })
    }
  }

  const apiSuccess = (response) => {
    setLoading(false)
    if (response.status === 'error') {
      openNotification('error', t('content.Oops!'), response.message)
    } else {
      openNotification(
        'success',
        t('content.Success'),
        popupMode == 'Add' ? t('content.Group Added successfully!') : t('content.Group Updated successfully!'),
      )
      getGroupList()
      closeEditModal()
    }
  }

  function handleLanguageChange(value) {
    var langVal = form.getFieldValue('translations')
    if (!langVal) {
      form.setFieldsValue({ translations: { ar: { title: '', description: '' }, de: { title: '', description: '' } } })
    }
    setActiveLanguages(value)
  }

  const translate = (data) => {
    return JSON.parse(JSON.stringify(data)).map((item) => {
      if (item.translations && item.translations.hasOwnProperty(selectedLanguage.key)) {
        item.name = item.translations[selectedLanguage.key].name
        item.description = item.translations[selectedLanguage.key].description
      }
      return item
    })
  }

  function showDeleteConfirm(group) {
    confirm({
      title: t('content.Are you sure to delete this group?'),
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: t('buttons.Yes'),
      okType: 'danger',
      cancelText: t('buttons.No'),
      onOk(e) {
        Service.delete({
          url: '/groups/' + group._id,
        })
          .then((response) => {
            setLoading(false)
            if (response.status === 'error') {
              openNotification('error', t('content.Oops!'), response.message)
              return
            } else {
              openNotification('success', t('content.Success'), t('content.Deleted Successfully!'))
              closeEditModal()
              getGroupList()
              Modal.destroyAll()
            }
          })
          .catch((err) => {
            setLoading(false)
            openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
          })
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  return (
    <Layout className="layout adminScreen bgWhite">
      <Content className="">
        <div className="site-layout-content">
          <div className="site-card-wrappe">
            {loading ? (
              <PageLoader />
            ) : (
              <Row type="flex" align="middle" gutter={24}>
                <div className="academyListing">
                  {groupList.length == 0 ? (
                    <NoDataFound />
                  ) : (
                    <>
                      {translate(groupList).map((group, index) => (
                        <div className="groupItem">
                          <div className="info">
                            <Link to={'/admin/group-users/' + group._id}>
                              <h2>{group.name}</h2>
                            </Link>
                            <span>
                              {group.users.length} {t('content.Members')}
                            </span>
                          </div>
                          <Menu mode="horizontal" className="listMenu">
                            <SubMenu key={`SubMenu:1:${index}`} icon={<MoreOutlined />} title="">
                              <Menu.Item
                                onClick={() => showEditModal(group)}
                                className="listSubMenuItems"
                                key={`setting:1:${index}`}
                              >
                                {t('buttons.Edit')}
                              </Menu.Item>

                              <Menu.Item
                                onClick={() => showDeleteConfirm(group)}
                                className="listSubMenuItems"
                                key={`setting:2:${index}`}
                              >
                                {t('buttons.Delete')}
                              </Menu.Item>

                              <Menu.Item className="listSubMenuItems group-menu-item" key={`setting:3:${index}`}>
                                <InviteGroupUser group={group._id} />
                              </Menu.Item>
                            </SubMenu>
                          </Menu>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </Row>
            )}
          </div>
        </div>
      </Content>
      <br />

      <Modal
        title={popupMode === 'Add' ? t('buttons.Add Group') : t('buttons.Edit Group')}
        visible={editModalVisible}
        onOk={handleOk}
        onCancel={closeEditModal}
        footer={false}
        width={500}
        maskClosable={false}
      >
        <Form
          {...layout}
          name="nest-messages"
          form={form}
          layout="vertical"
          onFinish={submitForm}
          initialValues={{}}
          validateMessages={validateMessages}
        >
          <Form.Item name={['language']} label={t('content.Add Language')} rules={[{ required: true }]}>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="Please select"
              onChange={handleLanguageChange}
            >
              <Option value={'en'} key={1}>
                {t('content.English')}
              </Option>
              <Option value={'de'} key={2}>
                {t('content.German')}
              </Option>
              <Option value={'ar'} key={3}>
                {t('content.Arabic')}
              </Option>
            </Select>
          </Form.Item>

          {activeLanguages.map((language, index) => (
            <div key={index}>
              <Form.Item
                name={['translations', language, 'name']}
                label={`Group Name (${languageInfo(language).name})`}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </div>
          ))}

          <Form.Item name={['defaultLanguage']} label={t('content.Default Language')} rules={[{ required: true }]}>
            <Select placeholder="Please select">
              <Option value="en" key={'en'}>
                {t('content.English')}
              </Option>
              <Option value="de" key={'de'}>
                {t('content.German')}
              </Option>
              <Option value="ar" key={'ar'}>
                {t('content.Arabic')}
              </Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('buttons.Save')}
            </Button>{' '}
            &nbsp; &nbsp;
            <Button type="default" htmlType="button" onClick={closeEditModal}>
              {t('buttons.Cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  )
}

export default AdminGroups
