import { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Layout, Tabs, Badge, Row, Col, Button } from 'antd'
import { Breadcrumb } from 'antd'

import { Service, Storage } from '../../services/Service'
import { openNotification, PageLoader } from '../common/components/Helper'
import CourseLearn from './components/CourseLearn'
import CourseInformation from './components/CourseInformation'
import CourseClassRoom from './components/CourseClassRoom'
import CourseStudents from './components/CourseStudents'
import { useParams, Link, useLocation } from 'react-router-dom'
import AdminCourseStudentOverview from '../admin/components/AdminCourseStudentOverview'
import { getCourseDetails } from '../../redux/actions/courseDetail'
import CourseMessageForm from './components/CourseMessageForm'
import ListCourseMeetingRoom from '../common/components/ListCourseMeetingRoom'
import { SocketContext } from '../context/socket'

const { Content } = Layout

const AcademyCourseDetail = () => {
  const userInfo = Storage.get('auth')

  const { t } = useTranslation()
  const dispatch = useDispatch()
  let { id } = useParams()
  let { search } = useLocation()

  const [tabKey, setTabKey] = useState('1')
  const [academyData, setAcademyData] = useState([])
  const [teamMembers, setTeamMembers] = useState([])
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)
  const { courseDetail, isCourseDetailFetching } = useSelector(({ courseDetailReducer }) => courseDetailReducer)
  const socket = useContext(SocketContext)

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getCourseDetails(id))
    }

    //ping server to get latest teamMember's online status. and list
    var interval = setInterval(() => {
      fetchTeamMembersList()
    }, 10000) //Every 10 sec

    //Change ping time to 20sec after 3mins delay
    setTimeout(() => {
      clearInterval(interval)
      interval = setInterval(() => {
        fetchTeamMembersList()
      }, 20000) //Every 20 sec
    }, 180000)

    const params = new URLSearchParams(search)
    setTabKey(params.get('tab') ?? '1')

    return () => clearInterval(interval)
  }, [id])

  useEffect(() => {
    if (courseDetail) {
      setAcademyData(courseDetail.academyId)
      setTeamMembers(courseDetail.teamMembers)
    }
  }, [courseDetail])

  useEffect(() => {
    const handleUserStatusChange = (userData) => {
      const changedUser = JSON.parse(userData)
      for (let i = 0; i < teamMembers.length; i++) {
        if (changedUser._id === teamMembers[i].user._id) {
          setTeamMembers([
            ...teamMembers.slice(0, i),
            { ...teamMembers[i], user: changedUser },
            ...teamMembers.slice(i + 1),
          ])
          break
        }
      }
    }
    socket.on('updateUserStatus', handleUserStatusChange)

    return () => {
      socket.off('updateUserStatus', handleUserStatusChange)
    }
  }, [socket, teamMembers])

  const getBadge = () => {
    var count = 0
    if (teamMembers) {
      for (let i = 0; i < teamMembers.length; i++) {
        if (
          teamMembers[i].user &&
          teamMembers[i].user.isOnline == true &&
          teamMembers[i].user.dnd === 0 &&
          userInfo._id != teamMembers[i].user._id
        )
          count = count + 1
      }
    }
    return (
      <>
        {t('content.Students')} (
        <Badge className="studentCount" text={count ? count : 0} color="#008000" style={{}} status="success" />)
      </>
    )
  }

  const getTabs = () => {
    if (courseDetail) {
      if (courseDetail.access == 'valid') {
        return (
          <Tabs
            defaultActiveKey="1"
            activeKey={tabKey}
            onChange={(key) => setTabKey(key)}
            className="academy-detail-tab"
          >
            <Tabs.TabPane tab={t('content.Learn')} key="1"></Tabs.TabPane>
            <Tabs.TabPane tab={t('content.Information')} key="2"></Tabs.TabPane>
            <Tabs.TabPane tab={getBadge()} key="3"></Tabs.TabPane>
            <Tabs.TabPane tab={t('content.Classroom')} key="4"></Tabs.TabPane>
            <Tabs.TabPane tab={t('content.Meeting Rooms')} key="5" />
            {userInfo.role === 'module_trainer' ? (
              <Tabs.TabPane tab={t('Student Overview')} key="6"></Tabs.TabPane>
            ) : null}

            {['module_trainer', 'admin'].indexOf(userInfo.role) > -1 && (
              <Tabs.TabPane tab={t('content.Email all')} key="7"></Tabs.TabPane>
            )}
          </Tabs>
        )
      } else {
        return (
          <Tabs defaultActiveKey="1" className="academy-detail-tab">
            <Tabs.TabPane tab={t('content.Information')} key="1"></Tabs.TabPane>
          </Tabs>
        )
      }
    }
  }

  const enrollToCourse = () => {
    Service.post({
      url: '/user/courseEnroll',
      body: JSON.stringify({ courseId: courseDetail._id }),
    }).then((resp) => {
      if (resp) {
        openNotification(
          'success',
          t('content.Enrollment to a Course'),
          t('content.You’re now enrolled in this course!'),
        )
        setTimeout(function () {
          window.location.reload()
        }, 2000)
      }
    })
  }

  const getTabContent = () => {
    if (courseDetail) {
      if (courseDetail.access == 'valid') {
        return (
          <div className="container tabContentPadding academy-tabcontent">
            {tabKey === '1' && (
              <>
                {isCourseDetailFetching ? (
                  <PageLoader />
                ) : (
                  <CourseLearn courseDetail={courseDetail} initialStep={new URLSearchParams(search).get('step')} />
                )}
              </>
            )}
            {tabKey === '2' && (
              <>{isCourseDetailFetching ? <PageLoader /> : <CourseInformation course={courseDetail} />}</>
            )}
            {tabKey === '3' && (
              <>{isCourseDetailFetching ? <PageLoader /> : <CourseStudents teamMembers={teamMembers} />}</>
            )}
            {tabKey === '4' && (
              <>{isCourseDetailFetching ? <PageLoader /> : <CourseClassRoom course={courseDetail} />}</>
            )}
            {tabKey === '5' && (
              <>
                {isCourseDetailFetching ? (
                  <PageLoader />
                ) : (
                  <ListCourseMeetingRoom course={courseDetail} focus={tabKey == '5'} />
                )}
              </>
            )}
            {tabKey === '6' && (
              <>{isCourseDetailFetching ? <PageLoader /> : <AdminCourseStudentOverview course={courseDetail} />}</>
            )}
            {tabKey === '7' && (
              <>{isCourseDetailFetching ? <PageLoader /> : <CourseMessageForm course={courseDetail} />}</>
            )}
          </div>
        )
      } else {
        return (
          <div className="container tabContentPadding">
            <CourseInformation course={courseDetail} />
          </div>
        )
      }
    }
  }

  const fetchTeamMembersList = async () => {
    Service.get({
      url: '/academy/course/users/' + id,
    }).then((response) => {
      var arr = []
      if (response && response.length > 0) {
        /*response.forEach(function(academy) {
					const found = arr.some(el => el.user._id === academy.user._id);
					if(!found) {
					arr.push(academy)
					}
				}); */
        console.log('GOT TEAM MEMBERS')
        console.log(response)
        setTeamMembers(response)
      }
    })
  }

  return (
    <>
      <Layout className="layout bgWhite">
        {isCourseDetailFetching ? (
          <PageLoader />
        ) : (
          <>
            <Content
              className="ipso-cont ent"
              style={{
                backgroundColor: '#f0f2f5',
                minHeight: '100px',
                paddingBottom: '0px',
              }}
            >
              <div className="container fullWidthGrey academy-detail-div">
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to={'/dashboard'}>{t('content.Academies')}</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={'/academy-detail/' + academyData._id}>
                      {academyData &&
                      academyData.translations &&
                      academyData.translations[selectedLanguage.key] !== undefined
                        ? academyData.translations[selectedLanguage.key].name
                        : academyData.name}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {courseDetail &&
                      courseDetail.translations &&
                      (courseDetail.translations[selectedLanguage.key]
                        ? courseDetail.translations[selectedLanguage.key].name
                        : courseDetail.name)}
                  </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-content">
                  <Row>
                    <Col lg={{ span: 12 }} md={{ span: 14 }} sm={{ span: 16 }} xs={{ span: 12 }}>
                      <h1 className="page-title">
                        {courseDetail &&
                          courseDetail.translations &&
                          (courseDetail.translations[selectedLanguage.key]
                            ? courseDetail.translations[selectedLanguage.key].name
                            : courseDetail.name)}{' '}
                      </h1>
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 10 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                      {courseDetail && courseDetail.access == 'invalid' ? (
                        <div className="enroll-pos-top">
                          {userInfo && userInfo.role === 'admin' ? (
                            <span className="view-enroll"> {t('Viewing as enrolled')} </span>
                          ) : (
                            <Button
                              ghost={true}
                              htmlType="button"
                              onClick={() => enrollToCourse()}
                              className="transparentButton"
                            >
                              {' '}
                              {t('content.Enroll Now')}{' '}
                            </Button>
                          )}
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </div>

                {getTabs()}
              </div>
            </Content>

            {getTabContent()}

            {/* <CustomFooter /> */}
          </>
        )}
      </Layout>
    </>
  )
}

export default AcademyCourseDetail
