import React, { useEffect, useState } from 'react'
import AdminHeader from '../admin/AdminHeader'
import StudentHeader from '../user/StudentHeader'
import { useLocation } from 'react-router'

const PageHeader = (props) => {
  const [isAdminMenu, setIsAdminMenu] = useState(false)
  const location = useLocation()

  //Dont display header on these pages
  const NoHeaderPage = ['/chime', '/classroom', '/buddycall']

  const enableHeader = NoHeaderPage.findIndex((element) => location.pathname.includes(element)) < 0

  useEffect(() => {
    setIsAdminMenu(location.pathname.indexOf('/admin') > -1)
  }, [location])

  return enableHeader ? isAdminMenu ? <AdminHeader /> : <StudentHeader /> : null
}

export default PageHeader
