import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useSelector,
  // useDispatch
} from 'react-redux'
import { Layout, Row, Col, notification, Button } from 'antd'

import { CustomFooter, PageLoader, NoDataFound } from '../common/components/Helper'
import AcademicCard from './AcademicCard'
import { Service } from '../../services/Service'
// import { getCourseByAcademy, setAcademy } from './../../redux/actions/admin'

const { Content } = Layout

const Dashboard = () => {
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)
  // const { academies, isFetching } = useSelector(({ adminReducer }) => adminReducer);

  const [haveOtherLng, setHaveOtherLng] = useState(false)
  const [allowOtherLng, setAllowOtherLng] = useState(false)
  const [loading, setLoading] = useState(false)
  const [academies, setAcademies] = useState([])
  const [activeAcademies, setActiveAcademies] = useState([])

  const { t } = useTranslation()
  // const dispatch = useDispatch()

  useEffect(() => {
    // dispatch(setAcademy('/academy'));
    getAcademyList()
  }, [])

  useEffect(() => {
    if (academies?.length > 0) {
      setActiveAcademies(translate(academies))
    }
  }, [selectedLanguage, allowOtherLng, academies])

  const getAcademyList = () => {
    setLoading(true)
    return Service.get({
      url: '/academy',
    })
      .then((data) => {
        setLoading(false)
        setAcademies(data)
        setActiveAcademies(translate(data))
      })
      .catch((err) => {
        setLoading(false)
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
  }

  const openNotification = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    })
  }

  const translate = (data) => {
    var otherLng = false
    var resultArr = []
    JSON.parse(JSON.stringify(data)).forEach((item) => {
      if (item.translations && item.translations.hasOwnProperty(selectedLanguage.key)) {
        item.name = item.translations[selectedLanguage.key].name
        item.subTitle = item.translations[selectedLanguage.key].subTitle
        resultArr.push(item)
      } else if (allowOtherLng || selectedLanguage.key === 'en') {
        resultArr.push(item) //Default mandatory language for academy is EN so directly allow
      } else if (!otherLng) {
        otherLng = true
      }
    })
    setHaveOtherLng(otherLng)
    return resultArr
  }

  const allowOtherLanguage = () => {
    setAllowOtherLng(true)
  }

  return (
    <Layout className="layout">
      <Content className="container ipso-content">
        {haveOtherLng && !allowOtherLng && (
          <div style={{ marginTop: '8px', float: selectedLanguage.direction === 'rtl' ? 'left' : 'right' }}>
            <Button onClick={allowOtherLanguage} type="link">
              {t('Info.Show Academy in other languages')}
            </Button>
          </div>
        )}

        <div className="site-layout-content">
          <Row>
            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 8 }} xs={{ span: 24 }}>
              <h1 className="page-title">{t('content.Academies')} </h1>
            </Col>

            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 16 }} xs={{ span: 24 }}></Col>
          </Row>
        </div>

        {loading ? (
          <PageLoader />
        ) : (
          <>
            {activeAcademies.length === 0 ? (
              <NoDataFound />
            ) : (
              <div className="site-card-wrappe">
                <Row type="flex" align="middle" gutter={24} style={{ margin: '0px' }}>
                  {activeAcademies.map((academy) => (
                    <Col span={16} order={1} xs={24} sm={24} md={24} lg={8} xl={8}>
                      <AcademicCard
                        id={academy._id}
                        title={academy.name}
                        course={
                          academy.course.length +
                          ' ' +
                          (academy.course.length <= 1 ? t('content.Course') : t('content.Courses'))
                        }
                        image={academy.image}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            )}
          </>
        )}
        <br />
      </Content>
      <CustomFooter />
    </Layout>
  )
}

export default Dashboard
