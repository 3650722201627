import { Service } from '../../services/Service'
import {
  IS_ADMIN,
  SET_ACADEMY,
  SET_ACADEMY_MODAL_VISIBILITY,
  SET_USER_DETAILS,
  GET_COURSE_BY_ACADEMY,
} from './../action-creators/admin'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'

// export const isAdmin = () => ({
//     type: IS_ADMIN
// });

const openNotification = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  })
}

export const setAcademy = (url) => {
  return (dispatch) => {
    dispatch({ type: SET_ACADEMY, payload: [], isFetching: true })

    Service.get({ url: url })
      .then((response) => {
        if (response) {
          dispatch({
            type: SET_ACADEMY,
            payload: response,
            isFetching: false,
          })
        } else {
          dispatch({ type: SET_ACADEMY, payload: [], isFetching: false })
        }
      })
      .catch((err) => {
        dispatch({ type: SET_ACADEMY, payload: [], isFetching: false })
      })
  }
}

export const setSortedAcademy = (academies) => {
  return { type: SET_ACADEMY, payload: academies, isFetching: false }
}

export const setAcademyModalVisible = (bool) => {
  return { type: SET_ACADEMY_MODAL_VISIBILITY, payload: bool }
}

export const editAcademy = (id, params) => {
  return (dispatch) => {
    Service.patch({
      url: '/academy/' + id,
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (response.status === 'error') {
          openNotification('error', i18n.t('content.Oops!'), response.message)
        } else {
          openNotification('success', i18n.t('content.Success'), i18n.t('content.Academy updated successfully!'))
          dispatch(setAcademyModalVisible(false))
          dispatch(setAcademy('/academy/admin'))
        }
      })
      .catch((err) => {
        openNotification('error', i18n.t('content.Oops!'), i18n.t('content.Something went wrong'))
      })
  }
}

export const addAcademy = (params) => {
  return (dispatch) => {
    Service.post({
      url: '/academy',
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (response.status === 'error') {
          openNotification('error', i18n.t('content.Oops!'), response.message)
        } else {
          openNotification('success', i18n.t('content.Success'), i18n.t('content.Academy added successfully!'))
          dispatch(setAcademyModalVisible(false))
          dispatch(setAcademy('/academy/admin'))
        }
      })
      .catch((err) => {
        openNotification('error', i18n.t('content.Oops!'), i18n.t('content.Something went wrong'))
      })
  }
}

export const getUserData = (searchText) => {
  return (dispatch) => {
    if (searchText) {
      Service.get({ url: `/user/search?email=${searchText}` })
        .then((resp) => {
          if (resp) {
            dispatch({
              type: SET_USER_DETAILS,
              payload: resp,
            })
          }
        })
        .catch((err) => {
          dispatch({ type: SET_USER_DETAILS, payload: [] })
        })
    } else {
      dispatch({ type: SET_USER_DETAILS, payload: [] })
    }
  }
}

export const getCourseByAcademy = (acadId) => {
  return (dispatch) => {
    dispatch({ type: GET_COURSE_BY_ACADEMY, payload: undefined, isCourseFetching: true })

    Service.get({ url: `/academy/${acadId}` })
      .then((response) => {
        if (response._id) {
          dispatch({
            type: GET_COURSE_BY_ACADEMY,
            payload: response,
            isCourseFetching: false,
          })
        } else {
          dispatch({ type: GET_COURSE_BY_ACADEMY, payload: undefined, isCourseFetching: false })
        }
      })
      .catch((err) => {
        dispatch({ type: GET_COURSE_BY_ACADEMY, payload: undefined, isCourseFetching: false })
      })
  }
}
