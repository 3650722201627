import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Layout, Row, Col, Tabs } from 'antd'
import AdminUsers from './components/AdminUsers'
import AdminGroups from './AdminGroups'
import InviteUserBtn from './components/InviteUserBtn'
import AddGroupBtn from './components/AddGroupBtn'

const { Content } = Layout
const { TabPane } = Tabs

const Users = (props) => {
  const { t } = useTranslation()
  const [tabKey, setTabKey] = useState(1)
  const [activeTab, setActiveTab] = useState('1')
  const [addGroup, setAddGroup] = useState(false)
  const addNewGroup = () => {
    setAddGroup(true)
  }

  const removeGroup = () => {
    setAddGroup(false)
  }

  useEffect(() => {}, [])

  const callback = (key) => {
    setActiveTab(key)
    setTabKey(key)
  }

  return (
    <Layout className="layout bgWhite">
      <Content
        className="ipso-cont ent"
        style={{ backgroundColor: '#f0f2f5', minHeight: '100px', paddingBottom: '0px' }}
      >
        <div className="container fullWidthGrey">
          <div className="site-layout-content">
            <Row>
              <Col span={12} lg={12} md={12} sm={12} xs={24}>
                <h1 className="page-title">{t('content.User Management')} </h1>
              </Col>
              <Col span={12} lg={12} md={12} sm={12} xs={24} className="users-btn-block">
                {activeTab == 1 ? <InviteUserBtn /> : <AddGroupBtn onClick={addNewGroup} reload={addGroup} />}
              </Col>
            </Row>
            <br />
          </div>
          <Tabs activeKey={activeTab} onChange={callback} className="usersList">
            <TabPane tab={t('content.All Users')} key="1"></TabPane>

            <TabPane tab={t('content.Groups')} key="2"></TabPane>
          </Tabs>
        </div>
      </Content>

      <div className="container tabContentPadding">
        {tabKey == 1 && (
          <>
            <AdminUsers />
          </>
        )}
        {tabKey == 2 && (
          <>
            <AdminGroups reload={addGroup} stopreload={removeGroup} />
          </>
        )}
      </div>
    </Layout>
  )
}

export default Users
