import { useMemo } from 'react'
import { StepContentElementTypesEnum } from '../../constants/StepContentBlock'
import DocumentViewer from '../../user/components/DocumentViewer'
import QuillViewer from './QuillViewer'
import StepImageViewer from './StepImageViewer'

const StepContentBlockViewer = ({ content }) => {
  const blockData = useMemo(() => {
    let decodedData = []
    try {
      decodedData = JSON.parse(content)
    } catch (err) {
      if (content) {
        decodedData = [
          {
            type: StepContentElementTypesEnum.TEXT,
            value: content,
          },
        ]
      }
    }

    return decodedData
  }, [content])

  return (
    <div className="step-content-block-viewer">
      {blockData.map((element) => {
        switch (element.type.toUpperCase()) {
          case StepContentElementTypesEnum.TEXT:
            return <QuillViewer content={element.value} />

          case StepContentElementTypesEnum.IMAGE:
            return <StepImageViewer src={element.value?.filePath} />

          case StepContentElementTypesEnum.VIDEO:
            return (
              <div
                className="ql-indent"
                dangerouslySetInnerHTML={{
                  __html: element.value,
                }}
              />
            )

          case StepContentElementTypesEnum.DOCUMENT:
            let doc = element.value
            if (doc?.filePath) {
              if (!doc?.originalName) {
                doc.originalName = doc.filePath.split('/')[1]
              }
              return <DocumentViewer document={doc} className="ql-indent" />
            }
            return null
        }
      })}
    </div>
  )
}

export default StepContentBlockViewer
