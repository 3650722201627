import React from 'react'
import { useTranslation } from 'react-i18next'
import { Result, Button } from 'antd'
import { Link } from 'react-router-dom'

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Result
      status="404"
      title="404"
      subTitle={t('content.Page Not Found')}
      extra={
        <Link to={'/'}>
          <Button type="primary">{t('content.Back Home')}</Button>
        </Link>
      }
    />
  )
}

export default NotFound
