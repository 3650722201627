import React, { useEffect, useState } from 'react'
import ListCourseClass from '../../common/components/ListCourseClass'

const AdminCourseClassRoom = (props) => {
  const [courseData, setCourseData] = useState([])

  useEffect(() => {
    if (props.course) {
      setCourseData(props.course)
    }
  }, [props?.course])

  return (
    <>
      <ListCourseClass course={courseData} />
    </>
  )
}

export default AdminCourseClassRoom
