import { DateTime } from 'luxon'

export const convertWeekdayTime = (weekdays, times, zone = 'utc') => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const newWeekDays = []
  let newTimes = ''
  const [hour, minute] = times.split(':')
  if (0 === weekdays.length || !hour || !minute) return { day: null, time: null }
  weekdays.forEach((day) => {
    const weekday = days.findIndex((d) => d === day)
    const convertedTime =
      zone === 'utc'
        ? DateTime.local().set({ weekday, hour, minute }).toUTC()
        : DateTime.utc().set({ weekday, hour, minute }).toLocal()
    newWeekDays.push(convertedTime.weekdayLong)
    newTimes = [String(convertedTime.hour).padStart(2, 0), String(convertedTime.minute).padStart(2, 0)].join(':')
  })
  return { day: newWeekDays, time: newTimes }
}
