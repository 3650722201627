import React, { useState } from 'react'
import { Service } from '../../services/Service'
import { Form, Button, Row, Col, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import AuthLayout from '../auth/AuthLayout'
import PasswordFields from '../common/PasswordFields'
import ImprintPrivacyLinks from '../common/components/ImprintPrivacyLinks'

const ResetPassword = ({ match }) => {
  let token = match.params.token
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()
  const history = useHistory()

  const onFinish = (values) => {
    setLoading(true)
    Service.post({
      url: '/user/updatePassword',
      body: JSON.stringify({
        newPassword: values.password,
        token: token,
      }),
    })
      .then((response) => {
        setLoading(false)
        if (response.status == 'error') {
          openNotification('error', t('content.Oops!'), t('errors.' + response.message + ''))
          return
        }

        openNotification('success', t('content.Success'), t('content.Your password has been changed successfully'))
        history.push('/login')
      })
      .catch((err) => {
        setLoading(false)
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
  }

  const openNotification = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    })
  }

  return (
    <AuthLayout>
      <Row type="flex" justify="center" align="middle">
        <Col span={6} xs={20} sm={16} md={15} lg={8} xl={6}>
          <div className="img" style={{ textAlign: 'center' }}>
            <span className="form-subtitle">{t('auth.Welcome back')} </span>
            <p>
              <b className="ffb form-title">{t('auth.Reset Password')}</b>{' '}
            </p>
          </div>

          <Form
            layout="vertical"
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <PasswordFields passwordLabel={t('auth.Password')} confirmPasswordLabel={t('auth.Confirm Password')} />

            <Form.Item>
              <Button
                htmlType="submit"
                className="login-form-button"
                style={{ backgroundColor: '#449674', color: 'white' }}
                block
                size={'large'}
                loading={loading}
              >
                {t('buttons.Submit')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <ImprintPrivacyLinks />
    </AuthLayout>
  )
}

export default ResetPassword
