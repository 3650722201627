import React from 'react'
import { Row, Col, Typography, Layout } from 'antd'
import { useTranslation } from 'react-i18next'
import LanguageDropdown from '../form-component/LanguageDropdown'
import { Logo, CustomFooter } from './components/Helper'
import ImprintPrivacyLinks from './components/ImprintPrivacyLinks'

const { Header, Content } = Layout

const ImprintDisclaimer = () => {
  const { t } = useTranslation()

  return (
    <>
      <Layout className="layout">
        <div className="bgWhite">
          <Header className="custom-header container header-admin">
            <div className="left" style={{ display: 'flex' }}>
              <Logo />
            </div>
            <div className="right">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <LanguageDropdown />
              </div>
            </div>
          </Header>
        </div>

        <Content className="container ipso-content">
          <Row>
            <Col span={20} xs={20} sm={16} md={15} lg={24} xl={24}>
              <Typography>
                <h3>{t('Info.Imprint')}</h3>
                <p>
                  <strong>Ipso gemeinnützige Gesellschaft mbH</strong>
                </p>
                <p>{t('Info.International Psychosocial Organisation')}</p>
                <p>{t('Info.Trade register local court')} Freiburg i.Br. B 702 197</p>
                <p>{t('Info.Founded in 2008')}</p>
                <p>
                  {t('Info.Purpose')}:<br />
                  {t('Info.Purpose Content')}
                </p>

                <p>
                  <strong>{t('Info.Legal Information')}</strong>
                </p>
                <p>{t('Info.Mandatory information according to § 5 TM')}</p>
                <p>
                  {t('Info.Operator and contacts')} <br /> Ipso gGmbH <br /> Münsterplatz 13 <br /> 78462 Konstanz
                </p>
                <p>{t('Info.Phone')}: +49 (0) 7531 2820231</p>
                <p>{t('Info.E-Mail')}: info@ipsocontext.org</p>

                <p>
                  <strong>{t('Info.Management')}</strong>
                </p>
                <p>
                  {t('Info.Ipso gGmbH is represented by Inge Missmahl')} <br /> {t('Info.Register and register number')}
                  : Freiburg B 702 197
                </p>

                <p>
                  <strong>{t('Info.Responsible according to § 55 State Broadcasting Treaty')}:</strong>
                </p>
                <p>
                  Inge Missmahl <br /> {t('Info.Address')}: {t('Info.see above')}
                </p>

                <br />
                <h3>{t('Info.Attribution')}</h3>
                <p>
                  <strong>{t('Info.Developer')}</strong>
                </p>
                <p>
                  {t('Info.This web application is crafted from the ground up by the eLearning experts at Pandigita')}
                </p>

                <p>
                  <strong>{t('Info.File Attributions')}</strong>
                </p>
                <p>{t('Info.Symbols and Vector images from')} thenounproject.com</p>
                <p>{t('Info.Ringtone sound from')} Zapsplat.com</p>
                <p>{t('Info.Chime sound from')} Zapsplat.com</p>

                <br />
                <h3>{t('Info.Disclaimer')}</h3>
                <p>
                  <strong>{t('Info.Content')}</strong>
                </p>
                <p>{t('Info.Content Message')}.</p>

                <p>
                  <strong>Links</strong>
                </p>
                <p>{t('Info.Links Content')}.</p>

                <p>
                  <strong>{t('Info.Copyright')}</strong>
                </p>
                <p>{t('Info.Copyright Content')}. </p>
              </Typography>
            </Col>
          </Row>

          <ImprintPrivacyLinks />
        </Content>

        <CustomFooter />
      </Layout>
    </>
  )
}

export default ImprintDisclaimer
