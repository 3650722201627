import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Layout, Tabs, Badge } from 'antd'
import { Breadcrumb } from 'antd'
import { PageLoader } from '../common/components/Helper'
import NotFound from '../common/NotFound'
import AdminCourseLearn from './components/AdminCourseLearn'
import AdminCourseInformation from './components/AdminCourseInformation'
import AdminCourseClassRoom from './components/AdminCourseClassRoom'
import AdminCourseStudents from './components/AdminCourseStudents'
import AdminCourseStudentOverview from './components/AdminCourseStudentOverview'
import { useParams, Link, useLocation } from 'react-router-dom'
import { getCourseDetails } from '../../redux/actions/courseDetail'
import CourseMessageForm from '../user/components/CourseMessageForm'
import ListCourseMeetingRoom from '../common/components/ListCourseMeetingRoom'

const { Content } = Layout

const AdminCourseDetail = (props) => {
  const { t } = useTranslation()
  let { id } = useParams()
  let { search } = useLocation()
  const dispatch = useDispatch()
  const [tabKey, setTabKey] = useState('1')
  const [activeTab, setActiveTab] = useState('1')
  const [courseData, setCourseData] = useState([])
  const [academyData, setAcademyData] = useState([])
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)
  const { courseDetail, isCourseDetailFetching } = useSelector(({ courseDetailReducer }) => courseDetailReducer)

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getCourseDetails(id))
    } else {
      return false
    }

    if (window.location.href.indexOf('edit-content') > -1) {
      setActiveTab('2')
      setTabKey('2')
    }

    const params = new URLSearchParams(search)
    setTabKey(params.get('tab') ?? '1')
    setActiveTab(params.get('tab') ?? '1')
  }, [id])

  useEffect(() => {
    if (courseDetail) {
      if (courseDetail.status === 'failed') {
        setCourseData(courseDetail)
        return false
      }

      setCourseData(courseDetail)
      setAcademyData(courseDetail.academyId)
    }
  }, [courseDetail])

  const callback = (key) => {
    setActiveTab(key)
    setTabKey(key)
  }

  // Count student's availability
  const getBadge = (members) => {
    var count = 0
    if (members) {
      for (let i = 0; i < members.length; i++) {
        if (members[i].user.isOnline === true && members[i].user.dnd === 0) count = count + 1
      }
    }
    return (
      <>
        {t('content.Students')} (
        <Badge className="studentCount" text={count ? count : 0} color="#008000" style={{}} status="success" />)
      </>
    )
  }

  return (
    <Layout className="layout bgWhite">
      {isCourseDetailFetching ? (
        <PageLoader />
      ) : (
        <>
          {courseData.status === 'failed' ? (
            <Content
              className="ipso-content"
              style={{ backgroundColor: '#f0f2f5', paddingBottom: '5%', paddingTop: '0px' }}
            >
              <NotFound />
            </Content>
          ) : (
            <>
              <Content
                className="ipso-content"
                style={{ backgroundColor: '#f0f2f5', minHeight: '100px', paddingBottom: '0px', paddingTop: '0px' }}
              >
                <div className="container fullWidthGrey academy-detail-div">
                  <Breadcrumb>
                    <Breadcrumb.Item key="11">
                      <Link to={'/admin/dashboard'}>{t('content.Academies')}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item key="12">
                      <Link to={'/admin/courses/' + academyData._id}>
                        {academyData &&
                        academyData.translations &&
                        academyData.translations[selectedLanguage.key] !== undefined
                          ? academyData.translations[selectedLanguage.key].name
                          : academyData.name}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item key="13">
                      {courseData &&
                      courseData.translations &&
                      courseData.translations[selectedLanguage.key] !== undefined
                        ? courseData.translations[selectedLanguage.key].name
                        : courseData.name}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <div className="site-layout-content">
                    <h1 className="page-title">
                      {courseData &&
                      courseData.translations &&
                      courseData.translations[selectedLanguage.key] !== undefined
                        ? courseData.translations[selectedLanguage.key].name
                        : courseData.name}{' '}
                    </h1>
                  </div>
                  <Tabs activeKey={activeTab} onChange={callback} className="academy-detail-tab">
                    <Tabs.TabPane tab={t('content.Learn')} key="1" />
                    <Tabs.TabPane tab={t('content.Information')} key="2" />
                    <Tabs.TabPane tab={getBadge(courseData.teamMembers)} key="3" />
                    <Tabs.TabPane tab={t('content.Classroom')} key="4" />
                    <Tabs.TabPane tab={t('content.Meeting Rooms')} key="5" />
                    <Tabs.TabPane tab={t('content.Student Overview')} key="6" />
                    <Tabs.TabPane tab={t('content.Email all')} key="7" />
                  </Tabs>
                </div>
              </Content>

              {/* Show Tabs Content Seperately */}
              <div className="container tabContentPadding academy-tabcontent">
                {tabKey === '1' && (
                  <>
                    {isCourseDetailFetching ? <PageLoader /> : <AdminCourseLearn courseId={id} course={courseData} />}
                  </>
                )}

                {tabKey === '2' && (
                  <>
                    {isCourseDetailFetching ? (
                      <PageLoader />
                    ) : (
                      <AdminCourseInformation courseId={id} course={courseData} />
                    )}
                  </>
                )}

                {tabKey === '3' && (
                  <>{isCourseDetailFetching ? <PageLoader /> : <AdminCourseStudents course={courseData} />}</>
                )}

                {tabKey === '4' && (
                  <>{isCourseDetailFetching ? <PageLoader /> : <AdminCourseClassRoom course={courseData} />}</>
                )}
                {tabKey === '5' && (
                  <>
                    {isCourseDetailFetching ? (
                      <PageLoader />
                    ) : (
                      <ListCourseMeetingRoom course={courseData} focus={tabKey == '5'} />
                    )}
                  </>
                )}
                {tabKey === '6' && (
                  <>{isCourseDetailFetching ? <PageLoader /> : <AdminCourseStudentOverview course={courseData} />}</>
                )}
                {tabKey === '7' && (
                  <>{isCourseDetailFetching ? <PageLoader /> : <CourseMessageForm course={courseData} />}</>
                )}
              </div>
            </>
          )}
        </>
      )}
    </Layout>
  )
}

export default AdminCourseDetail
