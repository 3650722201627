import { LinkOutlined } from '@ant-design/icons'
import { Service } from '../../../../services/Service'
import Config from '../../../../config.json'
import './styles.scss'
import { useEffect, useState } from 'react'
import { detectBrowser, detectOS } from '../../../utils/detect-browser'
import { useTranslation } from 'react-i18next'

const PDFViewEngineEnum = {
  BROWSER_DEFAULT: 'BROWSER_DEFAULT',
  VIEWER_JS: 'VIEWER_JS',
}

const downloadFile = (filePath, fileName) => {
  const downloadFilename = fileName || filePath?.spit('/')[1]

  fetch(Service.getImage(filePath), { method: 'GET' })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', downloadFilename)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    })
}

const DocumentViewer = ({
  document,
  width,
  height,
  mobileHeight,
  showViewer,
  openInNewTab,
  className,
  displayEngine = PDFViewEngineEnum.BROWSER_DEFAULT,
}) => {
  const { t } = useTranslation()
  const [onMobileDevices, setOnMobileDevices] = useState(window.innerWidth < 768)

  useEffect(() => {
    const onWindowResize = () => {
      const { innerWidth } = window
      if (innerWidth < 768 && !onMobileDevices) {
        setOnMobileDevices(true)
      } else if (onMobileDevices) {
        setOnMobileDevices(false)
      }
    }

    window.addEventListener('resize', onWindowResize)

    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [])

  if (document?.filePath) {
    const { originalName, name, filePath } = document
    let framePath = Service.getImage(filePath)

    if (displayEngine === PDFViewEngineEnum.VIEWER_JS) {
      framePath = `${Config.BASE_URL}/pdf-viewer#${framePath}`
    }

    const onDocumentUrlClick = (event) => {
      event.preventDefault()
      if (openInNewTab) {
        window.open(Service.getImage(filePath), '_blank')
      } else {
        downloadFile(filePath, originalName || name)
      }
    }

    return (
      <div className={['document-view-wrapper', className || ''].join(' ')}>
        {showViewer &&
          (detectOS() === 'Linux' && detectBrowser() === 'firefox' ? (
            <div style={{ backgroundColor: '#FEFBE6', margin: '12px', fontStyle: 'italic' }}>
              {t('Info.Your browser does not support showing PDFs inside of a website')}
            </div>
          ) : (
            <div className="document-view">
              <iframe src={framePath} width={width} height={onMobileDevices ? mobileHeight : height} />
            </div>
          ))}
        <div className="document-link-wrapper">
          <LinkOutlined />
          <a href="#/" onClick={onDocumentUrlClick}>
            {originalName || name}
          </a>
        </div>
      </div>
    )
  }

  return null
}

DocumentViewer.defaultProps = {
  width: '100%',
  height: 792,
  mobileHeight: 200,
  showViewer: true,
  openInNewTab: false,
}

export default DocumentViewer
