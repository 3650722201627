import { combineReducers, createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import languageReducer from './reducers/language'
import adminReducer from './reducers/admin'
import courseDetailReducer from './reducers/courseDetail'
import moduleDetailReducer from './reducers/moduleDetail'
import buddyCallReducer from './reducers/buddyCall'

const rootReducer = combineReducers({
  languageReducer: languageReducer,
  adminReducer: adminReducer,
  courseDetailReducer: courseDetailReducer,
  moduleDetailReducer,
  buddyCall: buddyCallReducer,
})

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

export default store
