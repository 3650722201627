import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PageLoader, NoDataFound, openNotification } from '../../common/components/Helper'
import { Row, Col, Button, Dropdown, Menu, Form, Input, Select, Modal, TimePicker } from 'antd'
import { Service, Storage } from '../../../services/Service'
import { Link, useParams } from 'react-router-dom'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import { languageInfo } from '../../../otherLanguages'
import moment from 'moment'
import { google, outlook, yahoo, ics } from 'calendar-link'
import Config from '../../../config.json'
import QuillEditor from '../QuillEditor'
import QuillViewer from './QuillViewer'
import { convertWeekdayTime } from '../../utils/time-zone'
import { detectBrowser } from '../../utils/detect-browser'
import SafariCompabilityAlert from './SafariCompabilityAlert'

const { SubMenu } = Menu
const { Option } = Select
const { confirm } = Modal
const format = 'HH:mm'

const ListCourseClass = (props) => {
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)
  const userInfo = Storage.get('auth')
  const { t } = useTranslation()
  let { id } = useParams()

  const [loading, setLoading] = useState(false)
  const [popupMode, setPopupMode] = useState('Add')
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [activeLanguages, setActiveLanguages] = useState([])
  const [editClassInfo, setEditClassInfo] = useState([])
  const [courseData, setCourseData] = useState([])
  const [moduleList, setModuleList] = useState([])
  const [classLists, setClassLists] = useState([])
  const [classMode, setClassMode] = useState('module')
  const [memberModules, setMemberModules] = useState([])

  useEffect(() => {
    if (props.course) {
      setCourseData(props.course)

      if (props.course.modules && props.course.modules.length > 0) {
        var members = props.course.teamMembers
        if (userInfo && userInfo.role === 'module_trainer') {
          let obj = members.find((x) => x.user._id === userInfo._id)
          if (obj) {
            setMemberModules(obj.modules)
            const modules = props.course.modules.filter((item) => {
              return obj.modules.includes(item._id)
            })
            setModuleList(modules)
          } else {
            setModuleList([])
          }
        } else {
          setModuleList(props.course.modules)
        }
      }

      fetchClassData()
    }
  }, [props?.course])

  const fetchClassData = async () => {
    setLoading(true)
    try {
      Service.get({
        url: '/class/' + id,
      }).then((response) => {
        setLoading(false)
        if (response) {
          setClassLists(response)
        }
      })
    } catch (e) {
      setLoading(false)
    }
  }

  const [form] = Form.useForm()
  const defaultAcademyObj = {
    translations: {
      de: { name: '', description: '' },
      ar: { name: '', description: '' },
      en: { name: '', description: '' },
    },
  }

  // Open add classroom popup
  const showAddModal = (type) => {
    setPopupMode('Add')
    setClassMode(type)
    form.resetFields()
    form.setFieldsValue(defaultAcademyObj)
    setEditModalVisible(true)
    setActiveLanguages([])
    setEditClassInfo({})
  }

  // Open edit classroom popup
  const showEditModal = (meeting) => {
    setClassMode(meeting.classType)
    var values = {
      weekDay: meeting.day[0],
      translations: meeting.translations,
      language: meeting.language,
      time: moment(meeting.time, format),
    }
    if (meeting.classType === 'module') {
      values.module = meeting.moduleId._id
    }

    if (meeting.defaultLanguage) {
      values.description = meeting.translations[meeting.defaultLanguage].description
    }

    setPopupMode('Edit')
    form.resetFields()
    setEditModalVisible(true)
    setActiveLanguages(meeting.language)
    setEditClassInfo(meeting)
    form.setFieldsValue(values)
  }

  //Modal ok button
  const handleOk = () => {
    setEditModalVisible(false)
  }

  //Modal cancel button
  const closeEditModal = () => {
    setEditModalVisible(false)
    form.resetFields()
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  }

  const validateMessages = {
    required: '${label} ' + t('errors.is required!'),
    types: {
      email: '${label} ' + t('errors.is not a valid email!'),
      number: '${label} ' + t('errors.is not a valid number!'),
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  const submitForm = (values) => {
    const { day, time } = convertWeekdayTime([values.weekDay], values.time.format('HH:mm'), 'utc')
    var params = {
      type: classMode,
      language: values.language,
      description:
        typeof values.translations[courseData.defaultLanguage] != 'undefined'
          ? values.translations[courseData.defaultLanguage].description
          : values.translations[values.language[0]].description,
      defaultLanguage: courseData.defaultLanguage,
      translations: values.translations ? values.translations : {},
      course: courseData._id,
      day,
      time,
    }
    if (classMode === 'module') {
      params.module = values.module
    }
    var defLngContent =
      typeof values.translations[courseData.defaultLanguage] != 'undefined'
        ? values.translations[courseData.defaultLanguage].description
        : values.translations[values.language[0]].description
    if (defLngContent.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      openNotification(
        'warning',
        t('content.Missing selected language content'),
        t('content.Please add description in selected language!'),
      )
      return false
    }

    setLoading(true)
    if (popupMode === 'Edit') {
      params.leader = editClassInfo.courseLeader._id
      // Edit Api Call
      Service.patch({
        url: '/class/' + editClassInfo._id,
        body: JSON.stringify(params),
      })
        .then((response) => {
          apiSuccess(response)
        })
        .catch((err) => {
          setLoading(false)
          openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
        })
    } else {
      params.leader = userInfo._id
      // Add Api Call
      Service.post({
        url: '/class',
        body: JSON.stringify(params),
      })
        .then((response) => {
          apiSuccess(response)
        })
        .catch((err) => {
          setLoading(false)
          openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
        })
    }

    const apiSuccess = (response) => {
      setLoading(false)
      if (response.status === 'error') {
        openNotification('error', t('content.Oops!'), response.message)
      } else {
        openNotification(
          'success',
          t('content.Success'),
          t('content.Class') +
            ' ' +
            (popupMode === 'Add' ? t('content.Added') : t('content.Updated')) +
            ' ' +
            t('content.successfully!'),
        )
        fetchClassData()
        closeEditModal()
      }
    }
  }

  const confirmContent = (
    <>
      {t('content.If you’re sure that you want to discard this class,  all contained settings and class content')}
      ,&nbsp;
      {t('content.type the words Delete irreversibly into the field')}.<br />
      <br />
      <Input
        id="confirmText"
        required
        placeholder={t('content.Type') + ' : ' + t('content.Delete irreversibly')}
      />{' '}
      <br />
    </>
  )

  function showDeleteConfirm(meeting) {
    confirm({
      title: t('content.Delete class and all contained content irreversibly?'),
      icon: false,
      content: confirmContent,
      okText: t('buttons.Delete'),
      okType: 'danger',
      cancelText: t('buttons.Cancel'),
      wrapClassName: 'deleteConfirmModal',
      className: 'deleteConfirmModal',
      onOk(e) {
        var inp = document.getElementById('confirmText').value
        if (inp != t('content.Delete irreversibly')) {
          openNotification('error', t('content.Oops!'), t('content.Invalid input for delete'))
          return false
        }

        //Api call
        Service.delete({
          url: '/class/' + meeting._id,
        })
          .then((response) => {
            setLoading(false)
            if (response.status === 'error') {
              openNotification('error', t('content.Oops!'), response.message)
              return
            } else {
              openNotification('success', t('content.Success'), t('content.Deleted Successfully!'))
              closeEditModal()
              fetchClassData()
              Modal.destroyAll()
            }
          })
          .catch((err) => {
            setLoading(false)
            openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
          })
      },
      onCancel() {},
    })
  }

  function handleLanguageChange(value) {
    var langVal = form.getFieldValue('translations')
    if (!langVal) {
      form.setFieldsValue({ translations: { ar: { title: '', description: '' }, de: { title: '', description: '' } } })
    }
    setActiveLanguages(value)
  }

  const translate = (data) => {
    try {
      return JSON.parse(JSON.stringify(data)).map((item) => {
        if (item.translations && item.translations.hasOwnProperty(selectedLanguage.key)) {
          item.name = item.translations[selectedLanguage.key].name
          item.description = item.translations[selectedLanguage.key].description
        }
        return item
      })
    } catch (e) {
      return []
    }
  }

  const GetJoinMeeting = (props) => {
    var meeting = props.meeting
    var time = meeting.time.split(':')
    var sDate = moment().day(meeting.day[0]).startOf('day').add(time[0], 'hours').add(time[1], 'minutes')
    var eot = moment()
    if (sDate.isBefore(eot)) {
      sDate.add(1, 'weeks')
    }

    //if((moment(sDate).isSame(moment(), 'day')) && (sDate.diff(eot, 'minutes') < 120)) {
    return (
      <div style={{ marginTop: '15px' }}>
        <Link to={'/classroom/' + meeting._id} target="_blank">
          <Button className="join-class-button">{t('buttons.Join Class')}</Button>
        </Link>
      </div>
    )
    // }
    //return null;
  }

  // Add to calendar
  const calendarLink = (meeting) => {
    try {
      var time = meeting.time.split(':')
      var sDate = moment().day(meeting.day[0]).startOf('day').add(time[0], 'hours').add(time[1], 'minutes')
      var eot = moment()
      if (sDate.isBefore(eot)) {
        sDate.add(1, 'weeks')
      }
    } catch (e) {
      var sDate = moment().add(1, 'days')
    }

    var title =
      meeting.classType === 'module'
        ? meeting.moduleId
          ? meeting.moduleId.name
          : ''
        : typeof courseData.translations[selectedLanguage.key] != 'undefined'
        ? courseData.translations[selectedLanguage.key].name
        : courseData.translations[courseData.defaultLanguage].name
    const event = {
      title: 'IPSO: ' + title,
      description:
        meeting.description.replace(/(<([^>]+)>)/gi, '') + ' ' + Config.SITE_URL + '/classroom/' + meeting._id,
      start: sDate.format('YYYY-MM-DD HH:mm'),
      duration: [3, 'hour'],
    }
    var googleurl = google(event)
    var yahoourl = yahoo(event)
    var icsurl = ics(event)
    var outlookurl = outlook(event)

    return (
      <Menu>
        <Menu.Item key="0">
          <a href={googleurl} target="_blank" rel="noreferrer">
            Google
          </a>
        </Menu.Item>
        <Menu.Item key="1">
          <a href={outlookurl} target="_blank" rel="noreferrer">
            Outlook
          </a>
        </Menu.Item>
        <Menu.Item key="2">
          <a href={yahoourl} target="_blank" rel="noreferrer">
            Yahoo
          </a>
        </Menu.Item>
        <Menu.Item key="3">
          <a download="ipso_meeting" href={icsurl} target="_blank" rel="noreferrer">
            iCalendar
          </a>
        </Menu.Item>
      </Menu>
    )
  }

  const currentBrowser = detectBrowser()

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          {currentBrowser === 'safari' && <SafariCompabilityAlert />}
          <Row style={{ marginBottom: '20px' }}>
            <Col className="schedule-btns" lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
              {userInfo.role === 'admin' || userInfo.role === 'module_trainer' ? (
                <Button
                  style={{ marginRight: '10px' }}
                  icon={<PlusOutlined />}
                  ghost={true}
                  onClick={() => showAddModal('module')}
                  className="transparentButton scheduleBtn"
                  htmlType="button"
                >
                  {t('buttons.Add Module Based Class')}
                </Button>
              ) : null}

              {userInfo.role === 'admin' ? (
                <Button
                  icon={<PlusOutlined />}
                  ghost={true}
                  onClick={() => showAddModal('course')}
                  className="transparentButton scheduleBtn"
                  htmlType="button"
                >
                  {t('buttons.Add Course Based Class')}
                </Button>
              ) : null}
            </Col>
          </Row>

          <Row>
            {classLists.length == 0 ? (
              <NoDataFound message={t("content.This course doesn't yet have any classes")} />
            ) : (
              <>
                <Col lg={{ span: 18 }} md={{ span: 18 }} sm={{ span: 18 }} xs={{ span: 24 }}>
                  {translate(classLists).map((meeting, index) => {
                    const { day, time } = convertWeekdayTime(meeting.day, meeting.time, 'local')
                    return (
                      <div key={`class-${index}`} className="class-room-block">
                        {userInfo.role === 'admin' ||
                        (userInfo.role === 'module_trainer' &&
                          meeting.classType == 'module' &&
                          meeting.moduleId &&
                          memberModules.includes(meeting.moduleId._id)) ? (
                          <Menu key={`Menu:1:${index}`} mode="horizontal" className="listMenu">
                            <SubMenu key={`SubMenu:1:${index}`} icon={<MoreOutlined />} title="">
                              <Menu.Item
                                className="listSubMenuItems"
                                key={`setting:1:${index}`}
                                onClick={() => showEditModal(meeting)}
                              >
                                {t('buttons.Edit Meeting Information')}
                              </Menu.Item>
                              <Menu.Item
                                className="listSubMenuItems"
                                key={`setting:2:${index}`}
                                onClick={() => showDeleteConfirm(meeting)}
                              >
                                {t('buttons.Delete')}
                              </Menu.Item>
                            </SubMenu>
                          </Menu>
                        ) : null}

                        <h3>
                          {meeting.classType == 'module'
                            ? t('content.Module class') + ': ' + (meeting.moduleId ? meeting.moduleId.name : '')
                            : t('content.Whole course class') +
                              ': ' +
                              (typeof courseData.translations[selectedLanguage.key] != 'undefined'
                                ? courseData.translations[selectedLanguage.key].name
                                : courseData.translations[courseData.defaultLanguage].name)}
                        </h3>
                        <QuillViewer content={meeting.description} />

                        <Row className="schedule">
                          <Col
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 12 }}
                            xs={{ span: 24 }}
                            style={{ marginBottom: '10px' }}
                          >
                            <h5 className="sub-title">{t('content.Instructor')}</h5>
                            <p>{meeting.courseLeader.name + ' ' + meeting.courseLeader.surname}</p>
                            <span style={{ color: '#3A926A' }}>{meeting.courseLeader.email}</span>
                          </Col>
                          <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                            <h5 className="sub-title">{t('content.Time')}</h5>
                            <p>{t('content.' + day[0] + '')}</p>
                            <span>{time}</span> &nbsp;
                            <Dropdown overlay={calendarLink(meeting)} trigger={['click']}>
                              <a
                                className="ant-dropdown-link"
                                onClick={(e) => e.preventDefault()}
                                style={{ color: '#3A926A' }}
                              >
                                {t('content.Add to Calendar')}
                              </a>
                            </Dropdown>
                          </Col>
                        </Row>

                        <GetJoinMeeting meeting={meeting} />
                      </div>
                    )
                  })}
                </Col>
              </>
            )}
          </Row>

          <Modal
            title={popupMode == 'Add' ? t('content.Add Schedule Settings') : t('content.Edit Schedule Settings')}
            visible={editModalVisible}
            onOk={handleOk}
            onCancel={closeEditModal}
            footer={false}
            width={500}
            maskClosable={false}
          >
            <Form
              {...layout}
              name="nest-messages"
              form={form}
              layout="vertical"
              onFinish={submitForm}
              initialValues={{}}
              validateMessages={validateMessages}
            >
              {classMode === 'module' ? (
                <Form.Item name={['module']} label={t('content.Choose Module')} rules={[{ required: true }]}>
                  <Select placeholder={t('content.Please select')}>
                    {moduleList.length == 0 ? null : (
                      <>
                        {moduleList.map((module, index) => (
                          <Option key={`opt-${index}`} value={module._id}>
                            {module.name}
                          </Option>
                        ))}
                      </>
                    )}
                  </Select>
                </Form.Item>
              ) : null}

              <Form.Item name={['language']} label={t('content.Add Language')} rules={[{ required: true }]}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={t('content.Please select')}
                  onChange={handleLanguageChange}
                >
                  <Option value={'en'} key={1}>
                    {t('content.English')}
                  </Option>
                  <Option value={'de'} key={2}>
                    {t('content.German')}
                  </Option>
                  <Option value={'ar'} key={3}>
                    {t('content.Arabic')}
                  </Option>
                </Select>
              </Form.Item>

              {activeLanguages.map((language, index) => (
                <div key={index}>
                  <Form.Item
                    name={['translations', language, 'description']}
                    rules={[{ required: true }]}
                    label={t('content.Description') + ' ' + ` (${t('content.' + languageInfo(language).name + '')})`}
                  >
                    <QuillEditor />
                  </Form.Item>
                </div>
              ))}

              <Form.Item name={['weekDay']} label={t('content.Week Day')} rules={[{ required: true }]}>
                <Select allowClear style={{ width: '100%' }} placeholder={t('content.Please select')}>
                  <Option key={'Mon'} value="Monday">
                    {t('content.Monday')}
                  </Option>
                  <Option key={'Tue'} value="Tuesday">
                    {t('content.Tuesday')}
                  </Option>
                  <Option key={'Wed'} value="Wednesday">
                    {t('content.Wednesday')}
                  </Option>
                  <Option key={'Thu'} value="Thursday">
                    {t('content.Thursday')}
                  </Option>
                  <Option key={'Fri'} value="Friday">
                    {t('content.Friday')}
                  </Option>
                  <Option key={'Sat'} value="Saturday">
                    {t('content.Saturday')}
                  </Option>
                  <Option key={'Sun'} value="Sunday">
                    {t('content.Sunday')}
                  </Option>
                </Select>
              </Form.Item>

              <Form.Item name={['time']} label={t('content.Time')} rules={[{ required: true }]}>
                <TimePicker format={format} />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t('buttons.Submit')}
                </Button>{' '}
                &nbsp; &nbsp;
                <Button type="default" htmlType="button" onClick={closeEditModal}>
                  {t('buttons.Cancel')}
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    </>
  )
}

export default ListCourseClass
