import { SET_MODULE_STUDENTS } from '../action-creators/moduleDetail'
import { GET_HOMEWORK_DETAILS } from '../action-creators/moduleDetail'

const initialState = {
  moduleStudentDetails: {},
  isModuleStudentFetching: false,
  isHomeworkDetailsFetching: false,
  homeWorkDetails: {},
}

const moduleStudentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODULE_STUDENTS: {
      return {
        ...state,
        moduleStudentDetails: action.payload && action.payload,
        isModuleStudentFetching: action.isModuleStudentFetching,
      }
    }
    case GET_HOMEWORK_DETAILS: {
      return {
        ...state,
        homeWorkDetails: action.payload && action.payload,
        isHomeworkDetailsFetching: action.isHomeworkDetailsFetching,
      }
    }

    default:
      return state
  }
}

export default moduleStudentsReducer
