import { Service } from '../../services/Service'

export const deleteFile = async (file) => {
  await Service.delete({ url: `/fileUpload`, body: JSON.stringify(file) })
}

export const downloadFile = (filePath, fileName, loadedCallback) => {
  fetch(Service.getImage(filePath), {
    method: 'GET',
  })
    .then((response) => response.blob())
    .then((blob) => {
      if (typeof loadedCallback === 'function') {
        loadedCallback()
      }
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    })
    .finally(() => {
      if (typeof loadedCallback === 'function') {
        loadedCallback()
      }
    })
}

export const downloadAttachments = (attachment) => {
  window.open(Service.getImage(attachment), '_blank')
}
