import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { NoDataFound, openNotification, PageLoader } from '../../common/components/Helper'
import {
  Layout,
  Row,
  Col,
  Menu,
  Button,
  Divider,
  Steps,
  Radio,
  Input,
  Checkbox,
  Upload,
  message,
  InputNumber,
} from 'antd'
import { Service } from '../../../services/Service'
import moment from 'moment'
import {
  getStepsProgress,
  saveHomework,
  savePolls,
  finishModule,
  finishStep,
} from '../../../redux/actions/courseDetail'

import {
  MenuOutlined,
  LeftOutlined,
  RightOutlined,
  CheckOutlined,
  LinkOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import './courseLearn.css'
import StepContentBlockViewer from '../../common/components/StepContentBlockViewer'
import Config from '../../../config.json'
import { downloadAttachments, downloadFile } from '../../utils/file.util'
import { DateTime } from 'luxon'
import FileDownloadLink from '../../common/components/FileDownloadLink'

const { Content, Sider } = Layout
const { SubMenu } = Menu
const { Step } = Steps
const { TextArea } = Input

const CourseLearn = ({ courseDetail, initialStep }) => {
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [collapsed, setCollapsed] = useState(false)

  let firstStepId = null
  if (courseDetail.modules.length > 0 && courseDetail.modules[0].steps.length > 0) {
    firstStepId = courseDetail.modules[0].steps[0]._id
  }

  const [selectedModule, setSelectedModule] = useState(0)
  const [selectedStep, setSelectedStep] = useState(0)
  const [selectedStepId, setSelectedStepId] = useState(firstStepId)
  const [homeworkText, setHomeworkText] = useState('')
  const [homeworkTitle, setHomeworkTitle] = useState('')
  const [visitedSteps, setVisitedSteps] = useState([])
  const [currentPollAtmpt, setCurrentPollAtmpt] = useState(1)
  const [showNextModule, setShowNextModule] = useState('')
  const [isLastModule, setIsLastModule] = useState(selectedModule === courseDetail.modules.length - 1)
  const [justMakedAsComplete, setJustMarkedAsComplete] = useState(false)

  const firstModule = courseDetail.modules[0]
  const firstModulesStepCount = firstModule ? firstModule.steps.length : 0

  const [totalStepsUnderCurrentModule, setTotalStepsUnderCurrentModule] = useState(firstModulesStepCount)
  const [pollResponse, setPollResponse] = useState([])
  const [showHomeworkError, setShowHomeworkError] = useState(false)

  const { stepProgress, isLoading } = useSelector(({ courseDetailReducer }) => courseDetailReducer)
  const [homeworkAttachment, setHomeworkAttachment] = useState()
  const [attachmentWordCount, setAttachmentWordCount] = useState(null)
  const [homeworkFeedback, setHomeworkFeedback] = useState(null)

  useEffect(() => {
    if (selectedStepId !== null) {
      /*setTimeout(
        () => dispatch(getStepsProgress(selectedStepId)),  500
      );*/
      dispatch(getStepsProgress(selectedStepId))
      var visitArray = visitedSteps
      visitArray.push(selectedStepId)
      setVisitedSteps(visitArray)
    }
    setCurrentPollAtmpt(1)
  }, [selectedStep, selectedModule])

  useEffect(() => {
    if (showNextModule !== '') {
      next()
    }
  }, [showNextModule])

  useEffect(() => {
    console.log('STEP PROGRESS', stepProgress)
    if (stepProgress && stepProgress.homework != null) {
      setHomeworkAttachment(stepProgress.homework?.attachment)
      setHomeworkTitle(stepProgress.homework?.title)
      onChangeHomework({ target: { value: stepProgress.homework.homework } }, stepProgress.homework.title)
      setHomeworkFeedback(stepProgress.homework.feedback)
    } else {
      setHomeworkText('')
      setHomeworkFeedback(null)
    }
  }, [stepProgress])

  useEffect(() => {
    //Show last accessed step
    if (courseDetail && courseDetail.moduleProgress.length > 0) {
      var lastAccessed = courseDetail.moduleProgress[0]
      var allModules = courseDetail.modules
      for (var i = 0; i < allModules.length; i++) {
        let obj = allModules[i].steps.find((x) => x._id === (initialStep || lastAccessed.currentStep))
        let index = allModules[i].steps.indexOf(obj)
        if (index >= 0) {
          let newStep = index
          setSelectedModule(i)
          setSelectedStep(newStep)
          setSelectedStepId(initialStep ?? lastAccessed.currentStep)
          setSelectedKeys(`step-${i + 1}-${newStep + 1}`)
          setTotalStepsUnderCurrentModule(allModules[i].steps.length)
          setIsLastModule(i === allModules.length - 1)
        }
      }
    }
  }, [])

  //Expand modules based on active step
  useEffect(() => {
    defaultModuleExpand()
  }, [selectedModule])

  const defaultModuleExpand = () => {
    try {
      var arr = ['module-' + (selectedModule + 1), 'module-' + (selectedModule + 2)]
      if (!collapsed) setOpenKeys(arr)
    } catch (e) {}
  }

  const onCollapse = () => {
    collapsed ? setCollapsed(false) : setCollapsed(true)
  }

  const next = async () => {
    let newStep = selectedStep + 1

    const currentModule = courseDetail.modules[selectedModule]
    setSelectedKeys(`step-${selectedModule + 1}-${newStep + 1}`)

    await dispatch(finishStep({ stepId: selectedStepId }))

    Service.get({
      url: '/progress/course/' + courseDetail._id,
    }).then((resp) => {
      if (resp) {
        courseDetail.moduleProgress = resp
      }
    })

    if (currentModule) {
      const currentStep = currentModule.steps[newStep]
      if (currentStep) {
        setSelectedStepId(currentStep._id)
      }
    }
    window.scrollTo(0, 0)
    setSelectedStep(newStep)
  }

  const prev = () => {
    let newStep = selectedStep - 1
    if (newStep === -1) {
      //Move to previous module
      let nxt = selectedModule - 1
      if (
        courseDetail.modules.length &&
        typeof courseDetail.modules[nxt] != 'undefined' &&
        typeof courseDetail.modules[nxt].steps[0] != 'undefined'
      ) {
        setTotalStepsUnderCurrentModule(courseDetail.modules[nxt].steps.length)
        setSelectedModule(nxt)
        setSelectedStep(courseDetail.modules[nxt].steps.length - 2)
        setShowNextModule(new Date())
        setIsLastModule(nxt === courseDetail.modules.length - 1)
      }
    } else {
      //Move to previous step
      setSelectedStep(newStep)
      const currentModule = courseDetail.modules[selectedModule]
      setSelectedKeys(`step-${selectedModule + 1}-${newStep + 1}`)

      if (currentModule) {
        const currentStep = currentModule.steps[newStep]
        setSelectedStepId(currentStep._id)
      }
    }
  }

  const captureResponse = (response, index) => {
    let existingResponses = []
    existingResponses[index] = response
    setPollResponse(existingResponses)
  }

  const submitHomeworkHandler = async (min, max, type, title) => {
    let count = homeworkText ? homeworkText?.split(' ')?.filter((item) => item).length : 0
    if (homeworkAttachment && homeworkAttachment.length > 0) count = attachmentWordCount ?? 0
    if (min && max) {
      if (count < min || count > max) {
        setShowHomeworkError(true)
        setTimeout(() => {
          setShowHomeworkError(false)
        }, 5000)
        return false
      }
    }

    setShowHomeworkError(false)
    const resp = await saveHomework(
      selectedStepId,
      homeworkText,
      title ?? homeworkTitle,
      type,
      homeworkAttachment ? homeworkAttachment[0].id : null,
      count,
    )
    if (resp === true) {
      dispatch(getStepsProgress(selectedStepId, false, true))
    }
  }

  const onChangeHomework = (e, title, min, max) => {
    try {
      setHomeworkTitle(title)
      setHomeworkText(e.target.value)
    } catch (e) {
      console.log('Homwork err', e)
    }
  }

  const onDocUploadChange = ({ file }) => {
    if (file.flag) {
      return
    }
    if (file.status === 'done' && file.response) {
      file.id = file.response.id
      file.percent = 100
      file.uid = file.response.id
      file.url = file.response.url
      // file.name= file.response.originalName
      file.type = file.response.contentType
      file.filePath = file.response.filePath
      setHomeworkAttachment([file])
    }
  }

  const nextButtonStatus = () => {
    var stepDetails = courseDetail.modules[selectedModule].steps[selectedStep]
    var isSubmissionPending = false

    const homeworkExists =
      stepDetails &&
      stepDetails.homework &&
      (stepDetails.homework.en || stepDetails.homework.ar || stepDetails.homework.de)?.content !== ''

    if (homeworkExists && stepProgress && stepProgress.homework === null && stepProgress.attachment === null) {
      isSubmissionPending = true
    }

    const pollExists =
      stepDetails &&
      stepDetails.polls &&
      ((stepDetails.polls['ar'] && stepDetails.polls['ar'].length > 0 && stepDetails.polls['ar'][0].question) ||
        (stepDetails.polls['en'] && stepDetails.polls['en'].length > 0 && stepDetails.polls['en'][0].question) ||
        (stepDetails.polls['de'] && stepDetails.polls['de'].length > 0 && stepDetails.polls['de'][0].question))

    if (pollExists && stepProgress && stepProgress.polls == null) {
      isSubmissionPending = true
    }

    if (stepProgress && stepProgress.homework && stepProgress.homework.status == 0) {
      //If saved as draft then dont allow to submit
      isSubmissionPending = true
    }

    return isSubmissionPending
  }

  const onAttachmentWordCountChanged = (value) => {
    const reg = /^\d*$/
    if ((!isNaN(value) && reg.test(value)) || value === '') {
      setAttachmentWordCount(value)
      console.log(value)
    }
  }

  const beforeAttachmentUpload = (file) => {
    console.log('loai file la ', file.type)
    if (
      [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.oasis.opendocument.formula',
        'application/vnd.oasis.opendocument.text',
      ].indexOf(file.type) < 0
    ) {
      file.flag = true
      message.error(t('errors.Invalid file extension'))
    }
    return [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.oasis.opendocument.formula',
      'application/vnd.oasis.opendocument.text',
    ].indexOf(file.type) > -1
      ? true
      : false
  }

  const onAttachmentDelete = async () => {
    await Service.delete({
      url: '/fileUpload',
      body: JSON.stringify(homeworkAttachment),
    })
    setHomeworkAttachment(undefined)
  }

  const getHomework = (currentStep, homeworkProgress) => {
    try {
      let alreadySubmitted = false

      let wordCount = 0

      try {
        wordCount = homeworkText ? homeworkText?.split(' ')?.filter((item) => item).length : 0
      } catch (err) {}

      let currentStepHomework
      if (
        currentStep.homework &&
        currentStep.homework[selectedLanguage.key] &&
        currentStep.homework[selectedLanguage.key].content &&
        currentStep.homework[selectedLanguage.key].content !== ''
      ) {
        currentStepHomework = currentStep.homework[selectedLanguage.key]
      } else if (
        currentStep.homework &&
        currentStep.homework[currentStep.defaultLanguage] &&
        currentStep.homework[currentStep.defaultLanguage].content &&
        currentStep.homework[currentStep.defaultLanguage].content !== ''
      ) {
        currentStepHomework = currentStep.homework[currentStep.defaultLanguage]
      }

      let homeworkValue
      if (currentStepHomework) {
        var submittedTime
        if (homeworkProgress && homeworkProgress.homework) {
          if (homeworkProgress.homework.status === 1) {
            alreadySubmitted = true
            wordCount = homeworkProgress.homework.homework ? homeworkProgress.homework.homework.match(/\w+/g).length : 0
            homeworkValue = homeworkProgress.homework.homework
            submittedTime = moment.utc(homeworkProgress.homework.createdAt).local().format('LLL')
          } else {
            // wordCount = (homeworkProgress.homework.homework ? homeworkProgress.homework.homework.match(/\w+/g).length : 0);
            // homeworkValue =  homeworkProgress.homework.homework;
          }
        }
        return (
          <>
            <h3 className="green">
              <b>{t('content.Homework')}: </b>
            </h3>
            <p style={{ fontSize: '16px', whiteSpace: 'pre-wrap' }}>{currentStepHomework.content}</p>
            {homeworkAttachment ? (
              <>
                {alreadySubmitted ? (
                  <>
                    <Row style={{ paddingBottom: '10px' }}>
                      <FileDownloadLink
                        filePath={homeworkAttachment.filePath}
                        name={homeworkAttachment.originalName}
                        rightContent={
                          <>
                            &nbsp; &nbsp;
                            <span>
                              {homeworkProgress.homework.wordCount}&nbsp; {t('Info.Word')}
                            </span>
                          </>
                        }
                      />
                    </Row>
                    <div>{`${t('content.Submitted at')}: ${submittedTime}`}</div>
                  </>
                ) : (
                  homeworkAttachment.length > 0 && (
                    <>
                      <div>
                        {t('Info.Your homework was successfully uploaded')} <b>{t('Info.not yet submitted')}</b>
                      </div>
                      <div>{t('Info.Please fill out the word count and sumit the file')}</div>
                      <FileDownloadLink {...homeworkAttachment[0]} showDeleteButton onDelete={onAttachmentDelete} />
                      <div>
                        <span>{t('Info.Word count')} &nbsp; &nbsp; </span>
                        <InputNumber
                          onChange={onAttachmentWordCountChanged}
                          value={attachmentWordCount}
                          type="number"
                          style={{ width: 100 }}
                        />
                        <span>
                          &nbsp; &nbsp; ({t('Info.Minimum')}: {currentStepHomework.min}, {t('Info.Maximum')}:{' '}
                          {currentStepHomework.max})
                        </span>
                      </div>
                      {showHomeworkError ? (
                        <p>
                          <small style={{ color: 'red' }}>{t('content.Please meet the word length criterion!')}</small>
                        </p>
                      ) : null}
                      <div style={{ margin: '15px' }} />
                      <Button
                        onClick={() =>
                          submitHomeworkHandler(
                            currentStepHomework.min,
                            currentStepHomework.max,
                            '1',
                            currentStepHomework?.content,
                          )
                        }
                        type="primary"
                      >
                        {' '}
                        {t('buttons.Submit now')}{' '}
                      </Button>
                    </>
                  )
                )}
              </>
            ) : (
              <>
                <small>
                  <pre>
                    {t('content.Minimum Words')}: {currentStepHomework.min && currentStepHomework.min}{' '}
                    {t('content.Maximum Words')}: {currentStepHomework.max && currentStepHomework.max}
                  </pre>
                </small>

                <TextArea
                  disabled={alreadySubmitted}
                  value={homeworkText}
                  id="homeworkTextArea"
                  onChange={(e) =>
                    onChangeHomework(
                      e,
                      currentStepHomework.content,
                      currentStepHomework.min && currentStepHomework.min,
                      currentStepHomework.max && currentStepHomework.max,
                    )
                  }
                  placeholder={t('content.Write your homework here')}
                  rows={4}
                />

                <small>{`${t('content.Current number of words')}: ${wordCount}`}</small>
                {showHomeworkError ? (
                  <p>
                    <small style={{ color: 'red' }}>{t('content.Please meet the word length criterion!')}</small>
                  </p>
                ) : null}

                <div className="homework-submit">
                  {alreadySubmitted ? (
                    `${t('content.Submitted at')}: ${submittedTime}`
                  ) : (
                    <Button
                      onClick={() => submitHomeworkHandler(currentStepHomework.min, currentStepHomework.max, '1')}
                      type="primary"
                    >
                      {' '}
                      {t('buttons.Submit')}{' '}
                    </Button>
                  )}

                  <Button
                    disabled={alreadySubmitted}
                    onClick={() => submitHomeworkHandler(currentStepHomework.min, currentStepHomework.max, '0')}
                    style={{ marginRight: '10px' }}
                  >
                    {' '}
                    {t('content.Save Draft')}{' '}
                  </Button>
                  {!alreadySubmitted && (
                    <Upload
                      action={`${Config.BASE_URL}/fileUpload `}
                      beforeUpload={beforeAttachmentUpload}
                      onChange={onDocUploadChange}
                      maxCount={1}
                      disabled={alreadySubmitted}
                      multiple={false}
                      showUploadList={false}
                      fileList={homeworkAttachment}
                      headers={{ 'x-access-token': localStorage.getItem('token') }}
                    >
                      <Button disabled={alreadySubmitted} icon={<UploadOutlined />}>
                        {t('buttons.Upload Homework as File')}
                      </Button>
                    </Upload>
                  )}
                </div>
              </>
            )}
            {homeworkFeedback && (
              <>
                <Divider />
                <h3>{t('buttons.Feedback')}:</h3>
                <p style={{ whiteSpace: 'pre-wrap' }}>{homeworkFeedback?.content}</p>
                <Row>
                  <span>{`${t('content.Feedback by')} ${
                    homeworkFeedback.feedbackBy?.name + ' ' + homeworkFeedback.feedbackBy?.surname
                  } ${t('content.at')} ${
                    homeworkFeedback?.feedbackAt
                      ? DateTime.fromISO(homeworkFeedback?.feedbackAt).toLocaleString(DateTime.DATETIME_SHORT)
                      : ''
                  }`}</span>
                </Row>
              </>
            )}
          </>
        )
      }
    } catch (err) {
      console.log("COULDN'T RENDER HW")
      console.log(err)
      return <div></div>
    }
  }

  const getPolls = (currentStep, pollProgress) => {
    try {
      const polls =
        currentStep.polls[selectedLanguage.key] && currentStep.polls[selectedLanguage.key].length > 0
          ? currentStep.polls[selectedLanguage.key]
          : currentStep.polls[currentStep.defaultLanguage]

      if (polls && polls.length > 0 && Array.isArray(polls) && Object.keys(polls[0]).length > 0 && polls[0].question) {
        var submittedTime = null
        var submittedPolls = null
        if (pollProgress && pollProgress.polls && pollProgress.polls.polls) {
          submittedTime = moment.utc(pollProgress.polls.createdAt).local().format('LLL')
          submittedPolls = pollProgress.polls.polls.submittedOptions
        }

        var choices = polls[0].options.filter(function (o) {
          return o.isCorrect === true
        })

        return (
          <>
            <div className="site-layout-content">
              <br />
              <br />
              <h3 className="green">
                <b>{t('content.Quiz')}: </b>
              </h3>
            </div>

            {submittedTime ? (
              <div>
                <h4>{pollProgress.polls.polls.question[selectedLanguage.key].question}</h4>
                <Radio.Group buttonStyle="solid">
                  {submittedPolls &&
                    submittedPolls.length > 0 &&
                    submittedPolls.map((index) => (
                      <>
                        <Radio.Button
                          key={'radio-button-poll' + index}
                          className={polls[0].options[index].isCorrect ? '' : 'wrong-answer'}
                        >
                          {polls[0].options[index].title}
                        </Radio.Button>
                      </>
                    ))}
                </Radio.Group>
                <br />
                {t('content.Submitted at')}: {submittedTime}
              </div>
            ) : (
              <>
                {polls.map((poll, index) => {
                  return (
                    <>
                      <h4>{poll.question}</h4>

                      <Checkbox.Group
                        className={`poll-question-${index}`}
                        onChange={(e) => captureResponse(e, index)}
                        value={pollResponse[0]}
                      >
                        {poll &&
                          poll.options &&
                          poll.options.map((option, j) => (
                            <Checkbox className={`poll-option-${j}`} value={option.title}>
                              {option.title}
                            </Checkbox>
                          ))}
                      </Checkbox.Group>
                    </>
                  )
                })}
                {choices.length > 1 ? (
                  <p style={{ fontStyle: 'italic' }}>{t('content.Select multiple answers to submit')}</p>
                ) : null}
                <div style={{ marginTop: '15px' }}>
                  <Button onClick={() => evaluatePoll(currentStep.polls)} type="primary" htmlType="submit">
                    {t('buttons.Submit')}
                  </Button>
                </div>
              </>
            )}
          </>
        )
      }
    } catch (err) {
      return <div></div>
    }
  }

  const evaluatePoll = (polls) => {
    let answerPool = true
    if (!polls[selectedLanguage.key] || polls[selectedLanguage.key].length === 0) {
      return false
    }

    if (pollResponse.length === 0 || pollResponse[0].length === 0) {
      openNotification('warning', t('content.Oops!'), t('content.Please select the options'))
      return false
    }

    var stepPolls = polls[selectedLanguage.key][0] //only 1 polls now for each step.

    //polls.map((poll, index) => {
    const correctAnswer = stepPolls.options.filter((option) => option.isCorrect)

    // Check multiple answer submission
    if (typeof correctAnswer != 'undefined' && correctAnswer.length > 1) {
      if (correctAnswer.length > pollResponse[0].length) {
        openNotification('warning', t('content.Oops!'), t('content.Please select multiple answers'))
        return false
      }
    }

    var optionSubmitted = []
    let submittedOptionsIndices = []
    pollResponse[0].map((value) => {
      const check = stepPolls.options.find((option) => option.title === value)
      if (check) {
        optionSubmitted.push(check)
        submittedOptionsIndices.push(stepPolls.options.indexOf(check))
      }
    })
    const checkSubmitted = optionSubmitted.filter((option) => !option.isCorrect)

    // Show selected wrong options
    if (checkSubmitted && checkSubmitted.length > 0) {
      answerPool = false
      var result = []
      var newResult = []
      const pollCheckboxEle = document.getElementsByClassName(`poll-question-${0}`)
      stepPolls.options.map((value, index) => {
        if (!value.isCorrect) {
          if (pollResponse[0].length === 1 && pollResponse[0].includes(value.title)) {
            const selectedAnsEle = pollCheckboxEle[0].getElementsByClassName(
              `ant-checkbox-wrapper-checked poll-option-${index}`,
            )
            if (typeof selectedAnsEle[0] != 'undefined') {
              selectedAnsEle[0].classList.add('wrong-answer')
            }
          }
        } else {
          if (pollResponse[0].length > 1 && pollResponse[0].includes(value.title)) {
            result.push(value.title)
          }
        }
      })
      if (pollResponse[0].length > 1 && currentPollAtmpt < stepPolls.attempts) {
        newResult[0] = result
        setPollResponse(newResult)
      }
    }

    //})

    if (stepPolls.attempts === 0 && answerPool === false) {
      //If Infinite attempt allowed to retry
      openNotification('warning', t('content.Incorrect answer!'), t('content.Please try again'))
      setCurrentPollAtmpt(currentPollAtmpt + 1)
      return false
    }
    if (currentPollAtmpt < stepPolls.attempts && answerPool === false) {
      //give another chance
      openNotification(
        'warning',
        t('content.Incorrect answer!'),
        stepPolls.attempts -
          currentPollAtmpt +
          '  ' +
          t('content.Attempt Remaining') +
          '. ' +
          t('content.Please try again'),
      )
      setCurrentPollAtmpt(currentPollAtmpt + 1)
      return false
    }

    let submittedQuestion = {}
    Object.keys(polls).forEach((language) => {
      if (polls[language].length > 0) {
        submittedQuestion[language] = {}
        submittedQuestion[language].question = polls[language][0].question
      }
    })
    var request = {
      stepId: selectedStepId,
      polls: {
        question: submittedQuestion,
        submittedOptions: submittedOptionsIndices,
      },
      attempt: currentPollAtmpt,
    }

    dispatch(savePolls(request))
    dispatch(getStepsProgress(selectedStepId))
  }

  const loadAttachments = (embeds) => {
    console.log('LOAD ATTACHMENTS', embeds)
    let response = []
    if (typeof embeds.audio != 'undefined' && embeds.audio !== null) {
      embeds.audio[selectedLanguage.key]?.map((audio, index) =>
        response.push(
          <div style={{ paddingBottom: '10px' }}>
            <LinkOutlined />{' '}
            <a href="#/" onClick={() => downloadAttachments(audio.filePath)}>
              {audio?.originalName || audio?.name}
            </a>{' '}
          </div>,
        ),
      )
    }

    if (typeof embeds.video != 'undefined' && embeds.video !== null) {
      embeds.video[selectedLanguage.key]?.map((video, index) =>
        response.push(
          <div style={{ paddingBottom: '10px' }}>
            <LinkOutlined />{' '}
            <a href="#/" onClick={() => downloadAttachments(video.filePath)}>
              {video?.originalName || video?.name}
            </a>{' '}
          </div>,
        ),
      )
    }

    if (typeof embeds.image != 'undefined' && embeds.image !== null) {
      embeds.image[selectedLanguage.key]?.map((image, index) =>
        response.push(
          <div style={{ paddingBottom: '10px' }}>
            <LinkOutlined />{' '}
            <a href="#/" onClick={() => downloadAttachments(image.filePath)}>
              {image?.originalName || image?.name}
            </a>
          </div>,
        ),
      )
    }

    if (typeof embeds.docs != 'undefined' && embeds.docs !== null) {
      embeds.docs[selectedLanguage.key]?.map((doc, index) => {
        const openType = embeds?.configs?.docs?.[selectedLanguage.key]?.[doc._id]?.openType
        const isPdf = doc.contentType?.includes('pdf')
        response.push(
          <div style={{ paddingBottom: '10px' }}>
            <LinkOutlined />{' '}
            <a
              href="#/"
              onClick={() =>
                isPdf && openType !== 'NEWTAB'
                  ? downloadFile(doc.filePath, doc.name)
                  : downloadAttachments(doc.filePath)
              }
            >
              {doc?.originalName || doc?.name}
            </a>
          </div>,
        )
      })
    }

    return response
  }

  const loadEmbedCode = (code) => {
    if (code) {
      return <p className="ql-indent" dangerouslySetInnerHTML={{ __html: code[selectedLanguage.key] }} />
    }
  }

  const getSelectedModuleContent = (moduleIndex, stepIndex) => {
    const currentModule = courseDetail.modules[moduleIndex]

    if (currentModule) {
      const currentStep = currentModule.steps[stepIndex]

      return (
        <>
          {isLoading ? (
            <>
              <br />
              <br />
              <br />
              <PageLoader />
            </>
          ) : null}

          {currentStep ? (
            <div className={`${isLoading ? 'hidden' : 'shown'}`}>
              <div className="site-layout-content">
                <h2 className="content-title green">
                  {` ${t('content.Module')} ${moduleIndex + 1} - ${
                    currentStep &&
                    currentStep.translations &&
                    currentStep.translations[selectedLanguage.key] !== undefined
                      ? currentStep.translations[selectedLanguage.key].name
                      : currentStep.name
                  }`}{' '}
                </h2>
              </div>

              {currentStep.stepImage && <img src={Service.getImage(currentStep.stepImage.filePath)} alt="Step"></img>}

              <StepContentBlockViewer
                content={
                  currentStep &&
                  currentStep.translations &&
                  (currentStep.translations[selectedLanguage.key] !== undefined
                    ? currentStep.translations[selectedLanguage.key].description
                    : currentStep.description)
                }
              />

              {loadEmbedCode(currentStep.embedCode)}
              {loadAttachments(currentStep)}

              {!isLoading ? (
                <>
                  <div style={{ marginTop: '20px' }}>{getHomework(currentStep, stepProgress)}</div>

                  <div className="step-polls">{getPolls(currentStep, stepProgress)}</div>
                </>
              ) : null}
            </div>
          ) : null}
        </>
      )
    }
  }
  const [openKeys, setOpenKeys] = React.useState(['module-1', 'module-2'])
  const [selectedKeys, setSelectedKeys] = React.useState(['step-1-1'])
  const loadModuleAndSteps = (modules, progress) => {
    const getSteps = (moduleId, steps, parentIndex) => {
      return translate(steps).map((step, index) => (
        <Menu.Item
          onClick={() => {
            setSelectedModule(parentIndex - 1)
            setSelectedStep(index)
            setSelectedKeys(`step-${parentIndex}-${index + 1}`)
            setSelectedStepId(step._id)
            setTotalStepsUnderCurrentModule(steps.length)
            setHomeworkAttachment(undefined)
            setAttachmentWordCount(null)
            setIsLastModule(parentIndex - 1 === modules.length - 1)
          }}
          className={
            (parentIndex === 1 && index + 1 === 1) ||
            step.access === '0' ||
            (selectedModule === parentIndex - 1 && selectedStep === index) ||
            visitedSteps.indexOf(step._id) >= 0
              ? ''
              : checkUnlockedSteps(moduleId, step._id)
          }
          data-stepid={step._id}
          key={`step-${parentIndex}-${index + 1}`}
          title={`${parentIndex}.${index + 1} ${step.name}`}
        >
          {`${parentIndex}.${index + 1} ${step.name}`}
        </Menu.Item>
      ))
    }

    const checkUnlockedSteps = (m_id, id) => {
      const result = progress.filter((item, index) => {
        if (item.module._id === m_id) {
          return item.unlockedSteps.includes(id)
        }
      })
      return result.length > 0 ? 'completed' : 'incomplete'
    }

    return translate(modules).map((module, index) => (
      <SubMenu
        key={`module-${index + 1}`}
        title={`${index + 1}. ${module.name}`}
        onTitleMouseEnter={(e) => {
          const moduleTitleEl = e.domEvent.target.classList.contains('ant-menu-submenu-title')
            ? e.domEvent.target
            : e.domEvent.target.parentElement
          if (moduleTitleEl) {
            moduleTitleEl.setAttribute('title', `${index + 1}. ${module.name}`)
          }
        }}
      >
        {getSteps(module._id, module.steps, index + 1)}
      </SubMenu>
    ))
  }

  const getModuleProgress = (totalModules) => {
    const steps = []
    for (var i = 0; i < totalModules; i++) {
      steps.push(<Step key={`course-step-${i}`} />)
    }
    return steps
  }

  const expandAll = () => {
    setOpenKeys(courseDetail.modules.map((module, index) => `module-${index + 1}`))
  }

  const collapseAll = () => {
    setOpenKeys([])
  }

  const onOpenChange = (keys) => {
    setOpenKeys(keys)
  }

  const completeModule = () => {
    const currentModule = courseDetail.modules[selectedModule]
    dispatch(finishStep({ stepId: selectedStepId }))
    dispatch(finishModule({ moduleId: currentModule._id, isLastModule }))

    if (isLastModule) {
      setJustMarkedAsComplete(true)
    }

    window.scrollTo(0, 0)

    //Move to next module
    let nxt = selectedModule + 1
    if (
      courseDetail.modules.length &&
      typeof courseDetail.modules[nxt] != 'undefined' &&
      typeof courseDetail.modules[nxt].steps[0] != 'undefined'
    ) {
      setTotalStepsUnderCurrentModule(courseDetail.modules[nxt].steps.length)
      setSelectedModule(nxt)
      setSelectedStep(-1)
      setShowNextModule(new Date())
      setIsLastModule(nxt === courseDetail.modules.length - 1)
    }
  }

  const translate = (data) => {
    try {
      return JSON.parse(JSON.stringify(data)).map((item) => {
        if (item.translations && item.translations.hasOwnProperty(selectedLanguage.key)) {
          item.name = item.translations[selectedLanguage.key].name
          item.description = item.translations[selectedLanguage.key].description
        }
        return item
      })
    } catch (err) {
      return []
    }
  }

  const completedModules = {}
  courseDetail.moduleProgress.forEach((cm) => {
    if (cm.status === 1) {
      completedModules[cm.module._id] = cm
    }
  })
  const lastModule = courseDetail.modules.length > 0 && courseDetail.modules[courseDetail.modules.length - 1]
  const lastStep = lastModule && lastModule.steps.length > 0 && lastModule.steps[lastModule.steps.length - 1]
  const completedCourse =
    lastModule &&
    lastStep &&
    completedModules[lastModule._id] &&
    completedModules[lastModule._id].completedSteps.includes(lastStep._id)

  return (
    <>
      {courseDetail.modules && courseDetail.modules.length === 0 ? (
        <NoDataFound message={t('errors.There are no modules yet')} />
      ) : (
        <>
          <Layout className="learnModule">
            <Sider collapsible collapsed={collapsed} trigger={null} width={280}>
              <Menu
                theme="light"
                openKeys={openKeys}
                selectedKeys={selectedKeys}
                onOpenChange={onOpenChange}
                mode="inline"
              >
                {loadModuleAndSteps(courseDetail.modules, courseDetail.moduleProgress)}
              </Menu>

              {collapsed ? null : (
                <div className="collapsed-btns">
                  <Button
                    style={{ margin: '14px 0px' }}
                    ghost={true}
                    className="transparentButton"
                    htmlType="button"
                    onClick={expandAll}
                  >
                    {t('buttons.Expand All')}
                  </Button>
                  &nbsp;
                  <Button ghost={true} className="transparentButton" htmlType="button" onClick={collapseAll}>
                    {t('buttons.Collapse All')}
                  </Button>
                </div>
              )}
            </Sider>

            <Layout className="site-layout">
              <Sider
                width={32}
                style={{
                  background: '#eaf2ef',
                  padding: 0,
                  textAlign: 'center',
                  paddingTop: '15px',
                }}
              >
                {collapsed ? (
                  <MenuOutlined className="trigger" onClick={onCollapse} />
                ) : (
                  <LeftOutlined className="trigger" onClick={onCollapse} />
                )}
              </Sider>
              <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background course-view-steps">
                  <Steps size="small" current={selectedModule} direction="horizontal">
                    {getModuleProgress(courseDetail.modules.length)}
                  </Steps>

                  <div className="steps-content">{getSelectedModuleContent(selectedModule, selectedStep)}</div>

                  {isLoading ? (
                    <>
                      <br />
                      <br />
                    </>
                  ) : (
                    <>
                      <br />
                      <Divider />
                      <div className="steps-action" style={{ marginTop: '10px' }}>
                        <Row>
                          <Col span={12} xs={10}>
                            {selectedModule === 0 && selectedStep === 0 ? (
                              ''
                            ) : (
                              <Button onClick={() => prev()}>
                                <LeftOutlined /> {t('buttons.Previous')} &nbsp;
                              </Button>
                            )}
                          </Col>
                          <Col span={12} xs={14} className="nextBtn-col">
                            {selectedStep < totalStepsUnderCurrentModule - 1 && (
                              <Button disabled={nextButtonStatus()} type="primary" onClick={() => next()}>
                                &nbsp; {t('buttons.Next')} <RightOutlined />
                              </Button>
                            )}

                            {selectedStep === totalStepsUnderCurrentModule - 1 &&
                              (!isLastModule || justMakedAsComplete || (isLastModule && !completedCourse)) && (
                                <Button
                                  disabled={justMakedAsComplete || nextButtonStatus()}
                                  type="primary"
                                  onClick={completeModule}
                                >
                                  &nbsp; {t(isLastModule ? 'buttons.Complete course' : 'buttons.Complete Module')}
                                  <CheckOutlined />
                                </Button>
                              )}
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                </div>
              </Content>
            </Layout>
          </Layout>
        </>
      )}
    </>
  )
}

export default CourseLearn
