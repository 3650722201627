import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Layout, Avatar, Form, Input, Button, Row, Col, Radio, Divider, Popconfirm } from 'antd'
import { GetInitial, openNotification } from '../../common/components/Helper'
import { Service, Storage } from '../../../services/Service'
import Config from './../../../config.json'
import PasswordFields from '../../common/PasswordFields'

const EditProfile = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const { Content } = Layout
  const [form] = Form.useForm()
  const [resetform] = Form.useForm()

  const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 24 },
  }

  const validateMessages = {
    required: '${label} ' + t('errors.is required!'),
    types: {
      email: '${label} ' + t('errors.is not a valid email!'),
      number: '${label} ' + t('errors.is not a valid number!'),
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  const onFinish = (values) => {
    const userInfo = Storage.get('auth')
    var params = {
      name: values.name,
      surname: values.surname,
      gender: values.gender,
    }

    setLoading(true)
    Service.patch({
      url: '/user/' + userInfo._id,
      body: JSON.stringify(params),
    })
      .then((response) => {
        setLoading(false)
        if (response.status === 'error') {
          openNotification('error', t('content.Oops!'), t('errors.' + response.message + ''))
        } else {
          openNotification('success', t('content.Updated!'), t('content.Profile Updated successfully'))
          const profile = {
            ...userInfo,
            ...params,
          }
          Storage.set('auth', profile)
        }
      })
      .catch((err) => {
        setLoading(false)
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
  }

  const onResetFinish = (values) => {
    var params = {
      oldPassword: values.currentPassword,
      newPassword: values.password,
    }

    setSubmitting(true)
    Service.patch({
      url: '/user/password',
      body: JSON.stringify(params),
    })
      .then((response) => {
        setSubmitting(false)
        if (response.status === 'error') {
          openNotification('error', t('content.Oops!'), t('errors.' + response.message + ''))
        } else {
          openNotification('success', t('content.Updated!'), t('content.Password changed successfully'))
          resetform.resetFields()
        }
      })
      .catch((err) => {
        setSubmitting(false)
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
  }

  const accountDeactivate = (values) => {
    setSubmitting(true)
    Service.post({
      url: '/user/deactivate',
      body: JSON.stringify({ deactivate: 'yes' }),
    })
      .then((response) => {
        setSubmitting(true)
        localStorage.clear()
        window.location = Config.SITE_URL
      })
      .catch((err) => {
        setSubmitting(false)
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
  }

  const userInfo = Storage.get('auth')

  return (
    <>
      <Layout className="layout bgWhite">
        <Content
          className="ipso-cont ent"
          style={{ backgroundColor: '#f0f2f5', minHeight: '100px', paddingBottom: '0px' }}
        >
          <div className="container profileDetails">
            <div className="site-layout-content">
              <Row>
                <Col span={2} lg={2} md={3} sm={5} xs={6}>
                  <div className="profile-avatar">
                    <Avatar
                      style={{
                        backgroundColor: userInfo.color,
                        color: '#595959',
                        width: '80px',
                        height: '80px',
                        lineHeight: '80px',
                      }}
                    >
                      <GetInitial text={userInfo.name} />
                    </Avatar>
                  </div>
                </Col>
                <Col span={22} lg={22} md={21} sm={19} xs={18}>
                  <div className="profile-title" style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                    <h1 className="page-title">{userInfo.name + ' ' + userInfo.surname}</h1>
                    <p>{userInfo.email}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Content>
        <div className="container personal-info">
          <Row justify="space-between">
            <Col className="gutter-row" lg={{ span: 10 }} md={{ span: 10 }} sm={{ span: 10 }} xs={{ span: 24 }}>
              <div className="site-layout-content">
                <h2 className="content-title green">{t('content.Personal Information')}</h2>
              </div>
              <div className="">
                <Form
                  {...layout}
                  form={form}
                  layout="vertical"
                  name="nest-messages"
                  onFinish={onFinish}
                  initialValues={userInfo}
                  validateMessages={validateMessages}
                >
                  <Form.Item name={['name']} label={t('auth.Given Name')} rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name={['surname']} label={t('auth.Family name')} rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name={['gender']}>
                    <Radio.Group>
                      <Radio value="male">{t('content.Male')}</Radio>
                      <Radio value="female">{t('content.Female')}</Radio>
                      <Radio value="diverse">{t('content.Diverse')}</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item name={['email']} label={t('auth.Email')} rules={[{ type: 'email' }]}>
                    <Input readOnly={true} />
                  </Form.Item>
                  <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      {t('buttons.Save')}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
            <Col className="gutter-row" lg={{ span: 10 }} md={{ span: 10 }} sm={{ span: 10 }} xs={{ span: 24 }}>
              <div className="site-layout-content">
                <h2 className="content-title green">{t('content.Change Password')}</h2>
              </div>
              <div className="passwordReset">
                <Form
                  form={resetform}
                  layout="vertical"
                  name="normal_login"
                  className="login-form"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onResetFinish}
                >
                  <Form.Item
                    name={['currentPassword']}
                    label={t('auth.Current Password')}
                    rules={[{ required: true, message: t('errors.Please enter current password') }]}
                  >
                    <Input.Password placeholder={t('auth.Current Password')} className="theme-input" maxLength={50} />
                  </Form.Item>

                  <PasswordFields
                    passwordLabel={t('auth.New Password')}
                    confirmPasswordLabel={t('auth.Confirm New Password')}
                  />

                  <Form.Item>
                    <Button type="primary" htmlType="submit" loading={submitting}>
                      {t('buttons.Save')}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
          <Divider plain> </Divider> <br />
          <Popconfirm
            title={t('content.Are you sure to delete your profile? This action cannot be undo!')}
            onConfirm={accountDeactivate}
            okText={t('content.Yes, I understand the risk, Delete it!')}
            okButtonProps={{ type: 'default' }}
            cancelText={t("content.No, Don't delete it now")}
            cancelButtonProps={{ type: 'primary' }}
          >
            <Button type="dashed" danger style={{ float: 'right' }}>
              {t('content.Delete my profile')}
            </Button>
          </Popconfirm>
        </div>
      </Layout>
    </>
  )
}

export default EditProfile
