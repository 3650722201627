const lng = {
  en: {
    name: 'English',
  },
  de: {
    name: 'German',
  },
  ar: {
    name: 'Arabic',
  },
}

export const languageInfo = (code) => {
  return typeof lng[code] != 'undefined' ? lng[code] : {}
}

export const otherLanguages = () => {
  return lng
}
