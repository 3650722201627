import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input, Popover } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

const PasswordFields = (props) => {
  const { t } = useTranslation()
  const [passwordPopover, setPasswordPopover] = useState(false)
  const [password, setPassword] = useState('')

  return (
    <>
      <Popover
        content={<PasswordPopup value={password} />}
        trigger="click"
        placement="rightTop"
        visible={passwordPopover}
      >
        <Form.Item
          label={props.passwordLabel}
          name="password"
          required={false}
          rules={[
            {
              required: true,
              message: ' ',
            },
            {
              message: ' ',
              min: 8,
            },
            {
              message: ' ',
              pattern: new RegExp('[A-Z]'),
            },
          ]}
        >
          <Input.Password
            size={'large'}
            placeholder={props.passwordLabel}
            onFocus={(f) => setPasswordPopover(true)}
            onBlur={(f) => setPasswordPopover(false)}
            onKeyUp={(e) => setPassword(e.target.value)}
            className="theme-input"
            maxLength={50}
          />
        </Form.Item>
      </Popover>

      <Form.Item
        required={false}
        label={props.confirmPasswordLabel}
        name="confirmPassword"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: t('errors.Please enter confirm password'),
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(t('errors.Password does not match'))
            },
          }),
        ]}
      >
        <Input
          size={'large'}
          type="password"
          placeholder={props.confirmPasswordLabel}
          className="theme-input"
          maxLength={50}
        />
      </Form.Item>
    </>
  )
}

const PasswordPopup = ({ value }) => {
  const { t } = useTranslation()

  return (
    <div>
      <p>{t('errors.Your password must be atleast')}</p>
      <p>
        {' '}
        {new RegExp('([a-zA-Z0-9_!@./#&$%^*?+-.=]){8,}').test(value) && value.trim().length > 7 ? (
          <CheckOutlined style={{ color: 'green' }} />
        ) : (
          <CloseOutlined style={{ color: 'red' }} />
        )}{' '}
        {t('errors.minimum 8 characters')}
      </p>
      <p>
        {' '}
        {new RegExp('[A-Z]').test(value) ? (
          <CheckOutlined style={{ color: 'green' }} />
        ) : (
          <CloseOutlined style={{ color: 'red' }} />
        )}{' '}
        {t('errors.1 capital letter')}
      </p>
      <p>
        {' '}
        {new RegExp('[!@#?$%^&*)(+=._-]').test(value) ? (
          <CheckOutlined style={{ color: 'green' }} />
        ) : (
          <CloseOutlined style={{ color: 'red' }} />
        )}{' '}
        {t('errors.punctuation-mark')}
      </p>
    </div>
  )
}
export default PasswordFields
