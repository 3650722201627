import { LinkOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Spin } from 'antd'
import { useState } from 'react'
import { downloadFile } from '../../utils/file.util'

const FileDownloadLink = ({ name, filePath, showDeleteButton, onDelete, rightContent }) => {
  const [fetchingFile, setFetchingFile] = useState(false)

  const onLinkClick = (event) => {
    event.preventDefault()
    if (!fetchingFile) {
      setFetchingFile(true)
      downloadFile(filePath, name, () => {
        setFetchingFile(false)
      })
    }
  }

  const onAttachmentDelete = () => {
    if (typeof onDelete === 'function') {
      onDelete()
    }
  }

  return (
    <div className="file-download-link">
      <LinkOutlined />
      <a href="#/" onClick={onLinkClick}>
        {name}
      </a>
      {rightContent}
      {showDeleteButton && <Button type="text" icon={<CloseOutlined />} size="small" onClick={onAttachmentDelete} />}
      {fetchingFile && (
        <div className="fetching-indicator">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      )}
    </div>
  )
}

export default FileDownloadLink
