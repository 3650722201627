import React, { useEffect } from 'react'
import { Menu, Button, Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import i18n from 'i18next'

import { onLanguageChange } from './../../redux/actions/language'
import { onLanguageChangeBeforeLogin } from './../../redux/actions/language'

const LanguageDropdown = (props) => {
  const { selectedLanguage, allLanguages } = useSelector(({ languageReducer }) => languageReducer)
  const dispatch = useDispatch()
  const userId = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth'))._id : null

  const handleMenuClick = (e) => {
    i18n.changeLanguage(allLanguages[e.key].key)
    dispatch(onLanguageChange(userId, allLanguages[e.key]))

    document.body.classList.remove('ltr')
    document.body.classList.remove('rtl')
    document.body.classList.add(allLanguages[e.key].direction)
  }
  const handleMenuBeforeLogin = (e) => {
    i18n.changeLanguage(allLanguages[e.key].key)
    dispatch(onLanguageChangeBeforeLogin(allLanguages[e.key]))
  }

  useEffect(() => {
    if (props.initialValue) {
      const initLanguage = allLanguages.find((lng) => lng.key === props.initialValue)
      dispatch(onLanguageChangeBeforeLogin(initLanguage))
      //i18n.changeLanguage(props.initialValue);
    }
  }, [])

  const menu = (
    <Menu onClick={userId ? handleMenuClick : handleMenuBeforeLogin}>
      {allLanguages.map((lang, index) => (
        <Menu.Item key={index}>{lang.label}</Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menu}>
      <Button>
        {selectedLanguage.label} <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export default LanguageDropdown
