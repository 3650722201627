import React from 'react'
import { Row, Col, Image } from 'antd'
import logo from '../../asset/images/logo.svg'

const AuthLayout = (props) => {
  return (
    <div className="auth-page-container">
      <Row type="flex" justify="center" align="middle" style={{ marginBottom: '20px' }}>
        <Col span={8} xs={20} sm={16} md={15} lg={8} xl={8}>
          <div className="img" style={{ textAlign: 'center' }}>
            <Image className="form-logo" src={logo} preview={false} />
          </div>
        </Col>
      </Row>
      {props.children}
    </div>
  )
}

export default AuthLayout
