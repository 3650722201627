import React from 'react'
import { connect } from 'react-redux'
import { Button, Form, Input, Select, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'

import Config from '../../../config.json'
import { Service } from '../../../services/Service'
import { openNotification } from '../../common/components/Helper'

const { Item } = Form

class CourseMessageForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      usingFiles: [],
      unusedFiles: [],
    }

    this.formRef = React.createRef()
  }

  async componentWillUnmount() {
    const { unusedFiles, usingFiles } = this.state

    let usingFilesConverted = usingFiles.map((f) => ({
      ...f.response,
      name: f.name,
    }))
    const toRemoveFiles = [...usingFilesConverted, unusedFiles]

    if (toRemoveFiles.length) {
      await Service.delete({
        url: '/fileUpload',
        body: JSON.stringify(toRemoveFiles),
      })
    }
  }

  onFileUploadRemove = (removedFile) => {
    this.setState({
      unusedFiles: [...this.state.unusedFiles, removedFile.response],
    })
  }

  onChangeFileUpload = ({ fileList: newFileList }) => {
    this.setState({
      usingFiles: [...newFileList],
    })
  }

  onSubmitForm = (defaultModule) => {
    const { t, course } = this.props
    const { unusedFiles, usingFiles } = this.state
    const { subject, body } = this.formRef.current?.getFieldsValue()
    let params = {
      subject,
      messageContent: body.replaceAll('\n', '<br>'),
      attachments: usingFiles.map((f) => ({ ...f.response, name: f.name })),
      unusedFiles,
      courseId: course._id,
    }

    this.setState({ sending: true }, () => {
      Service.post({
        url: '/academy/course/message/',
        body: JSON.stringify(params),
      })
        .then(() => {
          this.setState({ sending: false, unusedFiles: [], usingFiles: [] }, () => {
            this.formRef.current?.setFieldsValue({
              subject: '',
              body: '',
            })
            openNotification('success', t('content.Success'), t('content.Send Message Successfully!'))
          })
        })
        .catch(() => {
          this.setState({ sending: false })
          openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
        })
    })
  }

  render() {
    const { t } = this.props

    const validateMessages = {
      required: '${label} ' + t('errors.is required!'),
    }

    const { usingFiles } = this.state

    return (
      <Form
        name="courseMessageForm"
        layout="vertical"
        onFinish={() => {
          this.onSubmitForm()
        }}
        className="course-message-form"
        validateMessages={validateMessages}
        ref={this.formRef}
        initialValues={{}}
      >
        <h1 className="page-title" style={{ marginBottom: 18 }}>
          {t('content.Mailing to all students')}
        </h1>
        <Item name="subject" label={t('content.Message subject')} rules={[{ required: true }]}>
          <Input placeholder={t('content.Write here a subject')} />
        </Item>
        <Item name="body" label={t('content.Message body')} rules={[{ required: true }]}>
          <Input.TextArea placeholder={t('content.Write here a message to students')} rows={5} />
        </Item>
        <div className="form-footer">
          <Item name={['attachment']}>
            <Upload
              action={`${Config.BASE_URL}/fileUpload `}
              onChange={this.onChangeFileUpload}
              onRemove={this.onFileUploadRemove}
              fileList={usingFiles}
              multiple={true}
              headers={{
                'x-access-token': localStorage.getItem('token'),
              }}
              className="message-attactment-upload"
            >
              <Button icon={<UploadOutlined />}>{t('content.Upload Attachment')}</Button>
            </Upload>
          </Item>
          <Item>
            <Button type="primary" htmlType="submit">
              {t('content.Send now')}
            </Button>
          </Item>
        </div>
      </Form>
    )
  }
}

export default connect((state) => {
  return { selectedLanguage: state.languageReducer.selectedLanguage }
})(withTranslation()(CourseMessageForm))
