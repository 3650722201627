import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import Config from '../../../config.json'
import { SocketContext } from '../../context/socket'
import { Storage } from '../../../services/Service'
import { PhoneOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { setCallingUser } from '../../../redux/actions/buddyCall'

const BuddyCallBtn = ({ user }) => {
  const userInfo = Storage.get('auth')
  const { t } = useTranslation()
  const socket = useContext(SocketContext)
  const [loading, setLoading] = useState(false)
  const [calltimer, setCallTimer] = useState(false)
  const dispatch = useDispatch()

  const initiateCall = (userinfo) => {
    //console.log(userinfo)
    console.log('INITIATING CALL')
    dispatch(setCallingUser(userinfo))
    socket.emit('initiateCall', JSON.stringify({ target: userinfo._id }))
    setLoading(true)

    if (calltimer) {
      clearTimeout(calltimer)
      setCallTimer(false)
    }

    var tmptimer = setTimeout(() => {
      setLoading(false)
    }, Config.CALL_RING_TIME * 1000)
    setCallTimer(tmptimer)
    return () => clearTimeout(calltimer)
  }

  useEffect(() => {
    socket.on('userNotFoundError', () => {
      setLoading(false)
      clearTimeout(calltimer)
      dispatch(setCallingUser(null))
    })

    socket.on('meetingRequestRejected', () => {
      setLoading(false)
      clearTimeout(calltimer)
      dispatch(setCallingUser(null))
    })
  }, [])

  return (
    <>
      {userInfo._id != user._id ? (
        <div className="callButton">
          <Button
            className={`${!user.isOnline || user.dnd === 1 ? 'not-available' : ''}`}
            loading={loading}
            shape="round"
            type="secondary"
            htmlType="button"
            onClick={() => initiateCall(user)}
          >
            <PhoneOutlined rotate={90} />
            {t('content.Call')}
          </Button>
        </div>
      ) : null}
    </>
  )
}

export default BuddyCallBtn
