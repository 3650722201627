import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { ConfigProvider, Result, Button } from 'antd'
import './App.css'
import './App.scss'

import Login from './app/auth/Login'
import Signup from './app/auth/Signup'
import ForgotPassword from './app/auth/ForgotPassword'
import Linksend from './app/auth/Linksend'

import PermissionDenied from './app/common/PermissionDenied'

import Dashboard from './app/user/Dashboard'
import AcademyDetail from './app/user/AcademyDetail'
import AcademyCourseDetail from './app/user/AcademyCourseDetail'
import MyCourse from './app/user/MyCourse'
import AcceptAccess from './app/user/AcceptAccess'
import Settings from './app/common/components/EditProfile'
import ChimeIntegration from './app/user/ChimeIntegration'
import BuddyCall from './app/user/BuddyCall'

import AdminDashboard from './app/admin/dashboard/Dashboard'
import Users from './app/admin/Users'
import AdminCoursesList from './app/admin/AdminCoursesList'
import AdminCourseDetail from './app/admin/AdminCourseDetail'
import AdminGroups from './app/admin/AdminGroups'
import AdminGroupUsers from './app/admin/AdminGroupUsers'
import AdminPreviewStep from './app/admin/components/AdminPreviewStep'
import AdminSettings from './app/common/components/EditProfile'

import Terms from './app/common/Terms'
import PrivacyPolicy from './app/common/PrivacyPolicy'
import ImprintDisclaimer from './app/common/ImprintDisclaimer'

import NotFound from './app/common/NotFound'
import ErrorFallback from './app/common/ErrorFallback'
import ResetPassword from './app/auth/ResetPassword'
import enJson from '../src/language/en.json'
import arJson from '../src/language/ar.json'
import deJson from '../src/language/de.json'
import frJson from '../src/language/fr.json'
import faJson from '../src/language/fa.json'
import { Storage, Service } from './services/Service'
import { SocketContext, socket } from './app/context/socket'

import PageHeader from './app/common/PageHeader'

import { ErrorBoundary } from 'react-error-boundary'
import { useMatomo } from '@datapunt/matomo-tracker-react'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: enJson,
      ar: arJson,
      de: deJson,
      fr: frJson,
      fa: faJson,
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

const App = () => {
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)
  const { enableLinkTracking, trackPageView } = useMatomo()

  enableLinkTracking()
  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      const authString = localStorage.getItem('auth') ?? 'undefined'
      const logedinUser = authString === 'undefined' ? null : JSON.parse(authString)
      Service.get({
        url: '/user/account',
      })
        .then((response) => {
          if (response.status === 'error' || response.error) {
            return <Redirect to="/login" />
          }
          Storage.set('auth', { ...response.user, language: logedinUser?.language || response?.user?.language })
          Storage.setString('dnd', response.user.dnd)
        })
        .catch((err) => {})
    }
    i18n.changeLanguage(selectedLanguage.key)
    trackPageView()
  }, [])

  function ErrorFallbackTrigger({ error, resetErrorBoundary }) {
    return <ErrorFallback resetErrorBoundary={resetErrorBoundary} />
  }

  return (
    <ConfigProvider direction={selectedLanguage.direction}>
      <Switch>
        <Route path="/terms" component={Terms} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/imprint-attribution-disclaimer" component={ImprintDisclaimer} />
        <Route path="/permissionDenied" component={PermissionDenied} />

        {/* // common Routes */}
        <PublicPrivateRoute path="/login" exact>
          <Route component={Login} />
        </PublicPrivateRoute>
        <PublicPrivateRoute path="/signUp/:token">
          <Route component={Signup} />
        </PublicPrivateRoute>
        <PublicPrivateRoute path="/forgot-password">
          <Route component={ForgotPassword} />
        </PublicPrivateRoute>
        <PublicPrivateRoute path="/forgot-password-success">
          <Route component={Linksend} />
        </PublicPrivateRoute>
        <PublicPrivateRoute path="/reset/:token">
          <Route component={ResetPassword} />
        </PublicPrivateRoute>

        <SocketContext.Provider value={socket}>
          {/* Any rendering errors in our components hierarchy can then be gracefully handled. */}
          <ErrorBoundary FallbackComponent={ErrorFallbackTrigger} resetErrorBoundary={() => <Redirect to="/login" />}>
            {/* Page header */}
            <PageHeader />

            {/* // Student Routes */}
            <PrivateRoute path="/" exact>
              <Route component={Dashboard} />
            </PrivateRoute>
            <PrivateRoute path="/dashboard" exact>
              <Route component={Dashboard} />
            </PrivateRoute>
            <PrivateRoute path="/academy-detail/:id">
              <Route component={AcademyDetail} />
            </PrivateRoute>
            <PrivateRoute path="/academy-course-detail/:id">
              <Route component={AcademyCourseDetail} />
            </PrivateRoute>
            <PrivateRoute path="/my-courses">
              <Route component={MyCourse} />
            </PrivateRoute>
            <PrivateRoute path="/user/acceptAccess/:id">
              <Route component={AcceptAccess} />
            </PrivateRoute>
            <PrivateRoute path="/settings">
              <Route component={Settings} />
            </PrivateRoute>
            <PrivateRoute path="/classroom/:meetingId">
              <Route component={ChimeIntegration} />
            </PrivateRoute>
            <PrivateRoute path="/buddycall/:meetingId">
              <Route component={BuddyCall} />
            </PrivateRoute>

            {/* // Admin Routes */}
            <PrivateAdminRoute path="/admin/dashboard" exact>
              <Route component={AdminDashboard} />
            </PrivateAdminRoute>
            <PrivateAdminRoute path="/admin/users" exact>
              <Route component={Users} />
            </PrivateAdminRoute>
            <PrivateAdminRoute path="/admin/courses/:id" exact>
              <Route component={AdminCoursesList} />
            </PrivateAdminRoute>
            <PrivateAdminRoute path="/admin/course-detail/:id">
              <Route component={AdminCourseDetail} />
            </PrivateAdminRoute>
            <PrivateAdminRoute path="/admin/groups">
              <Route component={AdminGroups} />
            </PrivateAdminRoute>
            <PrivateAdminRoute path="/admin/group-users/:id" exact>
              <Route component={AdminGroupUsers} />
            </PrivateAdminRoute>
            <PrivateAdminRoute path="/admin/settings">
              <Route component={AdminSettings} />
            </PrivateAdminRoute>
            <PrivateAdminRoute path="/admin/preview-step/:id">
              <Route component={AdminPreviewStep} />
            </PrivateAdminRoute>
          </ErrorBoundary>
        </SocketContext.Provider>

        <Route component={NotFound} />
      </Switch>
    </ConfigProvider>
  )
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        Storage.isLogedin() ? (
          <>{children}</>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

function PrivateAdminRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        Storage.isLogedin() && Storage.isAdmin() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

function PublicPrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !Storage.isLogedin() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default App
