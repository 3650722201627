import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PageLoader, NoDataFound, GetInitial, RandColor, GetRole } from '../../common/components/Helper'
import { Layout, List, Avatar, Row, Col, Select, Form } from 'antd'
import { Service } from '../../../services/Service'
import BuddyCallBtn from '../../common/components/BuddyCallBtn'
import _ from 'lodash'

const { Option } = Select

const AcademyUsers = (props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [academyUsersList, setAcademyUsersList] = useState([])
  const [academyUsersOriginal, setAcademyUsersOriginal] = useState([])
  const [form] = Form.useForm()

  useEffect(() => {
    if (typeof props.academyId == 'undefined') {
      return false
    }
    const fetchAcademyUsers = async () => {
      try {
        setLoading(true)
        Service.get({
          url: '/academy/users/' + props.academyId,
        })
          .then((response) => {
            setLoading(false)
            if (response.hasOwnProperty('error')) {
              return
            }

            var arr = []
            if (response.length > 0) {
              response.forEach(function (academy) {
                const found = arr.some((el) => el.user._id === academy.user._id)
                if (!found) {
                  arr.push(academy)
                }
              })
            }

            if (response.length > 0) {
              setAcademyUsersList(arr)
              setAcademyUsersOriginal(arr)
            }
          })
          .catch((err) => {
            setLoading(false)
          })
      } catch (e) {
        console.log(e)
      }
    }
    fetchAcademyUsers()
  }, [])

  function onChange(value) {
    triggerFilter()
  }

  function onBlur() {
    //console.log('blur');
  }

  function onFocus() {
    //console.log('focus');
  }

  function onSearch(val) {
    //console.log('search:', val);
  }

  const triggerFilter = (data) => {
    var users = academyUsersOriginal
    var frmInput = form.getFieldsValue()
    var newArr = users

    //Filter
    if (typeof frmInput.isOnline != 'undefined') {
      newArr = newArr.filter(function (o) {
        return frmInput.isOnline
          ? o.user.isOnline == frmInput.isOnline && o.user.dnd === 0
          : o.user.isOnline == frmInput.isOnline || o.user.dnd === 1
      })
    }
    if (typeof frmInput.role != 'undefined') {
      newArr = newArr.filter(function (o) {
        return o.user.role === frmInput.role
      })
    }
    if (typeof frmInput.search != 'undefined') {
      newArr = newArr.filter(function (o) {
        return o.user.email === frmInput.search
      })
    }
    if (typeof frmInput.surname != 'undefined') {
      newArr = newArr.filter(function (o) {
        return o.user.surname === frmInput.surname
      })
    }

    //Sorting
    if (typeof frmInput.order != 'undefined') {
      if (frmInput.order === 'nameAsc') {
        newArr = _.orderBy(newArr, ['user.name'], ['asc'])
      } else if (frmInput.order === 'nameDesc') {
        newArr = _.orderBy(newArr, ['user.name'], ['desc'])
      } else if (frmInput.order === 'dateAsc') {
        newArr = _.orderBy(newArr, ['_id'], ['asc'])
      } else if (frmInput.order === 'dateDesc') {
        newArr = _.orderBy(newArr, ['_id'], ['desc'])
      }
    }

    setAcademyUsersList(newArr)
  }

  return (
    <Layout className="layout academyUsersList bgWhite">
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <Form form={form}>
            <Row>
              <Col span={3} xl={3} lg={3} xs={24} sm={24} md={12}>
                <Form.Item name="isOnline">
                  <Select
                    allowClear={true}
                    onChange={triggerFilter}
                    placeholder={t('content.Status')}
                    style={{ width: '100%' }}
                    bordered={false}
                  >
                    <Option value={true}>{t('content.Online')}</Option>
                    <Option value={false}>{t('content.Offline')}</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={3} xl={3} lg={3} xs={24} sm={24} md={12}>
                <Form.Item name="role">
                  <Select
                    allowClear={true}
                    onChange={triggerFilter}
                    placeholder={t('content.Role')}
                    style={{ width: '100%' }}
                    bordered={false}
                  >
                    <Option value="admin">{t('content.Course Leader')}</Option>
                    <Option value="module_trainer">{t('content.Module Trainer')}</Option>
                    <Option value="student">{t('content.Student')}</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={4} xl={4} lg={4} xs={24} sm={24} md={12}>
                <Form.Item name="order">
                  <Select
                    allowClear={true}
                    onChange={triggerFilter}
                    placeholder={t('content.Order By')}
                    style={{ width: '100%' }}
                    bordered={false}
                  >
                    <Option value="nameAsc">{t('content.Name Asc')}</Option>
                    <Option value="nameDesc">{t('content.Name Desc')}</Option>
                    <Option value="dateAsc">{t('content.Date Added Asc')}</Option>
                    <Option value="dateDesc">{t('content.Date Added Desc')}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col
                className="searchField"
                span={4}
                xl={{ span: 4, offset: 1 }}
                lg={{ span: 4, offset: 1 }}
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 1 }}
              >
                <Form.Item name="surname">
                  <Select
                    showSearch
                    style={{ width: '100%', float: 'right' }}
                    placeholder={t('content.Search by family name')}
                    optionFilterProp="children"
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    allowClear={true}
                  >
                    {academyUsersOriginal.map((academy, index) => (
                      <Option key={`key-${index}`} value={academy.user.surname}>
                        {academy.user.surname}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                span={7}
                xl={{ span: 7, offset: 2 }}
                lg={{ span: 7, offset: 2 }}
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 2 }}
              >
                <Form.Item name="search">
                  <Select
                    showSearch
                    style={{ width: '100%', float: 'right' }}
                    placeholder={t('content.Search by email or name')}
                    optionFilterProp="children"
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    allowClear={true}
                  >
                    {academyUsersOriginal.map((academy, index) => (
                      <Option key={`opt-${index}`} value={academy.user.email}>
                        {academy.user.email} ({academy.user.name})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          {!loading && academyUsersList.length === 0 ? <NoDataFound message={t('content.No Data')} /> : null}

          <List itemLayout="horizontal">
            {academyUsersList.map(({ user }, index) => (
              <List.Item key={`user-${index}`}>
                <div className="topProfileMenu">
                  <Avatar style={{ backgroundColor: user.color ? user.color : '' }}>
                    <GetInitial text={user.name} />
                  </Avatar>
                  <span
                    className={`availablityIndicator ${!user.isOnline || user.dnd === 1 ? 'inactive' : 'active'}`}
                  ></span>
                </div>

                <List.Item.Meta
                  title={user.name + ' ' + user.surname}
                  description={t('content.' + GetRole(user.role) + '')}
                  className="metaInfo"
                />

                <BuddyCallBtn user={user} />
              </List.Item>
            ))}
          </List>
        </>
      )}
    </Layout>
  )
}

export default AcademyUsers
