import { SET_COURSE_DETAILS, SET_STEP_PROGRESS } from '../action-creators/courseDetail'
import { notification } from 'antd'
import { Service } from '../../services/Service'
import i18n from 'i18next'

const openNotification = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  })
}

export const getCourseDetails = (courseId, fetching) => {
  return (dispatch) => {
    dispatch({
      type: SET_COURSE_DETAILS,
      payload: null,
      isCourseDetailFetching: typeof fetching != 'undefined' ? fetching : true,
    })

    Service.get({ url: `/academy/course/find/${courseId}` })
      .then((resp) => {
        if (resp) {
          dispatch({
            type: SET_COURSE_DETAILS,
            payload: resp,
            isCourseDetailFetching: false,
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: SET_COURSE_DETAILS,
          payload: null,
          isCourseDetailFetching: false,
        })
      })
  }
}

export const getStepsProgress = (stepId, loader, keepData = false) => {
  return (dispatch) => {
    dispatch({
      type: SET_STEP_PROGRESS,
      payload: undefined,
      isLoading: loader === false ? false : true,
      keepData,
    })

    setTimeout(
      () => {
        Service.get({ url: `/progress/${stepId}` }).then((resp) => {
          if (resp) {
            dispatch({
              type: SET_STEP_PROGRESS,
              payload: resp,
              isLoading: false,
            })
          } else {
            dispatch({
              type: SET_STEP_PROGRESS,
              payload: undefined,
              isLoading: false,
            })
          }
        })
      },
      loader === false ? 0 : 1000,
    )
  }
}

export const saveHomework = (stepId, homework, title, status, attachment, wordCount) => {
  return new Promise((resolve, reject) => {
    Service.post({
      url: '/progress/homework',
      body: JSON.stringify({ stepId, homework, title, status, attachment, wordCount }),
    })
      .then((resp) => {
        if (resp) {
          var text = status === '1' ? 'Submitted!' : 'Saved!'
          openNotification(
            'success',
            i18n.t('content.Success'),
            i18n.t('content.Homework') + ' ' + i18n.t('content.' + text + ''),
          )
          resolve(true)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const savePolls = (request) => {
  return (dispatch) => {
    Service.post({
      url: '/progress/polls',
      body: JSON.stringify(request),
    }).then((resp) => {
      if (resp) {
        openNotification('success', i18n.t('content.Success'), i18n.t('content.Polls Submitted'))
      }
    })
  }
}

export const finishModule = (module) => {
  return (dispatch) => {
    Service.post({
      url: '/progress/completeModule',
      body: JSON.stringify(module),
    }).then((resp) => {
      if (resp) {
        const msgBody = module.isLastModule
          ? 'content.You marked this course as completed'
          : 'content.Successfully completed module'
        openNotification('success', i18n.t('content.Congrats!'), i18n.t(msgBody))
      }
    })
  }
}

export const finishStep = (step) => {
  return (dispatch) => {
    Service.post({
      url: '/progress/completeStep',
      body: JSON.stringify(step),
    }).then((resp) => {
      if (resp) {
        //openNotification("success", "Congrats!", " Successfully completed step");
      }
    })
  }
}
