import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PageLoader, NoDataFound, GetInitial, GetRole } from '../../common/components/Helper'
import BuddyCallBtn from '../../common/components/BuddyCallBtn'
import { Layout, List, Avatar } from 'antd'
// import { useParams } from 'react-router-dom'

const CourseStudents = (props) => {
  const { t } = useTranslation()
  // let { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [courseUsers, setCourseUsers] = useState([])

  useEffect(() => {
    if (props && props.teamMembers) {
      const filteredMembers = props.teamMembers.filter((mem) => mem?.user?.name.indexOf('deleted:') === -1)
      setCourseUsers(filteredMembers)
    }
  }, [props.teamMembers])

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <Layout className="layout academyUsersList bgWhite">
            {courseUsers.length == 0 ? (
              <NoDataFound />
            ) : (
              <>
                <List itemLayout="horizontal">
                  {courseUsers.map(
                    ({ user }) =>
                      user && (
                        <List.Item key={`course-student-${user._id}`}>
                          <div className="topProfileMenu">
                            <Avatar
                              style={{
                                backgroundColor: user.color ? user.color : '',
                              }}
                            >
                              <GetInitial text={user.name} />
                            </Avatar>
                            <span
                              className={`availablityIndicator ${
                                !user.isOnline || user.dnd === 1 ? 'inactive' : 'active'
                              }`}
                            ></span>
                          </div>

                          <List.Item.Meta
                            title={user.name + ' ' + user.surname}
                            description={t('content.' + GetRole(user.role) + '')}
                            className="metaInfo"
                          />

                          <BuddyCallBtn user={user} />
                        </List.Item>
                      ),
                  )}
                </List>
              </>
            )}
          </Layout>
        </>
      )}
    </>
  )
}

export default CourseStudents
