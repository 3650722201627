import React from 'react'
import { useTranslation } from 'react-i18next'
import { Result, Button } from 'antd'
import { Link } from 'react-router-dom'

const PermissionDenied = () => {
  const { t } = useTranslation()

  return (
    <Result
      status="403"
      title="403"
      subTitle={t('Info.You are not authorized')}
      extra={
        <Link to={'/'}>
          <Button type="primary">{t('content.Back Home')}</Button>
        </Link>
      }
    />
  )
}

export default PermissionDenied
