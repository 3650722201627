import { SET_LANGUAGE } from './../action-creators/language'
const auth = JSON.parse(localStorage.getItem('auth'))

const defaultLanguage = {
  key: 'en',
  label: 'EN',
  direction: 'ltr',
}

const allLanguages = [
  {
    key: 'en',
    label: 'EN',
    direction: 'ltr',
  },
  {
    key: 'de',
    label: 'DE',
    direction: 'ltr',
  },
  {
    key: 'ar',
    label: 'عربي',
    direction: 'rtl',
  },
  {
    key: 'fr',
    label: 'FR',
    direction: 'ltr',
  },
  {
    key: 'fa',
    label: 'فارسی',
    direction: 'rtl',
  },
]
let languagesMap = {}
allLanguages.forEach((lang) => {
  languagesMap[lang.key] = lang
})

const initialState = {
  experience: 7,
  selectedLanguage: auth
    ? {
        key: auth.language,
        label: languagesMap[auth.language].label,
        direction: languagesMap[auth.language].direction,
      }
    : defaultLanguage,
  allLanguages,
}

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE: {
      return {
        ...state,
        selectedLanguage: action.payload,
      }
    }

    default:
      return state
  }
}

export default languageReducer
