export const detectBrowser = () => {
  const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') !== -1
  const isGoogleBot = navigator.userAgent.toLowerCase().indexOf('googlebot') !== -1
  // eslint-disable-next-line
  const isIE = /*@cc_on!@*/ false || !!document.documentMode
  const isEdge = !isIE && !!window.StyleMedia
  const isFirefox = typeof InstallTrigger !== 'undefined'
  const isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0
  const isChrome =
    !isGoogleBot &&
    !isEdge &&
    !isOpera &&
    !!window.chrome &&
    (!!window.chrome.webstore || navigator.vendor.toLowerCase().indexOf('google inc.') !== -1)
  const isSafari = !isChrome && navigator.userAgent.toLowerCase().indexOf('safari') !== -1
  const isBlink = (isChrome || isOpera) && !!window.CSS
  let browser

  if (isIE) {
    browser = 'ie'
  } else if (isEdge) {
    browser = 'edge'
  } else if (isFirefox) {
    browser = 'firefox'
  } else if (isOpera) {
    browser = 'opera'
  } else if (isChrome) {
    browser = 'chrome'
  } else if (isSafari) {
    browser = 'safari'
  } else if (isBlink) {
    browser = 'blink'
  } else if (isGoogleBot) {
    browser = 'googlebot'
  } else {
    browser = 'unknown'
  }

  return browser
}

export const detectOS = () => {
  if (navigator.userAgent.indexOf('Linux') != -1) return 'Linux'
  else if (navigator.userAgent.indexOf('Win') != -1) return 'Windows'
  else if (navigator.userAgent.indexOf('Mac') != -1) return 'MacOS'
  else if (navigator.userAgent.indexOf('X11') != -1) return 'UNIX'
  else return 'UNKNOW'
}
