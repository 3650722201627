import { memo, forwardRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './styles.scss'

const QuillEditor = memo(
  forwardRef(
    (
      {
        theme = 'snow',
        modules = {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ color: [] }, { background: [] }, 'clean'],
            [{ direction: 'rtl' }, { align: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            ['blockquote', 'code-block'],
          ],
        },
        ...props
      },
      ref,
    ) => {
      return <ReactQuill className="ipso-editor" ref={ref} theme={theme} modules={modules} {...props} />
    },
  ),
)

export default QuillEditor
