import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Menu, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { Service, Storage } from '../../../services/Service'
import { NoDataFound, openNotification, PageLoader } from '../../common/components/Helper'
import { detectBrowser } from '../../utils/detect-browser'
import SafariCompabilityAlert from './SafariCompabilityAlert'

const { SubMenu } = Menu
const { confirm } = Modal

const ListCourseMeetingRoom = (props) => {
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)
  const userInfo = Storage.get('auth')
  const { t } = useTranslation()
  let { id } = useParams()

  const [loading, setLoading] = useState(false)
  const [roomList, setRoomList] = useState([])
  const [editModalVisible, setEditModalVisible] = useState(false)
  const currentBrowser = detectBrowser()

  useEffect(() => {
    if (props.focus && props.course) {
      fetchClassData()
    }
  }, [props?.course, props?.focus])

  const fetchClassData = async () => {
    setLoading(true)
    try {
      Service.get({
        url: '/meetingRooms/' + id,
      }).then((response) => {
        setLoading(false)
        if (response) {
          setRoomList(response)
        }
      })
    } catch (e) {
      setLoading(false)
    }
  }

  const [form] = Form.useForm()

  //Modal cancel button
  const closeEditModal = () => {
    setEditModalVisible(false)
    form.resetFields()
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  }

  const submitForm = (formData) => {
    var params = {
      owner: userInfo._id,
      name: formData.name,
      courseId: props.course._id,
    }
    setLoading(true)

    // Add Api Call
    Service.post({
      url: '/meetingRooms',
      body: JSON.stringify(params),
    })
      .then((response) => {
        apiSuccess(response)
      })
      .catch((err) => {
        setLoading(false)
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
      .finally(() => {
        setEditModalVisible(false)
        form.resetFields()
      })

    const apiSuccess = (response) => {
      setLoading(false)
      if (response.status === 'error') {
        openNotification('error', t('content.Oops!'), response.message)
      } else {
        openNotification('success', t('content.Success'), t('Info.Meeting room added successfully'))
        fetchClassData()
      }
    }
  }

  const confirmContent = (
    <>
      {t('content.If you’re sure that you want to discard this meeting room')},&nbsp;
      {t('content.type the words Delete irreversibly into the field')}.<br />
      <br />
      <Input
        id="confirmText"
        required
        placeholder={t('content.Type') + ' : ' + t('content.Delete irreversibly')}
      />{' '}
      <br />
    </>
  )

  function showDeleteConfirm(room) {
    confirm({
      title: t('content.Delete meeting room irreversibly?'),
      icon: false,
      content: confirmContent,
      okText: t('buttons.Delete'),
      okType: 'danger',
      cancelText: t('buttons.Cancel'),
      wrapClassName: 'deleteConfirmModal',
      className: 'deleteConfirmModal',
      onOk(e) {
        var inp = document.getElementById('confirmText').value
        if (inp != t('content.Delete irreversibly')) {
          openNotification('error', t('content.Oops!'), t('content.Invalid input for delete'))
          return false
        }

        //Api call
        Service.delete({
          url: '/meetingRooms/' + room._id,
        })
          .then((response) => {
            setLoading(false)
            if (response.status === 'error') {
              openNotification('error', t('content.Oops!'), response.message)
              return
            } else {
              openNotification('success', t('content.Success'), t('content.Deleted Successfully!'))
              fetchClassData()
              Modal.destroyAll()
            }
          })
          .catch((err) => {
            setLoading(false)
            openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
          })
      },
      onCancel() {},
    })
  }

  const GetJoinMeeting = (props) => {
    return (
      <div style={{ marginTop: '15px' }}>
        <Link to={'/classroom/' + props.room._id + '?type=personal'} target="_blank">
          <Button className="join-class-button">{t('buttons.Join the room')}</Button>
        </Link>
      </div>
    )
  }

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          {currentBrowser === 'safari' && <SafariCompabilityAlert />}
          <Row style={{ marginBottom: '20px' }}>
            <Col className="schedule-btns" lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
              <Button
                style={{ marginRight: '10px' }}
                icon={<PlusOutlined />}
                ghost={true}
                onClick={() => setEditModalVisible(!editModalVisible)}
                className="transparentButton scheduleBtn"
                htmlType="button"
              >
                {t('buttons.Add new meetingroom')}
              </Button>
            </Col>
          </Row>

          <Row>
            {roomList.length == 0 ? (
              <NoDataFound message={t("content.This course doesn't yet have any meeting rooms")} />
            ) : (
              <>
                <Col lg={{ span: 18 }} md={{ span: 18 }} sm={{ span: 18 }} xs={{ span: 24 }}>
                  {roomList.map((room, index) => {
                    return (
                      <div key={`class-${index}`} className="class-room-block">
                        {(userInfo.role === 'admin' ||
                          userInfo.role === 'module_trainer' ||
                          userInfo._id === room.owner._id) && (
                          <Menu key={`Menu:1:${index}`} mode="horizontal" className="listMenu">
                            <SubMenu key={`SubMenu:1:${index}`} icon={<MoreOutlined />} title="">
                              <Menu.Item
                                className="listSubMenuItems"
                                key={`setting:2:${index}`}
                                onClick={() => showDeleteConfirm(room)}
                              >
                                {t('buttons.Delete')}
                              </Menu.Item>
                            </SubMenu>
                          </Menu>
                        )}

                        <Row className="schedule">
                          <Col
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 12 }}
                            xs={{ span: 24 }}
                            style={{ marginBottom: '5px' }}
                          >
                            <h5 className="sub-title">{room?.name}</h5>
                            <p>{t('content.Created by') + ' ' + room?.owner.name + ' ' + room?.owner.surname}</p>
                          </Col>
                        </Row>

                        <GetJoinMeeting room={room} />
                      </div>
                    )
                  })}
                </Col>
              </>
            )}
          </Row>
          <Modal
            title={t('content.Add Meeting Room')}
            visible={editModalVisible}
            onOk={() => setEditModalVisible(false)}
            onCancel={closeEditModal}
            footer={false}
            width={500}
            maskClosable={false}
          >
            <Form
              {...layout}
              name="nest-messages"
              form={form}
              layout="vertical"
              onFinish={submitForm}
              initialValues={{}}
              validateMessages={{ required: '${label} ' + t('errors.is required!') }}
            >
              <Form.Item name={'name'} rules={[{ required: true }]} label={t('content.Room Name')}>
                <Input placeholder={t('content.Please enter')} className="theme-input" size={'large'} />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t('buttons.Submit')}
                </Button>{' '}
                &nbsp; &nbsp;
                <Button type="default" htmlType="button" onClick={closeEditModal}>
                  {t('buttons.Cancel')}
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    </>
  )
}

export default ListCourseMeetingRoom
