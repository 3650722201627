import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Service, Storage } from '../../services/Service'
import { Form, Input, Button, Checkbox, Row, Col, notification } from 'antd'
import { useHistory, Link } from 'react-router-dom'
import LanguageDropdown from '../form-component/LanguageDropdown'
import AuthLayout from '../auth/AuthLayout'
import { useSelector, useDispatch } from 'react-redux'
import i18n from 'i18next'
import { onLanguageChangeBeforeLogin } from './../../redux/actions/language'
import ImprintPrivacyLinks from '../common/components/ImprintPrivacyLinks'

const Login = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { selectedLanguage, allLanguages } = useSelector(({ languageReducer }) => languageReducer)

  const dispatch = useDispatch()
  const updateLng = (lngCode) => {
    const filterValue = allLanguages.filter((obj) => obj.key === lngCode)[0]
    i18n.changeLanguage(lngCode)
    dispatch(onLanguageChangeBeforeLogin(filterValue))
  }

  const onFinish = (values) => {
    setLoading(true)
    Service.post({
      url: '/user/signIn',
      body: JSON.stringify({
        email: values.email,
        password: values.password,
      }),
    })
      .then((response) => {
        setLoading(false)
        if (response.status === 'error') {
          openNotification('error', t('content.Oops!'), t('errors.' + response.message + ''))
          return
        }
        Storage.set('auth', response.user)
        Storage.setString('token', response.token)
        Storage.setString('dnd', response.user.dnd)
        updateLng(selectedLanguage.key ? selectedLanguage.key : response.user.language)
        openNotification('success', t('auth.Welcome') + ' ' + response.user.name)

        if (response.user.role == 'admin') {
          history.push('/admin/dashboard')
        } else {
          if (typeof history.location.state.from.state != 'undefined')
            history.push(history.location.state.from.state.from.pathname)
          else history.push('/dashboard')
        }
      })
      .catch((err) => {
        console.log(err, 'Why error')
        setLoading(false)
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
  }

  const openNotification = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    })
  }

  return (
    <AuthLayout>
      <Row type="flex" justify="center" align="middle">
        <Col span={6} xs={20} sm={16} md={15} lg={8} xl={6}>
          <div className="img" style={{ textAlign: 'center' }}>
            <span className="form-subtitle">{t('auth.Welcome back')} </span>
            <p>
              <b className="ffb form-title">{t('auth.Login to your account')}</b>{' '}
            </p>
            <div style={{ marginBottom: 20 }}>
              <LanguageDropdown />
            </div>
          </div>

          <Form
            layout="vertical"
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              required={false}
              label={t('auth.Email')}
              name="email"
              rules={[
                {
                  required: true,
                  message: t('errors.invalid-email'),
                },
                {
                  type: 'email',
                  message: t('errors.Please enter valid email'),
                },
              ]}
              hasFeedback
            >
              <Input size={'large'} placeholder={t('auth.Email')} className="theme-input" />
            </Form.Item>
            <Form.Item
              required={false}
              label={t('auth.Password')}
              name="password"
              rules={[
                {
                  required: true,
                  message: t('errors.Invalid password'),
                },
              ]}
              hasFeedback
            >
              <Input size={'large'} type="password" placeholder={t('auth.Password')} className="theme-input" />
            </Form.Item>
            <Form.Item>
              {/* <Form.Item name="remember" noStyle valuePropName="checked">
                <Checkbox defaultChecked={false} value="false" checked="false">
                  {t('auth.Remember me')}
                </Checkbox>
              </Form.Item> */}
              <Link className="login-form-forgot pull-right forgot-password" to={'/forgot-password'}>
                {t('auth.Forgot password')}?
              </Link>
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                className="login-form-button theme-button"
                block
                size={'large'}
                loading={loading}
              >
                {t('auth.Login now')}
              </Button>
            </Form.Item>
            <p className="line-text" style={{ textAlign: 'center', marginTop: 80 }}>
              {t('auth.Registration is possible by invitation')}
            </p>

            <ImprintPrivacyLinks />
          </Form>
        </Col>
      </Row>
    </AuthLayout>
  )
}

export default Login
