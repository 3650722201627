import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Button, Modal, Select, Radio } from 'antd'
import { Service } from '../../../services/Service'
import { openNotification } from '../../common/components/Helper'

const { Option } = Select

const InviteGroupUser = (props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [inviteType, setInviteType] = useState('academy')
  const [inviteModalVisible, setInviteModalVisible] = useState(false)
  const [academyList, setAcademyList] = useState([])
  const [courseList, setCourseList] = useState([])
  const [form] = Form.useForm()

  useEffect(() => {
    getAcademyList()
  }, [])

  const getAcademyList = () => {
    //setLoading(true);
    Service.get({
      url: '/academy/admin',
    })
      .then((response) => {
        if (response && response.length > 0) setAcademyList(response)
      })
      .catch((err) => {
        //setLoading(false);
      })
  }

  const showInviteModal = () => {
    setInviteModalVisible(true)
    form.resetFields()
    setInviteType('academy')
    form.setFieldsValue({ inviteType: 'academy' })
  }

  const handleOk = () => {
    setInviteModalVisible(false)
  }

  const closeInviteModal = () => {
    setInviteModalVisible(false)
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  }

  const validateMessages = {
    required: '${label} ' + t('errors.is required!'),
    types: {
      email: '${label} ' + t('errors.is not a valid email!'),
      number: '${label} ' + t('errors.is not a valid number!'),
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  const handleTypeChange = (e) => {
    setInviteType(e.target.value)
  }

  const academyOnChange = (val) => {
    var tmp = academyList.find((x) => x._id === val)
    setCourseList(tmp.course)
  }

  const submitForm = (values) => {
    if (typeof values.academy == 'undefined') {
      openNotification('warning', t('errors.Missing Academy'), t('errors.Academy selection is missing'))
      return
    }

    if (values.inviteType === 'course' && typeof values.course == 'undefined') {
      openNotification('warning', t('errors.Missing Course'), t('errors.Course selection is missing'))
      return
    }

    values.groupId = props.group
    setLoading(true)
    Service.post({
      url: '/user/groupInvite',
      body: JSON.stringify(values),
    })
      .then((response) => {
        setLoading(true)
        if (response) {
          openNotification('success', t('content.Success'), t('content.Invited Successfully'))
        }
      })
      .catch((err) => {
        setLoading(false)
        setInviteModalVisible(false)
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
  }

  //Render html
  return (
    <>
      <Button style={{ margin: '14px 0px' }} type="primary" onClick={() => showInviteModal()} htmlType="button">
        {t('buttons.Invite To')}
      </Button>

      <Modal
        title={t('content.Invite Group')}
        visible={inviteModalVisible}
        onOk={handleOk}
        onCancel={closeInviteModal}
        footer={false}
        width={500}
        maskClosable={false}
      >
        <Form
          {...layout}
          name="nest-messages"
          form={form}
          layout="vertical"
          initialValues={{}}
          onFinish={submitForm}
          validateMessages={validateMessages}
        >
          <Form.Item name={['inviteType']} label={t('content.Choose Invitation')}>
            <Radio.Group onChange={handleTypeChange} defaultValue={inviteType}>
              <Radio value="course">{t('content.Course')}</Radio>
              <Radio value="academy">{t('content.Academy')}</Radio>
            </Radio.Group>
          </Form.Item>

          {/* {inviteType == 'academy' && */}
          <Form.Item name={['academy']} label={t('content.Academy')}>
            <Select placeholder={t('content.Please select')} onChange={academyOnChange}>
              {academyList.map(
                (academy, index) =>
                  academy.status === 1 && (
                    <Option value={academy._id} key={`${index}_acad`}>
                      {' '}
                      {academy.name}{' '}
                    </Option>
                  ),
              )}
            </Select>
          </Form.Item>
          {/* } */}

          {inviteType == 'course' && (
            <Form.Item name={['course']} label={t('content.Course')}>
              <Select placeholder={t('content.Please select')}>
                {courseList.map(
                  (course, index) =>
                    course.status === 1 && (
                      <Option value={course._id} key={`${index}_cour`}>
                        {' '}
                        {course.name}{' '}
                      </Option>
                    ),
                )}
              </Select>
            </Form.Item>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('buttons.Invite')}
            </Button>{' '}
            &nbsp; &nbsp;
            <Button type="default" htmlType="button" onClick={closeInviteModal}>
              {t('buttons.Cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default InviteGroupUser
