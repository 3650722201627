import { memo } from 'react'
import 'react-quill/dist/quill.snow.css'

const QuillViewer = memo(({ content, className }) => {
  if (!content) return null

  return (
    <div className={['quill-viewer ql-snow', className || ''].join(' ')}>
      <div className="ql-editor">
        <p className="ql-indent" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  )
})

export default QuillViewer
