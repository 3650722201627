import { useEffect, useRef, useState } from 'react'
import { sortableHandle, sortableContainer, sortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { Button, List, Upload, Modal, message, Input } from 'antd'
import { UploadOutlined, PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Config from '../../../config.json'
import { StepContentElementTypesEnum } from '../../constants/StepContentBlock'
import QuillEditor from '../../common/QuillEditor'
import QuillViewer from '../../common/components/QuillViewer'
import { Service } from '../../../services/Service'

const SortableTextInput = ({ content, onChange }) => {
  let interval = -1
  const wrapperRef = useRef(null)
  const editorRef = useRef(null)
  const [value, setValue] = useState(content || '')
  const [editorHeight, setEditorHeight] = useState(0)
  const [editting, setEditting] = useState(false)
  const { t } = useTranslation()

  const onDocumentClick = (event) => {
    if (wrapperRef?.current) {
      setEditting(wrapperRef.current.contains(event.target))
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', onDocumentClick)
    return () => {
      document.removeEventListener('mousedown', onDocumentClick)
    }
  }, [wrapperRef])

  useEffect(() => {
    if (editorRef.current?.editingArea?.offsetHeight && editorHeight !== editorRef.current.editingArea.offsetHeight) {
      setEditorHeight(editorRef.current.editingArea.offsetHeight)
    }

    if (content !== value) {
      setValue(content || '')
    }
  }, [content, editorHeight])

  const onTextChange = (newValue) => {
    if (interval > 0) {
      clearInterval(interval)
    }

    interval = setTimeout(() => {
      setValue(newValue)
      if (typeof onChange === 'function') {
        onChange(newValue)
      }
    }, 300)
  }

  return (
    <div className="editor-wrapper" ref={wrapperRef}>
      {editting ? (
        <QuillEditor onChange={onTextChange} value={value} ref={editorRef} />
      ) : (
        <QuillViewer
          content={value || `${t('content.Click here to start editing content')}...`}
          className={value ? '' : 'placeholder'}
        />
      )}
    </div>
  )
}

const SortableImageUpload = ({ onChange, defaultFileList }) => {
  const { t } = useTranslation()
  const hasFile = defaultFileList && defaultFileList.length

  const beforeUploadImage = (file) => {
    const size = file.size / 1024 / 1024 < 10
    if (!size) {
      message.error('Image must smaller than 10MB!')
      return false
    }
    return true
  }

  return (
    <Upload
      className="image-upload-wrapper"
      action={`${Config.BASE_URL}/fileUpload `}
      listType="picture"
      maxCount={1}
      headers={{ 'x-access-token': localStorage.getItem('token') }}
      defaultFileList={defaultFileList}
      beforeUpload={beforeUploadImage}
      onChange={(uploaded) => {
        if (uploaded?.fileList?.[0]?.response) {
          const uploadedImage = uploaded?.fileList?.[0]?.response
          onChange({
            ...uploadedImage,
            url: `${Config.BASE_URL}/${uploadedImage.filePath}`,
          })
        }
      }}
    >
      {!hasFile && (
        <>
          <Button icon={<UploadOutlined />}>{t('buttons.Upload')}</Button>
          <p className="ant-form-item-optional">
            {t('content.Image file size')}: {t('content.limit to 10MB')}
          </p>
        </>
      )}
    </Upload>
  )
}

const SortableVideoUpload = ({ content, onChange }) => {
  const { t } = useTranslation()

  return (
    <Input.TextArea
      rows={4}
      onChange={(event) => {
        onChange(event.target.value)
      }}
      defaultValue={content}
      placeholder={t('content.Only allowed services YouTube, Vimeo, Soundcloud')}
    />
  )
}

const SortableDocumentUpload = ({ defaultFileList, onChange }) => {
  const { t } = useTranslation()

  const beforeUploadDocs = (file) => {
    const size = file.size / 1024 / 1024 < 50
    if (!size) {
      message.error('Document must smaller than 50MB!')
      return false
    }
    return true
  }

  const listFiles = (defaultFileList || []).map((f) => {
    let updatedFile = { ...f }
    if (!f.name) {
      updatedFile.name = f.originalName || f.filePath.split('/')[1]
    }
    return updatedFile
  })

  return (
    <Upload
      action={`${Config.BASE_URL}/fileUpload `}
      beforeUpload={beforeUploadDocs}
      onChange={({ fileList }) => {
        if (fileList?.[0]?.response) {
          const uploadedDoc = fileList?.[0]?.response
          onChange({
            ...uploadedDoc,
            originalName: fileList?.[0]?.originFileObj?.name || '',
            url: `${Config.BASE_URL}/${uploadedDoc.filePath}`,
          })
        }
      }}
      multiple={true}
      defaultFileList={listFiles}
      headers={{
        'x-access-token': localStorage.getItem('token'),
      }}
      accept=".pdf"
    >
      {listFiles.length === 0 && (
        <>
          <Button icon={<UploadOutlined />}>{t('buttons.Upload Document')}</Button>
          <p className="ant-form-item-optional">
            {t('content.Document file size')}: {t('content.limit to 50MB')}
          </p>
        </>
      )}
    </Upload>
  )
}

const DragHandle = sortableHandle(() => <span className="draggableHanle">::</span>)

const SortableItem = sortableElement(({ item, onDeleteClick, onChange }) => {
  const wrapperRef = useRef(null)

  const renderContent = () => {
    switch (item.type.toUpperCase()) {
      case StepContentElementTypesEnum.TEXT:
        return (
          <SortableTextInput
            content={item.value}
            onChange={(value) => {
              onChange(item.index, value)
            }}
          />
        )

      case StepContentElementTypesEnum.IMAGE:
        const images = []
        if (item?.value?.filePath) {
          images.push({ ...item.value, url: Service.getImage(item.value.filePath) })
        }
        return <SortableImageUpload defaultFileList={images} onChange={(value) => onChange(item.index, value)} />

      case StepContentElementTypesEnum.VIDEO:
        return (
          <SortableVideoUpload
            content={item.value ? [item.value] : ''}
            onChange={(value) => onChange(item.index, value)}
          />
        )

      case StepContentElementTypesEnum.DOCUMENT:
        const docs = []
        if (item?.value?.filePath) {
          docs.push({ ...item.value, url: Service.getImage(item.value.filePath) })
        }
        return <SortableDocumentUpload defaultFileList={docs} onChange={(value) => onChange(item.index, value)} />
    }
  }

  return (
    <List.Item
      className={['learn-sortable z10000', wrapperRef?.current?.offsetHeight > 367 ? 'has-scroll' : ''].join(' ')}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        ref={wrapperRef}
      >
        <DragHandle />
        {renderContent()}
        <Button icon={<DeleteOutlined />} onClick={() => onDeleteClick(item.index)} />
      </div>
    </List.Item>
  )
})

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <List size="large">
      <ul style={{ paddingInlineStart: 0 }}>{children}</ul>
    </List>
  )
})

const StepContentBlock = ({ onChange, content }) => {
  const { t } = useTranslation()
  const [toDeleteIndex, setToDeleteIndex] = useState(-1)

  let items = []
  try {
    items = JSON.parse(content)
  } catch (err) {
    items = []
  }

  const onAddNewElement = (type) => {
    const newItems = [...items, { type }]
    if (typeof onChange === 'function') {
      onChange(JSON.stringify(newItems))
    }
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newItems = [...arrayMove(items, oldIndex, newIndex)]
      if (typeof onChange === 'function') {
        onChange(JSON.stringify(newItems))
      }
    }
  }

  const changeElementContent = (index, newValue) => {
    const changedItem = { ...items[index], value: newValue }
    const newFiles = []
    if ([StepContentElementTypesEnum.IMAGE, StepContentElementTypesEnum.DOCUMENT].indexOf(changedItem.type) >= 0) {
      newFiles.push({ id: newValue._id || newValue.id })
    }

    const newItems = [...items.slice(0, index), changedItem, ...items.slice(index + 1)]
    if (typeof onChange === 'function') {
      onChange(JSON.stringify(newItems), [], newFiles)
    }
  }

  const onDeleteClick = (toDelIndex) => {
    if (!items[toDelIndex].value) {
      deleteItem(toDelIndex)
    } else {
      setToDeleteIndex(toDelIndex)
    }
  }

  const deleteItem = async (toDelIndex) => {
    if (toDelIndex >= 0 && toDelIndex < items.length) {
      const deletedItem = items[toDelIndex]
      const deletedFiles = []
      const newItems = [...items.slice(0, toDelIndex), ...items.slice(toDelIndex + 1)]

      const { type, value } = deletedItem
      if ([StepContentElementTypesEnum.IMAGE, StepContentElementTypesEnum.DOCUMENT].indexOf(type) > -1) {
        deletedFiles.push(value)
      }
      if (typeof onChange === 'function') {
        onChange(JSON.stringify(newItems), deletedFiles)
      }
    }
    setToDeleteIndex(-1)
  }

  return (
    <div className="content-block-wrapper">
      <SortableContainer items={items} onSortEnd={onSortEnd} useDragHandle>
        {items.map((item, index) => {
          return (
            <SortableItem
              index={index}
              item={{ ...item, index }}
              key={`content-element-${index}`}
              onChange={changeElementContent}
              onDeleteClick={onDeleteClick}
            />
          )
        })}
      </SortableContainer>
      <div className="controller-wrapper">
        <Button
          icon={<PlusCircleOutlined />}
          className="editor-add-element"
          onClick={() => onAddNewElement(StepContentElementTypesEnum.TEXT)}
        >
          {t('content.Add Text')}
        </Button>
        <Button
          icon={<PlusCircleOutlined />}
          className="editor-add-element"
          onClick={() => onAddNewElement(StepContentElementTypesEnum.IMAGE)}
        >
          {t('content.Add Image')}
        </Button>
        <Button
          icon={<PlusCircleOutlined />}
          className="editor-add-element"
          onClick={() => onAddNewElement(StepContentElementTypesEnum.VIDEO)}
        >
          {t('content.Add Video')}
        </Button>
        <Button
          icon={<PlusCircleOutlined />}
          className="editor-add-element"
          onClick={() => onAddNewElement(StepContentElementTypesEnum.DOCUMENT)}
        >
          {t('content.Add PDF')}
        </Button>
      </div>
      <Modal
        visible={toDeleteIndex >= 0}
        onOk={() => deleteItem(toDeleteIndex)}
        onCancel={() => {
          setToDeleteIndex(-1)
        }}
      >
        {t('Do you want to delete this item?')}
      </Modal>
    </div>
  )
}

export default StepContentBlock
