import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Form, Button, Modal, Col, Row, Select } from 'antd'
import { openNotification } from '../../common/components/Helper'
import { PlusOutlined } from '@ant-design/icons'

import { Service } from '../../../services/Service'

const { TextArea } = Input

const InviteUserBtn = (props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [lang, setLang] = useState(localStorage.getItem('invite_lang') ?? 'en')
  const [form] = Form.useForm()

  const showModal = () => {
    setEditModalVisible(true)
  }

  const handleOk = () => {
    setEditModalVisible(false)
  }

  const closeEditModal = () => {
    setEditModalVisible(false)
    form.resetFields()
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  }

  const validateMessages = {
    required: '${label} ' + t('errors.is required!'),
    types: {
      email: '${label} ' + t('errors.is not a valid email!'),
      number: '${label} ' + t('errors.is not a valid number!'),
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  const submitForm = (values) => {
    var isValid = checkEmailInput(values.email)

    console.log(isValid)
    if (!isValid) {
      openNotification('error', t('content.Oops!'), t('content.Email value invalid!'))
      return false
    }

    var params = {
      email: values.email.replace(/,/g, '\n'),
      language: lang,
    }
    setLoading(true)
    Service.post({
      url: '/user/invite',
      body: JSON.stringify(params),
    })
      .then((response) => {
        apiSuccess(response)
        localStorage.setItem('invite_lang', lang)
      })
      .catch((err) => {
        setLoading(false)
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
  }

  const apiSuccess = (response) => {
    setLoading(false)
    if (response.status === 'error') {
      openNotification('error', 'Oops!', response.message)
    } else {
      openNotification('success', t('content.Success'), t('content.Users Invited successfully!'))
      closeEditModal()
    }
  }

  const checkEmailInput = (value) => {
    if (!value.replace(/\s/g, '').length) {
      openNotification('error', t('content.Oops!'), t('content.Email value invalid!'))
      return false
    }
    var isValid = ''

    const regEx =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    value = value.replace(/,/g, '\n')
    var result = value.split(/\r?\n/)
    for (var i = 0; i < result.length; i++) {
      if (result[i] != '') {
        if (regEx.test(result[i])) {
          console.log('valid')
          isValid = true
        } else {
          isValid = false
          break
        }
      }
    }
    return isValid
  }

  //Render html
  return (
    <>
      <Button
        style={{ float: 'right', margin: '14px 0px' }}
        icon={<PlusOutlined />}
        ghost={true}
        onClick={showModal}
        className="transparentButton"
        htmlType="button"
      >
        {t('buttons.Invite new user')}
      </Button>

      <Modal
        title={t('content.Invite User to Signup')}
        visible={editModalVisible}
        onOk={handleOk}
        onCancel={closeEditModal}
        footer={false}
        width={500}
        maskClosable={false}
      >
        <Form
          {...layout}
          name="nest-messages"
          form={form}
          layout="vertical"
          onFinish={submitForm}
          initialValues={{}}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={['email']}
            label={t('content.Enter Email (New line for each email id)')}
            rules={[{ required: true, whitespace: true }]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item>
            <Row>
              <Col span={16}>
                {loading ? (
                  <Button loading={true} disabled={true} htmlType="button">
                    {t('buttons.Submit')}
                  </Button>
                ) : (
                  <Button type="primary" htmlType="submit">
                    {t('buttons.Submit')}
                  </Button>
                )}
                &nbsp; &nbsp;
                <Button type="default" htmlType="button" onClick={closeEditModal}>
                  {t('buttons.Cancel')}
                </Button>
              </Col>
              <Col span={8}>
                <Select mode="single" value={lang} onChange={(language) => setLang(language)}>
                  <Select.Option value={'en'} key={1}>
                    {t('content.English')}
                  </Select.Option>
                  <Select.Option value={'de'} key={2}>
                    {t('content.German')}
                  </Select.Option>
                  <Select.Option value={'ar'} key={3}>
                    {t('content.Arabic')}
                  </Select.Option>
                  <Select.Option value={'fr'} key={4}>
                    {t('content.French')}
                  </Select.Option>
                  <Select.Option value={'fa'} key={5}>
                    {t('content.Dari/Farsi')}
                  </Select.Option>
                </Select>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default InviteUserBtn
