import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { LogoGrey, PageLoader, NoDataFound, openNotification } from '../common/components/Helper'
import { List, Layout, Row, Col, Image, Menu, Input, Form, Button, Modal, Select, Upload, Radio, Tooltip } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { PlusOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Service } from '../../services/Service'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import Config from '../../config.json'
import ImgCrop from 'antd-img-crop'

import { useParams, Link } from 'react-router-dom'
import InviteToBtn from './components/InviteToBtn'
import arrayMove from 'array-move'
import noImage from '../../asset/images/no-image.png'
import { MoreOutlined } from '@ant-design/icons'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Breadcrumb } from 'antd'
import { languageInfo } from '../../otherLanguages'
import { getCourseByAcademy } from '../../redux/actions/admin'
import QuillEditor from '../common/QuillEditor'

const { Content, Footer } = Layout
const { SubMenu } = Menu
const { Option } = Select
const { confirm } = Modal
const DragHandle = sortableHandle(() => <span className="draggableHanle">::</span>)

const SortableItem = sortableElement(({ value }) => (
  <List.Item>
    <DragHandle />
    {value}
  </List.Item>
))

const SortableContainer = sortableContainer(({ children }) => {
  return <List size="large">{children}</List>
})

const AdminCoursesList = () => {
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)
  const { courseListWithDetails, isCourseFetching } = useSelector(({ adminReducer }) => adminReducer)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  let { id } = useParams()

  const [popupMode, setPopupMode] = useState('Add')
  const [fileSelection, setFileSelection] = useState({})
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [editAcademyInfo, setEditAcademyInfo] = useState([])
  const [activeLanguages, setActiveLanguages] = useState([])
  const [academyDetails, setAcademyDetails] = useState([])
  const [courseList, setCourseList] = useState([])
  const [unusedFiles, setUnusedFiles] = useState([])
  const [waitingSubmitFiles, setWaitingSubmitFiles] = useState([])

  useEffect(() => {
    dispatch(getCourseByAcademy(id))
  }, [])

  useEffect(() => {
    if (courseListWithDetails) {
      setCourseList(courseListWithDetails.course)
      setAcademyDetails(courseListWithDetails)
    }
  }, [courseListWithDetails])

  //Sort instantly when user drag drop ite,
  const onSortEnd = ({ oldIndex, newIndex }) => {
    var sorted = arrayMove(courseList, oldIndex, newIndex)
    setCourseList(sorted)
    var newArr = []

    //Create array of sorted order
    for (var i = 0; i < sorted.length; i++) {
      newArr.push({ id: sorted[i]._id, order: i })
    }

    if (newArr.length > 0)
      Service.patch({
        url: '/academy/course/order/' + id,
        body: JSON.stringify({
          courses: newArr,
        }),
      })
  }

  const [form] = Form.useForm()

  const showEditModal = (academy) => {
    if (academy.defaultLanguage) {
      academy.name = academy.translations[academy.defaultLanguage].name
      academy.description = academy.translations[academy.defaultLanguage].description
    }

    setPopupMode('Edit')
    form.resetFields()
    setEditModalVisible(true)
    setActiveLanguages(academy.language)
    setEditAcademyInfo(academy) //Keeping active data in temp variable
    form.setFieldsValue(academy) //Pushing datas to react form
    setUnusedFiles([])
    setWaitingSubmitFiles([])

    //Populating existing image selection
    if (academy.image && typeof academy.image.filePath != 'undefined') {
      setFileSelection([
        {
          uid: '1',
          name: academy.image.name,
          status: 'success',
          url: Config.BASE_URL + '/' + academy.image.filePath,
          rawData: { ...academy.image },
        },
      ])
    } else {
      setFileSelection([]) //Clearing any image selection
    }
  }

  const defaultCourseObj = {
    translations: {
      de: { name: '', description: '' },
      ar: { name: '', description: '' },
      en: { name: '', description: '' },
    },
  }

  const showAddModal = () => {
    form.resetFields()
    form.setFieldsValue(defaultCourseObj)
    setPopupMode('Add')
    setEditModalVisible(true)
    setActiveLanguages([])
    setEditAcademyInfo({})
    setFileSelection([])
    setUnusedFiles([])
    setWaitingSubmitFiles([])
  }

  //Modal ok button
  const handleOk = () => {
    setEditModalVisible(false)
  }

  // Modal cancel button
  const onEditModalCancel = async () => {
    // Call API to delete waiting submit files
    if (waitingSubmitFiles.length > 0) {
      await Service.delete({
        url: '/fileUpload',
        body: JSON.stringify(waitingSubmitFiles),
      })
    }

    closeEditModal()
  }

  const closeEditModal = () => {
    setEditModalVisible(false)
    form.resetFields()
    setUnusedFiles([])
  }

  const clearFormData = () => {
    form.setFieldsValue(defaultCourseObj) //To avoid quill texteditor error
    setUnusedFiles([])
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  }

  const validateMessages = {
    required: '${label} ' + t('errors.is required!'),
    types: {
      email: '${label} ' + t('errors.is not a valid email!'),
      number: '${label} ' + t('errors.is not a valid number!'),
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  const submitForm = (values) => {
    var params = {
      academyId: id,
      language: values.language,
      defaultLanguage: values.defaultLanguage,
      translations: values.translations ? values.translations : {},
      courseType: values.courseType,
      maxAttempts: values.maxAttempts,
      status: 0,
      unusedFiles,
    }

    if (typeof values.translations[values.defaultLanguage] == 'undefined') {
      openNotification(
        'warning',
        t('content.Missing default lanugage content'),
        t('content.Please add Title & Description in default language - ') + languageInfo(values.defaultLanguage).name,
      )
    } else {
      var defLngContent = values.translations[values.defaultLanguage]
      params.name = defLngContent.name
      params.description = defLngContent.description
    }

    //Push image selection
    if (values.image && typeof values.image.id == 'undefined') {
      params.image = values.image
    } else if (!values.image) {
      openNotification('warning', t('content.Missing Image'), t('content.Please select course image!'))
      return
    }

    if (popupMode == 'Edit') {
      params['status'] = values.status

      // EDIT Api call
      Service.patch({
        url: '/academy/course/' + editAcademyInfo._id,
        body: JSON.stringify(params),
      })
        .then((response) => {
          apiSuccess(apiSuccess)
        })
        .catch((err) => {
          openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
        })
    } else {
      // Add Api Call
      Service.post({
        url: '/academy/course/',
        body: JSON.stringify(params),
      })
        .then((response) => {
          apiSuccess(apiSuccess)
        })
        .catch((err) => {
          openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
        })
    }
  }

  const apiSuccess = (response) => {
    if (response.status === 'error') {
      openNotification('error', t('content.Oops!'), response.message)
    } else {
      openNotification(
        'success',
        t('content.Success'),
        popupMode == 'Add' ? t('content.Course Added successfully!') : t('content.Course Updated successfully!'),
      )
      closeEditModal()
      dispatch(getCourseByAcademy(id))
    }
  }

  //Language selection from Edit Course popup
  function handleLanguageChange(value) {
    var langVal = form.getFieldValue('translations')
    if (!langVal) {
      form.setFieldsValue({
        translations: {
          ar: { title: '', description: '' },
          de: { title: '', description: '' },
        },
      })
    }
    setActiveLanguages(value)
  }

  const translate = (data) => {
    return JSON.parse(JSON.stringify(data)).map((item) => {
      if (item.translations && item.translations.hasOwnProperty(selectedLanguage.key)) {
        item.name = item.translations[selectedLanguage.key].name
        item.description = item.translations[selectedLanguage.key].description
      }
      return item
    })
  }

  //Any change on filepicker
  const onFileUploadChange = ({ fileList: newFileList }) => {
    if (typeof newFileList[0] != 'undefined' && newFileList[0].response) {
      setWaitingSubmitFiles([...waitingSubmitFiles, { id: newFileList[0].response.id }])
      form.setFieldsValue({ image: newFileList[0].response.id })
    } else {
      form.setFieldsValue({ image: null })
    }
  }

  //Preview selected image
  const onPreview = async (file) => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow.document.write(image.outerHTML)
  }

  // handle remove course image
  const onRemoveCourseImage = (removedImage) => {
    if (removedImage.rawData || removedImage.response) {
      setUnusedFiles([...unusedFiles, removedImage.rawData || removedImage.response])
    }
  }

  const confirmContent = (
    <>
      {t(
        'content.If you’re sure that you want to discard this course,  all contained modules, settings and course content',
      )}
      ,&nbsp; {t('content.type the words Delete irreversibly into the field')}.<br />
      <br />
      <Input
        id="confirmText"
        required
        placeholder={t('content.Type') + ' : ' + t('content.Delete irreversibly')}
      />{' '}
      <br />
      <br />
      <b>{t('content.You could instead set this course to unpublished in the settings to hide it from students')}. </b>
    </>
  )

  function showDeleteConfirm(course) {
    confirm({
      title:
        t('content.Delete course') + ' "' + course.name + '" ' + t('content.and all contained content irreversibly?'),
      icon: false,
      content: confirmContent,
      okText: t('buttons.Delete'),
      okType: 'danger',
      cancelText: t('buttons.Cancel'),
      wrapClassName: 'deleteConfirmModal',
      className: 'deleteConfirmModal',
      onOk(e) {
        var inp = document.getElementById('confirmText').value
        if (inp != t('content.Delete irreversibly')) {
          openNotification('error', t('content.Oops!'), t('content.Invalid input for delete'))
          return false
        }

        //Api call
        Service.delete({
          url: '/academy/course/' + course._id,
        })
          .then((response) => {
            if (response.status === 'error') {
              openNotification('error', t('content.Oops!'), response.message)
              return
            } else {
              openNotification('success', t('content.Success'), t('content.Deleted Successfully!'))
              closeEditModal()
              dispatch(getCourseByAcademy(id))
              Modal.destroyAll()
            }
          })
          .catch((err) => {
            openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
          })
      },
      onCancel() {},
    })
  }

  const publishCourse = (course) => {
    confirm({
      title: t('content.Are you sure to publish') + ' ' + course.name + '?',
      icon: <ExclamationCircleOutlined />,
      content: '(' + t('content.Published course will be visible to students') + ')',
      okText: t('buttons.Yes'),
      cancelText: t('buttons.No'),
      onOk() {
        //Api call
        Service.patch({
          url: '/academy/course/' + course._id,
          body: JSON.stringify({ status: 1 }),
        })
          .then((response) => {
            if (response.status === 'error') {
              openNotification('error', t('content.Oops!'), response.message)
              return
            } else {
              openNotification('success', t('content.Success'), t('content.Published Successfully!'))
              closeEditModal()
              dispatch(getCourseByAcademy(id))
            }
          })
          .catch((err) => {
            openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
          })
      },
      onCancel() {},
    })
  }

  // Duplicate Course
  const duplicateCourse = (course) => {
    confirm({
      title: t('content.Do you want to duplicate this course?'),
      icon: <ExclamationCircleOutlined />,
      okText: t('buttons.Yes'),
      cancelText: t('buttons.Cancel'),
      onOk() {
        Service.post({
          url: '/duplicate/course',
          body: JSON.stringify({ courseId: course._id }),
        })
          .then((response) => {
            if (response.status === 'success') {
              openNotification('success', t('content.Success'), t('content.Duplicated Successfully!'))
              dispatch(getCourseByAcademy(id))
            }
          })
          .catch((err) => {
            openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
          })
      },
      onCancel() {},
    })
  }

  //Render html
  return (
    <Layout className="layout adminScreen">
      <Content className="container ipso-content">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={'/admin/dashboard'}>{t('content.Academies')}</Link>
          </Breadcrumb.Item>

          {!isCourseFetching && (
            <Breadcrumb.Item>
              {academyDetails &&
              academyDetails.translations &&
              academyDetails.translations[selectedLanguage.key] !== undefined
                ? academyDetails.translations[selectedLanguage.key].name
                : academyDetails.name}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>

        <div className="site-layout-content">
          <Row>
            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 8 }} xs={{ span: 24 }}>
              <h1 className="page-title">{t('content.Courses')} </h1>
            </Col>
            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 16 }} xs={{ span: 24 }}>
              <Button
                style={{ float: 'right', margin: '14px 0px' }}
                icon={<PlusOutlined />}
                ghost={true}
                onClick={showAddModal}
                className="transparentButton"
                htmlType="button"
              >
                {t('buttons.Add Course')}
              </Button>

              <InviteToBtn sourceName={'Course'} sourceList={translate(courseList)} />
            </Col>
          </Row>
        </div>
        <div className="site-card-wrappe">
          {isCourseFetching ? (
            <PageLoader />
          ) : (
            <Row type="flex" align="middle" gutter={24}>
              <div className="academyListing">
                <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                  {/* <List itemLayout="horizontal"> */}
                  {courseList.length == 0 ? (
                    <NoDataFound message={t("errors.This academy doesn't yet have any courses")} />
                  ) : (
                    <>
                      {translate(courseList).map((course, index) => (
                        <SortableItem
                          key={`item-${index}`}
                          index={index}
                          value={
                            <div className="academyItem">
                              <div className="info">
                                <div style={{ display: 'flex' }}>
                                  <Tooltip placement="top" title={course.name}>
                                    <Link to={'/admin/course-detail/' + course._id}>
                                      <h2>
                                        {course.name.length < 60 ? course.name : course.name.substring(0, 60) + '...'}
                                      </h2>
                                    </Link>
                                  </Tooltip>
                                  <span className="infoSubtitle">{t(`Info.${course.courseType}`)}</span>
                                </div>
                                {course.modules.length}{' '}
                                {course.modules.length <= 1 ? t('content.Module') : t('content.Modules')}
                              </div>

                              {/* triggerSubMenuAction={['click']} */}
                              <Menu mode="horizontal" className="listMenu">
                                <SubMenu key="SubMenu" icon={<MoreOutlined />} title="">
                                  <Menu.Item
                                    onClick={() => showEditModal(course)}
                                    className="listSubMenuItems"
                                    key={`setting:1:${index}`}
                                  >
                                    {t('buttons.Edit Course Information')}
                                  </Menu.Item>

                                  <Menu.Item className="listSubMenuItems" key={`setting:2:${index}`}>
                                    <Link to={'/admin/course-detail/' + course._id + '/edit-content'}>
                                      {t('buttons.Edit Content')}
                                    </Link>
                                  </Menu.Item>

                                  <Menu.Item
                                    onClick={() => showDeleteConfirm(course)}
                                    className="listSubMenuItems"
                                    key={`setting:3:${index}`}
                                  >
                                    {t('buttons.Delete')}
                                  </Menu.Item>

                                  <Menu.Item
                                    onClick={() => duplicateCourse(course)}
                                    className="listSubMenuItems"
                                    key={`setting:4:${index}`}
                                  >
                                    {t('buttons.Duplicate')}
                                  </Menu.Item>
                                </SubMenu>
                              </Menu>

                              {course.image ? (
                                <Image
                                  preview={true}
                                  height={80}
                                  width={70}
                                  fallback={noImage}
                                  src={Service.getImage(course.image.filePath)}
                                />
                              ) : (
                                <Image
                                  src="https://via.placeholder.com/150"
                                  preview={false}
                                  width={80}
                                  height={80}
                                  fallback={noImage}
                                />
                              )}

                              {!course.status || course.status == 0 ? (
                                <Button
                                  onClick={() => publishCourse(course)}
                                  className="publishBtn"
                                  type="primary"
                                  htmlType="button"
                                >
                                  {t('buttons.Publish')} <ArrowRightOutlined />
                                </Button>
                              ) : null}
                            </div>
                          }
                        />
                      ))}
                    </>
                  )}
                  {/* </List> */}
                </SortableContainer>
              </div>
            </Row>
          )}
        </div>
        <br />
      </Content>

      {/* Add/Edit Course modal */}
      <Modal
        title={t('buttons.' + popupMode) + ' ' + t('content.Course Information')}
        visible={editModalVisible}
        onOk={handleOk}
        onCancel={onEditModalCancel}
        afterClose={clearFormData}
        footer={false}
        width={500}
        maskClosable={false}
      >
        <Form
          {...layout}
          name="nest-messages"
          form={form}
          layout="vertical"
          onFinish={submitForm}
          initialValues={{}}
          validateMessages={validateMessages}
        >
          <Form.Item name={['language']} label={t('content.Course Languages')} rules={[{ required: true }]}>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder={t('content.Please select')}
              onChange={handleLanguageChange}
            >
              <Option value={'en'} key={1}>
                {t('content.English')}
              </Option>
              <Option value={'de'} key={2}>
                {t('content.German')}
              </Option>
              <Option value={'ar'} key={3}>
                {t('content.Arabic')}
              </Option>
            </Select>
          </Form.Item>

          {activeLanguages.map((language, index) => (
            <div key={index}>
              <Form.Item
                name={['translations', language, 'name']}
                label={t('content.Course Name') + ' (' + languageInfo(language).name + ')'}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name={['translations', language, 'description']}
                label={t('content.Main Content') + ' (' + languageInfo(language).name + ')'}
              >
                <QuillEditor />
              </Form.Item>
            </div>
          ))}

          <Form.Item name={['defaultLanguage']} label={t('content.Default Language')} rules={[{ required: true }]}>
            <Select placeholder={t('content.Please select')}>
              <Option value="en">{t('content.English')}</Option>
              <Option value="de">{t('content.German')}</Option>
              <Option value="ar">{t('content.Arabic')}</Option>
            </Select>
          </Form.Item>

          <Form.Item name={['image']} label={t('content.Choose Course Image')} rules={[{ required: true }]}>
            <ImgCrop rotate aspect={0.8 / 1}>
              <Upload
                action={`${Config.BASE_URL}/fileUpload `}
                listType="picture"
                onChange={onFileUploadChange}
                onPreview={onPreview}
                maxCount={1}
                defaultFileList={fileSelection}
                headers={{ 'x-access-token': localStorage.getItem('token') }}
                onRemove={onRemoveCourseImage}
              >
                <Button icon={<UploadOutlined />}>{t('buttons.Upload')}</Button>
              </Upload>
            </ImgCrop>
          </Form.Item>

          <Form.Item name={['courseType']} label={t('content.Course Type')} rules={[{ required: true }]}>
            <Select placeholder={t('content.Course Type')}>
              <Option value="public">{t('content.Public')}</Option>
              <Option value="private">{t('content.Private')}</Option>
            </Select>
          </Form.Item>
          {popupMode == 'Edit' && (
            <Form.Item name={['status']} label={t('content.Course Status')} rules={[{ required: true }]}>
              <Radio.Group>
                <Radio value={0}>{t('content.Unpublished')}</Radio>
                <Radio value={1}>{t('content.Published')}</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          <Form.Item
            name={['maxAttempts']}
            label={t('content.Attempts to complete the course')}
            rules={[{ required: true }]}
          >
            <Select>
              <Option value={1}>1</Option>
              <Option value={2}>2</Option>
              <Option value={3}>3</Option>
              <Option value={4}>4</Option>
              <Option value={5}>5</Option>
              <Option value={0}>{t('content.Unlimited')}</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('buttons.Submit')}
            </Button>{' '}
            &nbsp; &nbsp;
            <Button type="default" htmlType="button" onClick={onEditModalCancel}>
              {t('buttons.Cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Footer style={{ textAlign: 'center' }}>
        <LogoGrey />
      </Footer>
    </Layout>
  )
}

export default AdminCoursesList
