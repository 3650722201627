import { SET_LANGUAGE } from './../action-creators/language'
import { notification } from 'antd'
import { Service } from '../../services/Service'

const openNotification = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  })
}

export const setLanguage = (para) => {
  const data = {
    ...JSON.parse(localStorage.getItem('auth')),
    language: para.key,
  }
  localStorage.setItem('auth', JSON.stringify(data))
  return { type: SET_LANGUAGE, payload: para }
}

export const onLanguageChangeBeforeLogin = (params) => {
  const data = {
    ...JSON.parse(localStorage.getItem('auth')),
    language: params.key,
  }
  localStorage.setItem('auth', JSON.stringify(data))
  return { type: SET_LANGUAGE, payload: params }
}

export const onLanguageChange = (id, params) => {
  return (dispatch) => {
    Service.patch({
      url: '/user/' + id,
      body: JSON.stringify({
        language: params.key,
      }),
    })
      .then((response) => {
        if (response.status === 'error') {
          openNotification('error', 'Oops!', response.message)
        } else {
          dispatch(setLanguage(params))
        }
      })
      .catch((err) => {
        openNotification('error', 'Oops!', 'Something went wrong')
      })
  }
}
