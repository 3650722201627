import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Logo, GetInitial } from '../common/components/Helper'
import DndMenu from '../common/components/DndMenu'
import BuddyCallNotification from '../common/components/BuddyCallNotification'
import { Storage } from '../../services/Service'
import { Layout, Menu, Dropdown, List, Avatar, Switch, Row, Col, Divider, Button } from 'antd'
import Config from '../../config.json'
import LanguageDropdown from '../form-component/LanguageDropdown'
import { SocketContext } from '../context/socket'

const { Header } = Layout

const StudentHeader = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [userInfo, setUserInfo] = useState(false)
  const [isDnd, setIsDnd] = useState(false)
  const socket = useContext(SocketContext)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const mobileMenuBtnRef = useRef(null)
  const mobileMenuRef = useRef(null)

  useEffect(() => {
    reloadIndicator()
    setUserInfo(Storage.get('auth'))
  }, [])

  useEffect(() => {
    const handleMouseClick = (event) => {
      if (
        !mobileMenuBtnRef ||
        !mobileMenuRef ||
        (!mobileMenuBtnRef.current.contains(event.target) && !mobileMenuRef.current.contains(event.target))
      ) {
        setShowMobileMenu(false)
      }
    }
    window.addEventListener('mousedown', handleMouseClick)

    return () => {
      window.removeEventListener('mousedown', handleMouseClick)
    }
  }, [])

  const reloadIndicator = () => {
    var dnd = localStorage.getItem('dnd') && localStorage.getItem('dnd') == 1 ? true : false
    setIsDnd(dnd)
  }

  const logout = () => {
    if (socket) {
      socket.disconnect()
    }
    Storage.logout()
    history.push('/login')
  }

  const toggleUserMode = (checked) => {
    if (checked) {
      setTimeout(function () {
        window.location.href = Config.SITE_URL + '/admin/dashboard'
      }, 500)
    }
  }

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  const profilemenu = (
    <Menu>
      <Menu.Item>
        <Link to="/settings">
          <List>{t('content.Edit Profile')}</List>
        </Link>
      </Menu.Item>
      <Menu.Item onClick={logout}>
        <List>{t('content.Logout')}</List>
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout>
      <div className="bgWhite">
        {userInfo && (
          <>
            <Header className="custom-header container header-user">
              <div className="left" style={{ display: 'flex', flex: 1 }}>
                <Logo />
                <Menu className="desktop-menu" mode="horizontal" style={{ marginLeft: '100px', flex: 1 }}>
                  <Menu.Item className="heading" key="2">
                    <Link to="/dashboard">{t('content.Academies')}</Link>
                  </Menu.Item>
                  <Menu.Item className="heading1" key="3">
                    <Link to="/my-courses">{t('content.My Courses')}</Link>
                  </Menu.Item>
                </Menu>
                <Button className="mobile-menu-toggle" onClick={toggleMobileMenu} ref={mobileMenuBtnRef}>
                  <span>|||</span>
                </Button>
              </div>
              <div className="right">
                <div
                  style={{
                    display: 'inline-flex',
                    justifyContent: 'right',
                    alignItems: 'center',
                    float: 'right',
                  }}
                >
                  {userInfo.role === 'admin' && (
                    <Switch
                      onChange={toggleUserMode}
                      checkedChildren={t('content.Admin Mode')}
                      unCheckedChildren={t('content.Student Mode')}
                      style={{ marginRight: '15px' }}
                    />
                  )}

                  <LanguageDropdown />

                  <DndMenu reloadIndicator={reloadIndicator} />

                  <Dropdown overlay={profilemenu} placement="bottomCenter" ref={mobileMenuRef}>
                    <div className="topProfileMenu">
                      <Avatar
                        style={{
                          backgroundColor: userInfo.color,
                        }}
                      >
                        <GetInitial text={userInfo.name} />
                      </Avatar>
                      <span className={`availablityIndicator ${isDnd ? 'inactive' : 'active'}`}></span>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </Header>

            <BuddyCallNotification />

            <div className="mobile-menu" style={{ display: showMobileMenu ? 'block' : 'none' }} ref={mobileMenuRef}>
              <Row>
                <Col span={12}>
                  <Link to="/dashboard">{t('content.Academies')}</Link>
                </Col>
                <Col span={12}>
                  <Link to="/my-courses">{t('content.My Courses')}</Link>
                </Col>
              </Row>
              <Divider />
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

export default StudentHeader
