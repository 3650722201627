import { Service } from '../../services/Service'

export const deleteFile = async (file) => {
  await Service.delete({ url: `/fileUpload`, body: JSON.stringify(file) })
}

export const findNewUploadedFiles = (oldFiles, newUploadedFiles) => {
  if (!newUploadedFiles || newUploadedFiles.length === 0) {
    return []
  }

  if (!oldFiles || oldFiles.length === 0) {
    return (newUploadedFiles || []).map((f) => ({ id: f._id || f.id }))
  }

  const oldMap = {}
  oldFiles.forEach((f) => {
    const data = f.rawData || f.response
    const fid = data?._id || data?.id
    if (fid) {
      oldMap[fid] = data
    }
  })

  const newFiles = []
  newUploadedFiles.forEach((f) => {
    const data = f.response || f.rawData
    if (data) {
      const fid = data._id || data.id
      if (fid && !oldMap[fid]) {
        newFiles.push({ id: fid })
      }
    }
  })

  return newFiles
}
