import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InviteToCourse from '../components/InviteToCourse'
import { PageLoader, NoDataFound, GetInitial, GetRole } from '../../common/components/Helper'
import { Layout, List, Avatar, Button, Row, Col, Select, Form, Modal } from 'antd'
import { Service } from '../../../services/Service'
import { useParams } from 'react-router-dom'

import _ from 'lodash'
import { SocketContext } from '../../context/socket'
const { Option } = Select

const AdminCourseStudents = (props) => {
  const { t } = useTranslation()
  let { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [courseUsers, setCourseUsers] = useState([])
  const [usersListOriginal, setUsersListOriginal] = useState([])
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})

  const [form] = Form.useForm()
  const [userform] = Form.useForm()
  const socket = useContext(SocketContext)

  useEffect(() => {
    if (props.course) {
      fetchCourseData()
    }
  }, [props?.course])

  useEffect(() => {
    const handleUserStatusChange = (userData) => {
      const changedUser = JSON.parse(userData)
      for (let i = 0; i < courseUsers.length; i++) {
        if (changedUser._id === courseUsers[i].user._id) {
          setCourseUsers([
            ...courseUsers.slice(0, i),
            { ...courseUsers[i], user: changedUser },
            ...courseUsers.slice(i + 1),
          ])
          break
        }
      }
    }
    socket.on('updateUserStatus', handleUserStatusChange)

    return () => {
      socket.off('updateUserStatus', handleUserStatusChange)
    }
  }, [socket, courseUsers])

  const fetchCourseData = async () => {
    setLoading(true)
    try {
      Service.get({
        url: '/academy/course/users/' + id,
      }).then((response) => {
        setLoading(false)

        var arr = []
        if (response && response.length > 0) {
          response.forEach(function (academy) {
            const found = arr.some((el) => el.user._id === academy.user._id)
            if (!found && academy.user.name.indexOf('deleted:') === -1) {
              arr.push(academy)
            }
          })
        }

        setCourseUsers(arr)
        setUsersListOriginal(arr)
      })
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const showEditModal = (data) => {
    userform.resetFields()
    setSelectedUser(data)
    setEditModalVisible(true)
    userform.setFieldsValue(data)
  }

  const handleOk = () => {
    setEditModalVisible(false)
  }

  const closeEditModal = () => {
    setEditModalVisible(false)
    userform.resetFields()
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  }

  const validateMessages = {
    required: '${label} ' + t('errors.is required!'),
    types: {
      email: '${label} ' + t('errors.is not a valid email!'),
      number: '${label} ' + t('errors.is not a valid number!'),
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  const submitForm = (values) => {}

  function onChange() {
    triggerFilter()
  }

  function onBlur() {}

  function onFocus() {}

  function onSearch(val) {}

  const triggerFilter = () => {
    var users = usersListOriginal
    var frmInput = form.getFieldsValue()
    var newArr = users

    //Filter
    if (typeof frmInput.isOnline != 'undefined') {
      newArr = newArr.filter(function (o) {
        return frmInput.isOnline
          ? o.user.isOnline == frmInput.isOnline && o.user.dnd === 0
          : o.user.isOnline == frmInput.isOnline || o.user.dnd === 1
      })
    }
    if (typeof frmInput.role != 'undefined') {
      newArr = newArr.filter(function (o) {
        return o.user.role == frmInput.role
      })
    }
    if (typeof frmInput.search != 'undefined') {
      newArr = newArr.filter(function (o) {
        return o.user.email == frmInput.search
      })
    }
    if (typeof frmInput.surname != 'undefined') {
      newArr = newArr.filter(function (o) {
        return o.user.surname == frmInput.surname
      })
    }

    //Sorting
    if (typeof frmInput.order != 'undefined') {
      if (frmInput.order == 'nameAsc') {
        newArr = _.orderBy(newArr, ['user.name'], ['asc'])
      } else if (frmInput.order == 'nameDesc') {
        newArr = _.orderBy(newArr, ['user.name'], ['desc'])
      } else if (frmInput.order == 'dateAsc') {
        newArr = _.orderBy(newArr, ['_id'], ['desc'])
      } else if (frmInput.order == 'dateDesc') {
        newArr = _.orderBy(newArr, ['_id'], ['asc'])
      }
    }

    setCourseUsers(newArr)
  }

  return (
    <>
      <Layout className="layout academyUsersList bgWhite">
        {loading ? (
          <PageLoader />
        ) : (
          <>
            <Form form={form}>
              <Row>
                <Col span={2} xl={2} lg={2} xs={24} sm={24} md={3}>
                  <Form.Item name="isOnline">
                    <Select
                      allowClear={true}
                      onChange={triggerFilter}
                      placeholder={t('content.Status')}
                      style={{ width: '100%' }}
                      bordered={false}
                    >
                      <Option value={true}>{t('content.Online')}</Option>
                      <Option value={false}>{t('content.Offline')}</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={3} xl={3} lg={3} xs={24} sm={24} md={3}>
                  <Form.Item name="role">
                    <Select
                      allowClear={true}
                      onChange={triggerFilter}
                      placeholder={t('content.Role')}
                      style={{ width: '100%' }}
                      bordered={false}
                    >
                      <Option value="admin">{t('content.Course Leader')}</Option>
                      <Option value="module_trainer">{t('content.Module Trainer')}</Option>
                      <Option value="student">{t('content.Student')}</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={4} xl={4} lg={4} xs={24} sm={24} md={4}>
                  <Form.Item name="order">
                    <Select
                      allowClear={true}
                      onChange={triggerFilter}
                      placeholder={t('content.Order By')}
                      style={{ width: '100%' }}
                      bordered={false}
                      allowClear={true}
                    >
                      <Option value="nameAsc">{t('content.Name Asc')}</Option>
                      <Option value="nameDesc">{t('content.Name Desc')}</Option>
                      <Option value="dateAsc">{t('content.Date Added Asc')}</Option>
                      <Option value="dateDesc">{t('content.Date Added Desc')}</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="searchField" span={5} xl={5} lg={5} xs={24} sm={24} md={7}>
                  <Form.Item name="surname">
                    <Select
                      showSearch
                      style={{ width: '100%', float: 'right' }}
                      placeholder={t('content.Search by family name')}
                      optionFilterProp="children"
                      onChange={onChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onSearch={onSearch}
                      bordered={false}
                      allowClear={true}
                    >
                      {usersListOriginal.map((academy, index) => (
                        <Option key={`key-${index}`} value={academy.user.surname}>
                          {academy.user.surname}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6} xl={6} lg={6} xs={24} sm={24} md={8} className="searchField">
                  <Form.Item name="search">
                    <Select
                      showSearch
                      placeholder={t('content.Search by email or name')}
                      optionFilterProp="children"
                      onChange={onChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onSearch={onSearch}
                      bordered={false}
                      allowClear={true}
                    >
                      {usersListOriginal.map((academy, index) => (
                        <Option key={`opt-${index}`} value={academy.user.email}>
                          {academy.user.email} ({academy.user.name})
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4} xl={4} lg={4} xs={24} sm={24} md={6}>
                  <div className="invite-student-btn">
                    <InviteToCourse id={id} />
                  </div>
                </Col>
              </Row>
            </Form>

            {courseUsers.length == 0 ? <NoDataFound message={t('content.No users found')} /> : null}

            <List itemLayout="horizontal">
              {courseUsers.map((user, index) => (
                <List.Item key={`user-${index}`}>
                  <div className="topProfileMenu">
                    <Avatar style={{ backgroundColor: user.user.color ? user.user.color : '' }}>
                      <GetInitial text={user.user.name} />
                    </Avatar>
                    <span
                      className={`availablityIndicator ${
                        !user.user.isOnline || user.user.dnd === 1 ? 'inactive' : 'active'
                      }`}
                    ></span>
                  </div>

                  <List.Item.Meta
                    title={user.user.name + ' ' + user.user.surname}
                    description={t('content.' + GetRole(user.user.role) + '')}
                    className="metaInfo"
                  />

                  <div className="callButton">
                    {/* <Button 
          shape="round" 
          onClick={() => showEditModal(user.user)}
					className="transparentButton" htmlType="button">
					{t('buttons.Edit')} <EditOutlined  />
				  </Button>  */}
                  </div>
                </List.Item>
              ))}
            </List>
          </>
        )}
      </Layout>

      <Modal
        title={false}
        visible={editModalVisible}
        onOk={handleOk}
        onCancel={closeEditModal}
        footer={false}
        width={400}
        maskClosable={false}
      >
        <div className="profileViewHead">
          <div className="topProfileMenu">
            <Avatar style={{ backgroundColor: selectedUser.color ? selectedUser.color : '' }}>
              <GetInitial text={selectedUser.name} />
            </Avatar>
          </div>
          <h2>{selectedUser.name}</h2>
          <span className="subtitle">{selectedUser.email}</span>
        </div>

        <Form
          {...layout}
          name="nest-messages"
          form={userform}
          layout="vertical"
          onFinish={submitForm}
          initialValues={{}}
          validateMessages={validateMessages}
        >
          <Form.Item name={['role']} label={t('content.Role')} rules={[{ required: true }]}>
            <Select placeholder={t('content.Role')} style={{ width: '100%' }}>
              <Option value="admin">{t('content.Admin')}</Option>
              <Option value="student">{t('content.Student')}</Option>
              <Option value="module_trainer">{t('content.Module Trainer')}</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('buttons.Save')}
            </Button>{' '}
            &nbsp; &nbsp;
            <Button type="default" htmlType="button" onClick={closeEditModal}>
              {t('buttons.Cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AdminCourseStudents
