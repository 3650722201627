import { SET_CALLING_USER } from '../action-creators/buddyCall'

const initialState = {
  callingUser: null,
}

const buddyCallReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CALLING_USER:
      return {
        ...state,
        callingUser: { ...action.user },
      }

    default:
      return state
  }
}

export default buddyCallReducer
