import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const ImprintPrivacyLinks = () => {
  const { t } = useTranslation()
  return (
    <div className="imprint-privacy-links">
      <Link className="custom-anchor" to={'/imprint-attribution-disclaimer'} target="_blank">
        {t('content.Imprint, Attribution & Disclaimer')}
      </Link>
      <Link className="custom-anchor" to={'/privacy-policy'} target="_blank">
        {t('auth.Privacy Policy')}
      </Link>
    </div>
  )
}

export default ImprintPrivacyLinks
