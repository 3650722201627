import React from 'react'
import { Row, Col, Typography, Layout } from 'antd'
import { useTranslation } from 'react-i18next'
import LanguageDropdown from '../form-component/LanguageDropdown'
import { Logo, CustomFooter } from './components/Helper'
import ImprintPrivacyLinks from './components/ImprintPrivacyLinks'

const { Title, Paragraph } = Typography
const { Header, Content } = Layout

const Terms = () => {
  const { t } = useTranslation()

  return (
    <>
      <Layout className="layout">
        <div className="bgWhite">
          <Header className="custom-header container header-admin">
            <div className="left" style={{ display: 'flex' }}>
              <Logo />
            </div>
            <div className="right">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <LanguageDropdown />
              </div>
            </div>
          </Header>
        </div>

        <Content className="container ipso-content">
          <Row>
            <Col span={20} xs={20} sm={16} md={15} lg={24} xl={24}>
              <Typography>
                <Title>{t('auth.Terms & Conditions')}</Title>
                <Paragraph>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel orci at metus pretium commodo sed a
                  leo. Ut euismod mauris facilisis nisi tincidunt viverra. Donec vestibulum metus non magna viverra
                  tempor. Fusce volutpat non neque in varius. Integer ac nunc egestas, rhoncus est a, aliquam nisi.
                  Etiam lorem libero, tincidunt sit amet venenatis et, suscipit porta leo. Duis tempus lectus sit amet
                  nisi efficitur imperdiet. Maecenas pretium odio non ligula scelerisque vulputate. Proin quis mauris
                  non metus venenatis faucibus. Donec ac urna luctus eros consectetur sagittis a ut turpis.
                </Paragraph>
                <Paragraph>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel orci at metus pretium commodo sed a
                  leo. Ut euismod mauris facilisis nisi tincidunt viverra. Donec vestibulum metus non magna viverra
                  tempor. Fusce volutpat non neque in varius. Integer ac nunc egestas, rhoncus est a, aliquam nisi.
                  Etiam lorem libero, tincidunt sit amet venenatis et, suscipit porta leo. Duis tempus lectus sit amet
                  nisi efficitur imperdiet. Maecenas pretium odio non ligula scelerisque vulputate. Proin quis mauris
                  non metus venenatis faucibus. Donec ac urna luctus eros consectetur sagittis a ut turpis.
                </Paragraph>
                <Paragraph>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel orci at metus pretium commodo sed a
                  leo. Ut euismod mauris facilisis nisi tincidunt viverra. Donec vestibulum metus non magna viverra
                  tempor. Fusce volutpat non neque in varius. Integer ac nunc egestas, rhoncus est a, aliquam nisi.
                  Etiam lorem libero, tincidunt sit amet venenatis et, suscipit porta leo. Duis tempus lectus sit amet
                  nisi efficitur imperdiet. Maecenas pretium odio non ligula scelerisque vulputate. Proin quis mauris
                  non metus venenatis faucibus. Donec ac urna luctus eros consectetur sagittis a ut turpis.
                </Paragraph>
                <Paragraph>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel orci at metus pretium commodo sed a
                  leo. Ut euismod mauris facilisis nisi tincidunt viverra. Donec vestibulum metus non magna viverra
                  tempor. Fusce volutpat non neque in varius. Integer ac nunc egestas, rhoncus est a, aliquam nisi.
                  Etiam lorem libero, tincidunt sit amet venenatis et, suscipit porta leo. Duis tempus lectus sit amet
                  nisi efficitur imperdiet. Maecenas pretium odio non ligula scelerisque vulputate. Proin quis mauris
                  non metus venenatis faucibus. Donec ac urna luctus eros consectetur sagittis a ut turpis.
                </Paragraph>
                <Paragraph>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel orci at metus pretium commodo sed a
                  leo. Ut euismod mauris facilisis nisi tincidunt viverra. Donec vestibulum metus non magna viverra
                  tempor. Fusce volutpat non neque in varius. Integer ac nunc egestas, rhoncus est a, aliquam nisi.
                  Etiam lorem libero, tincidunt sit amet venenatis et, suscipit porta leo. Duis tempus lectus sit amet
                  nisi efficitur imperdiet. Maecenas pretium odio non ligula scelerisque vulputate. Proin quis mauris
                  non metus venenatis faucibus. Donec ac urna luctus eros consectetur sagittis a ut turpis.
                </Paragraph>
              </Typography>
            </Col>
          </Row>
          <ImprintPrivacyLinks />
        </Content>

        <CustomFooter />
      </Layout>
    </>
  )
}

export default Terms
