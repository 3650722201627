import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Layout, Row, Col } from 'antd'
import { PageLoader } from '../common/components/Helper'
import { useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import {
  useMeetingManager,
  MeetingProvider,
  lightTheme,
  VideoTileGrid,
  Grid,
  useUserActivityState,
  ControlBar,
  AudioInputControl,
  VideoInputControl,
  ContentShareControl,
  AudioOutputControl,
  ControlBarButton,
  Phone,
  MeetingStatus,
  useMeetingStatus,
  Flex,
  Heading,
} from 'amazon-chime-sdk-component-library-react'
import { ConsoleLogger, LogLevel } from 'amazon-chime-sdk-js'
import { Service } from '../../services/Service'

import MeetingRoster from '../chime/containers/MeetingRoster'
import SafariCompabilityAlert from '../common/components/SafariCompabilityAlert'
import { detectBrowser } from '../utils/detect-browser'

const BuddyCall = (props) => {
  const { t } = useTranslation()
  let { meetingId } = useParams()
  const [loading, setLoading] = useState(false)
  const [meetStatus, setMeetStatus] = useState(0)
  const [autoJoinMeeting, setAutoJoinMeeting] = useState(false)

  useEffect(() => {
    //setMeetStatus(meetingStatus)
  })

  const MeetingManager = () => {
    const meetingManager = useMeetingManager()
    const meetingStatus = useMeetingStatus()

    useEffect(() => {
      setMeetStatus(meetingStatus)
      if (meetingStatus === MeetingStatus.Ended) {
        console.log('[useMeetingEndRedirect] Meeting ended')
      }
    }, [meetingStatus])

    const joinMeetingById = async () => {
      setLoading(true)
      return Service.get({
        url: '/meeting/join/' + meetingId,
      }).then(async (data) => {
        setLoading(false)
        if (!data || data.status == 'failed') {
          alert(t('content.Meeting Ended Already'))
          return false
        } else {
          const joinData = {
            meetingInfo: data.meeting.Meeting,
            attendeeInfo: data.attendee.Attendee,
          }
          await meetingManager.join(joinData) // create a meeting session
          await meetingManager.start() // let users setup their devices, or start the session immediately
        }
      })
    }

    //Join to meeting directly
    if (meetingId && meetingId != '' && !autoJoinMeeting) {
      setAutoJoinMeeting(true)
      joinMeetingById()
    }

    // const leaveMeeting = async () => {
    //   await meetingManager.leave()
    // }

    return (
      <>
        {/* { (meetStatus != 1) ? <button onClick={joinMeeting}>Join</button> : null } */}
        {/* { (meetStatus == 1) ? <button onClick={leaveMeeting}>Leave meeting</button> : null } */}
      </>
    )
  }

  const MeetingManagerControls = () => {
    const meetingManager = useMeetingManager()

    const leaveMeeting = async () => {
      await meetingManager.leave()
    }

    return (
      <>
        {meetStatus == 1 ? (
          <div className="chimeControls controls" active={!!useUserActivityState}>
            <ControlBar className="controls-menu" layout="undocked-horizontal" showLabels>
              <AudioInputControl muteLabel={t('content.Mute')} unmuteLabel={t('content.Unmute')} />
              <VideoInputControl label={t('content.Video')} />
              <ContentShareControl label={t('content.Present')} pauseLabel={t('buttons.Pause Presenting')} />
              <AudioOutputControl label={t('content.Speaker')} />
              <ControlBarButton icon={<Phone />} onClick={leaveMeeting} label={t('content.Leave')} />
            </ControlBar>
          </div>
        ) : null}
      </>
    )
  }

  // const endMeetingForAll = async () => {
  //   try {
  //     if (meetingId) {
  //       alert('Call ended')
  //       //await endMeeting(meetingId);
  //       //await meetingManager.leave();
  //       //history.push(routes.HOME);
  //     }
  //   } catch (e) {
  //     console.log('Could not end meeting', e)
  //   }
  // }

  const MeetingDetails = () => {
    const currentBrowser = detectBrowser()
    return (
      <Flex container layout="fill-space-centered">
        {currentBrowser === 'safari' && (
          <div style={{ position: 'absolute', right: 20, top: 20, width: 400 }}>
            <SafariCompabilityAlert />
          </div>
        )}
        <Flex mb="2rem" mr={{ md: '2rem' }} px="1rem">
          {loading ? <PageLoader /> : null}
          <Heading level={4} tag="h1" mb={2}>
            {t('content.BuddyCall (One on One Meeting)')}
          </Heading>
          <div>
            <div>
              <dt>{t('content.Meeting ID')}</dt>
              <dd>{meetingId}</dd>
            </div>
            <div>
              <dt>{t('content.Status')}</dt>
              <dd>
                {meetStatus === 0 && t('content.Not Joined')}
                {meetStatus === 1 && t('content.Joined')}
                {meetStatus === 2 && t('content.Failed')}
                {(meetStatus === 3 || meetStatus === 5) && t('content.Ended')}
              </dd>
            </div>
            <div>
              <dt>{t('content.Hosted region')}</dt>
              <dd>eu-central-1 (Frankfurt)</dd>
            </div>
          </div>
        </Flex>
      </Flex>
    )
  }

  const meetingLogger = new ConsoleLogger('SDK', LogLevel.INFO)
  //Render html
  return (
    <Layout className="layout chimeIntegration">
      <ThemeProvider theme={lightTheme}>
        <MeetingProvider logger={meetingLogger}>
          <Row className="fullHeight">
            <Col span={18} push={6}>
              <MeetingManager />

              {/* <LocalVideoToggle /> */}

              <Grid>
                <VideoTileGrid noRemoteVideoView={<MeetingDetails />} />
                {/* <FeaturedRemoteVideos /> */}
              </Grid>

              {/* <RemoteVideoTile /> */}
            </Col>

            <Col span={6} pull={18}>
              <MeetingRoster />
            </Col>
          </Row>

          <MeetingManagerControls />
        </MeetingProvider>
      </ThemeProvider>
    </Layout>
  )
}

export default BuddyCall
