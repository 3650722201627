import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Button, Modal, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { openNotification } from '../../common/components/Helper'

import { Service } from '../../../services/Service'
import { getUserData } from '../../../redux/actions/admin'

const { Option } = Select

const InviteToBtn = ({ sourceList, sourceName }) => {
  const { userDetails } = useSelector(({ adminReducer }) => adminReducer)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [form] = Form.useForm()

  if (sourceList === false) {
    //Call Api to get list of academy or course
  }

  const showModal = () => {
    setEditModalVisible(true)
  }

  const handleOk = () => {
    setEditModalVisible(false)
  }

  const closeEditModal = () => {
    setEditModalVisible(false)
    form.resetFields()
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  }

  const validateMessages = {
    required: '${label} ' + t('errors.is required!'),
    types: {
      email: '${label} ' + t('errors.is not a valid email!'),
      number: '${label} ' + t('errors.is not a valid number!'),
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  let timeout

  const handleUserIdSearch = (value) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(() => dispatch(getUserData(value)), 500)
  }

  const getUserIDOptions = () => {
    return userDetails.map((userId) => (
      <Option key={userId._id}>
        {userId.email} ({userId.name + ' ' + userId.surname}){' '}
      </Option>
    ))
  }

  const submitForm = (values) => {
    if (values.academy === '') {
      openNotification('warning', `Missing ${sourceName}`, `Please select ${sourceName}.`)
      return
    }
    if (values.userId === '') {
      openNotification('warning', t('content.Missing user'), t('content.User selection missing'))
      return
    }

    var params = {
      userId: values.userId,
      resourceId: values.academy.join(), //Change to comma seperated string
      inviteType: sourceName === 'Academy' ? 'academy' : 'course',
    }
    setLoading(true)
    Service.post({
      url: '/user/inviteAccess',
      body: JSON.stringify(params),
    })
      .then((response) => {
        setLoading(false)
        if (response.status === 'failed') {
          openNotification('error', t('content.Oops!'), response.message)
        } else {
          openNotification('success', t('content.Success'), t(`content.Users Invited to ${sourceName} successfully`))
          closeEditModal()
        }
      })
      .catch((err) => {
        setLoading(false)
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
  }

  //Render html
  return (
    <>
      <Button
        style={{ float: 'right', margin: '14px 5px' }}
        icon={<PlusOutlined />}
        ghost={true}
        onClick={showModal}
        className="transparentButton"
        htmlType="button"
      >
        {t('buttons.Invite To')}
        {` ${t('content.' + sourceName)}`}
      </Button>

      <Modal
        title={t('content.Invite User to') + ' ' + t('content.' + sourceName)}
        visible={editModalVisible}
        onOk={handleOk}
        onCancel={closeEditModal}
        footer={false}
        width={500}
        maskClosable={false}
      >
        <Form
          {...layout}
          name="nest-messages"
          form={form}
          layout="vertical"
          onFinish={submitForm}
          initialValues={{}}
          validateMessages={validateMessages}
        >
          <Form.Item name={['academy']} label={t('content.' + sourceName)} rules={[{ required: true }]}>
            <Select
              mode="multiple"
              showSearch
              allowClear
              style={{ width: '100%' }}
              placeholder={t('content.Please select')}
              optionFilterProp="children"
            >
              {sourceList.map((source, index) =>
                sourceName === 'Academy' && source.academyType === 'private' && source.status === 1 ? (
                  <Option value={source._id} key={index}>
                    {' '}
                    {source.name}{' '}
                  </Option>
                ) : (
                  sourceName === 'Course' &&
                  source.courseType === 'private' &&
                  source.status === 1 && (
                    <Option value={source._id} key={index}>
                      {' '}
                      {source.name}{' '}
                    </Option>
                  )
                ),
              )}
            </Select>
          </Form.Item>

          <Form.Item name={['userId']} label={t('content.User Email')} rules={[{ required: true }]}>
            <Select
              showSearch
              placeholder={t('content.Please select a user email')}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={handleUserIdSearch}
              notFoundContent={null}
            >
              {getUserIDOptions()}
            </Select>
          </Form.Item>

          <Form.Item>
            {loading ? (
              <Button loading={true} disabled={true} htmlType="button">
                {t('buttons.Submit')}
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                {t('buttons.Submit')}
              </Button>
            )}
            &nbsp; &nbsp;
            <Button type="default" htmlType="button" onClick={closeEditModal}>
              {t('buttons.Cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default InviteToBtn
