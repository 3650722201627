import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Layout, Row, Col, Button, Modal, Form, Select } from 'antd'
import GroupUsers from './components/GroupUsers'
import { openNotification } from '../common/components/Helper'
import InviteGroupUser from './components/InviteGroupUser'
import { PlusOutlined } from '@ant-design/icons'
import { Breadcrumb } from 'antd'
import { useParams, Link } from 'react-router-dom'
import { Service } from '../../services/Service'

import { getUserData } from '../../redux/actions/admin'

const { Content } = Layout
const { Option } = Select

const AdminGroupUsers = (props) => {
  const { userDetails } = useSelector(({ adminReducer }) => adminReducer)

  const { t } = useTranslation()
  let { id } = useParams()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const childRef = useRef()

  useEffect(() => {
    if (typeof id == 'undefined') {
      return false
    }
  }, [])

  const [form] = Form.useForm()

  const showModal = () => {
    setEditModalVisible(true)
  }

  const handleOk = () => {
    setEditModalVisible(false)
  }

  const closeEditModal = () => {
    setEditModalVisible(false)
    form.resetFields()
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  }

  const validateMessages = {
    required: '${label} ' + t('errors.is required!'),
    types: {
      email: '${label} ' + t('errors.is not a valid email!'),
      number: '${label} ' + t('errors.is not a valid number!'),
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  let timeout

  const handleUserIdSearch = (value) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(() => dispatch(getUserData(value)), 500)
  }

  const getUserIDOptions = () => {
    return userDetails.map((userId) => (
      <Option key={userId._id}>
        {userId.email} ({userId.name + ' ' + userId.surname}){' '}
      </Option>
    ))
  }

  const submitForm = (values) => {
    var params = {
      groupId: id,
      userId: values.email,
    }

    setLoading(true)
    Service.post({
      url: '/groups/addUser',
      body: JSON.stringify(params),
    })
      .then((response) => {
        apiSuccess(apiSuccess)
      })
      .catch((err) => {
        setLoading(false)
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
  }

  const apiSuccess = (response) => {
    setLoading(false)
    if (response.status === 'error') {
      openNotification('error', t('content.Oops!'), response.message)
    } else {
      openNotification('success', t('content.Success'), t('content.Users added successfully!'))
      childRef.current.reloadGroupUser()
      closeEditModal()
    }
  }

  return (
    <Layout className="layout bgWhite">
      <Content
        className="ipso-cont ent"
        style={{ backgroundColor: '#f0f2f5', minHeight: '100px', paddingBottom: '0px' }}
      >
        <div className="container fullWidthGrey">
          <div className="site-layout-content">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={'/admin/users'}>{t('content.Groups')}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('content.Users')}</Breadcrumb.Item>
            </Breadcrumb>
            <Row justify="space-between">
              <Col span={12} lg={12} md={12} sm={12} xs={24}>
                <h1 className="page-title">{t('content.Group Users')} </h1>
              </Col>
              <Col span={12} lg={12} md={12} sm={12} xs={24}>
                <div style={{ textAlign: 'right' }}>
                  <InviteGroupUser group={id} /> &nbsp; &nbsp;
                  <Button
                    style={{ float: 'right', margin: '14px 0px' }}
                    icon={<PlusOutlined />}
                    ghost={true}
                    onClick={showModal}
                    className="transparentButton"
                    htmlType="button"
                  >
                    {t('buttons.Add User')}
                  </Button>
                </div>
              </Col>
            </Row>
            <br />
          </div>
        </div>
      </Content>

      <div className="container tabContentPadding">
        <GroupUsers ref={childRef} />
      </div>

      <Modal
        title={t('content.Add New User')}
        visible={editModalVisible}
        onOk={handleOk}
        onCancel={closeEditModal}
        footer={false}
        width={500}
        maskClosable={false}
      >
        <Form
          {...layout}
          name="nest-messages"
          form={form}
          layout="vertical"
          onFinish={submitForm}
          initialValues={{}}
          validateMessages={validateMessages}
        >
          <Form.Item name={['email']} label={t('auth.Email')} rules={[{ required: true }]}>
            <Select
              mode="multiple"
              placeholder={t('content.Please select a user email')}
              filterOption={false}
              onSearch={handleUserIdSearch}
              notFoundContent={null}
            >
              {getUserIDOptions()}
            </Select>
          </Form.Item>

          <Form.Item>
            {loading ? (
              <Button loading={true} disabled={true} htmlType="button">
                {t('buttons.Add')}
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                {t('buttons.Add')}
              </Button>
            )}
            &nbsp; &nbsp;
            <Button type="default" htmlType="button" onClick={closeEditModal}>
              {t('buttons.Cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  )
}

export default AdminGroupUsers
