import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { PhoneOutlined } from '@ant-design/icons'
import { Menu, Dropdown } from 'antd'
import { Service } from '../../../services/Service'

const DndMenu = (props) => {
  const { t } = useTranslation()
  const [dndStatus, setDndStatus] = useState(1)

  useEffect(() => {
    setDndStatus((localStorage.getItem('dnd') ? localStorage.getItem('dnd') : 0).toString())
  }, [])

  const toggleDnd = (checked) => {
    setDndStatus(checked.key)
    localStorage.setItem('dnd', checked.key)
    props.reloadIndicator()
    Service.post({
      url: '/user/dnd',
      body: JSON.stringify({ status: checked.key }),
    })
      .then((response) => {})
      .catch((err) => {})
  }

  const availabilityMenu = (
    <Menu selectedKeys={[dndStatus]} onClick={toggleDnd}>
      <Menu.Item key="0">{t('content.Available')}</Menu.Item>
      <Menu.Item key="1">{t('content.Not Available')}</Menu.Item>
    </Menu>
  )

  return (
    <>
      <Dropdown overlay={availabilityMenu}>
        <a href="" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <PhoneOutlined rotate={90} />

          {dndStatus == 1 ? <label>{t('content.Not Available')}</label> : <label>{t('content.Available')}</label>}
        </a>
      </Dropdown>
    </>
  )
}

export default DndMenu
