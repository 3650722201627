import React, { useState, useEffect } from 'react'
import logo from '../../asset/images/logo.svg'
import { Service } from '../../services/Service'
import { Form, Input, Button, Row, Col, Image, notification, Select, Checkbox } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LanguageDropdown from '../form-component/LanguageDropdown'
import moment from 'moment'
import { openNotification } from '../common/components/Helper'
import PasswordFields from '../common/PasswordFields'
import Config from '../../config.json'
import i18n from 'i18next'
import ImprintPrivacyLinks from '../common/components/ImprintPrivacyLinks'

const Signup = ({ match }) => {
  let token = match.params.token
  const [loading, setLoading] = useState(false)
  const [validtoken, setValidtoken] = useState(true)
  const [checked, setChecked] = useState(false)
  const [checkedPrivacy, setPrivacyChecked] = useState(false)
  const [tosTimestamp, setTosTimestamp] = useState('')
  const [ppTimestamp, setPpTimestamp] = useState('')
  const history = useHistory()
  const { t } = useTranslation()
  const { Option } = Select
  let { search } = useLocation()
  const lang = new URLSearchParams(search).get('lang') ?? 'en'

  if (!token) {
    history.push('/login')
  }

  const [form] = Form.useForm()
  useEffect(() => {
    // Add Api Call
    Service.post({
      url: '/user/validateSignUpToken',
      body: JSON.stringify({ referral_code: token }),
    })
      .then((response) => {
        if (response && response.status === 'success') {
          form.setFieldsValue({ email: response.email })
          setValidtoken(true)
          i18n.changeLanguage(lang)
        } else {
          setValidtoken(false)
          openBlockNotification('warning', t('auth.You cannot signup'), t('auth.link expired'))
        }
      })
      .catch((err) => {
        setValidtoken(false)
      })
  }, [])

  const onPpChange = async (e) => {
    setPrivacyChecked(e.target.checked)
    form.validateFields(['policy'])
    setPpTimestamp(moment.utc().format())
  }

  const onTosChange = async (e) => {
    setChecked(e.target.checked)
    form.validateFields(['terms'])
    setTosTimestamp(moment.utc().format())
  }

  const termsvalidation = (rule, value, callback) => {
    if (checked) {
      return callback()
    }
    const errmsg = t('errors.Please agree the Terms and Conditions')
    return callback(errmsg)
  }

  const privacyValidation = (rule, value, callback) => {
    if (checkedPrivacy) {
      return callback()
    }
    const errmsg = t('errors.Please agree the Privacy Policy')
    return callback(errmsg)
  }

  const onFinish = (values) => {
    setLoading(true)
    Service.post({
      url: '/user/signUp',
      body: JSON.stringify({
        name: values.name,
        surname: values.surname,
        email: values.email,
        password: values.password,
        language: values.language,
        referral_code: token,
        tos: tosTimestamp,
        pp: ppTimestamp,
      }),
    })
      .then((response) => {
        setLoading(false)
        if (response.status === 'error') {
          openNotification('error', t('content.Oops!'), t('errors.' + response.message + ''))
          return
        }
        openNotification('success', t('content.Success'), t('auth.You have been signup successfully, please login'))

        history.push('/login')
      })
      .catch((err) => {
        setLoading(false)
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
  }

  const openBlockNotification = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
      closeIcon: false,
      duration: 0,
    })
  }

  return (
    <div className="auth-page-container" style={{ paddingBottom: 16 }}>
      <Row type="flex" justify="center" align="middle">
        <Col span={8} xs={20} sm={16} md={15} lg={8} xl={8}>
          <div className="img" style={{ textAlign: 'center' }}>
            <Image preview={false} className="form-logo" src={logo} style={{ marginBottom: '50px' }} />
            <br />
            <span className="form-subtitle">{t('auth.Hello! Nice to see you with us')}</span>
            <p className="ffb form-title">{t('auth.Welcome to our academy')}</p>

            <div style={{ marginBottom: 20, textAlign: 'center' }}>
              <LanguageDropdown initialValue={lang} />
            </div>
          </div>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <Col span={6} xs={20} sm={16} md={15} lg={8} xl={6}>
          <Form
            form={form}
            layout="vertical"
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label={t('auth.Given Name')}
              name="name"
              required={false}
              rules={[
                {
                  required: true,
                  message: t('errors.Invalid name'),
                },
              ]}
            >
              <Input placeholder={t('auth.name')} className="theme-input" size={'large'} />
            </Form.Item>
            <Form.Item
              label={t('auth.Family name')}
              name="surname"
              required={false}
              rules={[
                {
                  required: true,
                  message: t('errors.Invalid family name'),
                },
              ]}
            >
              <Input placeholder={t('auth.Family name')} className="theme-input" size={'large'} />
            </Form.Item>

            <Form.Item
              name="language"
              required={false}
              rules={[
                {
                  required: true,
                  message: t('auth.Select Preferred Language'),
                },
              ]}
              label={t('auth.Preferred Content Language')}
            >
              <Select placeholder={t('auth.Select Language')}>
                <Option value={'en'} key={1}>
                  {t('content.English')}
                </Option>
                <Option value={'de'} key={2}>
                  {t('content.German')}
                </Option>
                <Option value={'ar'} key={3}>
                  {t('content.Arabic')}
                </Option>
                <Option value={'fr'} key={4}>
                  {t('content.French')}
                </Option>
                <Option value={'fa'} key={5}>
                  {t('content.Dari/Farsi')}
                </Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={t('auth.Email')}
              name="email"
              required={false}
              rules={[
                {
                  required: true,
                  message: t('errors.invalid-email'),
                },
                {
                  type: 'email',
                  message: t('errors.Please enter valid email'),
                },
              ]}
            >
              <Input size={'large'} disabled={true} placeholder={t('auth.Email')} className="theme-input" />
            </Form.Item>

            <PasswordFields passwordLabel={t('auth.Password')} confirmPasswordLabel={t('auth.Confirm Password')} />
            {Config.TOS === 'ON' && (
              <Form.Item name="terms" required={true} rules={[{ validator: termsvalidation }]}>
                <Checkbox checked={checked} onChange={onTosChange}></Checkbox>{' '}
                <span style={{ fontWeight: 400 }}>{t('auth.I agree with')}</span>{' '}
                <a href="/terms" target="_blank" className="terms-link">
                  {' '}
                  {t('auth.Terms & Conditions')}{' '}
                </a>
              </Form.Item>
            )}
            <Form.Item name="policy" required={true} rules={[{ validator: privacyValidation }]}>
              <Checkbox checked={checkedPrivacy} onChange={onPpChange}></Checkbox>{' '}
              <span style={{ fontWeight: 400 }}>{t('auth.I agree with')}</span>{' '}
              <a href="/privacy-policy" target="_blank" className="terms-link">
                {t('auth.Privacy Policy')}
              </a>
            </Form.Item>

            {validtoken ? (
              <Form.Item>
                <Button
                  size={'large'}
                  htmlType="submit"
                  className="login-form-button theme-button"
                  block
                  loading={loading}
                  style={{ marginTop: '5px' }}
                >
                  {t('auth.Sign Up')}
                </Button>
              </Form.Item>
            ) : (
              <h4 align="center">{t('auth.link expired')}</h4>
            )}
          </Form>
          <br />
          <br />
        </Col>
      </Row>
      <ImprintPrivacyLinks />
    </div>
  )
}

export default Signup
