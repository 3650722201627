import React from 'react'
import { useTranslation } from 'react-i18next'
import { Result, Button } from 'antd'
import { Link } from 'react-router-dom'

const ErrorFallback = (props) => {
  const { t } = useTranslation()

  return (
    <Result
      status="warning"
      title={t('errors.Something went wrong')}
      subTitle={t('errors.Please refresh')}
      extra={
        <Button type="primary" key="console" onClick={props.resetErrorBoundary}>
          {t('errors.Try again')}
        </Button>
      }
    />
  )
}

export default ErrorFallback
