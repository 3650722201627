import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PageLoader, NoDataFound, GetInitial } from '../../common/components/Helper'
import { Row, Col, Image, Card, Progress, Popover, Button, Avatar } from 'antd'
import { Service, Storage } from '../../../services/Service'
import { Link } from 'react-router-dom'
import noImage from '../../../asset/images/no-image.png'
import QuillViewer from '../../common/components/QuillViewer'

const CoursesCard = (props) => {
  const { t } = useTranslation()

  return (
    <Link to={props.link ? props.link : '#'}>
      <Card
        className="academic-list"
        bordered={false}
        style={{ marginBottom: '10px', height: '200px', background: '#f7f7f5', boxShadow: 'none' }}
      >
        <Row>
          <Popover
            content={
              <div style={{ width: '350px' }}>
                <h2>{props.title}</h2>
                <QuillViewer content={props.description} />
                {props.teamMembers &&
                  props.teamMembers.length > 0 &&
                  props.teamMembers.some((el) => el.role === 'admin') && (
                    <>
                      <h4 style={{ fontWeight: '600' }}>{t('content.Course Leader')}</h4>
                      {props.teamMembers.map((user) => (
                        <>
                          {user.role === 'admin' ? (
                            <div className="courseLeader">
                              <Avatar style={{ backgroundColor: user.user.color ? user.user.color : '' }}>
                                <GetInitial text={user.user.name} />
                              </Avatar>
                              <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                                {user.user.name + ' ' + user.user.surname}
                              </span>
                            </div>
                          ) : null}
                        </>
                      ))}
                    </>
                  )}
                <div className="course-progress">
                  <Button>
                    {props.modules.length} {props.modules.length <= 1 ? t('content.Module') : t('content.Modules')}
                  </Button>
                  <Button style={{ marginLeft: '10px', marginRight: '10px' }}>
                    {props.steps} {props.steps <= 1 ? t('content.Step') : t('content.Steps')}
                  </Button>
                </div>
              </div>
            }
            title={false}
          >
            <Col span={14} order={1}>
              <h2 className="title">{props.title}</h2>
            </Col>
          </Popover>
          <Col span={10} order={2} className="image-container">
            {typeof props.image != 'undefined' ? (
              <Image fallback={noImage} width={100} src={Service.getImage(props.image.filePath)} />
            ) : (
              <Image fallback={noImage} width={100} src={noImage} />
            )}
          </Col>
        </Row>
        <div className="go-to-next-academic">
          <p className="description">{props.subtitle}</p>
          {props.progress ? <Progress percent={props.progress} /> : null}
        </div>
      </Card>
    </Link>
  )
}

const AcademyCourses = (props) => {
  const userInfo = Storage.get('auth')
  const { t } = useTranslation()
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)
  const [loading, setLoading] = useState(false)
  const [myCourses, setMyCourses] = useState([])
  const [otherCourses, setOtherCourses] = useState([])
  const [myActiveCourses, setMyActiveCourses] = useState([])
  const [otherActiveCourses, setOtherActiveCourses] = useState([])
  const [haveOtherLng, setHaveOtherLng] = useState(false)
  const [allowOtherLng, setAllowOtherLng] = useState(false)

  useEffect(() => {
    //My Courses
    if (props.courses) {
      if (userInfo.role === 'admin') {
        setMyCourses(props.courses)
        setMyActiveCourses(translate(props.courses, 'mycourses'))
      } else {
        setMyCourses(props.courses.myCourses)
        setMyActiveCourses(translate(props.courses.myCourses, 'mycourses'))
      }
      getOtherCourses()
    }
  }, [props?.courses])

  const getOtherCourses = () => {
    Service.get({
      url: '/academy/othercourses/' + props.academyId,
    })
      .then((response) => {
        setLoading(false)
        if (response.hasOwnProperty('error')) {
          return
        } else if (response !== '' && response.length > 0) {
          setOtherCourses(response)
          setOtherActiveCourses(translate(response))
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (myCourses.length > 0) setMyActiveCourses(translate(myCourses, 'mycourses'))

    if (otherCourses.length > 0) setOtherActiveCourses(translate(otherCourses))
  }, [selectedLanguage, allowOtherLng])

  const sumStepsCount = (modules) => {
    var count = 0
    if (modules.length > 0) {
      modules.forEach(function (element) {
        if (element.steps) count = count + element.steps.length
      })
    }
    return count
  }

  const allowOtherLanguage = () => {
    setAllowOtherLng(true)
  }

  const translate = (data, courseType) => {
    if (!data || data.length == 0) {
      return []
    }
    var otherLng = false
    var resultArr = []
    JSON.parse(JSON.stringify(data)).map((item) => {
      if (item.translations) {
        item.name = item.translations[selectedLanguage.key] ? item.translations[selectedLanguage.key].name : item.name
        item.description = item.translations[selectedLanguage.key]
          ? item.translations[selectedLanguage.key].description
          : item.description
        resultArr.push(item)
      } else if (allowOtherLng) {
        resultArr.push(item)
      } else if (!otherLng) {
        otherLng = true
      }
    })
    setHaveOtherLng(otherLng)
    return resultArr
  }

  return (
    <div className="">
      <div className="site-layout-content">
        <h2 className="content-title green">{t('content.My Courses')} </h2>
      </div>

      <Row className="coursesList" type="flex" align="middle" gutter={24}>
        {myActiveCourses.length === 0 ? (
          <NoDataFound message={t('content.No course found')} />
        ) : (
          <>
            {myActiveCourses.map((courses) => (
              <Col span={8} xs={24} md={8}>
                <CoursesCard
                  id={courses._id}
                  title={courses.name}
                  description={
                    courses.description
                      ? courses.description.length < 300
                        ? courses.description
                        : courses.description.substring(0, 200) + '...'
                      : ''
                  }
                  subtitle={
                    courses.modules.length +
                    ' ' +
                    (courses.modules.length <= 1 ? t('content.Module') : t('content.Modules')) +
                    ' | ' +
                    sumStepsCount(courses.modules) +
                    ' ' +
                    (sumStepsCount(courses.modules) <= 1 ? t('content.Step') : t('content.Steps'))
                  }
                  image={courses.image}
                  modules={courses.modules ? courses.modules : []}
                  steps={sumStepsCount(courses.modules)}
                  link={'/academy-course-detail/' + courses._id}
                  teamMembers={courses.teamMembers}
                />
              </Col>
            ))}
          </>
        )}
      </Row>

      <br />
      <br />

      <Row>
        <Col span={12} xs={24} md={12}>
          <div className="site-layout-content" style={{}}>
            <h2 className="content-title green">{t('content.Available Courses')} </h2>
          </div>
        </Col>
        <Col span={12} xs={24} md={12}>
          {haveOtherLng && !allowOtherLng && (
            <div style={{ marginTop: '20px', float: selectedLanguage.direction === 'rtl' ? 'left' : 'right' }}>
              <Button onClick={allowOtherLanguage} type="link">
                {t('Info.Show Courses in other languages')}
              </Button>
            </div>
          )}
        </Col>
      </Row>

      {loading ? (
        <PageLoader />
      ) : (
        <Row className="coursesList" type="flex" align="middle" gutter={24}>
          {otherActiveCourses.length === 0 ? (
            <NoDataFound message={t('content.No course found')} />
          ) : (
            <>
              {otherActiveCourses.map((courses) => (
                <Col span={8} xs={24} md={8}>
                  <CoursesCard
                    id={courses._id}
                    title={courses.name}
                    description={
                      courses.description.length < 400
                        ? courses.description
                        : courses.description.substring(0, 400) + '...'
                    }
                    subtitle={
                      courses.modules.length +
                      ' ' +
                      (courses.modules.length <= 1 ? t('content.Module') : t('content.Modules')) +
                      ' | ' +
                      sumStepsCount(courses.modules) +
                      ' ' +
                      (sumStepsCount(courses.modules) <= 1 ? t('content.Step') : t('content.Steps'))
                    }
                    image={courses.image}
                    modules={courses.modules ? courses.modules : []}
                    steps={sumStepsCount(courses.modules)}
                    link={'/academy-course-detail/' + courses._id}
                    teamMembers={courses.teamMembers}
                  />
                </Col>
              ))}
            </>
          )}
        </Row>
      )}
    </div>
  )
}

export default AcademyCourses
