import React, { useEffect, useState } from 'react'
import { PageLoader, openNotification } from '../common/components/Helper'
import { Layout, Row } from 'antd'
import { Service } from '../../services/Service'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const { Content } = Layout

const AcceptAccess = (props) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  let { id } = useParams()
  const history = useHistory()
  const { t } = useTranslation()
  useEffect(() => {
    const processInvitation = async () => {
      if (typeof id == 'undefined') {
        openNotification('error', t('content.Oops!'), t('content.Invalid Request'))
        return
      }
      setMessage('')
      setLoading(true)
      try {
        Service.post({
          url: '/user/processInvitation',
          body: JSON.stringify({
            token: id,
            status: 'accept',
          }),
        })
          .then((response) => {
            setLoading(false)
            if (response.status === 'success') {
              if (response.result.type === 'academy') {
                openNotification('success', t('content.You’re now enrolled in this course!'))
                history.push('/academy-detail/' + response.result.academy)
              } else if (response.result.type === 'course') {
                openNotification('success', t('content.You’re now enrolled in this academy!'))
                history.push('/academy-course-detail/' + response.result.course)
              }
            } else {
              openNotification('error', t('content.Unable to process your invitation'))
              setMessage(t('content.Unable to process your invitation'))
            }
          })
          .catch((err) => {
            setLoading(false)
          })
      } catch (e) {
        console.log(e)
      }
    }
    processInvitation()
  }, [])

  return (
    <Layout className="layout">
      <Content className="container ipso-content">
        <div className="site-layout-content">
          <Row>
            <br />
            <br />
          </Row>
        </div>

        {loading ? (
          <>
            {' '}
            <h2 align="center">{t('content.Please wait, We are processing your invitation!')}</h2> <br />
            <PageLoader />{' '}
          </>
        ) : (
          <></>
        )}
        <h2 align="center">{message}</h2>
      </Content>
    </Layout>
  )
}

export default AcceptAccess
