// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState } from 'react'
import {
  Roster,
  RosterGroup,
  useRosterState,
  RosterAttendee,
  // RosterCell
} from 'amazon-chime-sdk-component-library-react'
import { Badge } from 'antd'
import { useTranslation } from 'react-i18next'

const MeetingRoster = () => {
  const { roster } = useRosterState()
  // const [filter, setFilter] = useState('')
  const { t } = useTranslation()
  let attendees = Object.values(roster)

  // if (filter) {
  //   attendees = attendees.filter((attendee: any) => attendee?.name.toLowerCase().includes(filter.trim().toLowerCase()))
  // }

  // const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
  //   setFilter(e.target.value)
  // }

  const attendeeItems = attendees.map((attendee: any) => {
    const { chimeAttendeeId, externalUserId } = attendee || {}
    return (
      <RosterAttendee subtitle={externalUserId.split('#').pop()} key={chimeAttendeeId} attendeeId={chimeAttendeeId} />
    )
  })

  return (
    <Roster className="roster">
      {attendees.length > 0 && (
        <div style={{ padding: '12px', borderBottom: '1px solid #d0d0d0', marginBottom: '10px' }}>
          {t('content.Present')} &nbsp;{' '}
          <Badge
            className="site-badge-count-109"
            count={attendees.length}
            style={{ backgroundColor: '#52c41a', padding: '0px 10px' }}
          />
        </div>
      )}

      {/* <RosterCell
        name="Michael Scott"
        subtitle="Regional Manager"
        muted={false}
        videoEnabled={true} 
        a11yMenuLabel="Contact options"
      /> */}

      <RosterGroup>{attendeeItems}</RosterGroup>
    </Roster>
  )
}

export default MeetingRoster
