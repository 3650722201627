import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PageLoader, openNotification } from '../../common/components/Helper'
import NotFound from '../../common/NotFound'
import { Layout, Button, Radio, Input } from 'antd'
import { Service } from '../../../services/Service'
import { useParams } from 'react-router-dom'
import { LinkOutlined } from '@ant-design/icons'
import StepContentBlockViewer from '../../common/components/StepContentBlockViewer'

const { Content } = Layout
const { TextArea } = Input

const AdminPreviewStep = () => {
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)
  const { t } = useTranslation()
  let { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [stepData, setStateData] = useState([])

  useEffect(() => {
    getStep()
  }, [id])

  const getStep = async () => {
    setLoading(true)
    try {
      Service.get({
        url: '/academy/steps/' + id,
      })
        .then((response) => {
          setLoading(false)
          if (response) {
            console.log('GOT STEP', response)
            setStateData(response)
          }
        })
        .catch((err) => {
          setLoading(false)
        })
    } catch (e) {
      setLoading(false)
    }
  }

  const onSubmit = () => {
    openNotification('info', t('Info.No Submission'), t('Info.This is only for admin preview the step information!'))
  }

  const downloadAttachments = (attachment) => {
    window.open(Service.getImage(attachment), '_blank')
  }

  const loadAttachments = (embeds) => {
    if (!embeds) {
      return []
    }
    console.log('LOAD ATTACHMENTS EMBEDS')
    console.log(embeds)
    let response = []
    if (typeof embeds.audio != 'undefined' && embeds.audio !== null) {
      embeds.audio[selectedLanguage.key].map((audio, index) =>
        response.push(
          <div style={{ paddingBottom: '10px' }}>
            <LinkOutlined />{' '}
            <a href="#/" onClick={() => downloadAttachments(audio.filePath)}>
              {audio?.originalName || audio?.name}
            </a>{' '}
          </div>,
        ),
      )
    }

    if (typeof embeds.video != 'undefined' && embeds.video !== null) {
      embeds.video[selectedLanguage.key].map((video, index) =>
        response.push(
          <div style={{ paddingBottom: '10px' }}>
            <LinkOutlined />{' '}
            <a href="#/" onClick={() => downloadAttachments(video.filePath)}>
              {video?.originalName || video?.name}
            </a>{' '}
          </div>,
        ),
      )
    }

    if (typeof embeds.image != 'undefined' && embeds.image !== null) {
      embeds.image[selectedLanguage.key].map((image, index) =>
        response.push(
          <div style={{ paddingBottom: '10px' }}>
            <LinkOutlined />{' '}
            <a href="#/" onClick={() => downloadAttachments(image.filePath)}>
              {image?.originalName || image?.name}
            </a>
          </div>,
        ),
      )
    }

    if (typeof embeds.docs != 'undefined' && embeds.docs !== null) {
      embeds.docs[selectedLanguage.key].map((doc, index) => {
        const openType = embeds?.configs?.docs?.[selectedLanguage.key]?.[doc._id]?.openType
        const isPdf = doc.contentType?.includes('pdf')
        response.push(
          <div style={{ paddingBottom: '10px' }}>
            <LinkOutlined />{' '}
            <a
              href="#/"
              onClick={() =>
                isPdf && openType !== 'NEWTAB'
                  ? downloadFile(doc.filePath, doc.name)
                  : downloadAttachments(doc.filePath)
              }
            >
              {doc?.originalName || doc?.name}
            </a>
          </div>,
        )
      })
    }

    return response
  }

  const getHomework = (currentStep) => {
    let currentStepHomework
    if (
      currentStep.homework &&
      currentStep.homework[selectedLanguage.key] &&
      currentStep.homework[selectedLanguage.key].content &&
      currentStep.homework[selectedLanguage.key].content !== ''
    ) {
      currentStepHomework = currentStep.homework[selectedLanguage.key]
    } else if (
      currentStep.homework &&
      currentStep.homework[currentStep.defaultLanguage] &&
      currentStep.homework[currentStep.defaultLanguage].content &&
      currentStep.homework[currentStep.defaultLanguage].content !== ''
    ) {
      currentStepHomework = currentStep.homework[currentStep.defaultLanguage]
    }
    if (currentStepHomework) {
      return (
        <>
          <h3 className="green">
            <b>{t('content.Homework')}: </b>
          </h3>
          <p style={{ fontSize: '16px', whiteSpace: 'pre-wrap' }}>{currentStepHomework.content}</p>
          <small>
            <pre>
              {t('content.Minimum Words')}: {currentStepHomework.min && currentStepHomework.min}{' '}
              {t('content.Maximum Words')}: {currentStepHomework.max && currentStepHomework.max}
            </pre>
          </small>
          <TextArea placeholder={t('content.Write your homework here')} rows={4} />
          <div style={{ marginTop: '15px' }}>
            <Button onClick={() => onSubmit()} type="primary">
              {' '}
              {t('buttons.Submit')}{' '}
            </Button>
          </div>
        </>
      )
    }
  }

  const getPolls = (currentStep) => {
    try {
      const polls =
        currentStep.polls[selectedLanguage.key] && currentStep.polls[selectedLanguage.key].length > 0
          ? currentStep.polls[selectedLanguage.key]
          : currentStep.polls[currentStep.defaultLanguage]
      if (polls && polls.length > 0 && Array.isArray(polls) && Object.keys(polls[0]).length > 0 && polls[0].question) {
        return (
          <>
            <div className="site-layout-content">
              <br />
              <br />
              <h3 className="green">
                <b>{t('content.Quiz')}: </b>
              </h3>
            </div>

            {polls.map((poll, index) => {
              return (
                <>
                  <h4>{poll.question}</h4>

                  <Radio.Group className={`poll-question-${index}`} buttonStyle="solid">
                    {poll &&
                      poll.options &&
                      poll.options.map((option, j) => <Radio.Button value={option.title}>{option.title}</Radio.Button>)}
                  </Radio.Group>
                </>
              )
            })}

            <div style={{ marginTop: '15px' }}>
              <Button type="primary" onClick={() => onSubmit()} htmlType="submit">
                {t('buttons.Submit')}
              </Button>
            </div>
          </>
        )
      }
    } catch (err) {
      return <div></div>
    }
  }

  const downloadFile = (filePath, fileName) => {
    fetch(Service.getImage(filePath), {
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      })
  }

  return (
    <>
      <Layout className="learnModule">
        <Content className="container ipso-content">
          <div style={{ background: '#fff', padding: '25px' }}>
            {loading ? (
              <PageLoader />
            ) : (
              <>
                {stepData.status === 'failed' ? (
                  <NotFound />
                ) : (
                  <>
                    <div className="site-layout-background course-view-steps">
                      {stepData.name && (
                        <h2 className="content-title green">
                          {' '}
                          {stepData &&
                          stepData.translations &&
                          stepData.translations[selectedLanguage.key] !== undefined
                            ? stepData.translations[selectedLanguage.key].name
                            : stepData.name}{' '}
                        </h2>
                      )}
                      {stepData.stepImage && (
                        <img
                          src={Service.getImage(stepData.stepImage.filePath)}
                          style={{ width: '100%' }}
                          alt="Step"
                        ></img>
                      )}

                      <StepContentBlockViewer
                        content={
                          stepData &&
                          stepData.translations &&
                          (stepData.translations[selectedLanguage.key] !== undefined
                            ? stepData.translations[selectedLanguage.key].description
                            : stepData.description)
                        }
                      />

                      {loadAttachments(stepData)}

                      <div style={{ marginTop: '20px' }}>{getHomework(stepData)}</div>

                      <div className="step-polls">{getPolls(stepData)}</div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </Content>
      </Layout>
    </>
  )
}

export default AdminPreviewStep
