import { Input, Form, Button, Modal, Select, Upload, Radio, notification } from 'antd'
import ImgCrop from 'antd-img-crop'
import { useTranslation } from 'react-i18next'
import { UploadOutlined } from '@ant-design/icons'

import { languageInfo } from '../../../otherLanguages'
import Config from './../../../config.json'
import { useSelector, useDispatch } from 'react-redux'
import { editAcademy, addAcademy, setAcademyModalVisible } from '../../../redux/actions/admin'
import QuillEditor from '../../common/QuillEditor'
import { useEffect, useState } from 'react'
import { Service } from '../../../services/Service'

const AddAcademyModal = ({
  popupMode,
  form,
  activeLanguages,
  onFileUploadChange,
  onPreview,
  handleLanguageChange,
  fileSelection,
  editAcademyInfo,
}) => {
  const { academyModalVisibility } = useSelector(({ adminReducer }) => adminReducer)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [unusedFiles, setUnusedFiles] = useState([])
  const [waitingSubmitFiles, setWaitingSubmitFiles] = useState([])

  useEffect(() => {
    if (academyModalVisibility) {
      setWaitingSubmitFiles([])
      setUnusedFiles([])
    }
  }, [academyModalVisibility])

  const openNotification = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    })
  }

  //Modal ok button
  const handleOk = () => {
    dispatch(setAcademyModalVisible(false))
  }

  const closeEditModal = async () => {
    // Call API to delele unused files
    if (waitingSubmitFiles.length > 0) {
      await Service.delete({
        url: '/fileUpload',
        body: JSON.stringify(waitingSubmitFiles),
      })
    }

    dispatch(setAcademyModalVisible(false))
    form.resetFields()
    setUnusedFiles([])
  }

  const clearFormData = () => {
    form.setFieldsValue({ subTitle: '' }) //To avoid quill texteditor error
    setUnusedFiles([])
  }

  // handle image removed
  const onRemoveUploadedImage = (removedImage) => {
    if (removedImage.rawData || removedImage.response) {
      setUnusedFiles([...unusedFiles, removedImage.rawData || removedImage.response])
    }
  }

  // handle upload file change
  const onFileUploadedChange = ({ fileList }) => {
    if (typeof fileList[0] != 'undefined' && fileList[0].response) {
      setWaitingSubmitFiles([...waitingSubmitFiles, { id: fileList[0].response.id }])
    }
    if (typeof onFileUploadChange === 'function') {
      onFileUploadChange({ fileList })
    }
  }

  const submitForm = (values) => {
    var params = {
      name: values.name,
      subTitle: values.subTitle,
      language: values.language,
      defaultLanguage: values.defaultLanguage,
      translations: values.translations ? values.translations : {},
      academyType: values.academyType,
      status: 0,
      unusedFiles,
    }

    //Push image selection
    if (values.image && typeof values.image.id == 'undefined') {
      params.image = values.image
    } else if (!values.image) {
      openNotification('warning', t('content.Missing Image'), t('content.Please select academy image!'))
      return
    }

    if (popupMode == 'Edit') {
      params['status'] = values.status
      dispatch(editAcademy(editAcademyInfo._id, params))
    } else {
      dispatch(addAcademy(params))
    }
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  }

  const validateMessages = {
    required: '${label} ' + t('errors.is required!'),
    types: {
      email: '${label} ' + t('errors.is not a valid email!'),
      number: '${label} ' + t('errors.is not a valid number!'),
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }
  const { Option } = Select

  return (
    <Modal
      title={
        popupMode == 'Add'
          ? t('buttons.Add') + ' ' + t('auth.Academy Information')
          : t('buttons.Edit') + ' ' + t('auth.Academy Information')
      }
      visible={academyModalVisibility}
      onOk={handleOk}
      onCancel={closeEditModal}
      afterClose={clearFormData}
      footer={false}
      width={500}
      maskClosable={false}
    >
      <Form
        {...layout}
        name="nest-messages"
        form={form}
        layout="vertical"
        onFinish={submitForm}
        initialValues={{}}
        validateMessages={validateMessages}
      >
        <Form.Item name={['name']} label={t('content.Academy Name (English)')} rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item name={['subTitle']} label={t('content.Subtitle (English)')}>
          <QuillEditor />
        </Form.Item>

        <Form.Item name={['language']} label={t('content.Add Language')}>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder={t('content.Please select')}
            onChange={handleLanguageChange}
          >
            <Option value={'de'} key={2}>
              German
            </Option>
            <Option value={'ar'} key={3}>
              Arabic
            </Option>
          </Select>
        </Form.Item>

        {activeLanguages.map((language, index) => (
          <div key={index}>
            <Form.Item
              name={['translations', language, 'name']}
              label={t('content.Academy Name') + ' ' + `(${languageInfo(language).name})`}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={['translations', language, 'subTitle']}
              label={t('content.Subtitle') + ' ' + ` (${languageInfo(language).name})`}
            >
              <QuillEditor />
            </Form.Item>
          </div>
        ))}

        <Form.Item name={['defaultLanguage']} label={t('content.Default Language')} rules={[{ required: true }]}>
          <Select placeholder={t('content.Please select')}>
            <Option value="en">{t('content.English')}</Option>
            <Option value="de">{t('content.German')}</Option>
            <Option value="ar">{t('content.Arabic')}</Option>
          </Select>
        </Form.Item>

        <Form.Item name={['academyType']} label={t('content.Academy Type')} rules={[{ required: true }]}>
          <Select placeholder={t('content.Academy Type')}>
            <Option value="public">{t('content.Public')}</Option>
            <Option value="private">{t('content.Private')}</Option>
          </Select>
        </Form.Item>

        {popupMode == 'Edit' && (
          <Form.Item name={['status']} label={t('content.Academy Status')} rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={0}>{t('content.Unpublished')}</Radio>
              <Radio value={1}>{t('content.Published')}</Radio>
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item name={['image']} label={t('content.Choose Academy Image')} rules={[{ required: true }]}>
          <ImgCrop rotate aspect={0.8 / 1}>
            <Upload
              action={`${Config.BASE_URL}/fileUpload `}
              listType="picture"
              onChange={onFileUploadedChange}
              onPreview={onPreview}
              maxCount={1}
              defaultFileList={fileSelection}
              headers={{ 'x-access-token': localStorage.getItem('token') }}
              onRemove={onRemoveUploadedImage}
            >
              <Button icon={<UploadOutlined />}>{t('buttons.Upload')}</Button>
            </Upload>
          </ImgCrop>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t('buttons.Submit')}
          </Button>{' '}
          &nbsp; &nbsp;
          <Button type="default" htmlType="button" onClick={closeEditModal}>
            {t('buttons.Cancel')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddAcademyModal
