import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PhoneOutlined } from '@ant-design/icons'
import { Avatar, Button, Row, Col, notification } from 'antd'
import { Storage } from '../../../services/Service'
import { SocketContext } from '../../context/socket'
import Config from '../../../config.json'
import { GetInitial } from './Helper'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setCallingUser } from '../../../redux/actions/buddyCall'

const BuddyCallNotification = (props) => {
  const { t } = useTranslation()
  const { callingUser } = useSelector((state) => state, shallowEqual).buddyCall
  const dispatch = useDispatch()

  const socket = useContext(SocketContext)
  const [buddyCall, setBuddyCall] = useState(false)
  const [callInitiated, setCallInitiated] = useState(false)
  const [timer, setTimer] = useState(false)
  const [userInfo, setUserInfo] = useState(false)
  const [buddyCallURL, setBuddyCallURL] = useState('')
  const [clickedAcceptCall, setClickedAcceptCall] = useState(false)
  const [fixedBar, setFixedBar] = useState(false)

  const [ringAudio] = useState(new Audio(Config.SITE_URL + '/audio/ringtone.mp3'))
  // const [joinAudio] = useState(new Audio(Config.SITE_URL + '/audio/join_meeting.mp3'))
  const [rejectAudio] = useState(new Audio(Config.SITE_URL + '/audio/reject.mp3'))

  useEffect(() => {
    setUserInfo(Storage.get('auth'))

    socket.on('connect', () => {
      console.log('connected')

      const token = Storage.getToken('token')
      if (token) {
        socket.emit('setOnline', JSON.stringify({ token }))
      }
    })

    socket.removeAllListeners('meetingRequestReceived')
    socket.on('meetingRequestReceived', (data) => {
      setBuddyCall(JSON.parse(data))
      ringAudio.currentTime = 0
      ringAudio.loop = true
      ringAudio.play()
      //Ring for given sec, If user not accepts then clear timer
      if (timer) {
        clearTimeout(timer)
        setTimer(false)
      }
      var tmptimer = setTimeout(() => {
        setBuddyCall(false)
        ringAudio.pause()
      }, Config.CALL_RING_TIME * 1000)
      setTimer(tmptimer)
      //return () => clearTimeout(timer);
    })

    socket.removeAllListeners('meetingRequestAccepted')
    socket.on('meetingRequestAccepted', (data) => {
      setClickedAcceptCall(false)
      setBuddyCall(false)
      ringAudio.pause()
      setCallInitiated(true)
      setTimeout(() => {
        // joinAudio.play();
        setCallInitiated(false)
        // let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;
        let meet = JSON.parse(data)
        if (meet.meeting && meet.meeting != '') {
          const callingTab = window.open(Config.SITE_URL + '/buddycall/' + meet.meeting, '_blank')
          if (!callingTab || callingTab.callingTab || typeof callingTab.closed == 'undefined') {
            setBuddyCallURL(Config.SITE_URL + '/buddycall/' + meet.meeting)
          } else {
            callingTab.focus()
          }
        } else {
          alert('Unable to create meeting. Please try again')
        }
      }, 500)
    })

    socket.removeAllListeners('userNotFoundError')
    socket.on('userNotFoundError', () => {
      openNotificationWithIcon('warning', t('errors.User is offline now'), '')
    })

    socket.removeAllListeners('meetingRequestRejected')
    socket.on('meetingRequestRejected', (data) => {
      ringAudio.pause()
      rejectAudio.play()
      openNotificationWithIcon('error', t('errors.User rejected your call'), '')
    })

    const token = Storage.getToken('token')
    if (token) {
      socket.emit('setOnline', JSON.stringify({ token }))
    }

    return () => {
      //socket.off('meetingRequestRejected', eventHandler);
    }
  }, [])

  useEffect(() => {
    const onWindowScroll = () => {
      if (window.scrollY > 100) {
        setFixedBar(true)
      } else {
        setFixedBar(false)
      }
    }

    window.addEventListener('scroll', onWindowScroll)

    return () => {
      window.removeEventListener('scroll', onWindowScroll)
    }
  }, [fixedBar])

  const openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    })
  }

  const cancelCall = () => {
    setBuddyCall(false)
    clearTimeout(timer)
    setTimer(false)
    ringAudio.pause()
    socket.emit('rejectCall', JSON.stringify({ sender: buddyCall._id, receiver: userInfo._id }))
  }

  const acceptCall = () => {
    if (!clickedAcceptCall) {
      setClickedAcceptCall(true)
      ringAudio.pause()
      socket.emit('acceptCall', JSON.stringify({ sender: buddyCall._id, receiver: userInfo._id }))
    }
  }

  return (
    <>
      {/* Show this bar if buddy call rings */}
      {buddyCall && (
        <div className={['site-page-header-ghost-wrapper container buddy-talk', fixedBar ? 'fixed' : ''].join(' ')}>
          <Row className="container" style={{ padding: '20px 40px' }}>
            <Col span={18} lg={18} sm={12} xs={24} className="buddy-details">
              <Avatar className="callring">
                <GetInitial text={buddyCall.name} />
              </Avatar>
              <span className="title">{buddyCall.name} </span>
              <span className="sub-title">
                {t('content.is calling you!')} <PhoneOutlined rotate={90} />
              </span>
            </Col>
            <Col span={6} lg={6} sm={12} xs={24} className="buddy-buttons">
              <Button onClick={acceptCall} key="1" className="callring">
                {t('buttons.Accept Call')}
              </Button>
              <Button onClick={cancelCall} key="2" className="callreject">
                {t('buttons.Reject Call')}
              </Button>
            </Col>
          </Row>
        </div>
      )}
      {/* end buddy call */}

      {/* Show this bar if buddy call accepted and on progress */}
      {callInitiated && (
        <div className={['site-page-header-ghost-wrapper container buddy-talk', fixedBar ? 'fixed' : ''].join(' ')}>
          <Row className="container" style={{ padding: '20px 40px' }}>
            <Col span={18} lg={18} xs={24} className="buddy-details">
              <span>{t('content.Please hold, Connecting to opponent')}:</span>
              <Avatar className="callring">
                <GetInitial text={buddyCall.name} />
              </Avatar>
              <span className="title">{buddyCall.name} </span>
            </Col>
            <Col span={6} lg={6} xs={24} className="buddy-buttons">
              <Button onClick={cancelCall} key="2" className="callreject">
                {t('buttons.Cancel Call')}
              </Button>
            </Col>
          </Row>
        </div>
      )}
      {/* end buddy call progress*/}

      {buddyCallURL && !callInitiated && (
        <div
          className={[
            'site-page-header-ghost-wrapper container buddy-talk buddy-talk-popup',
            fixedBar ? 'fixed' : '',
          ].join(' ')}
        >
          <Row className="container" style={{ padding: '20px 40px' }}>
            <Col className="popup-prevent-message">
              {callingUser && (
                <span>
                  <b>{`${callingUser.name || ''} ${callingUser.surname || ''}`}</b>&nbsp;
                  {`${t('content.has been accepted your call')}`}
                </span>
              )}
              {!callingUser && <span>{t('content.Your browser is blocking pop-up windows')}</span>}
              &nbsp;
              <span>{t('content.Click the button to join the call')}</span>
              <Button
                href={buddyCallURL}
                target="_blank"
                className="join-buddy-btn"
                onClick={() => {
                  setBuddyCallURL('')
                  dispatch(setCallingUser(null))
                }}
              >
                {t('buttons.Join Call')}
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default BuddyCallNotification
