import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Layout, Tabs } from 'antd'
import { Breadcrumb, notification } from 'antd'
import { PageLoader } from '../common/components/Helper'
import { Service } from '../../services/Service'
import AcademyUsers from './components/AcademyUsers'
import AcademyCourses from './components/AcademyCourses'
import { BrowserRouter as Router, useParams, Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

const { Content } = Layout

const AcademyDetail = (props) => {
  const { t } = useTranslation()
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)

  let { id } = useParams()

  const [tabKey, setTabKey] = useState('1')

  const [loading, setLoading] = useState(false)

  const [academyDetails, setAcademyDetails] = useState({ courses: {} })

  const history = useHistory()

  useEffect(() => {
    if (typeof id == 'undefined') {
      return false
    }
    const fetchAcademyCourses = async () => {
      setLoading(true)
      try {
        Service.get({
          url: '/academy/' + id,
        })
          .then((response) => {
            setLoading(false)
            if (response.status === 'error') {
              notification.warning({
                message: 'Not found',
                description: 'Academy not found!',
                duration: 0,
              })
              history.push('/dashboard')
            } else {
              setAcademyDetails(response)
            }
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
          })
      } catch (e) {
        console.log(e)
      }
    }
    fetchAcademyCourses()
  }, [])

  const callback = (key) => {
    setTabKey(key)
  }

  return (
    <Layout className="layout bgWhite">
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <Content
            className="ipso-cont ent"
            style={{ backgroundColor: '#f0f2f5', minHeight: '100px', paddingBottom: '0px' }}
          >
            <div className="container fullWidthGrey">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={'/dashboard'}>{t('content.Academies')}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a href="#/">
                    {academyDetails &&
                    academyDetails.translations &&
                    academyDetails.translations[selectedLanguage.key] !== undefined
                      ? academyDetails.translations[selectedLanguage.key].name
                      : academyDetails.name}
                  </a>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div className="site-layout-content">
                <h1 className="page-title">
                  {academyDetails &&
                  academyDetails.translations &&
                  academyDetails.translations[selectedLanguage.key] !== undefined
                    ? academyDetails.translations[selectedLanguage.key].name
                    : academyDetails.name}
                </h1>
              </div>
              <Tabs defaultActiveKey="1" onChange={callback} className="academy-detail-tab">
                <Tabs.TabPane tab={t('content.Courses')} key="1"></Tabs.TabPane>
                <Tabs.TabPane tab={t('content.Learners')} key="2"></Tabs.TabPane>
              </Tabs>
            </div>
          </Content>

          {/* Show Tabs Content Seperately */}
          <div className="container tabContentPadding user-academy-details">
            {tabKey === '1' && (
              <>{loading ? <PageLoader /> : <AcademyCourses academyId={id} courses={academyDetails.course} />}</>
            )}

            {tabKey === '2' && <AcademyUsers academyId={id} />}
          </div>
        </>
      )}
    </Layout>
  )
}

export default AcademyDetail
