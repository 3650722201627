import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Service, Storage } from '../../../services/Service'
import { useSelector, useDispatch } from 'react-redux'
import { GetInitial, RandColor, NoDataFound, openNotification } from '../../common/components/Helper'
import { Menu, Button, List, Avatar, Col, Row, Progress, Modal, Divider, Input } from 'antd'
import { LeftOutlined, LinkOutlined } from '@ant-design/icons'
import { getModuleStudents, getModuleHomeworkDetails } from '../../../redux/actions/moduleDetail'
import { DateTime } from 'luxon'
import FileDownloadLink from '../../common/components/FileDownloadLink'

const AdminCourseStudentOverview = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { SubMenu } = Menu
  const userInfo = Storage.get('auth')
  const [viewStudentSection, setViewStudentSection] = useState(false)
  const [checkPopup, setCheckPopup] = useState(false)
  const [moduleList, setModuleList] = useState([])
  const [currentStudent, setCurrentStudent] = useState([])
  const [currentModuleSteps, setCurrentModuleSteps] = useState()
  const [currentModuleName, setCurrentModuleName] = useState()
  const [currentModuleId, setCurrentModuleId] = useState()
  const [openKeys, setOpenKeys] = useState([])
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)
  const { moduleStudentDetails, homeWorkDetails } = useSelector(({ moduleDetailReducer }) => moduleDetailReducer)
  const [coursePercentage, setCoursePercentage] = useState(0)
  const [totalSteps, setTotalSteps] = useState(0)
  const [homeworkDetailIndex, setHomeworkDetailIndex] = useState(0)
  const [feedbackContent, setFeedbackContent] = useState('')

  useEffect(() => {
    if (props.course) {
      if (props.course.modules && props.course.modules.length > 0) {
        var members = props.course.teamMembers
        if (userInfo && userInfo.role == 'module_trainer') {
          let obj = members.find((x) => x.user._id === userInfo._id)
          if (obj) {
            const modules = props.course.modules.filter((item) => {
              return obj.modules.includes(item._id)
            })
            setModuleList(modules)
          } else {
            setModuleList([])
          }
        } else {
          setModuleList(props.course.modules)
        }

        var tmp_totalSteps = 0
        props.course.modules.forEach((a, i) => {
          tmp_totalSteps = tmp_totalSteps + a.steps.length
        })
        setTotalSteps(tmp_totalSteps)
      }
    }
  }, [props?.course])

  const rootKeys = moduleList.map((module, key) => `item-${key}`)

  const translate = (data) => {
    return JSON.parse(JSON.stringify(data)).map((item) => {
      if (item.translations && item.translations.hasOwnProperty(selectedLanguage.key)) {
        item.name = item.translations[selectedLanguage.key].name
        item.description = item.translations[selectedLanguage.key].description
      }
      return item
    })
  }

  const viewStudent = (studentList, moduleId, moduleName, moduleSteps) => {
    //setCurrentModuleName(moduleName)
    setCurrentStudent(studentList && studentList)
    setCurrentModuleSteps(moduleSteps)
    dispatch(getModuleHomeworkDetails(moduleId, studentList.userId._id))
    getCourseProgress(props.course._id, studentList.userId._id)
    setViewStudentSection(true)
  }
  const viewStudentOverview = () => {
    setViewStudentSection(false)
  }

  const getCourseProgress = (courseId, userId) => {
    try {
      Service.get({
        url: '/progress/courseUser/' + courseId + '/' + userId,
      })
        .then((response) => {
          var completedMod = 0
          var completedsteps = 0
          if (response && response.length > 0) {
            response.forEach((a, index) => {
              if (index === 0) {
                setCurrentModuleName(a.module.name)
                setCurrentModuleId(a.module._id)
              }
              if (a.status == 1) {
                completedMod = completedMod + 1
              }
              completedsteps = completedsteps + a.completedSteps.length
            })
          }
          //var percentage = Math.round( (completedMod / moduleList.length) * 100 )
          var percentage = Math.round((completedsteps / totalSteps) * 100)
          setCoursePercentage(percentage)
        })
        .catch((err) => {})
    } catch (e) {
      console.log(e)
    }
  }

  const getListItem = (studentList, moduleId, moduleName, moduleSteps, key, view) => {
    return (
      studentList.userId && (
        <List
          key={`student-list-item-${key}`}
          itemLayout="horizontal"
          className="student-section"
          onClick={() => viewStudent(studentList && studentList, moduleId, moduleName, moduleSteps)}
        >
          <List.Item key={`list:${key}`}>
            <div className="topProfileMenu">
              <Avatar
                className="avatar"
                style={{ backgroundColor: studentList.userId.color ? studentList.userId.color : '' }}
              >
                <GetInitial text={studentList && studentList.userId && studentList.userId.name} />
              </Avatar>
              <span className={`availablityIndicator ${studentList.userId.isOnline ? 'active' : 'inactive'}`}></span>
            </div>

            <List.Item.Meta
              title={
                (studentList && studentList.userId && studentList.userId.name) +
                ' ' +
                (studentList && studentList.userId && studentList.userId.surname)
              }
              description={
                typeof view == 'undefined' && moduleName
                  ? ' ' + t('content.Module') + ': ' + moduleName
                  : studentList && studentList.userId && studentList.userId.email
              }
              className="metaInfo"
            />
          </List.Item>
        </List>
      )
    )
  }

  const getCurrentSubMenu = (studentList, moduleId, moduleName, moduleSteps, key) => {
    return (
      <SubMenu className="status" key={`current-${key}`} title={t('content.Current')}>
        {studentList &&
          studentList.map((student, index) => getListItem(student, moduleId, moduleName, moduleSteps, index, 'list'))}
      </SubMenu>
    )
  }

  const getCompletedSubMenu = (studentList, moduleId, moduleName, moduleSteps, key) => {
    return (
      <SubMenu className="status" key={`completed-${key}`} title={t('content.Completed')}>
        {studentList &&
          studentList.map((student, index) => getListItem(student, moduleId, moduleName, moduleSteps, index, 'list'))}
      </SubMenu>
    )
  }

  const openCheckPopup = (index) => {
    setHomeworkDetailIndex(index)
    setCheckPopup(true)
  }
  const closePopup = () => {
    setCheckPopup(false)
  }

  const moduleStudentsHandler = (moduleId) => {
    dispatch(getModuleStudents(moduleId))
  }
  const onOpenChange = (items) => {
    const latestOpenKey = items.find((key) => openKeys.indexOf(key) === -1)
    if (rootKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(items)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const sendFeedback = (feedbackId) => {
    Service.patch({
      url: `/progress/homework`,
      body: JSON.stringify({
        id: feedbackId,
        feedback: {
          feedbackBy: userInfo._id,
          content: feedbackContent,
          feedbackAt: DateTime.utc().toISO(),
        },
        courseId: props.course._id,
        step: popupHomework?.step._id,
      }),
    }).then((response) => {
      openNotification('success', t('content.Success'), t('Info.Feedback successfully sent'))
      dispatch(getModuleHomeworkDetails(currentModuleId, currentStudent.userId._id))
    })
  }

  const popupHomework = homeWorkDetails?.homework?.[homeworkDetailIndex]
  return viewStudentSection ? (
    <>
      <Modal
        className="admin-student-overview"
        visible={checkPopup}
        footer={false}
        width={705}
        onCancel={closePopup}
        maskClosable={false}
      >
        {getListItem(currentStudent && currentStudent, '', currentModuleName)}

        {popupHomework && (
          <>
            {popupHomework.title ? (
              <h4 className="content-title green" style={{ whiteSpace: 'pre-wrap' }}>
                {popupHomework.title}
              </h4>
            ) : null}
            {popupHomework.homework && (
              <Row>
                <Col span={20}>
                  <p style={{ whiteSpace: 'pre-wrap', marginLeft: '8px' }}>{popupHomework.homework}</p>
                </Col>
                <Col span={4}>
                  {popupHomework.wordCount ?? popupHomework?.homework.split(' ')?.filter((item) => item).length ?? 0}
                  &nbsp; {t('Info.Word')}
                </Col>
              </Row>
            )}
            {popupHomework.attachment && (
              <Row style={{ paddingBottom: '10px', marginLeft: '5px' }}>
                <Col span={20} style={{ marginTop: -10 }}>
                  <FileDownloadLink
                    filePath={popupHomework.attachment.filePath}
                    name={popupHomework.attachment.originalName}
                  />
                </Col>
                <Col span={4}>
                  {popupHomework.wordCount}&nbsp; {t('Info.Word')}
                </Col>
              </Row>
            )}
            <Divider />
            {
              <>
                <h3>{t('buttons.Feedback')}: </h3>
                <Input.TextArea
                  disabled={popupHomework?.feedback?.content}
                  value={popupHomework?.feedback?.content}
                  onChange={(e) => setFeedbackContent(e.target.value)}
                  placeholder={t('content.Write your feedback here')}
                  rows={4}
                  key={popupHomework?._id}
                />
                {!popupHomework?.feedback?.content && (
                  <Row justify="end" style={{ marginTop: '10px' }}>
                    <Button onClick={() => sendFeedback(popupHomework._id)} type="primary">
                      {t('buttons.Feedback')}
                    </Button>
                  </Row>
                )}
              </>
            }
          </>
        )}
      </Modal>
      <Row className="admin-student-overview">
        <Col span={24} className="back-to-overview" onClick={viewStudentOverview}>
          <LeftOutlined style={{ marginRight: '10px' }} />
          {t('content.Student Overview')}
        </Col>
        <Col span={24}>
          <div className="class-room-block overview-block">
            {getListItem(currentStudent && currentStudent)}
            <Row className="schedule">
              <Col span={8} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                <div className="courseProgress" style={{ marginBottom: '20px' }}>
                  <h4 className="content-title green">{t('content.Module Progress')} </h4>
                  <Progress
                    percent={Math.round((currentStudent.completedSteps.length / currentModuleSteps) * 100)}
                    status="active"
                  />
                  <h4 className="content-title green">{t('content.Course Progress')} </h4>
                  <Progress percent={coursePercentage} status="active" />
                </div>
              </Col>
              {homeWorkDetails.polls && homeWorkDetails.polls.length > 0 ? (
                <Col
                  className="mcq-section"
                  span={5}
                  xl={{ span: 5, offset: 3 }}
                  lg={{ span: 5, offset: 3 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 5, offset: 3 }}
                >
                  <div>{t('content.MC Question Result')}</div>
                  <br></br>
                  {homeWorkDetails.polls &&
                    homeWorkDetails.polls.map((steps, index) => (
                      <div className="mcq-steps" key={'step-polls-' + index}>
                        {steps.attempt > 1
                          ? t('content.Step') + ' ' + ` ${index + 1}:  ${steps.attempt} ` + t('content.attempts')
                          : t('content.Step') + ' ' + ` ${index + 1}: ${steps.attempt} ` + t('content.attempt')}
                      </div>
                    ))}
                </Col>
              ) : null}
            </Row>

            <Row className="current-module" style={{ marginTop: '15px' }}>
              <Col className="module" span={24} style={{ marginTop: '15px' }}>
                <div>
                  {t('content.Current Module')}: <span style={{ fontWeight: 400 }}>{currentModuleName}</span>{' '}
                </div>
                {homeWorkDetails.homework && homeWorkDetails.homework.length > 0 && (
                  <div style={{ marginBottom: '0.5em' }}> {t('content.Homework Assignments')}: </div>
                )}
              </Col>
            </Row>

            {homeWorkDetails.homework &&
              homeWorkDetails?.homework.map((details, idx) => (
                <Row key={'homework-row-' + idx}>
                  <Col
                    className="homeworks"
                    lg={{ span: 18 }}
                    md={{ span: 18 }}
                    sm={{ span: 18 }}
                    xs={{ span: 24 }}
                    style={{ marginTop: '15px' }}
                  >
                    <h5 className="hw-title">{details.title}</h5>
                    {details.homework && (
                      <Row>
                        <Col span={16}>
                          <p style={{ whiteSpace: 'pre-wrap', marginLeft: '8px' }}>
                            {details.homework.length < 110
                              ? details.homework
                              : details.homework.substring(0, 110) + '...'}
                          </p>
                        </Col>
                        <Col span={8}>
                          {details.wordCount ?? details?.homework.split(' ')?.filter((item) => item).length ?? 0}&nbsp;{' '}
                          {t('Info.Word')}
                        </Col>
                      </Row>
                    )}
                    {details.attachment && (
                      <Row style={{ paddingBottom: '10px', marginLeft: '5px' }}>
                        <Col span={16} style={{ marginTop: -10 }}>
                          <FileDownloadLink
                            filePath={details.attachment.filePath}
                            name={details.attachment.originalName}
                          />
                        </Col>
                        <Col span={8}>
                          {details.wordCount}&nbsp; {t('Info.Word')}
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col lg={{ span: 2 }} md={{ span: 2 }} sm={{ span: 2 }} xs={{ span: 0 }}></Col>
                  <Col
                    lg={{ span: 4 }}
                    md={{ span: 4 }}
                    sm={{ span: 4 }}
                    xs={{ span: 24 }}
                    style={{ alignSelf: 'center' }}
                  >
                    <Button className="join-class-button" onClick={() => openCheckPopup(idx)}>
                      {t('buttons.Check')}
                    </Button>
                  </Col>
                  <Divider />
                </Row>
              ))}
          </div>
        </Col>
      </Row>
    </>
  ) : (
    <>
      {moduleList && moduleList.length == 0 ? <NoDataFound /> : null}

      <Menu
        onOpenChange={onOpenChange}
        openKeys={openKeys}
        className="admin-student-overview"
        theme="light"
        defaultSelectedKeys={['1']}
        mode="inline"
      >
        {translate(moduleList).map((module, key) => (
          <SubMenu
            onTitleClick={() => moduleStudentsHandler(module._id)}
            className="module-name"
            key={`item-${key}`}
            title={module.name}
          >
            {getCurrentSubMenu(
              moduleStudentDetails[module._id] && moduleStudentDetails[module._id].filter((student) => !student.status),
              module._id,
              module.name,
              module.steps.length,
              key,
            )}
            {getCompletedSubMenu(
              moduleStudentDetails[module._id] && moduleStudentDetails[module._id].filter((student) => student.status),
              module._id,
              module.name,
              module.steps.length,
              key,
            )}
          </SubMenu>
        ))}
      </Menu>
    </>
  )
}

export default AdminCourseStudentOverview
