import {
  IS_ADMIN,
  SET_ACADEMY,
  SET_ACADEMY_MODAL_VISIBILITY,
  SET_USER_DETAILS,
  GET_COURSE_BY_ACADEMY,
} from './../action-creators/admin'

const initialState = {
  isAdmin: undefined,
  academies: [],
  isFetching: false,
  userDetails: [],
  courseListWithDetails: undefined,
}

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_ADMIN:
      if (!localStorage.getItem('auth')) {
        return {
          ...state,
          isAdmin: false,
        }
      } else {
        var userRole = JSON.parse(localStorage.getItem('auth')).role
        if (userRole == 'admin') {
          return {
            ...state,
            isAdmin: true,
          }
        } else {
          return {
            ...state,
            isAdmin: false,
          }
        }
      }

    case SET_ACADEMY_MODAL_VISIBILITY: {
      return {
        ...state,
        academyModalVisibility: action.payload,
      }
    }

    case SET_ACADEMY: {
      return {
        ...state,
        academies: action.payload,
        isFetching: action.isFetching,
      }
    }

    case SET_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.payload,
      }
    }

    case GET_COURSE_BY_ACADEMY: {
      return {
        ...state,
        courseListWithDetails: action.payload,
        isCourseFetching: action.isCourseFetching,
      }
    }

    default:
      return state
  }
}

export default adminReducer
