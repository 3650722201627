import React from 'react'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Row, Col, Image, Card, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { Service } from '../../services/Service'
import noImage from '../../asset/images/no-image.png'

const AcademicCard = (props) => {
  return (
    <Link to={'academy-detail/' + props.id}>
      <Card className="academic-list" bordered={false} style={{ marginBottom: '24px', height: '200px' }}>
        <Row>
          <Col span={14} order={1}>
            <Tooltip placement="top" title={props.title}>
              <h2 className="title">{props.title}</h2>
            </Tooltip>
            <p className="description">{props.course}</p>
          </Col>
          <Col span={10} order={2} className="image-container">
            {props.image ? (
              <Image
                preview={false}
                height={150}
                width={130}
                style={{ objectFit: 'cover' }}
                fallback={noImage}
                src={Service.getImage(props.image.filePath)}
              />
            ) : (
              <Image
                width={130}
                height={150}
                src="https://via.placeholder.com/150"
                preview={false}
                fallback={noImage}
              />
            )}
          </Col>
        </Row>
        <div className="go-to-next-academic">
          <ArrowRightOutlined />
        </div>
      </Card>
    </Link>
  )
}

export default AcademicCard
