import React, { useEffect, useState } from 'react'
import { PageLoader } from '../../common/components/Helper'
import ListCourseClass from '../../common/components/ListCourseClass'

const CourseClassRoom = (props) => {
  const [loading, setLoading] = useState(false)
  const [courseData, setCourseData] = useState([])

  useEffect(() => {
    if (props.course) {
      setCourseData(props.course)
    }
  }, [props?.course])

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <ListCourseClass course={courseData} />
        </>
      )}
    </>
  )
}

export default CourseClassRoom
