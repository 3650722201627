import React, { useEffect, useState, useImperativeHandle, forwardRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PageLoader, NoDataFound, GetInitial, openNotification, GetRole } from '../../common/components/Helper'
import { Layout, List, Avatar, Button, Row, Col, Select, Form, Tooltip, Modal } from 'antd'
import { Service } from '../../../services/Service'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { SocketContext } from '../../context/socket'

const { Option } = Select
const { confirm } = Modal

const GroupUsers = forwardRef((props, ref) => {
  const { t } = useTranslation()
  let { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [usersList, setUsersList] = useState([])
  const [usersListOriginal, setUsersListOriginal] = useState([])
  const [form] = Form.useForm()
  const socket = useContext(SocketContext)

  useImperativeHandle(ref, () => ({
    reloadGroupUser() {
      fetchGroupUsers()
    },
  }))

  useEffect(() => {
    if (typeof id == 'undefined') {
      return false
    }
    fetchGroupUsers()
  }, [])

  useEffect(() => {
    const handleUserStatusChange = (userData) => {
      const changedUser = JSON.parse(userData)
      for (let i = 0; i < usersList.length; i++) {
        if (changedUser._id === usersList[i]._id) {
          setUsersList([...usersList.slice(0, i), changedUser, ...usersList.slice(i + 1)])
          break
        }
      }
    }
    socket.on('updateUserStatus', handleUserStatusChange)

    return () => {
      socket.off('updateUserStatus', handleUserStatusChange)
    }
  }, [socket, usersList])

  const fetchGroupUsers = async () => {
    try {
      setLoading(true)
      Service.get({
        url: '/groups/' + id,
      })
        .then((response) => {
          setLoading(false)
          if (response.hasOwnProperty('error')) {
            return
          }
          if (response.result.users.length > 0) {
            setUsersList(response.result.users)
            setUsersListOriginal(response.result.users)
          }
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        })
    } catch (e) {
      console.log(e)
    }
  }

  function onChange(value) {
    triggerFilter()
  }

  function onBlur() {}
  function onFocus() {}
  function onSearch(val) {}

  function showDeleteConfirm(group) {
    var params = {
      userId: group._id,
      groupId: id,
    }

    confirm({
      title: t('content.Are you sure to delete user from this group?'),
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: t('buttons.Yes'),
      okType: 'danger',
      cancelText: t('buttons.No'),
      onOk(e) {
        Service.delete({
          url: '/groups/removeUser',
          body: JSON.stringify(params),
        })
          .then((response) => {
            setLoading(false)
            if (response.status === 'error') {
              openNotification('error', t('content.Oops!'), response.message)
              return
            } else {
              openNotification('success', t('content.Success'), t('content.Deleted Successfully!'))
              Modal.destroyAll()
              fetchGroupUsers()
            }
          })
          .catch((err) => {
            setLoading(false)
            openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
          })
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const triggerFilter = (data) => {
    var users = usersListOriginal
    var frmInput = form.getFieldsValue()
    var newArr = users

    //Filter
    if (typeof frmInput.isOnline != 'undefined') {
      newArr = newArr.filter(function (o) {
        return frmInput.isOnline
          ? o.isOnline == frmInput.isOnline && o.dnd === 0
          : o.isOnline == frmInput.isOnline || o.dnd === 1
      })
    }
    if (typeof frmInput.role != 'undefined') {
      newArr = newArr.filter(function (o) {
        return o.role == frmInput.role
      })
    }
    if (typeof frmInput.search != 'undefined') {
      newArr = newArr.filter(function (o) {
        return o.email == frmInput.search
      })
    }
    if (typeof frmInput.surname != 'undefined') {
      newArr = newArr.filter(function (o) {
        return o.surname == frmInput.surname
      })
    }

    //Sorting
    if (typeof frmInput.order != 'undefined') {
      if (frmInput.order == 'nameAsc') {
        newArr = _.orderBy(newArr, ['name'], ['asc'])
      } else if (frmInput.order == 'nameDesc') {
        newArr = _.orderBy(newArr, ['name'], ['desc'])
      } else if (frmInput.order == 'dateAsc') {
        newArr = _.orderBy(newArr, ['_id'], ['desc'])
      } else if (frmInput.order == 'dateDesc') {
        newArr = _.orderBy(newArr, ['_id'], ['asc'])
      }
    }

    setUsersList(newArr)
  }

  return (
    <Layout className="layout academyUsersList bgWhite">
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <Form form={form}>
            <Row>
              <Col span={3} xl={3} lg={3} xs={24} sm={24} md={12}>
                <Form.Item name="isOnline">
                  <Select
                    allowClear={true}
                    onChange={triggerFilter}
                    placeholder={t('content.Status')}
                    style={{ width: '100%' }}
                    bordered={false}
                  >
                    <Option value={true}>{t('content.Online')}</Option>
                    <Option value={false}>{t('content.Offline')}</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={3} xl={3} lg={3} xs={24} sm={24} md={12}>
                <Form.Item name="role">
                  <Select
                    allowClear={true}
                    onChange={triggerFilter}
                    placeholder={t('content.Role')}
                    style={{ width: '100%' }}
                    bordered={false}
                  >
                    <Option value="admin">{t('Info.Course Leader')}</Option>
                    <Option value="student">{t('Info.Student')}</Option>
                    <Option value="module_trainer">{t('Info.Module Trainer')}</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={4} xl={4} lg={4} xs={24} sm={24} md={12}>
                <Form.Item name="order">
                  <Select
                    allowClear={true}
                    onChange={triggerFilter}
                    placeholder={t('content.Order By')}
                    style={{ width: '100%' }}
                    bordered={false}
                    allowClear={true}
                  >
                    <Option value="nameAsc">{t('content.Name Asc')}</Option>
                    <Option value="nameDesc">{t('content.Name Desc')}</Option>
                    <Option value="dateAsc">{t('content.Date Added Asc')}</Option>
                    <Option value="dateDesc">{t('content.Date Added Desc')}</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="searchField"
                span={4}
                xl={{ span: 4, offset: 1 }}
                lg={{ span: 4, offset: 1 }}
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 1 }}
              >
                <Form.Item name="surname">
                  <Select
                    showSearch
                    style={{ width: '100%', float: 'right' }}
                    placeholder={t('content.Search by family name')}
                    optionFilterProp="children"
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    bordered={false}
                    allowClear={true}
                  >
                    {usersListOriginal.map((userInfo, index) => (
                      <Option key={`key-${index}`} value={userInfo.surname}>
                        {userInfo.surname}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="searchField"
                span={7}
                xl={{ span: 7, offset: 2 }}
                lg={{ span: 7, offset: 2 }}
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 2 }}
              >
                <Form.Item name="search">
                  <Select
                    showSearch
                    style={{ width: '100%', float: 'right' }}
                    placeholder={t('content.Search by email or name')}
                    optionFilterProp="children"
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    bordered={false}
                    allowClear={true}
                  >
                    {usersListOriginal.map((academy, index) => (
                      <Option key={`key-${index}`} value={academy.email}>
                        {academy.email} ({academy.name})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          {usersList.length == 0 ? <NoDataFound message={t('content.No users found')} /> : null}

          <List itemLayout="horizontal" pagination={true}>
            {usersList.map((user, index) => (
              <List.Item key={`user-${index}`}>
                <div className="topProfileMenu">
                  <Tooltip title={user.email}>
                    <Avatar style={{ backgroundColor: user.color }}>
                      <GetInitial text={user.name} />
                    </Avatar>
                    <span
                      className={`availablityIndicator ${!user.isOnline || user.dnd === 1 ? 'inactive' : 'active'}`}
                    ></span>
                  </Tooltip>
                </div>

                <List.Item.Meta
                  title={user.name + ' ' + user.surname}
                  description={t('content.' + GetRole(user.role) + '')}
                  className="metaInfo"
                />

                <div className="callButton">
                  <Button
                    shape="round"
                    onClick={() => showDeleteConfirm(user)}
                    className="transparentButton"
                    htmlType="button"
                  >
                    <DeleteOutlined /> {t('buttons.Delete')}
                  </Button>
                </div>
              </List.Item>
            ))}
          </List>
        </>
      )}
    </Layout>
  )
})

export default GroupUsers
