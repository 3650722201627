import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PageLoader, NoDataFound, GetInitial, RandColor } from '../common/components/Helper'
import { Layout, Row, Col, Image, Card, Progress, Popover, Button, Avatar } from 'antd'
import { Service } from '../../services/Service'
import { Link } from 'react-router-dom'
import noImage from '../../asset/images/no-image.png'
import QuillViewer from '../common/components/QuillViewer'

const { Content } = Layout

const CoursesCard = (props) => {
  const { t } = useTranslation()
  return (
    <Card
      className="academic-list"
      bordered={false}
      style={{ marginBottom: '10px', height: '200px', background: '#f7f7f5', boxShadow: 'none' }}
    >
      <Link to={props.link}>
        <Row>
          <Popover
            content={
              <div style={{ width: '350px' }}>
                <h2>{props.title}</h2>
                <QuillViewer content={props.description} />
                {props.teamMembers &&
                  props.teamMembers.length > 0 &&
                  props.teamMembers.some((el) => el.role === 'admin') && (
                    <>
                      <h4 style={{ fontWeight: '600' }}>{t('content.Course Leader')}</h4>
                      {props.teamMembers.map((user) => (
                        <>
                          {user.role === 'admin' ? (
                            <div className="courseLeader">
                              <Avatar style={{ backgroundColor: user.user.color ? user.user.color : '' }}>
                                <GetInitial text={user.user.name} />
                              </Avatar>
                              <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                                {user.user.name + ' ' + user.user.surname}
                              </span>
                            </div>
                          ) : null}
                        </>
                      ))}
                    </>
                  )}
                <div className="course-progress">
                  <Button>
                    {props.modules} {props.modules <= 1 ? t('content.Module') : t('content.Modules')}
                  </Button>
                  <Button style={{ marginLeft: '10px', marginRight: '10px' }}>
                    {props.steps} {props.steps <= 1 ? t('content.Step') : t('content.Steps')}
                  </Button>
                </div>
              </div>
            }
            title={false}
          >
            <Col span={14} order={1}>
              <h2 className="title">{props.title}</h2>
            </Col>
          </Popover>
          <Col span={10} order={2} className="image-container">
            {typeof props.image != 'undefined' ? (
              <Image
                fallback={noImage}
                width={130}
                height={150}
                style={{ objectFit: 'cover' }}
                src={Service.getImage(props.image.filePath)}
              />
            ) : (
              <Image width={130} height={150} src={noImage} />
            )}
          </Col>
        </Row>
        <div className="go-to-next-academic">
          <p className="description">{props.subtitle}</p>
          {props.progress ? <Progress percent={props.progress} /> : null}
        </div>
      </Link>
    </Card>
  )
}

const MyCourse = () => {
  const { t } = useTranslation()
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)
  const [loading, setLoading] = useState(false)
  const [courseList, setCourseList] = useState([])

  useEffect(() => {
    const fetchMyCourses = async () => {
      setLoading(true)
      try {
        Service.get({
          url: '/academy/mycourses',
        })
          .then((response) => {
            setLoading(false)
            var academyObj = {}
            if (response.courses && response.courses.length > 0) {
              response.courses.forEach(function (course) {
                if (typeof academyObj[course.academyId._id] == 'undefined') {
                  academyObj[course.academyId._id] = {
                    info: course.academyId,
                    courses: [],
                  }
                }

                var stepsCount = 0
                var completedModules = 0
                var coursePercentage = 0
                if (course.modules && course.modules.length > 0) {
                  course.modules.forEach(function (mod) {
                    //Step count
                    stepsCount = stepsCount + mod.steps.length

                    //Completed course
                    if (
                      response.progress &&
                      response.progress.completedModules &&
                      response.progress.completedModules.length > 0 &&
                      response.progress.completedModules.indexOf(mod._id) >= 0
                    ) {
                      completedModules++
                    }
                  })
                  coursePercentage = Math.round((100 * completedModules) / course.modules.length)
                }

                course.stepsCount = stepsCount
                course.completedModule = completedModules
                course.coursePercentage = coursePercentage

                academyObj[course.academyId._id].courses.push(course)
              })
              setCourseList(Object.values(academyObj))
            }
          })
          .catch((err) => {
            setLoading(false)
          })
      } catch (e) {
        console.log(e)
      }
    }
    fetchMyCourses()
  }, [])

  const translate = (data) => {
    return JSON.parse(JSON.stringify(data)).map((item) => {
      if (item.translations && item.translations.hasOwnProperty(selectedLanguage.key)) {
        item.name =
          item.translations[selectedLanguage.key] !== undefined
            ? item.translations[selectedLanguage.key].name
            : item.name
        item.description =
          item.translations[selectedLanguage.key] !== undefined
            ? item.translations[selectedLanguage.key].description
            : item.description
      }
      return item
    })
  }

  return (
    <Layout className="layout bgWhite">
      <Content className="ipso-cont ent" style={{ backgroundColor: '#f0f2f5', minHeight: '100px' }}>
        <div className="container fullWidthGrey" style={{ paddingBottom: '2em' }}>
          <div className="site-layout-content">
            <h1 className="page-title">{t('content.My Courses')} </h1>
          </div>
        </div>
      </Content>

      <div className="container tabContentPadding user-academy-details">
        <>
          {loading ? (
            <PageLoader />
          ) : (
            <>
              {courseList.length === 0 ? (
                <NoDataFound message={t('content.No Data')} />
              ) : (
                <>
                  {courseList.map((academies) => (
                    <>
                      <div className="site-layout-content" style={{}}>
                        <h2 className="content-title green">{academies.info.name} </h2>
                      </div>

                      <Row className="coursesList" type="flex" align="middle" gutter={24}>
                        {translate(academies.courses).map((courses) => (
                          <Col span={16} order={1} xs={24} sm={24} md={24} lg={8} xl={8}>
                            <CoursesCard
                              id={courses._id}
                              title={courses.name}
                              description={
                                courses.description.length < 400
                                  ? courses.description
                                  : courses.description.substring(0, 400) + '...'
                              }
                              subtitle={
                                (courses.modules ? courses.modules.length : 0) +
                                ' ' +
                                (courses.modules.length <= 1 ? t('content.Module') : t('content.Modules')) +
                                ' | ' +
                                courses.stepsCount +
                                ' ' +
                                (courses.stepsCount <= 1 ? t('content.Step') : t('content.Steps'))
                              }
                              // progress= {courses.coursePercentage}
                              image={courses.image}
                              modules={courses.modules ? courses.modules.length : 0}
                              steps={courses.stepsCount}
                              link={'/academy-course-detail/' + courses._id}
                              teamMembers={courses.teamMembers}
                            />
                          </Col>
                        ))}
                      </Row>
                      <br />
                      <br />
                    </>
                  ))}
                </>
              )}
            </>
          )}
        </>
      </div>
    </Layout>
  )
}

export default MyCourse
