import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { LogoGrey, PageLoader, NoDataFound, openNotification } from '../../common/components/Helper'
import { List, Layout, Row, Col, Image, Menu, Input, Form, Button, Modal, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Service } from '../../../services/Service'
import InviteToBtn from '../components/InviteToBtn'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import Config from './../../../config.json'

import { Link } from 'react-router-dom'

import arrayMove from 'array-move'
import noImage from '../../../asset/images/no-image.png'
import { MoreOutlined } from '@ant-design/icons'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { setAcademy, setSortedAcademy, setAcademyModalVisible } from '../../../redux/actions/admin'
import AddAcademyModal from './AddAcademyModal'

const { Content, Footer } = Layout
const { SubMenu } = Menu
const { confirm } = Modal
const DragHandle = sortableHandle(() => <span className="draggableHanle">::</span>)

const SortableItem = sortableElement(({ value }) => (
  <List.Item>
    <DragHandle />
    {value}
  </List.Item>
))

const SortableContainer = sortableContainer(({ children }) => {
  return <List size="large">{children}</List>
})

const Dashboard = (props) => {
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)
  const { academies, isFetching } = useSelector(({ adminReducer }) => adminReducer)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [popupMode, setPopupMode] = useState('Add')
  const [fileSelection, setFileSelection] = useState({})
  const [editAcademyInfo, setEditAcademyInfo] = useState([])
  const [activeLanguages, setActiveLanguages] = useState([])

  useEffect(() => {
    dispatch(setAcademy('/academy/admin'))
  }, [])

  //Sort instantly when user drag drop ite,
  const onSortEnd = ({ oldIndex, newIndex }) => {
    var sorted = arrayMove(academies, oldIndex, newIndex)
    dispatch(setSortedAcademy(sorted))
    var newArr = []

    //Create array of sorted order
    for (var i = 0; i < sorted.length; i++) {
      newArr.push({ id: sorted[i]._id, order: i })
    }

    if (newArr.length > 0)
      Service.patch({
        url: '/academy/order',
        body: JSON.stringify({
          academys: newArr,
        }),
      })
  }

  const [form] = Form.useForm()

  const showEditModal = (academy) => {
    setPopupMode('Edit')
    form.resetFields()
    dispatch(setAcademyModalVisible(true))
    setActiveLanguages(academy.language)
    setEditAcademyInfo(academy) //Keeping active data in temp variable
    form.setFieldsValue(academy) //Pushing datas to react form

    //Populating existing image selection
    if (academy.image && typeof academy.image.filePath != 'undefined') {
      setFileSelection([
        {
          uid: '1',
          name: academy.image.name,
          status: 'success',
          url: Config.BASE_URL + '/' + academy.image.filePath,
          rawData: { ...academy.image },
        },
      ])
    } else {
      setFileSelection([]) //Clearing any image selection
    }
  }

  const defaultAcademyObj = {
    name: '',
    subTitle: '',
    language: [],
    defaultLanguage: 'en',
    translations: { de: { name: '', subTitle: '' }, ar: { name: '', subTitle: '' } },
  }

  const showAddModal = () => {
    form.resetFields()
    form.setFieldsValue(defaultAcademyObj)
    setPopupMode('Add')
    dispatch(setAcademyModalVisible(true))
    setActiveLanguages([])
    setEditAcademyInfo({})
    setFileSelection([])
  }

  //Language selection from Edit academy popup
  function handleLanguageChange(value) {
    var langVal = form.getFieldValue('translations')
    if (!langVal) {
      form.setFieldsValue({ translations: { ar: { title: '', subTitle: '' }, de: { title: '', subTitle: '' } } })
    }
    setActiveLanguages(value)
  }

  const translate = (data) => {
    return JSON.parse(JSON.stringify(data)).map((item) => {
      if (item.translations && item.translations.hasOwnProperty(selectedLanguage.key)) {
        item.name = item.translations[selectedLanguage.key].name
        item.subTitle = item.translations[selectedLanguage.key].subTitle
      }
      return item
    })
  }

  //Any change on filepicker
  const onFileUploadChange = ({ fileList: newFileList }) => {
    if (typeof newFileList[0] != 'undefined' && newFileList[0].response) {
      form.setFieldsValue({ image: newFileList[0].response.id })
    } else {
      form.setFieldsValue({ image: null })
    }
  }

  //Preview selected image
  const onPreview = async (file) => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow.document.write(image.outerHTML)
  }

  const confirmContent = (
    <>
      {t(
        'content.If you’re sure that you want to discard this academy,  all contained courses, settings and course content',
      )}
      ,&nbsp; {t('content.type the words Delete irreversibly into the field')}.<br />
      <br />
      <Input
        id="confirmText"
        required
        placeholder={t('content.Type') + ' : ' + t('content.Delete irreversibly')}
      />{' '}
      <br />
      <br />
      <b>{t('content.You could instead set this academy to unpublished in the settings to hide it from students')}. </b>
    </>
  )

  const closeEditModal = () => {
    dispatch(setAcademyModalVisible(false))
    form.resetFields()
  }

  function showDeleteConfirm(academy) {
    confirm({
      title:
        t('content.Delete academy') + ' "' + academy.name + '" ' + t('content.and all contained content irreversibly?'),
      icon: false,
      content: confirmContent,
      okText: t('buttons.Delete'),
      okType: 'danger',
      cancelText: t('buttons.Cancel'),
      wrapClassName: 'deleteConfirmModal',
      className: 'deleteConfirmModal',
      onOk(e) {
        var inp = document.getElementById('confirmText').value
        if (inp != t('content.Delete irreversibly')) {
          openNotification('error', t('content.Oops!'), t('content.Invalid input for delete'))
          return false
        }
        //Delete Api call
        Service.delete({
          url: '/academy/' + academy._id,
        })
          .then((response) => {
            setLoading(false)
            if (response.status === 'error') {
              openNotification('error', t('content.Oops!'), response.message)
              return
            } else {
              openNotification('success', t('content.Success'), t('content.Deleted Successfully!'))
              closeEditModal()
              dispatch(setAcademy('/academy/admin'))
              Modal.destroyAll()
            }
          })
          .catch((err) => {
            setLoading(false)
            openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
          })
      },
      onCancel() {},
    })
  }

  const publishAcademy = (academy) => {
    confirm({
      title: t('content.Are you sure to publish') + ' ' + academy.name + '?',
      icon: <ExclamationCircleOutlined />,
      content: t('content.Published academy will be visible to students'),
      okText: t('buttons.Yes'),
      cancelText: t('buttons.No'),
      onOk() {
        //Api call
        Service.patch({
          url: '/academy/' + academy._id,
          body: JSON.stringify({ status: 1 }),
        })
          .then((response) => {
            setLoading(false)
            if (response.status === 'error') {
              openNotification('error', t('content.Oops!'), response.message)
              return
            } else {
              openNotification('success', t('content.Success'), t('content.Published Successfully!'))
              closeEditModal()
              dispatch(setAcademy('/academy/admin'))
            }
          })
          .catch((err) => {
            setLoading(false)
            openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
          })
      },
      onCancel() {},
    })
  }

  // Duplicate Academy
  const duplicateAcademy = (academy) => {
    confirm({
      title: t('content.Do you want to duplicate this academy?'),
      icon: <ExclamationCircleOutlined />,
      okText: t('buttons.Yes'),
      cancelText: t('buttons.Cancel'),
      onOk() {
        Service.post({
          url: '/duplicate/academy',
          body: JSON.stringify({ academyId: academy._id }),
        })
          .then((response) => {
            if (response.status === 'success') {
              openNotification('success', t('content.Success'), t('content.Duplicated Successfully!'))
              dispatch(setAcademy('/academy/admin'))
            }
          })
          .catch((err) => {
            openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
          })
      },
      onCancel() {},
    })
  }

  //Render html
  return (
    <Layout className="layout adminScreen">
      <Content className="container ipso-content">
        <div className="site-layout-content">
          <Row>
            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 8 }} xs={{ span: 24 }}>
              <h1 className="page-title">{t('content.Academies')} </h1>
            </Col>
            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 16 }} xs={{ span: 24 }}>
              <Button
                style={{ float: 'right', margin: '14px 0px' }}
                icon={<PlusOutlined />}
                ghost={true}
                onClick={showAddModal}
                className="transparentButton"
                htmlType="button"
              >
                {t('buttons.Add New Academy')}
              </Button>

              <InviteToBtn sourceName={'Academy'} sourceList={academies} />
            </Col>
          </Row>
        </div>
        <div className="site-card-wrappe">
          {isFetching ? (
            <PageLoader />
          ) : (
            <Row type="flex" align="middle" gutter={24}>
              <div className="academyListing">
                {academies.length > 0 ? (
                  <>
                    <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                      {/* <List itemLayout="horizontal"> */}
                      {translate(academies).map((academy, index) => (
                        <SortableItem
                          key={`item-${index}`}
                          index={index}
                          value={
                            <div className="academyItem">
                              <div className="info">
                                <div style={{ display: 'flex' }}>
                                  <Tooltip placement="top" title={academy.name}>
                                    <Link to={'courses/' + academy._id}>
                                      <h2>
                                        {academy.name.length < 60
                                          ? academy.name
                                          : academy.name.substring(0, 60) + '...'}
                                      </h2>
                                    </Link>
                                  </Tooltip>
                                  <span className="infoSubtitle">{t(`Info.${academy.academyType}`)}</span>
                                </div>
                                {academy.course.length}{' '}
                                {academy.course.length <= 1 ? t('content.Course') : t('content.Courses')}
                              </div>

                              {/* triggerSubMenuAction={['click']} */}
                              <Menu mode="horizontal" className="listMenu">
                                <SubMenu key={`SubMenu:1:${index}`} icon={<MoreOutlined />} title="">
                                  <Menu.Item
                                    onClick={() => showEditModal(academy)}
                                    className="listSubMenuItems"
                                    key={`setting:1:${index}`}
                                  >
                                    {t('buttons.Edit')}
                                  </Menu.Item>

                                  <Menu.Item
                                    onClick={() => showDeleteConfirm(academy)}
                                    className="listSubMenuItems"
                                    key={`setting:2:${index}`}
                                  >
                                    {t('buttons.Delete')}
                                  </Menu.Item>

                                  <Menu.Item
                                    onClick={() => duplicateAcademy(academy)}
                                    className="listSubMenuItems"
                                    key={`setting:3:${index}`}
                                  >
                                    {t('buttons.Duplicate')}
                                  </Menu.Item>
                                </SubMenu>
                              </Menu>

                              {academy.image ? (
                                <Image
                                  preview={true}
                                  height={80}
                                  width={70}
                                  fallback={noImage}
                                  src={Service.getImage(academy.image.filePath)}
                                />
                              ) : (
                                <Image
                                  src="https://via.placeholder.com/150"
                                  preview={false}
                                  width={80}
                                  height={80}
                                  fallback={noImage}
                                />
                              )}

                              {!academy.status || academy.status == 0 ? (
                                <Button
                                  onClick={() => publishAcademy(academy)}
                                  className="publishBtn"
                                  type="primary"
                                  htmlType="button"
                                >
                                  {t('buttons.Publish')} <ArrowRightOutlined />
                                </Button>
                              ) : null}
                            </div>
                          }
                        />
                      ))}
                      {/* </List> */}
                    </SortableContainer>
                  </>
                ) : (
                  <NoDataFound message={t('errors.There are no academies yet')} />
                )}
              </div>
            </Row>
          )}
        </div>
        <br />
      </Content>

      {/* Add/Edit Academy modal */}
      <AddAcademyModal
        popupMode={popupMode}
        form={form}
        activeLanguages={activeLanguages}
        onFileUploadChange={onFileUploadChange}
        handleLanguageChange={handleLanguageChange}
        onPreview={onPreview}
        fileSelection={fileSelection}
        editAcademyInfo={editAcademyInfo}
      />

      <Footer style={{ textAlign: 'center' }}>
        <LogoGrey />
      </Footer>
    </Layout>
  )
}

export default Dashboard
