import ReactDOM from 'react-dom'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { debugContextDevtool } from 'react-context-devtool'
import store from './redux/store'
import App from './App'
import Config from './config.json'
import './index.css'
import { MatomoProvider, createInstance, useMatomo } from '@datapunt/matomo-tracker-react'

const instance = createInstance({
  urlBase: process.env.REACT_APP_TRACKING_URL ?? 'https://ipa-matamo.pandigita.com',
  siteId: process.env.REACT_APP_TRACKING_ID ?? 1,
  userId: JSON.parse(localStorage.getItem('auth') ?? '{}')?.email, // optional, default value: `undefined`.
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: {
    // optional, default value: {}
    setRequestMethod: 'POST',
  },
})

const container = document.getElementById('root')

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <MatomoProvider value={instance}>
        <App />
      </MatomoProvider>
    </Provider>
  </BrowserRouter>,
  container,
)

// Devtool for React Context and useReducer Hook
debugContextDevtool(container, {
  disable: Config.NODE_ENV === 'production',
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
