import { Service } from '../../services/Service'
import { SET_MODULE_STUDENTS } from '../action-creators/moduleDetail'
import { GET_HOMEWORK_DETAILS } from '../action-creators/moduleDetail'

import store from '../store'

export const getModuleStudents = (moduleId) => {
  let studentList = { ...store.getState().moduleDetailReducer.moduleStudentDetails }

  return (dispatch) => {
    // dispatch({ type: SET_MODULE_STUDENTS, payload: [], isModuleStudentFetching: true});

    Service.get({ url: `/progress/module/${moduleId}` })
      .then((resp) => {
        if (resp) {
          studentList[moduleId] = resp
          dispatch({
            type: SET_MODULE_STUDENTS,
            payload: studentList,
            isModuleStudentFetching: false,
          })
        }
      })
      .catch((err) => {
        dispatch({ type: SET_MODULE_STUDENTS, payload: [], isModuleStudentFetching: false })
      })
  }
}

export const getModuleHomeworkDetails = (moduleId, userId) => {
  return (dispatch) => {
    dispatch({ type: GET_HOMEWORK_DETAILS, payload: [], isHomeworkDetailsFetching: true })

    Service.get({ url: `/progress/moduleUser/${moduleId}/${userId}` })
      .then((resp) => {
        if (resp) {
          dispatch({
            type: GET_HOMEWORK_DETAILS,
            payload: resp,
            isHomeworkDetailsFetching: false,
          })
        }
      })
      .catch((err) => {
        dispatch({ type: GET_HOMEWORK_DETAILS, payload: [], isHomeworkDetailsFetching: false })
      })
  }
}
