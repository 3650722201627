import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { NoDataFound, GetInitial, RandColor, GetRole, openNotification } from '../../common/components/Helper'
import { Row, Col, Image, Input, Form, Button, List, Avatar, Modal, Select, Upload, Checkbox, Divider } from 'antd'
import { Service } from '../../../services/Service'
import { useParams } from 'react-router-dom'
import noImage from '../../../asset/images/no-image.png'
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { languageInfo } from '../../../otherLanguages'
import Config from '../../../config.json'
import ImgCrop from 'antd-img-crop'

import { getCourseDetails } from '../../../redux/actions/courseDetail'
import QuillEditor from '../../common/QuillEditor'
import QuillViewer from '../../common/components/QuillViewer'

const { Option } = Select
const { confirm } = Modal
const CheckboxGroup = Checkbox.Group
const defaultCheckedList = []

const AdminCourseInformation = (props) => {
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)

  const { t } = useTranslation()
  let { id } = useParams()
  const dispatch = useDispatch()
  const [popupMode, setPopupMode] = useState('Add')
  const [fileSelection, setFileSelection] = useState({})
  const [addModalVisible, setAddModalVisible] = useState(false)
  const [editCourseModalVisible, setEditCourseModalVisible] = useState(false)
  const [editCourseInfo, setEditCourseInfo] = useState([])
  const [activeLanguages, setActiveLanguages] = useState([])
  const [usersList, setUsersList] = useState([])
  const [courseData, setCourseData] = useState([])
  const [teamMembers, setTeamMembers] = useState([])
  const [moduleOptions, setModuleOptions] = useState([])
  const [checkedList, setCheckedList] = useState(defaultCheckedList)
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)
  const [userRole, setUserRole] = useState('courseLeader')
  const [unusedFiles, setUnusedFiles] = useState([])
  const [waitingSubmitFiles, setWaitingSubmitFiles] = useState([])

  const [form] = Form.useForm()
  const [userform] = Form.useForm()

  useEffect(() => {
    if (props.course) {
      setCourseData(props.course)

      var arr = []
      if (
        typeof props.course.teamMembers != 'undefined' &&
        props.course.teamMembers &&
        props.course.teamMembers.length > 0
      ) {
        props.course.teamMembers.forEach(function (user) {
          if (user.role == 'admin' || user.role == 'module_trainer') {
            arr.push(user)
          }
        })
      }
      setTeamMembers(arr)

      if (props.course.modules) {
        var moduleArr = []
        for (var i = 0; i < props.course.modules.length; i++) {
          moduleArr.push({ value: props.course.modules[i]._id, label: props.course.modules[i].name })
        }
        setModuleOptions(moduleArr)
      }
    }
  }, [props?.course])

  const courseReload = async () => {
    dispatch(getCourseDetails(id, false))
  }

  const showCourseEditModal = (course) => {
    if (course.defaultLanguage) {
      course.name = course.translations[course.defaultLanguage].name
      course.description = course.translations[course.defaultLanguage].description
    }

    setWaitingSubmitFiles([])
    setUnusedFiles([])
    setPopupMode('Edit')
    form.resetFields()
    setEditCourseModalVisible(true)
    setActiveLanguages(course.language)
    setEditCourseInfo(course)
    form.setFieldsValue(course)

    //Populating existing image selection
    if (course.image && typeof course.image.filePath != 'undefined') {
      setFileSelection([
        {
          uid: '1',
          name: course.image.name,
          status: 'success',
          url: Config.BASE_URL + '/' + course.image.filePath,
          rawData: { ...course.image },
        },
      ])
    } else {
      setFileSelection([])
    }
  }

  const showAddModal = () => {
    userform.resetFields()
    setPopupMode('Add')
    setAddModalVisible(true)
    setUnusedFiles([])
    setWaitingSubmitFiles([])
  }

  const handleOk = () => {
    setAddModalVisible(false)
  }

  const handleEditOk = () => {
    setEditCourseModalVisible(false)
  }
  const closeAddModal = () => {
    setAddModalVisible(false)
    userform.resetFields()
    setUnusedFiles([])
  }

  const onCloseEditModal = async () => {
    // Call API to delete unused files
    if (waitingSubmitFiles.length > 0) {
      await Service.delete({
        url: '/fileUpload',
        body: JSON.stringify(waitingSubmitFiles),
      })
    }

    closeEditModal()
  }

  const closeEditModal = () => {
    setEditCourseModalVisible(false)
    form.resetFields()
    setUnusedFiles([])
  }

  const clearFormData = () => {
    form.setFieldsValue(defaultCourseObj) //To avoid quill texteditor error
    setUnusedFiles([])
  }

  const validateMessages = {
    required: '${label} ' + t('errors.is required!'),
    types: {
      email: '${label} ' + t('errors.is not a valid email!'),
      number: '${label} ' + t('erros.is not a valid number!'),
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  const submitEditForm = (values) => {
    var params = {
      academyId: editCourseInfo.academyId,
      language: values.language,
      defaultLanguage: values.defaultLanguage,
      translations: values.translations ? values.translations : {},
      courseType: values.courseType,
      maxAttempts: values.maxAttempts,
      status: 0,
      unusedFiles,
    }

    if (typeof values.translations[values.defaultLanguage] == 'undefined') {
      openNotification(
        'warning',
        t('content.Missing default lanugage content'),
        t('content.Please add Title & Description in default language - ') + languageInfo(values.defaultLanguage).name,
      )
    } else {
      var defLngContent = values.translations[values.defaultLanguage]
      params.name = defLngContent.name
      params.description = defLngContent.description
    }

    if (values.image && typeof values.image.id == 'undefined') {
      params.image = values.image
    } else if (!values.image) {
      openNotification('warning', t('content.Missing Image'), t('content.Please select course image!'))
      return
    }

    Service.patch({
      url: '/academy/course/' + editCourseInfo._id,
      body: JSON.stringify(params),
    })
      .then((response) => {
        apiSuccess(apiSuccess)
      })
      .catch((err) => {
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
  }

  const apiSuccess = (response) => {
    courseReload()
    if (response.status === 'error') {
      openNotification('error', t('content.Oops!'), response.message)
    } else {
      openNotification('success', t('content.Success'), t('content.Course Updated successfully!'))
      closeEditModal()
    }
  }

  const defaultCourseObj = {
    translations: {
      en: { title: '', description: '' },
      ar: { title: '', description: '' },
      de: { title: '', description: '' },
    },
  }

  //Language selection from Edit Course popup
  function handleLanguageChange(value) {
    var langVal = form.getFieldValue('translations')
    console.log(langVal)
    if (!langVal) {
      form.setFieldsValue(defaultCourseObj)
    }
    setActiveLanguages(value)
  }

  //Any change on filepicker
  const onFileUploadChange = ({ fileList: newFileList }) => {
    if (typeof newFileList[0] != 'undefined' && newFileList[0].response) {
      setWaitingSubmitFiles([...waitingSubmitFiles, { id: newFileList[0].response.id }])
      form.setFieldsValue({ image: newFileList[0].response.id })
    } else {
      form.setFieldsValue({ image: null })
    }
  }

  //Preview selected image
  const onPreview = async (file) => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow.document.write(image.outerHTML)
  }

  // handling an uploaded image is removed
  const onRemoveUploadedImage = (removedImage) => {
    if (removedImage.rawData || removedImage.response) {
      setUnusedFiles([...unusedFiles, removedImage.rawData || removedImage.response])
    }
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  }

  const validateUserMessages = {
    required: '${label} ' + t('errors.is required!'),
    types: {
      email: '${label} ' + t('errors.is not a valid email!'),
      number: '${label} ' + t('errors.is not a valid number!'),
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  const submitForm = (values) => {
    var params = {
      courseId: courseData._id,
      role: values.role,
      userId: values.name,
    }
    if (values.role === 'module_trainer') {
      params.modules = checkedList
    }

    Service.post({
      url: '/academy/course/teamMembers',
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (response.status === 'error') {
          openNotification('error', t('content.Oops!'), response.message)
        } else {
          courseReload()
          openNotification('success', t('content.Success'), t('content.Team member added successfully!'))
          closeAddModal()
        }
      })
      .catch((err) => {
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
  }

  function showDeleteConfirm(user) {
    confirm({
      title: t('errors.Are you sure you want to remove member from this course team'),
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: t('buttons.Yes'),
      okType: 'danger',
      cancelText: t('buttons.No'),
      onOk() {
        Service.delete({
          url: '/academy/course/teamMembers/' + courseData._id,
          body: JSON.stringify({ userId: user._id }),
        })
          .then((response) => {
            if (response.status === 'error') {
              openNotification('error', t('content.Oops!'), response.message)
            } else {
              openNotification('success', t('content.Success'), t('content.Team member deleted successfully!'))
              courseReload()
              closeAddModal()
            }
          })
          .catch((err) => {
            openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
          })
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const handleRoleChange = (e) => {
    userform.setFieldsValue({ name: undefined })
    setUserRole(e)

    Service.get({
      url: '/user/search?role=' + e,
    })
      .then((response) => {
        if (Array.isArray(response)) {
          setUsersList(response)
        } else {
          setUsersList([])
        }
      })
      .catch((err) => {})
  }
  const onChangeModule = (list) => {
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < moduleOptions.length)
    setCheckAll(list.length === moduleOptions.length)
  }

  const onCheckAllChange = (e) => {
    const values = moduleOptions.map((record) => record.value)
    setCheckedList(e.target.checked ? values : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  const courseInfo =
    courseData &&
    courseData.translations &&
    (courseData.translations[selectedLanguage.key] !== undefined
      ? courseData.translations[selectedLanguage.key].description
      : courseData.description)

  return (
    <>
      <div className="course-info">
        <div className="site-layout-content" style={{}}>
          <h2 className="content-title green">{t('content.About Course')} </h2>
          <Button
            ghost={true}
            htmlType="button"
            style={{ marginLeft: '15px' }}
            className="transparentButton"
            icon={<EditOutlined />}
            onClick={() => showCourseEditModal(courseData)}
          >
            {' '}
            {t('buttons.Edit')}{' '}
          </Button>
        </div>

        <Row className="coursesDetail" justify="space-between">
          <Col lg={{ span: 15 }} md={{ span: 15 }} sm={{ span: 15 }} xs={{ span: 24 }}>
            <QuillViewer content={courseInfo} />
          </Col>
          <Col lg={{ span: 7 }} md={{ span: 7 }} sm={{ span: 8 }} xs={{ span: 24 }} style={{ textAlign: 'right' }}>
            {typeof courseData.image != 'undefined' ? (
              <Image fallback={noImage} src={Service.getImage(courseData.image.filePath)} />
            ) : (
              <Image fallback={noImage} src={noImage} />
            )}
          </Col>
        </Row>
      </div>

      <div className="courseTeam" style={{ marginTop: '20px' }}>
        <div className="site-layout-content">
          <Row>
            <Col xs={24} lg={3} sm={6}>
              <h2 className="content-title green">{t('content.Course Team')} </h2>
            </Col>
            <Col xs={24} lg={21} sm={18}>
              <Button
                ghost={true}
                htmlType="button"
                className="transparentButton"
                onClick={showAddModal}
                icon={<PlusOutlined />}
              >
                {' '}
                {t('buttons.Add Team Member')}{' '}
              </Button>
            </Col>
          </Row>
        </div>

        {teamMembers.length == 0 ? (
          <NoDataFound message={t('content.No Team Members Found')} />
        ) : (
          <>
            <List itemLayout="horizontal">
              {teamMembers.map((user, index) => (
                <>
                  {user.role == 'admin' || user.role == 'module_trainer' ? (
                    <List.Item key={`user:${index}`}>
                      <List.Item.Meta
                        avatar={
                          <Avatar style={{ backgroundColor: user.user.color ? user.user.color : '' }}>
                            <GetInitial text={user.user.name} />{' '}
                          </Avatar>
                        }
                        title={user.user.name + ' ' + user.user.surname}
                        description={t('content.' + GetRole(user.user.role) + '')}
                      />
                      <div className="courseAction">
                        <Button
                          shape="round"
                          onClick={() => showDeleteConfirm(user.user)}
                          className="transparentButton"
                          htmlType="button"
                        >
                          <DeleteOutlined /> {t('buttons.Delete')}
                        </Button>
                      </div>
                    </List.Item>
                  ) : null}
                </>
              ))}
            </List>
          </>
        )}
      </div>

      {/* Edit Course modal */}
      <Modal
        title={t('buttons.Edit Course Information')}
        visible={editCourseModalVisible}
        onOk={handleEditOk}
        onCancel={onCloseEditModal}
        afterClose={clearFormData}
        footer={false}
        width={500}
        maskClosable={false}
      >
        <Form
          {...layout}
          name="nest-messages"
          form={form}
          layout="vertical"
          onFinish={submitEditForm}
          initialValues={{}}
          validateMessages={validateMessages}
        >
          <Form.Item name={['language']} label={t('content.Add Language')} rules={[{ required: true }]}>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder={t('content.Please select')}
              onChange={handleLanguageChange}
            >
              <Option value={'en'} key={1}>
                {t('content.English')}
              </Option>
              <Option value={'de'} key={2}>
                {t('content.German')}
              </Option>
              <Option value={'ar'} key={3}>
                {t('content.Arabic')}
              </Option>
            </Select>
          </Form.Item>

          {activeLanguages.map((language, index) => (
            <div key={index}>
              <Form.Item
                name={['translations', language, 'name']}
                label={`${t('content.Course Name')} (${t('content.' + languageInfo(language).name + '')})`}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name={['translations', language, 'description']}
                label={`${t('content.Description')} (${t('content.' + languageInfo(language).name + '')})`}
              >
                <QuillEditor />
              </Form.Item>
            </div>
          ))}

          <Form.Item name={['defaultLanguage']} label={t('content.Default Language')} rules={[{ required: true }]}>
            <Select placeholder="Please select">
              <Option key={'en'} value="en">
                {t('content.English')}
              </Option>
              <Option key={'de'} value="de">
                {t('content.German')}
              </Option>
              <Option key={'ar'} value="ar">
                {t('content.Arabic')}
              </Option>
            </Select>
          </Form.Item>

          <Form.Item name={['image']} label={t('content.Choose Course Image')} rules={[{ required: true }]}>
            <ImgCrop rotate aspect={1 / 0.8}>
              <Upload
                action={`${Config.BASE_URL}/fileUpload `}
                listType="picture"
                onChange={onFileUploadChange}
                onPreview={onPreview}
                maxCount={1}
                defaultFileList={fileSelection}
                headers={{ 'x-access-token': localStorage.getItem('token') }}
                onRemove={onRemoveUploadedImage}
              >
                <Button icon={<UploadOutlined />}>{t('buttons.Upload')}</Button>
              </Upload>
            </ImgCrop>
          </Form.Item>

          <Form.Item name={['courseType']} label={t('content.Course Type')} rules={[{ required: true }]}>
            <Select placeholder="Course Type">
              <Option value="public">{t('content.Public')}</Option>
              <Option value="private">{t('content.Private')}</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name={['maxAttempts']}
            label={t('content.Attempts to complete the course')}
            rules={[{ required: true }]}
          >
            <Select>
              <Option key={'a1'} value={1}>
                1
              </Option>
              <Option key={'a2'} value={3}>
                2
              </Option>
              <Option key={'a3'} value={3}>
                3
              </Option>
              <Option key={'a4'} value={4}>
                4
              </Option>
              <Option key={'a5'} value={5}>
                5
              </Option>
              <Option key={'a0'} value={0}>
                {t('content.Unlimited')}
              </Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('buttons.Submit')}
            </Button>{' '}
            &nbsp; &nbsp;
            <Button type="default" htmlType="button" onClick={onCloseEditModal}>
              {t('buttons.Cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={popupMode == 'Add' ? t('buttons.Add Team Member') : 'Edit Team Member'}
        visible={addModalVisible}
        onOk={handleOk}
        onCancel={closeAddModal}
        className="team-member-add"
        footer={false}
        width={500}
        maskClosable={false}
      >
        <Form
          {...layout}
          name="nest-messages"
          form={userform}
          layout="vertical"
          onFinish={submitForm}
          initialValues={{}}
          validateMessages={validateUserMessages}
        >
          <Form.Item name={['role']} label={t('content.Role')} rules={[{ required: true }]}>
            <Select placeholder={t('content.Choose Role')} onChange={handleRoleChange}>
              <Option value="admin">{t('content.Course Leader')}</Option>
              <Option value="module_trainer">{t('content.Module Trainer')}</Option>
            </Select>
          </Form.Item>

          <Form.Item name={['name']} label={t('auth.name')} rules={[{ required: true }]}>
            <Select placeholder={t('content.Choose Members')}>
              {usersList.length == 0 ? null : (
                <>
                  {usersList.map((user) => (
                    <>
                      {user.role == 'admin' || user.role == 'module_trainer' ? (
                        <Option value={user._id}>{user.name + ' ' + user.surname}</Option>
                      ) : null}
                    </>
                  ))}
                </>
              )}
            </Select>
          </Form.Item>
          <Divider />

          {userRole == 'module_trainer' && (
            <Form.Item name={['modules']}>
              <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                {t('content.All Modules')}
              </Checkbox>
              <CheckboxGroup options={moduleOptions} value={checkedList} onChange={onChangeModule} />
            </Form.Item>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('buttons.Save')}
            </Button>{' '}
            &nbsp; &nbsp;
            <Button type="default" htmlType="button" onClick={closeAddModal}>
              {t('buttons.Cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AdminCourseInformation
