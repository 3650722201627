import Config from '../config.json'
import { notification } from 'antd'

//import { BehaviorSubject } from 'rxjs';
//const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('userProfile')));

const openNotification = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  })
}

const Service = {
  get: (para) => {
    let header = {
      'content-type': 'application/json',
      accept: 'application/json',
    }

    let token = localStorage.getItem('token')
    if (token) {
      header['x-access-token'] = token
    }
    return fetch(Config.BASE_URL + para.url, {
      method: 'GET',
      headers: header,
      body: para.body,
    }).then(
      (response) => {
        if (response.status == 401) {
          //Unauthorized.  Invalid JWT Token
          Storage.logout()
          window.location.href = Config.SITE_URL + '/login'
        } else {
          return response.json()
        }
      },
      (error) => {
        console.log(error)
        if (error == 'TypeError: NetworkError when attempting to fetch resource.') {
          openNotification('error', 'Unable to reach server.', 'Please check your network connectivity')
        }
      },
    )
    //.then(response => response.json());
  },
  post: (para) => {
    let header = {
      'content-type': 'application/json',
      accept: 'application/json',
    }

    let token = localStorage.getItem('token')
    if (token) {
      header['x-access-token'] = token
    }

    return fetch(Config.BASE_URL + para.url, {
      method: 'POST',
      headers: header,
      body: para.body,
    }).then(
      (response) => {
        if (response.status == 401) {
          //Unauthorized.  Invalid JWT Token
          Storage.logout()
          window.location.href = Config.SITE_URL + '/login'
        } else if (response.status == 403) {
          window.location.href = Config.SITE_URL + '/permissionDenied'
        } else {
          return response.json()
        }
      },
      (error) => {
        console.log(error)
        if (error == 'TypeError: NetworkError when attempting to fetch resource.') {
          openNotification('error', 'Unable to reach server.', 'Please check your network connectivity')
        }
      },
    )
    //.then(response => response.json())
  },
  patch: (para) => {
    let header = {
      'content-type': 'application/json',
      accept: 'application/json',
    }

    let token = localStorage.getItem('token')
    if (token) {
      header['x-access-token'] = token
    }

    return fetch(Config.BASE_URL + para.url, {
      method: 'PATCH',
      headers: header,
      body: para.body,
    }).then(
      (response) => {
        if (response.status == 401) {
          //Unauthorized.  Invalid JWT Token
          Storage.logout()
          window.location.href = Config.SITE_URL + '/login'
        } else if (response.status == 403) {
          window.location.href = Config.SITE_URL + '/permissionDenied'
        } else {
          return response.json()
        }
      },
      (error) => {
        console.log(error)
      },
    )
    //.then(response => response.json())
  },
  delete: (para) => {
    let header = {
      'content-type': 'application/json',
      accept: 'application/json',
    }

    let token = localStorage.getItem('token')
    if (token) {
      header['x-access-token'] = token
    }
    return fetch(Config.BASE_URL + para.url, {
      method: 'DELETE',
      headers: header,
      body: para.body,
    }).then(
      (response) => {
        if (response.status == 401) {
          //Unauthorized.  Invalid JWT Token
          Storage.logout()
          window.location.href = Config.SITE_URL + '/login'
        } else if (response.status == 403) {
          window.location.href = Config.SITE_URL + '/permissionDenied'
        } else {
          return response.json()
        }
      },
      (error) => {
        console.log(error)
        if (error == 'TypeError: NetworkError when attempting to fetch resource.') {
          openNotification('error', 'Unable to reach server.', 'Please check your network connectivity')
        }
      },
    )
    //.then(response => response.json());
  },
  getImage(image) {
    return Config.BASE_URL + '/' + image
  },
  postLocalTesting: (para) => {
    let header = {
      'content-type': 'application/json',
      accept: 'application/json',
    }

    let token = localStorage.getItem('token')
    if (token) {
      header['x-access-token'] = token
    }

    return fetch(para.url, {
      method: 'POST',
      headers: header,
      body: para.body,
    }).then(
      (response) => {
        if (response.status == 401) {
          //Unauthorized.  Invalid JWT Token
          Storage.logout()
          window.location.href = Config.SITE_URL + '/login'
        } else {
          return response.json()
        }
      },
      (error) => {
        console.log(error)
        if (error == 'TypeError: NetworkError when attempting to fetch resource.') {
          openNotification('error', 'Unable to reach server.', 'Please check your network connectivity')
        }
      },
    )
    //.then(response => response.json())
  },
}

const Storage = {
  isLogedin: (para) => {
    return localStorage.getItem('token') !== null
  },
  set: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
  },
  get: (key, value) => {
    return JSON.parse(localStorage.getItem(key))
  },
  setString: (key, value) => {
    localStorage.setItem(key, value)
  },
  logout: () => {
    localStorage.removeItem('token')
    localStorage.removeItem('auth')
  },
  isAdmin: () => {
    // return currentUserSubject.value
    if (!localStorage.getItem('auth')) return false

    var userRole = JSON.parse(localStorage.getItem('auth')).role
    return userRole == 'admin' ? true : false
  },
  getToken: (key, value) => {
    return localStorage.getItem('token') ? localStorage.getItem('token') : false
  },
}

export { Service, Storage }
