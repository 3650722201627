import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Button, Row, Col } from 'antd'
import { useHistory } from 'react-router-dom'
import AuthLayout from '../auth/AuthLayout'
import ImprintPrivacyLinks from '../common/components/ImprintPrivacyLinks'

const Linksend = () => {
  const { t } = useTranslation()
  const onFinish = (values) => {}
  const history = useHistory()
  return (
    <AuthLayout>
      <Row type="flex" justify="center" align="middle" style={{ marginBottom: 120 }}>
        <Col span={6} xs={20} sm={16} md={15} lg={8} xl={6}>
          <div className="img" style={{ textAlign: 'center', marginTop: 40, marginBottom: 70 }}>
            <p className="form-subtitle" style={{ textAlign: 'center' }}>
              {t('auth.Link was successfully sent to your email address')}
            </p>
          </div>
          <Form
            layout="vertical"
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item>
              <Button
                htmlType="submit"
                className="login-form-button theme-button"
                block
                onClick={() => history.push('login')}
              >
                {t('auth.Back to Log In')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <ImprintPrivacyLinks />
    </AuthLayout>
  )
}

export default Linksend
