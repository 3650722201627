import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Typography, Layout } from 'antd'
import LanguageDropdown from '../form-component/LanguageDropdown'
import { Logo, CustomFooter } from './components/Helper'
import ImprintPrivacyLinks from './components/ImprintPrivacyLinks'

const { Title, Paragraph } = Typography
const { Header, Content } = Layout

const PrivacyPolicy = () => {
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)
  const langCode = selectedLanguage.key || 'en'

  const renderGemanPolicies = () => {
    return (
      <Typography>
        <Title style={{ fontWeight: 'normal', marginBottom: 4 }}>
          Datenschutzerklärung von &nbsp;
          <b>ipsoacademy.org</b>
        </Title>
        <Paragraph>Diese Anwendung erhebt personenbezogene Daten von ihren Nutzern.</Paragraph>
        <Paragraph>
          Dieses Dokument kann zu Zwecken der Aufbewahrung über den Befehl „Drucken“ im Browser ausgedruckt werden.
        </Paragraph>
        <Paragraph>
          <h2 style={{ marginBottom: 4 }}>Anbieter und Verantwortlicher</h2>
          <h2 style={{ marginTop: 0, marginBottom: 4 }}>Arten der erhobenen Daten</h2>
          <div>
            Zu den personenbezogenen Daten, die diese Anwendung selbstständig oder durch Dritte verarbeitet, gehören:
            Tracker; Nutzungsdaten; E-Mail; Vorname; Nachname; Nutzername; Passwort; Sprache.
          </div>
        </Paragraph>
        <Paragraph>
          <div>
            Vollständige Details zu jeder Art von verarbeiteten personenbezogenen Daten werden in den dafür vorgesehenen
            Abschnitten dieser Datenschutzerklärung oder punktuell durch Erklärungstexte bereitgestellt, die vor der
            Datenerhebung angezeigt werden.
          </div>
          <div>
            Personenbezogene Daten können vom Nutzer freiwillig angegeben oder, im Falle von Nutzungsdaten, automatisch
            erhoben werden, wenn diese Anwendung genutzt wird.
          </div>
          <div>
            Sofern nicht anders angegeben, ist die Angabe aller durch diese Anwendung angeforderten Daten obligatorisch.
            Weigert sich der Nutzer, die Daten anzugeben, kann dies dazu führen, dass diese Anwendung dem Nutzer ihre
            Dienste nicht zur Verfügung stellen kann. In Fällen, in denen diese Anwendung die Angabe personenbezogener
            Daten ausdrücklich als freiwillig bezeichnet, dürfen sich die Nutzer dafür entscheiden, diese Daten ohne
            jegliche Folgen für die Verfügbarkeit oder die Funktionsfähigkeit des Dienstes nicht anzugeben.
          </div>
          <div>
            Nutzer, die sich darüber im Unklaren sind, welche personenbezogenen Daten obligatorisch sind, können sich an
            den Anbieter wenden.
          </div>
          <div>
            Jegliche Verwendung von Cookies – oder anderer Tracking-Tools – durch diese Anwendung oder Anbieter von
            Drittdiensten, die durch diese Anwendung eingesetzt werden, dient dem Zweck, den vom Nutzer gewünschten
            Dienst zu erbringen, und allen anderen Zwecken, die im vorliegenden Dokument und, falls vorhanden, in der
            Cookie-Richtlinie beschrieben sind.
          </div>
          <div>
            Die Nutzer sind für alle personenbezogenen Daten Dritter verantwortlich, die durch diese Anwendung
            beschafft, veröffentlicht oder weitergegeben werden, und bestätigen, dass sie die Zustimmung zur
            Übermittlung personenbezogener Daten etwaiger Dritter an diese Anwendung eingeholt haben.
          </div>
        </Paragraph>

        <h2>Art und Ort der Datenverarbeitung</h2>
        <Paragraph>
          <h3>Verarbeitungsmethoden</h3>
          <div>
            Der Anbieter verarbeitet die Nutzerdaten auf ordnungsgemäße Weise und ergreift angemessene
            Sicherheitsmaßnahmen, um den unbefugten Zugriff und die unbefugte Weiterleitung, Veränderung oder
            Vernichtung von Daten zu vermeiden.
          </div>
          <div>
            Die Datenverarbeitung wird mittels Computern oder IT-basierten Systemen nach organisatorischen Verfahren und
            Verfahrensweisen durchgeführt, die gezielt auf die angegebenen Zwecke abstellen. Zusätzlich zum
            Verantwortlichen könnten auch andere Personen intern (Personalverwaltung, Vertrieb, Marketing,
            Rechtsabteilung, Systemadministratoren) oder extern – und in dem Fall soweit erforderlich, vom
            Verantwortlichen als Auftragsverarbeiter benannt (wie Anbieter technischer Dienstleistungen,
            Zustellunternehmen, Hosting-Anbieter, IT-Unternehmen oder Kommunikationsagenturen) - diese Anwendung
            betreiben und damit Zugriff auf die Daten haben. Eine aktuelle Liste dieser Beteiligten kann jederzeit vom
            Anbieter verlangt werden.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>Rechtsgrundlagen der Verarbeitung</h3>
          <div>
            Der Anbieter darf personenbezogene Daten von Nutzern nur dann verarbeiten, wenn einer der folgenden Punkte
            zutrifft:
          </div>
          <ul style={{ listStyleType: 'disc', paddingLeft: 30 }}>
            <li>
              Die Nutzer haben ihre Einwilligung für einen oder mehrere bestimmte Zwecke erteilt. Hinweis: In einigen
              Gesetzgebungen kann es dem Anbieter gestattet sein, personenbezogene Daten zu verarbeiten, bis der Nutzer
              einer solchen Verarbeitung widerspricht („Opt-out“), ohne sich auf die Einwilligung oder eine andere der
              folgenden Rechtsgrundlagen verlassen zu müssen. Dies gilt jedoch nicht, wenn die Verarbeitung
              personenbezogener Daten dem europäischen Datenschutzrecht unterliegt;
            </li>
            <li>
              die Datenerhebung ist für die Erfüllung eines Vertrages mit dem Nutzer und/oder für vorvertragliche
              Maßnahmen daraus erforderlich;
            </li>
            <li>
              die Verarbeitung ist für die Erfüllung einer rechtlichen Verpflichtung, der der Anbieter unterliegt,
              erforderlich;
            </li>
            <li>
              die Verarbeitung steht im Zusammenhang mit einer Aufgabe, die im öffentlichen Interesse oder in Ausübung
              hoheitlicher Befugnisse, die dem Anbieter übertragen wurden, durchgeführt wird;
            </li>
            <li>
              die Verarbeitung ist zur Wahrung der berechtigten Interessen des Anbieters oder eines Dritten
              erforderlich.
            </li>
          </ul>
          <div>
            In jedem Fall erteilt der Anbieter gerne Auskunft über die konkrete Rechtsgrundlage, auf der die
            Verarbeitung beruht, insbesondere darüber, ob die Angabe personenbezogener Daten eine gesetzliche oder
            vertragliche Verpflichtung oder eine Voraussetzung für den Abschluss eines Vertrages ist.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>Ort</h3>
          <div>
            Die Daten werden in der Niederlassung des Anbieters und an allen anderen Orten, an denen sich die an der
            Datenverarbeitung beteiligten Stellen befinden, verarbeitet.
          </div>
          <br />
          <div>
            Je nach Standort der Nutzer können Datenübertragungen die Übertragung der Daten des Nutzers in ein anderes
            Land als das eigene beinhalten. Um mehr über den Ort der Verarbeitung der übermittelten Daten zu erfahren,
            können die Nutzer den Abschnitt mit den ausführlichen Angaben zur Verarbeitung der personenbezogenen Daten
            konsultieren.
          </div>
          <br />
          <div>
            Die Nutzer haben auch das Recht, sich über die Rechtsgrundlage der Datenübermittlung in ein Land außerhalb
            der Europäischen Union oder an eine internationale Organisation, die dem Völkerrecht unterliegt oder von
            zwei oder mehr Ländern gegründet wurde, wie beispielsweise die UNO, sowie sich über die vom Anbieter
            ergriffenen Sicherheitsmaßnahmen zum Schutz ihrer Daten aufklären zu lassen.
          </div>
          <br />
          <div>
            Wenn eine solche Übermittlung stattfindet, kann der Nutzer mehr darüber erfahren, indem er die
            entsprechenden Abschnitte dieses Dokuments überprüft oder sich mit dem Anbieter über die im Kontaktteil
            angegebenen Informationen in Verbindung setzt.
          </div>
        </Paragraph>

        <Paragraph>
          <h3>Speicherdauer</h3>
          <div>
            Personenbezogene Daten werden so lange verarbeitet und gespeichert, wie es der Zweck erfordert, zu dem sie
            erhoben wurden.
          </div>
          <br />
          <div>Daher gilt:</div>
          <ul style={{ listStyleType: 'disc', paddingLeft: 30 }}>
            <li>
              Personenbezogene Daten, die zu Zwecken der Erfüllung eines zwischen dem Anbieter und dem Nutzer
              geschlossenen Vertrages erhoben werden, werden bis zur vollständigen Erfüllung dieses Vertrages
              gespeichert.
            </li>
            <li>
              Personenbezogene Daten, die zur Wahrung der berechtigten Interessen des Anbieters erhoben werden, werden
              so lange aufbewahrt, wie es zur Erfüllung dieser Zwecke erforderlich ist. Nutzer können nähere
              Informationen über die berechtigten Interessen des Anbieters in den entsprechenden Abschnitten dieses
              Dokuments oder durch Kontaktaufnahme zum Anbieter erhalten.
            </li>
          </ul>
          <div>
            Darüber hinaus ist es dem Anbieter gestattet, personenbezogene Daten für einen längeren Zeitraum zu
            speichern, wenn der Nutzer in eine solche Verarbeitung eingewilligt hat, solange die Einwilligung nicht
            widerrufen wird. Darüber hinaus kann der Anbieter verpflichtet sein, personenbezogene Daten für einen
            längeren Zeitraum aufzubewahren, wenn dies zur Erfüllung einer gesetzlichen Verpflichtung oder auf Anordnung
            einer Behörde erforderlich ist
          </div>
          <br />
          <div>
            Nach Ablauf der Aufbewahrungsfrist werden personenbezogene Daten gelöscht. Daher können das Auskunftsrecht,
            das Recht auf Löschung, das Recht auf Berichtigung und das Recht auf Datenübertragbarkeit nach Ablauf der
            Aufbewahrungsfrist nicht geltend gemacht werden.
          </div>
        </Paragraph>

        <h2>Zwecke der Verarbeitung</h2>
        <Paragraph>
          <div>
            Personenbezogene Daten über den Nutzer werden erhoben, damit der Anbieter den Dienst erbringen und des
            Weiteren seinen gesetzlichen Verpflichtungen nachkommen, auf Durchsetzungsforderungen reagieren, seine
            Rechte und Interessen (oder die der Nutzer oder Dritter) schützen, böswillige oder betrügerische Aktivitäten
            aufdecken kann. Darüber hinaus werden Daten zu folgenden Zwecken erhoben: Analytik, Kontaktieren des
            Nutzers, Kommentarfunktion, Hosting und Backend-Infrastruktur, Verwalten von Kontakten und Versenden von
            Nachrichten und Registrierung und Anmeldung unmittelbar über diese Anwendung.
          </div>
          <br />
          <div>
            Nutzer können im Abschnitt “Ausführliche Angaben über die Verarbeitung personenbezogener Daten” dieses
            Dokuments weitere detaillierte Informationen zu diesen Verarbeitungszwecken und die zu den für den
            jeweiligen Zweck verwendeten personenbezogenen Daten vorfinden.
          </div>
        </Paragraph>

        <h2>Ausführliche Angaben über die Verarbeitung personenbezogener Daten</h2>
        <Paragraph>
          <div>
            Personenbezogene Daten werden zu folgenden Zwecken unter Inanspruchnahme folgender Dienstleistungen erhoben:
          </div>
        </Paragraph>
        <Paragraph>
          <h3>Analytik</h3>
          <div>
            Mit den in diesem Abschnitt aufgeführten Dienstleistungen kann der Anbieter den Datenverkehr überwachen und
            analysieren sowie das Verhalten von Nutzern nachverfolgen.
          </div>
        </Paragraph>

        <Paragraph>
          <h3>Matomo (diese Anwendung)</h3>
          <div>
            Matomo ist ein Analyse-Softwareprogramm, welches diese Anwendung nutzt, um Daten direkt ohne die Hilfe
            Dritter zu analysieren.
          </div>
          <br />
          <div>Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker.</div>
        </Paragraph>
        <Paragraph>
          <h3>Hosting und Backend-Infrastruktur</h3>
          <div>
            Diese Art von Diensten haben zum Zweck, Daten und Dateien zu hosten, damit diese Anwendung verwaltet und
            verwendet werden kann. Des Weiteren können diese Angebote eine vorgefertigte Infrastruktur zur Verfügung
            stellen, die spezifische Funktionen oder ganze Bestandteile für diese Anwendung abwickelt.
          </div>
          <br />
          <div>
            Einige der unten aufgeführten Dienste können, müssen aber nicht, über geografisch verteilte Server
            funktionieren, was es schwierig macht, den tatsächlichen Speicherort der personenbezogenen Daten zu
            bestimmen.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Amazon Web Services (AWS) (Amazon Web Services, Inc.)</h4>
          <div>
            Amazon Web Services (AWS) ist ein Webhosting und Backend Dienst, bereitgestellt von Amazon Web Services,
            Inc.
          </div>
          <div>
            Verarbeitete personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerklärung des Dienstes
            beschrieben.
          </div>
          <div>Verarbeitungsort: Deutschland – Datenschutzerklärung.</div>
        </Paragraph>

        <Paragraph>
          <h3>Kontaktieren des Nutzers</h3>
          <h4 style={{ marginTop: 0 }}>Mailingliste oder Newsletter (diese Anwendung)</h4>
          <div>
            Durch Anmelden zur Mailingliste oder zum Newsletter wird die E-Mail-Adresse des Nutzers der Kontaktliste der
            Personen hinzugefügt, die möglicherweise E-Mail-Nachrichten mit gewerblichen oder verkaufsfördernden
            Informationen in Bezug auf diese Anwendung erhalten. Darüber hinaus kann Ihre E-Mail-Adresse dieser Liste
            zugefügt werden, wenn Sie sich für diese Anwendung angemeldet haben oder nachdem Sie einen Kauf vorgenommen
            haben.
          </div>
          <br />
          <div>Verarbeitete personenbezogene Daten: E-Mail; Nachname; Vorname.</div>
        </Paragraph>

        <Paragraph>
          <h3>Registrierung und Anmeldung unmittelbar über diese Anwendung</h3>
          <div>
            Mit der Registrierung oder Anmeldung berechtigen Nutzer diese Anwendung, sie zu identifizieren und ihnen
            Zugriff auf spezifische Dienste zu gewähren. Personenbezogene Daten werden ausschließlich zu Registrierungs-
            und Identifizierungszwecken erhoben und gespeichert. Betroffen sind nur solche Daten, die für die Erbringung
            der vom Nutzer gewünschten Dienstleistung notwendig sind.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Direkte Registrierung (diese Anwendung)</h4>
          <div>
            Nutzer registrieren sich, indem sie das Anmeldeformular ausfüllen und ihre personenbezogenen Daten direkt
            über diese Anwendung angeben.
          </div>
          <br />
          <div>Verarbeitete personenbezogene Daten: E-Mail; Nachname; Passwort; Sprache; Vorname</div>
        </Paragraph>
        <Paragraph>
          <h3>Verwalten von Kontakten und Versenden von Nachrichten</h3>
          <div>Diese Art von Diensten ermöglichen die Verwaltung einer Datenbank mit E-Mail-Kontakten,</div>
          <div>Rufnummern oder jeglichen weiteren Kontaktinformationen, um mit dem Nutzer zu kommunizieren.</div>
          <div>
            Die Dienste können auch Daten darüber sammeln, an welchem Datum und zu welcher Uhrzeit Nachricht vom Nutzer
            gelesen wurde, sowie darüber, wann der Nutzer mit eingehenden Nachrichten interagiert, beispielsweise durch
            Klicken auf Links, die darin enthalten sind.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Mailjet (SAS Mailjet)</h4>
          <div>
            Mailjet ist ein von SAS Mailjet bereitgestellter Service für die Verwaltung von E-Mail-Adressen und den
            Versand von Nachrichten.
          </div>
          <br />
          <div>Verarbeitete personenbezogene Daten: E-Mail; Nachname; Vorname.</div>
          <div>Verarbeitungsort: Frankreich – Datenschutzerklärung.</div>
        </Paragraph>

        <h2>Die Rechte der Nutzer</h2>
        <Paragraph>
          <div>Die Nutzer können bestimmte Rechte in Bezug auf ihre vom Anbieter verarbeiteten Daten ausüben.</div>
          <br />
          <div>Nutzer haben insbesondere das Recht, Folgendes zu tun:</div>
          <ul style={{ listStyleType: 'disc', paddingLeft: 30 }}>
            <li>
              <b>Die Einwilligungen jederzeit widerrufen.</b>&nbsp;
              <span>
                Hat der Nutzer zuvor in die Verarbeitung personenbezogener Daten eingewilligt, so kann er die eigene
                Einwilligung jederzeit widerrufen.
              </span>
            </li>
            <li>
              <b>Widerspruch gegen die Verarbeitung ihrer Daten einlegen.</b>&nbsp;
              <span>
                Der Nutzer hat das Recht, der Verarbeitung seiner Daten zu widersprechen, wenn die Verarbeitung auf
                einer anderen Rechtsgrundlage als der Einwilligung erfolgt. Weitere Informationen hierzu sind weiter
                unten aufgeführt.
              </span>
            </li>
            <li>
              <b>Auskunft bezüglich ihrer Daten erhalten.</b>&nbsp;
              <span>
                Der Nutzer hat das Recht zu erfahren, ob die Daten vom Anbieter verarbeitet werden, über einzelne
                Aspekte der Verarbeitung Auskunft zu erhalten und eine Kopie der Daten zu erhalten.
              </span>
            </li>
            <li>
              <b>Überprüfen und berichtigen lassen.</b>&nbsp;
              <span>
                Der Nutzer hat das Recht, die Richtigkeit seiner Daten zu überprüfen und deren Aktualisierung oder
                Berichtigung zu verlangen.
              </span>
            </li>
            <li>
              <b>Einschränkung der Verarbeitung ihrer Daten verlangen. </b>&nbsp;
              <span>
                Die Nutzer haben das Recht, unter bestimmten Umständen die Verarbeitung ihrer Daten einzuschränken. In
                diesem Fall wird der Anbieter die Daten zu keinem anderen Zweck als der Speicherung verarbeiten.
              </span>
            </li>
            <li>
              <b>Löschung oder anderweitiges Entfernen der personenbezogenen Daten verlangen.</b>&nbsp;
              <span>
                Die Nutzer haben unter bestimmten Umständen das Recht, die Löschung ihrer Daten vom Anbieter zu
                verlangen.
              </span>
            </li>
            <li>
              <b>Ihre Daten erhalten und an einen anderen Verantwortlichen übertragen lassen. </b>
              &nbsp;
              <span>
                Nutzer hat das Recht, seine Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu
                erhalten und, sofern technisch möglich, ungehindert an einen anderen Verantwortlichen übermitteln zu
                lassen. Diese Bestimmung ist anwendbar, sofern die Daten automatisiert verarbeitet werden und die
                Verarbeitung auf der Zustimmung des Nutzers, auf einem Vertrag, an dem der Nutzer beteiligt ist, oder
                auf vorvertraglichen Verpflichtungen beruht.
              </span>
            </li>
            <li>
              <b>Beschwerde einreichen</b>&nbsp;
              <span>
                Die Nutzer haben das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
              </span>
            </li>
          </ul>
        </Paragraph>
        <Paragraph>
          <h3>Details zum Widerspruchsrecht bezüglich der Verarbeitung</h3>
          <div>
            Werden personenbezogene Daten im öffentlichen Interesse, in Ausübung eines dem Anbieter übertragenen
            hoheitlichen Befugnisses oder zur Wahrung der berechtigten Interessen des Anbieters verarbeitet, kann der
            Nutzer dieser Verarbeitung widersprechen, indem er einen Rechtfertigungsgrund angibt, der sich auf seine
            besondere Situation bezieht.
          </div>
          <br />
          <div>
            Nutzer werden darüber informiert, dass sie der Verarbeitung der personenbezogenen Daten für Direktwerbung
            jederzeit ohne Angabe von Gründen widersprechen können. Ob der Anbieter personenbezogene Daten für
            Direktwerbungszwecke verarbeitet, können die Nutzer den entsprechenden Abschnitten dieses Dokuments
            entnehmen.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>Wie die Rechte ausgeübt werden können</h3>
          <div>
            Alle Anfragen zur Ausübung der Nutzerrechte können über die in diesem Dokument angegebenen Kontaktdaten an
            den Anbieter gerichtet werden. Anträge können kostenlos ausgeübt werden und werden vom Anbieter so früh wie
            möglich, spätestens innerhalb eines Monats, bearbeitet.
          </div>
        </Paragraph>

        <h2>Weitere Informationen über die Erhebung und Verarbeitung von Daten</h2>
        <Paragraph>
          <h3>Rechtliche Maßnahmen</h3>
          <div>
            Die personenbezogenen Daten des Nutzers können vom Anbieter zu Zwecken der Rechtsdurchsetzung innerhalb oder
            in Vorbereitung gerichtlicher Verfahren verarbeitet werden, die sich daraus ergeben, dass diese Anwendung
            oder die dazugehörigen Dienste nicht ordnungsgemäß genutzt wurden.
          </div>
          <div>
            Der Nutzer erklärt, sich dessen bewusst zu sein, dass der Anbieter von den Behörden zur Herausgabe von
            personenbezogenen Daten verpflichtet werden könnte.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>Weitere Informationen über die personenbezogenen Daten des Nutzers</h3>
          <div>
            Zusätzlich zu den in dieser Datenschutzerklärung aufgeführten Informationen kann diese Anwendung dem Nutzer
            auf Anfrage weitere kontextbezogene Informationen zur Verfügung stellen, die sich auf bestimmte Dienste oder
            auf die Erhebung und Verarbeitung personenbezogener Daten beziehen.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>Systemprotokolle und Wartung</h3>
          <div>
            Diese Anwendung und die Dienste von Dritten können zu Betriebs- und Wartungszwecken Dateien erfassen, die
            die über diese Anwendung stattfindende Interaktion aufzeichnen (Systemprotokolle), oder andere
            personenbezogene Daten (z. B. IP-Adresse) zu diesem Zweck verwenden.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>Nicht in dieser Datenschutzerklärung enthaltene Informationen</h3>
          <div>
            Weitere Informationen über die Erhebung oder Verarbeitung personenbezogener Daten können jederzeit vom
            Anbieter über die aufgeführten Kontaktangaben angefordert werden.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>Wie „Do Not Track“ Anfragen behandelt werden</h3>
          <div>Diese Anwendung unterstützt keine Nicht-Verfolgen-Anfragen („Do Not Track”) durch Webbrowser.</div>
          <div>
            Die Information, ob integrierte Drittdienste das Nicht-Verfolgen Protokoll unterstützen, entnehmen Nutzer
            der Datenschutzerklärung des jeweiligen Dienstes.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>Änderungen dieser Datenschutzerklärung</h3>
          <div>
            Der Anbieter behält sich vor, jederzeit Änderungen an dieser Datenschutzerklärung vorzunehmen, indem Nutzer
            auf dieser Seite und gegebenenfalls über diese Anwendung und/oder - soweit technisch und rechtlich möglich –
            durch das Versenden einer Mitteilung über dem Anbieter vorliegende Kontaktdaten der Nutzer informiert
            werden. Nutzern wird daher nahe gelegt, diese Seite regelmäßig aufzurufen und insbesondere das am Seitenende
            angegebene Datum der letzten Änderung zu prüfen.
          </div>
          <br />
          <div>
            Soweit Änderungen eine auf der Einwilligung des Nutzers basierte Datennutzung betreffen, so wird der
            Anbieter - soweit erforderlich - eine neue Einwilligung einholen.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>Begriffsbestimmungen und rechtliche Hinweise</h3>
        </Paragraph>
        <Paragraph>
          <h4>Personenbezogene Daten (oder Daten)</h4>
          <div>
            Alle Informationen, durch die direkt oder in Verbindung mit weiteren Informationen die Identität einer
            natürlichen Person bestimmt wird oder werden kann.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Nutzungsdaten</h4>
          <div>
            Informationen, die diese Anwendung (oder Dienste Dritter, die diese Anwendung in Anspruch nimmt),
            automatisch erhebt, z. B.: die IP-Adressen oder Domain-Namen der Computer von Nutzern, die diese Anwendung
            verwenden, die URI-Adressen (Uniform Resource Identifier), den Zeitpunkt der Anfrage, die Methode, die für
            die Übersendung der Anfrage an den Server verwendet wurde, die Größe der empfangenen Antwort-Datei, der
            Zahlencode, der den Status der Server-Antwort anzeigt (erfolgreiches Ergebnis, Fehler etc.), das
            Herkunftsland, die Funktionen des vom Nutzer verwendeten Browsers und Betriebssystems, die diversen
            Zeitangaben pro Aufruf (z. B. wie viel Zeit auf jeder Seite der Anwendung verbracht wurde) und Angaben über
            den Pfad, dem innerhalb einer Anwendung gefolgt wurde, insbesondere die Reihenfolge der besuchten Seiten,
            sowie sonstige Informationen über das Betriebssystem des Geräts und/oder die IT-Umgebung des Nutzers.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Nutzer</h4>
          <div>
            Die diese Anwendung verwendende Person, die, soweit nicht anders bestimmt, mit dem Betroffenen
            übereinstimmt.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Betroffenert</h4>
          <div>Die natürliche Person, auf die sich die personenbezogenen Daten beziehen.</div>
        </Paragraph>
        <Paragraph>
          <h4>Auftragsverarbeiter (oder Datenverarbeiter)</h4>
          <div>
            Natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im
            Auftrag des Verantwortlichen verarbeitet, wie in dieser Datenschutzerklärung beschrieben.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Verantwortlicher (oder Anbieter, teilweise auch Eigentümer)</h4>
          <div>
            Die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam
            mit anderen über die Zwecke und Mittel der Verarbeitung personenbezogener Daten sowie die hierfür
            verwendeten Mittel entscheidet, einschließlich der Sicherheitsmaßnahmen bezüglich des sich auf diese
            Anwendung beziehenden Betriebs und der Nutzung. Soweit nichts anderes angegeben ist, ist der Verantwortliche
            die natürliche oder juristische Person, über welche diese Anwendung angeboten wird.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Diese Anwendung</h4>
          <div>
            Das Hardware- oder Software-Tool, mit dem die personenbezogenen Daten des Nutzers erhoben und verarbeitet
            werden.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Dienst</h4>
          <div>
            Der durch diese Anwendung angebotene Dienst, wie in den entsprechenden AGBs (falls vorhanden) und auf dieser
            Website/Anwendung beschrieben.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Europäische Union (oder EU)</h4>
          <div>
            Sofern nicht anders angegeben, beziehen sich alle Verweise in diesem Dokument auf die Europäische Union auf
            alle derzeitigen Mitgliedstaaten der Europäischen Union und den Europäischen Wirtschaftsraum (EWR).
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Cookie</h4>
          <div>Cookies sind Tracker, die aus einem kleinen, im Browser des Nutzers abgelegten Datensatz bestehen.</div>
        </Paragraph>
        <Paragraph>
          <h4>Tracker</h4>
          <div>
            Der Begriff Tracker bezeichnet jede Technologie – z. B. Cookies, eindeutige Identifizierungen, Web Beacons,
            eingebettete Skripts, E-Tags oder Fingerprinting – durch die Nutzer nachverfolgt werden können, z. B. indem
            der Zugriff auf oder die Speicherung von Informationen auf dem Nutzergerät ermöglicht wird.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Rechtlicher Hinweis</h4>
          <div>
            Diese Datenschutzerklärung wurde auf der Grundlage von Bestimmungen verschiedener Gesetzgebungen verfasst,
            einschließlich Art. 13/14 der Verordnung (EU) 2016/679 (Datenschutz-Grundverordnung).
          </div>
          <div>
            Diese Datenschutzerklärung bezieht sich ausschließlich auf diese Anwendung, sofern in diesem Dokument nicht
            anders angegeben.
          </div>
        </Paragraph>
      </Typography>
    )
  }

  const renderOtherLangsPolicies = () => {
    return (
      <Typography>
        <Title style={{ fontWeight: 'normal', marginBottom: 4 }}>
          Privacy Policy of &nbsp;
          <b>ipsoacademy.org</b>
        </Title>
        <Paragraph>This Application collects some Personal Data from its Users.</Paragraph>
        <Paragraph>
          This document can be printed for reference by using the print command in the settings of any browser.
        </Paragraph>
        <Paragraph>
          <h2 style={{ marginBottom: 4 }}>Owner and Data Controller</h2>
          <h2 style={{ marginTop: 0, marginBottom: 4 }}>Types of Data collected</h2>
          <div>
            Among the types of Personal Data that this Application collects, by itself or through third parties, there
            are: Tracker; Usage Data; email address; first name; last name; username; password; language.
          </div>
        </Paragraph>
        <Paragraph>
          <div>
            Complete details on each type of Personal Data collected are provided in the dedicated sections of this
            privacy policy or by specific explanation texts displayed prior to the Data collection.
          </div>
          <div>
            Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when
            using this Application.
          </div>
          <div>
            Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this
            Data may make it impossible for this Application to provide its services. In cases where this Application
            specifically states that some Data is not mandatory, Users are free not to communicate this Data without
            consequences to the availability or the functioning of the Service.
          </div>
          <div>Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.</div>
          <div>
            Any use of Cookies – or of other tracking tools – by this Application or by the owners of third-party
            services used by this Application serves the purpose of providing the Service required by the User, in
            addition to any other purposes described in the present document and in the Cookie Policy, if available.
          </div>
          <div>
            Users are responsible for any third-party Personal Data obtained, published or shared through this
            Application and confirm that they have the third party's consent to provide the Data to the Owner.
          </div>
        </Paragraph>

        <h2>Mode and place of processing the Data</h2>
        <Paragraph>
          <h3>Methods of processing</h3>
          <div>
            The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or
            unauthorized destruction of the Data.
          </div>
          <div>
            The Data processing is carried out using computers and/or IT enabled tools, following organizational
            procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases,
            the Data may be accessible to certain types of persons in charge, involved with the operation of this
            Application (administration, sales, marketing, legal, system administration) or external parties (such as
            third-party technical service providers, mail carriers, hosting providers, IT companies, communications
            agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be
            requested from the Owner at any time.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>Legal basis of processing</h3>
          <div>The Owner may process Personal Data relating to Users if one of the following applies:</div>
          <ul style={{ listStyleType: 'disc', paddingLeft: 30 }}>
            <li>
              Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner
              may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without
              having to rely on consent or any other of the following legal bases. This, however, does not apply,
              whenever the processing of Personal Data is subject to European data protection law;
            </li>
            <li>
              provision of Data is necessary for the performance of an agreement with the User and/or for any
              pre-contractual obligations thereof;
            </li>
            <li>processing is necessary for compliance with a legal obligation to which the Owner is subject;</li>
            <li>
              processing is related to a task that is carried out in the public interest or in the exercise of official
              authority vested in the Owner;
            </li>
            <li>
              processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third
              party.
            </li>
          </ul>
          <div>
            In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing,
            and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a
            requirement necessary to enter into a contract.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>Place</h3>
          <div>
            The Data is processed at the Owner's operating offices and in any other places where the parties involved in
            the processing are located.
          </div>
        </Paragraph>
        <Paragraph>
          Depending on the User's location, data transfers may involve transferring the User's Data to a country other
          than their own. To find out more about the place of processing of such transferred Data, Users can check the
          section containing details about the processing of Personal Data.
        </Paragraph>
        <Paragraph>
          Users are also entitled to learn about the legal basis of Data transfers to a country outside the European
          Union or to any international organization governed by public international law or set up by two or more
          countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.
        </Paragraph>
        <Paragraph>
          If any such transfer takes place, Users can find out more by checking the relevant sections of this document
          or inquire with the Owner using the information provided in the contact section.
        </Paragraph>
        <Paragraph>
          <h3>Retention time</h3>
          <div>
            Personal Data shall be processed and stored for as long as required by the purpose they have been collected
            for.
          </div>
        </Paragraph>
        <Paragraph>
          <div>Therefore:</div>
          <ul style={{ listStyleType: 'disc', paddingLeft: 30 }}>
            <li>
              Personal Data collected for purposes related to the performance of a contract between the Owner and the
              User shall be retained until such contract has been fully performed.
            </li>
            <li>
              Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as
              needed to fulfill such purposes. Users may find specific information regarding the legitimate interests
              pursued by the Owner within the relevant sections of this document or by contacting the Owner.
            </li>
          </ul>
          <div>
            The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to
            such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain
            Personal Data for a longer period whenever required to do so for the performance of a legal obligation or
            upon order of an authority.
          </div>
          <br />
          <div>
            Once the retention period expires, Personal Data shall be deleted. Therefore, the right of access, the right
            to erasure, the right to rectification and the right to data portability cannot be enforced after expiration
            of the retention period.
          </div>
        </Paragraph>

        <h2>The purposes of processing</h2>
        <Paragraph>
          <div>
            The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal
            obligations, respond to enforcement requests, protect its rights and interests (or those of its Users or
            third parties), detect any malicious or fraudulent activity, as well as the following: Analytics, Contacting
            the User, Content commenting, Hosting and backend infrastructure, Managing contacts and sending messages and
            Registration and authentication provided directly by this Application.
          </div>
          <br />
          <div>
            For specific information about the Personal Data used for each purpose, the User may refer to the section
            “Detailed information on the processing of Personal Data”.
          </div>
        </Paragraph>

        <h2>Detailed information on the processing of Personal Data</h2>
        <Paragraph>
          <div>Personal Data is collected for the following purposes and using the following services:</div>
        </Paragraph>
        <Paragraph>
          <h3>Analytics</h3>
          <div>
            The services contained in this section enable the Owner to monitor and analyze web traffic and can be used
            to keep track of User behavior.
          </div>
        </Paragraph>

        <Paragraph>
          <h3>Matomo (this Application)</h3>
          <div>
            Matomo is an analytics software used by this Application to analyze data directly without the help of third
            parties.
          </div>
          <br />
          <div>Personal Data processed: Tracker; Usage Data.</div>
        </Paragraph>
        <Paragraph>
          <h3>Contacting the User</h3>
          <h3 style={{ marginTop: 0 }}>Mailing list or newsletter (this Application)</h3>
          <div>
            By registering on the mailing list or for the newsletter, the User’s email address will be added to the
            contact list of those who may receive email messages containing information of commercial or promotional
            nature concerning this Application. Your email address might also be added to this list as a result of
            signing up to this Application or after making a purchase.
          </div>
          <br />
          <div>Personal Data processed: email address; first name; last name.</div>
        </Paragraph>
        <Paragraph>
          <h3>Hosting and backend infrastructure</h3>
          <div>
            This type of service has the purpose of hosting Data and files that enable this Application to run and be
            distributed as well as to provide a ready-made infrastructure to run specific features or parts of this
            Application.
          </div>
          <br />
          <div>
            Some services among those listed below, if any, may work through geographically distributed servers, making
            it difficult to determine the actual location where the Personal Data are stored.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Amazon Web Services (AWS) (Amazon Web Services, Inc.)</h4>
          <div>Amazon Web Services (AWS) is a hosting and backend service provided by Amazon Web Services, Inc.</div>
          <div>Personal Data processed: various types of Data as specified in the privacy policy of the service.</div>
          <div>Place of processing: Germany – Privacy Policy.</div>
        </Paragraph>
        <Paragraph>
          <h3>Managing contacts and sending messages</h3>
          <div>
            This type of service makes it possible to manage a database of email contacts, phone contacts or any other
            contact information to communicate with the User.
          </div>
          <div>
            These services may also collect data concerning the date and time when the message was viewed by the User,
            as well as when the User interacted with it, such as by clicking on links included in the message.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Mailjet (SAS Mailjet)</h4>
          <div>Mailjet is an email address management and message sending service provided by SAS Mailjet.</div>
          <br />
          <div>Personal Data processed: email address; first name; last name.</div>
          <div>Place of processing: France – Privacy Policy.</div>
        </Paragraph>
        <Paragraph>
          <h3>Registration and authentication provided directly by this Application</h3>
          <div>
            By registering or authenticating, Users allow this Application to identify them and give them access to
            dedicated services. The Personal Data is collected and stored for registration or identification purposes
            only. The Data collected are only those necessary for the provision of the service requested by the Users.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>Direct registration (this Application)</h3>
          <div>
            The User registers by filling out the registration form and providing the Personal Data directly to this
            Application.
          </div>
          <br />
          <div>Personal Data processed: email address; first name; language; last name; password.</div>
        </Paragraph>

        <h2>The rights of Users</h2>
        <Paragraph>
          <div>Users may exercise certain rights regarding their Data processed by the Owner.</div>
          <br />
          <div>In particular, Users have the right to do the following:</div>
          <ul style={{ listStyleType: 'disc', paddingLeft: 30 }}>
            <li>
              <b>Withdraw their consent at any time.</b>&nbsp;
              <span>
                Users have the right to withdraw consent where they have previously given their consent to the
                processing of their Personal Data.
              </span>
            </li>
            <li>
              <b>Object to processing of their Data.</b>&nbsp;
              <span>
                Users have the right to object to the processing of their Data if the processing is carried out on a
                legal basis other than consent. Further details are provided in the dedicated section below.
              </span>
            </li>
            <li>
              <b>Access their Data.</b>&nbsp;
              <span>
                Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding
                certain aspects of the processing and obtain a copy of the Data undergoing processing.
              </span>
            </li>
            <li>
              <b>Verify and seek rectification.</b>&nbsp;
              <span>
                Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.
              </span>
            </li>
            <li>
              <b>Restrict the processing of their Data.</b>&nbsp;
              <span>
                Users have the right, under certain circumstances, to restrict the processing of their Data. In this
                case, the Owner will not process their Data for any purpose other than storing it.
              </span>
            </li>
            <li>
              <b>Have their Personal Data deleted or otherwise removed.</b>&nbsp;
              <span>
                Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.
              </span>
            </li>
            <li>
              <b>Receive their Data and have it transferred to another controller.</b>
              &nbsp;
              <span>
                Users have the right to receive their Data in a structured, commonly used and machine readable format
                and, if technically feasible, to have it transmitted to another controller without any hindrance. This
                provision is applicable provided that the Data is processed by automated means and that the processing
                is based on the User's consent, on a contract which the User is part of or on pre-contractual
                obligations thereof.
              </span>
            </li>
            <li>
              <b>Lodge a complaint.</b>&nbsp;
              <span>Users have the right to bring a claim before their competent data protection authority.</span>
            </li>
          </ul>
        </Paragraph>
        <Paragraph>
          <h3>Details about the right to object to processing</h3>
          <div>
            Where Personal Data is processed for a public interest, in the exercise of an official authority vested in
            the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such
            processing by providing a ground related to their particular situation to justify the objection.
          </div>
          <br />
          <div>
            Users must know that, however, should their Personal Data be processed for direct marketing purposes, they
            can object to that processing at any time without providing any justification. To learn, whether the Owner
            is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this
            document.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>How to exercise these rights</h3>
          <div>
            Any requests to exercise User rights can be directed to the Owner through the contact details provided in
            this document. These requests can be exercised free of charge and will be addressed by the Owner as early as
            possible and always within one month.
          </div>
        </Paragraph>

        <h2>Additional information about Data collection and processing</h2>
        <Paragraph>
          <h3>Legal action</h3>
          <div>
            The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to
            possible legal action arising from improper use of this Application or the related Services.
          </div>
          <div>
            The User declares to be aware that the Owner may be required to reveal personal data upon request of public
            authorities.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>Additional information about User's Personal Data</h3>
          <div>
            In addition to the information contained in this privacy policy, this Application may provide the User with
            additional and contextual information concerning particular Services or the collection and processing of
            Personal Data upon request.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>System logs and maintenance</h3>
          <div>
            For operation and maintenance purposes, this Application and any third-party services may collect files that
            record interaction with this Application (System logs) use other Personal Data (such as the IP Address) for
            this purpose.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>Information not contained in this policy</h3>
          <div>
            More details concerning the collection or processing of Personal Data may be requested from the Owner at any
            time. Please see the contact information at the beginning of this document.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>How “Do Not Track” requests are handled</h3>
          <div>This Application does not support “Do Not Track” requests</div>
          <div>
            To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read
            their privacy policies.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>Changes to this privacy policy</h3>
          <div>
            The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users on
            this page and possibly within this Application and/or - as far as technically and legally feasible - sending
            a notice to Users via any contact information available to the Owner. It is strongly recommended to check
            this page often, referring to the date of the last modification listed at the bottom.
          </div>
          <br />
          <div>
            Should the changes affect processing activities performed on the basis of the User’s consent, the Owner
            shall collect new consent from the User, where required.
          </div>
        </Paragraph>
        <Paragraph>
          <h3>Definitions and legal references</h3>
        </Paragraph>
        <Paragraph>
          <h4>Personal Data (or Data)</h4>
          <div>
            Any information that directly, indirectly, or in connection with other information — including a personal
            identification number — allows for the identification or identifiability of a natural person.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Usage Data</h4>
          <div>
            Information collected automatically through this Application (or third-party services employed in this
            Application), which can include: the IP addresses or domain names of the computers utilized by the Users who
            use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method
            utilized to submit the request to the server, the size of the file received in response, the numerical code
            indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the
            features of the browser and the operating system utilized by the User, the various time details per visit
            (e.g., the time spent on each page within the Application) and the details about the path followed within
            the Application with special reference to the sequence of pages visited, and other parameters about the
            device operating system and/or the User's IT environment.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>User</h4>
          <div>
            The individual using this Application who, unless otherwise specified, coincides with the Data Subject.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Data Subject</h4>
          <div>The natural person to whom the Personal Data refers.</div>
        </Paragraph>
        <Paragraph>
          <h4>Data Processor (or Data Supervisor)</h4>
          <div>
            The natural or legal person, public authority, agency or other body which processes Personal Data on behalf
            of the Controller, as described in this privacy policy.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Data Controller (or Owner)</h4>
          <div>
            The natural or legal person, public authority, agency or other body which, alone or jointly with others,
            determines the purposes and means of the processing of Personal Data, including the security measures
            concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is
            the Owner of this Application.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>This Application</h4>
          <div>The means by which the Personal Data of the User is collected and processed.</div>
        </Paragraph>
        <Paragraph>
          <h4>Service</h4>
          <div>
            The service provided by this Application as described in the relative terms (if available) and on this
            site/application.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>European Union (or EU)</h4>
          <div>
            Unless otherwise specified, all references made within this document to the European Union include all
            current member states to the European Union and the European Economic Area.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Cookie</h4>
          <div>Cookies are Trackers consisting of small sets of data stored in the User's browser.</div>
        </Paragraph>
        <Paragraph>
          <h4>Tracker</h4>
          <div>
            Tracker indicates any technology - e.g Cookies, unique identifiers, web beacons, embedded scripts, e-tags
            and fingerprinting - that enables the tracking of Users, for example by accessing or storing information on
            the User’s device.
          </div>
        </Paragraph>
        <Paragraph>
          <h4>Legal information</h4>
          <div>
            This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14
            of Regulation (EU) 2016/679 (General Data Protection Regulation).
          </div>
          <div>
            This privacy policy relates solely to this Application, if not stated otherwise within this document.
          </div>
        </Paragraph>
      </Typography>
    )
  }

  return (
    <>
      <Layout className="layout">
        <div className="bgWhite">
          <Header className="custom-header container header-admin">
            <div className="left" style={{ display: 'flex' }}>
              <Logo />
            </div>
            <div className="right">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <LanguageDropdown />
              </div>
            </div>
          </Header>
        </div>

        <Content className="container ipso-content">
          <Row>
            <Col span={20} xs={20} sm={16} md={15} lg={24} xl={24}>
              {langCode === 'de' ? renderGemanPolicies() : renderOtherLangsPolicies()}
            </Col>
          </Row>

          <ImprintPrivacyLinks />
        </Content>

        <CustomFooter />
      </Layout>
    </>
  )
}

export default PrivacyPolicy
