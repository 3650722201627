import { Alert } from 'antd'
import { useTranslation } from 'react-i18next'

const SafariCompabilityAlert = () => {
  const { t } = useTranslation()
  return (
    <Alert
      type="warning"
      message={t('Info.Compatibility Alert')}
      description={t('Info.Unsupported browser banner')}
      showIcon
      className="safari-warning"
    ></Alert>
  )
}

export default SafariCompabilityAlert
