import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Layout, Typography, notification } from 'antd'
import { PageLoader } from '../common/components/Helper'
import { useParams, useLocation } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { WarningOutlined, CheckCircleTwoTone } from '@ant-design/icons'
import Config from '../../config.json'

import {
  useMeetingManager,
  MeetingProvider,
  lightTheme,
  VideoTileGrid,
  useLocalVideo,
  Grid,
  useUserActivityState,
  ControlBar,
  AudioInputControl,
  VideoInputControl,
  ContentShareControl,
  AudioOutputControl,
  ControlBarButton,
  Phone,
  MeetingStatus,
  useMeetingStatus,
  Flex,
  Heading,
  useDevicePermissionStatus,
  Microphone,
  Camera,
  useSelectVideoQuality,
} from 'amazon-chime-sdk-component-library-react'
import { ConsoleLogger, LogLevel } from 'amazon-chime-sdk-js'
import { Service } from '../../services/Service'

import MeetingRoster from '../chime/containers/MeetingRoster'
import { detectBrowser } from '../utils/detect-browser'
import SafariCompabilityAlert from '../common/components/SafariCompabilityAlert'

const ChimeIntegration = (props) => {
  const { Content, Sider } = Layout
  const { Title } = Typography
  const { t } = useTranslation()
  let { meetingId } = useParams()
  const [loading, setLoading] = useState(false)
  const [meetStatus, setMeetStatus] = useState(0)
  const [autoJoinMeeting, setAutoJoinMeeting] = useState(false)
  const [invalidMeeting, setInvalidMeeting] = useState(false)
  const [classInfo, setClassInfo] = useState(false)
  const [roomInfo, setRoomInfo] = useState(null)
  const [failedMsg, setFailedMsg] = useState('')
  const [joinAudio] = useState(new Audio(Config.SITE_URL + '/audio/join_meeting.mp3'))
  const currentBrowser = detectBrowser()
  let { search } = useLocation()

  useEffect(() => {
    if (meetStatus === 1) {
      currentBrowser !== 'safari' && joinAudio.play()
      setLoading(false)
    }
  }, [meetStatus, currentBrowser, joinAudio])

  const MeetingManager = () => {
    const meetingManager = useMeetingManager()
    const meetingStatus = useMeetingStatus()
    const selectVideoQuality = useSelectVideoQuality()

    useEffect(() => {
      if (!meetingId || meetingId === '') {
        setInvalidMeeting(true)
        setFailedMsg('content.Invalid meeting url')
        return false
      }

      setMeetStatus(meetingStatus)
      if (meetingStatus === MeetingStatus.Ended) {
        console.log('[useMeetingEndRedirect] Meeting ended')
      }

      if (meetingStatus === MeetingStatus.Succeeded) {
        selectVideoQuality('360p')
      }
    }, [meetingStatus])

    const joinMeeting = async () => {
      setLoading(true)
      return Service.post({
        url: '/meeting',
        body: JSON.stringify({ meetingId: meetingId, roomType: new URLSearchParams(search).get('type') ?? 'class' }),
      })
        .then(async (data) => {
          if (!data || data.meeting === false) {
            setLoading(false)
            setInvalidMeeting(true)
            setFailedMsg(data.message)
            return false
          } else {
            setClassInfo(data.classInfo)
            data.roomInfo && setRoomInfo(data.roomInfo)
            const joinData = {
              meetingInfo: data.meeting.Meeting,
              attendeeInfo: data.attendee.Attendee,
            }

            // create a meeting session
            await meetingManager.join(joinData)

            // let users setup their devices, or start the session immediately
            await meetingManager.start()

            // set video quality 360p
            selectVideoQuality('360p')
          }
        })
        .catch((err) => {
          setLoading(false)
        })
    }

    // Join to meeting directly
    if (meetingId && meetingId !== '' && !autoJoinMeeting) {
      setAutoJoinMeeting(true)
      joinMeeting()
    }

    return <></>
  }

  const MeetingManagerControls = () => {
    const meetingManager = useMeetingManager()
    const devicePermissionStatus = useDevicePermissionStatus()
    const [permissionAudioState, setPermissionAudioState] = useState('ALLOW')
    const [permissionVideoState, setPermissionVideoState] = useState('ALLOW')
    const leaveMeeting = async () => {
      await meetingManager.leave()
    }

    useEffect(() => {
      navigator.getUserMedia =
        navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia
      if (navigator.getUserMedia) {
        navigator.getUserMedia(
          { audio: true },
          function (stream) {
            setPermissionAudioState('ALLOW')
          },
          function (e) {
            console.log('audio permission states: ', e.name)
            setPermissionAudioState('DENIED')
          },
        )
        navigator.getUserMedia(
          { video: true },
          function (stream) {
            setPermissionVideoState('ALLOW')
          },
          function (e) {
            console.log('video permission states: ', e.name)
            setPermissionVideoState('DENIED')
          },
        )
      }
    }, [devicePermissionStatus])

    useEffect(() => {
      if ('ALLOW' !== permissionAudioState || 'ALLOW' !== permissionVideoState) {
        notification.warn({
          key: 'meeting-permission-warning',
          message: t('Info.Permission Alert'),
          closeIcon: <div></div>,
          icon: (
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="exclamation-circle"
              width="1em"
              height="1em"
              fill="#FAAF1B"
              aria-hidden="true"
            >
              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
              <path d="M464 688a48 48 0 1096 0 48 48 0 10-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
            </svg>
          ),
          style: { backgroundColor: '#FEFBE6', width: 450 },
          description: permissionGuides[currentBrowser],
          duration: null,
        })
      }
    }, [permissionVideoState, permissionAudioState])

    return (
      <>
        {meetStatus === 1 ? (
          <div className="chimeControls controls" active={!!useUserActivityState}>
            <ControlBar className="controls-menu" layout="undocked-horizontal" showLabels>
              {permissionAudioState !== 'ALLOW' ? (
                <ControlBarButton
                  isSelected={false}
                  label="Disabled"
                  className="browser-permission-not-allow"
                  icon={<Microphone muted />}
                />
              ) : (
                <AudioInputControl
                  className="avControl"
                  muteLabel={t('content.Mute')}
                  unmuteLabel={t('content.Unmute')}
                />
              )}
              {permissionVideoState !== 'ALLOW' ? (
                <ControlBarButton
                  isSelected={false}
                  label="Disabled"
                  className="browser-permission-not-allow"
                  icon={<Camera disabled />}
                />
              ) : (
                <VideoInputControl label={t('content.Video')} />
              )}
              <ContentShareControl label={t('content.Present')} pauseLabel={t('buttons.Pause Presenting')} />
              <AudioOutputControl label={t('content.Speaker')} />
              <ControlBarButton icon={<Phone />} onClick={leaveMeeting} label={t('content.Leave')} />
            </ControlBar>
          </div>
        ) : null}
      </>
    )
  }

  const MeetingDetails = () => {
    const { isVideoEnabled } = useLocalVideo()

    console.log('meetstatus', meetStatus)

    return (
      <Flex container layout="fill-space-centered">
        <Flex mb="2rem" mr={{ md: '2rem' }} px="1rem">
          {loading ? <PageLoader /> : null}
          <Heading level={4} tag="h1" mb={2}>
            {new URLSearchParams(search).get('type') === 'personal'
              ? t('content.Meeting Room')
              : t('content.Classroom Meeting')}
          </Heading>
          <div>
            <div>
              <dt>
                <b>{t('content.Meeting ID')}:</b> {meetingId}
              </dt>
            </div>
            <div>
              <dt>
                <b>{t('content.Your Status')}:</b>
                {meetStatus === 0 && ` ${t('content.Not Joined')}`}
                {meetStatus === 1 && (
                  <>
                    {` ${t('content.Joined')} `}
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                  </>
                )}
                {meetStatus === 2 && ` ${t('content.Failed')}`}
                {(meetStatus === 3 || meetStatus === 5) && ` ${t('content.Ended')}`}
              </dt>
            </div>

            {classInfo && classInfo?.classType && (
              <>
                <div>
                  <dt>
                    <b>{t('content.Class Name')}:</b>{' '}
                    {classInfo.classType === 'course' ? classInfo.courseId.name : classInfo.moduleId.name}
                  </dt>
                </div>
                <div>
                  <dt>
                    <b>{t('content.Class Leader')}</b> : {classInfo.courseLeader.name} {classInfo.courseLeader.surname}
                  </dt>
                </div>
              </>
            )}

            {roomInfo && roomInfo.owner && (
              <>
                <div>
                  <dt>
                    <b>{t('content.Meeting room name')} :</b> {roomInfo?.name}
                  </dt>
                </div>
                <div>
                  <dt>
                    <b>{t('content.Created by')}</b> : {roomInfo.owner?.name + ' ' + roomInfo.owner?.surname}
                  </dt>
                </div>
              </>
            )}

            <div>
              <dt>
                <b>{t('content.Hosted region')}:</b> eu-central-1 (Frankfurt)
              </dt>
            </div>
          </div>

          {meetStatus === 1 && !isVideoEnabled && (
            <i>
              <br />
              <br />({t('content.Currently nobody is showing their video or screen')}. )
            </i>
          )}

          {isVideoEnabled && (
            <i>
              <br />
              <br />( {t('content.Your video enabled')}! ){' '}
            </i>
          )}
        </Flex>
      </Flex>
    )
  }
  const meetingLogger = new ConsoleLogger('SDK', LogLevel.INFO)
  const permissionGuides = {
    firefox: (
      <div>
        <div>{t('Info.Not all permissions were granted')}</div>
        <ol>
          <li>{t('Info.Click the crossed out Camera/Microphone icons leftwards the website name')}</li>
          <li>{t('Info.Click the Blocked Temporarily button in the Use the microphone section')}</li>
          <li>{t('Info.Click the Blocked Temporarily button in the Use the camera section')}</li>
          <li>{t('Info.Reload the page')}</li>
          <li>{t('Info.Grant the requested permissions')}</li>
        </ol>
      </div>
    ),
    chrome: (
      <div>
        <div>{t('Info.Not all permissions were granted')}</div>
        <ol>
          <li>{t('Info.Click the lock button leftwards the website name')}</li>
          <li>{t('Info.For Camera select Allow option')}</li>
          <li>{t('Info.For Microphone select Allow option')}</li>
          <li>{t('Info.Reload the page')}</li>
        </ol>
      </div>
    ),
    edge: (
      <div>
        <div>{t('Info.Not all permissions were granted')}</div>
        <ol>
          <li>{t('Info.Click the Camera icon on the right side of the address panel')}</li>
          <li>{t('Info.Select Always Allow option')}</li>
          <li>{t('Info.Click Done button')}</li>
          <li>{t('Info.Reload the page')}</li>
        </ol>
      </div>
    ),
  }

  // Render html
  return (
    <Layout className="layout chimeIntegration">
      {invalidMeeting ? (
        <center>
          <br />
          <br />
          <Title>
            <WarningOutlined twoToneColor="#eb2f96" /> {t(failedMsg)}.
          </Title>
        </center>
      ) : (
        <ThemeProvider theme={lightTheme}>
          <MeetingProvider logger={meetingLogger}>
            <Layout>
              <Sider width={280} breakpoint="lg" collapsedWidth="0">
                <MeetingRoster />
              </Sider>
              <Layout>
                <Content style={{ margin: '0px 16px 0', position: 'relative', minHeight: '100vh' }}>
                  {currentBrowser === 'safari' && (
                    <div
                      style={{
                        zIndex: 9,
                        position: 'absolute',
                        right: 40,
                        top: 20,
                        width: 400,
                      }}
                    >
                      <SafariCompabilityAlert />
                    </div>
                  )}
                  <MeetingManager />
                  <Grid>
                    <VideoTileGrid noRemoteVideoView={<MeetingDetails />} layout="standard" />
                  </Grid>
                </Content>
              </Layout>
            </Layout>

            <MeetingManagerControls />
          </MeetingProvider>
        </ThemeProvider>
      )}
    </Layout>
  )
}

export default ChimeIntegration
