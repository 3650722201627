import { SET_COURSE_DETAILS, SET_STEP_PROGRESS } from '../action-creators/courseDetail'

const initialState = {
  courseDetail: null,
  isCourseDetailFetching: false,
  stepProgress: null,
}

const courseDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSE_DETAILS: {
      return {
        ...state,
        courseDetail: action.payload,
        isCourseDetailFetching: action.isCourseDetailFetching,
      }
    }

    case SET_STEP_PROGRESS: {
      return {
        ...state,
        stepProgress: action.keepData ? state.stepProgress : action.payload,
        isLoading: action.isLoading,
      }
    }

    default:
      return state
  }
}

export default courseDetailReducer
