import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Form, Input, Button, Row, Col, notification } from 'antd'
import LanguageDropdown from '../form-component/LanguageDropdown'
import { Service } from '../../services/Service'
import AuthLayout from '../auth/AuthLayout'
import ImprintPrivacyLinks from '../common/components/ImprintPrivacyLinks'

const ForgotPassword = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const onFinish = (values) => {
    setLoading(true)
    Service.post({
      url: '/user/resetPassword',
      body: JSON.stringify({
        email: values.email,
      }),
    })
      .then((response) => {
        setLoading(false)
        if (response.status === 'error') {
          openNotification('error', t('content.Oops!'), t('errors.' + response.message + ''))
          return
        }
        history.push('/forgot-password-success')
      })
      .catch((err) => {
        setLoading(false)
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
  }
  const openNotification = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    })
  }

  return (
    <AuthLayout>
      <Row type="flex" justify="center" align="middle" style={{ marginTop: '50px' }}>
        <Col span={10} xs={20} sm={16} md={16} lg={10} xl={10}>
          <p className="form-subtitle" style={{ textAlign: 'center' }}>
            {t('auth.forgot-sub-title')}
          </p>
          <div style={{ marginBottom: 20, textAlign: 'center' }}>
            <LanguageDropdown />
          </div>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <Col span={6} xl={6} lg={8} md={8} sm={16} xs={18}>
          <Form
            layout="vertical"
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label={t('auth.Email')}
              name="email"
              required={false}
              rules={[
                {
                  required: true,
                  message: t('errors.invalid-email'),
                },
                {
                  type: 'email',
                  message: t('errors.Please enter valid email'),
                },
              ]}
            >
              <Input size={'large'} className="theme-input" placeholder={t('auth.Email')} />
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" className="login-form-button theme-button" block loading={loading}>
                {t('auth.Send me a link')}
              </Button>
            </Form.Item>
            <p className="line-text" style={{ textAlign: 'center', marginTop: 40 }}>
              <Link to={'login'} className="custom-anchor">
                {t('auth.Go Back')}
              </Link>
            </p>
          </Form>
        </Col>
      </Row>
      <ImprintPrivacyLinks />
    </AuthLayout>
  )
}

export default ForgotPassword
