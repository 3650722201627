import React from 'react'
import { Image, pageLoader } from 'antd'
import logo from '../../../asset/images/logo.svg'
import logoGrey from '../../../asset/images/logo-grey.svg'
import { Layout, notification, Spin, Empty } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'

const { Footer } = Layout
const colorsArr = [
  '#FFA39E',
  '#FFBB96',
  '#FFD591',
  '#95DE64',
  '#5CDBD3',
  '#85A5FF',
  '#D3ADF7',
  '#FFADD2',
  '#FF7875',
  '#FE905D',
  '#FFD666',
  '#D4B106',
  '#5B8C00',
  '#91D5FF',
  '#722ED1',
  '#9E1068',
]
const Logo = () => {
  return (
    <Link to="/dashboard">
      <Image className="logo" src={logo} preview={false} />
    </Link>
  )
}
const LogoGrey = () => {
  return (
    <Link to="/dashboard">
      <Image className="logo" src={logoGrey} preview={false} />
    </Link>
  )
}
const CustomFooter = () => {
  return (
    <Footer>
      <center>
        <Image className="logo" src={logoGrey} preview={false} />
      </center>
    </Footer>
  )
}
const PageLoader = () => {
  return (
    <center>
      <Spin size="large" />
    </center>
  )
}
const NoDataFound = (props) => {
  return <Empty description={<span>{props.message ? props.message : i18n.t('content.No Data')}</span>} />
}
const GetInitial = (props) => {
  if (typeof props.text != 'undefined' && props.text) {
    var nameparts = props.text.split(' ')
    return (
      nameparts[0].charAt(0).toUpperCase() +
      (typeof nameparts[1] != 'undefined' ? nameparts[1].charAt(0).toUpperCase() : '')
    )
  } else {
    return ''
  }
}

const RandColor = () => {
  return colorsArr[Math.floor(Math.random() * colorsArr.length)]
}

const GetRole = (text) => {
  if (typeof text != 'undefined' && text) {
    text = text == 'admin' ? 'course_leader' : text
    var nameparts = text.split('_')
    return (
      nameparts[0].charAt(0).toUpperCase() +
      nameparts[0].slice(1) +
      (typeof nameparts[1] != 'undefined' ? ' ' + nameparts[1].charAt(0).toUpperCase() + nameparts[1].slice(1) : '')
    )
  } else {
    return ''
  }
}

const openNotification = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  })
}

export { Logo, LogoGrey, CustomFooter, PageLoader, NoDataFound, GetInitial, RandColor, GetRole, openNotification }
