import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PageLoader, NoDataFound, GetInitial, GetRole, openNotification } from '../../common/components/Helper'
import { Layout, List, Avatar, Button, Row, Col, Select, Form, Divider, Tooltip, Modal, Popconfirm } from 'antd'
import { Service } from '../../../services/Service'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { Storage } from '../../../services/Service'
import { SocketContext } from '../../context/socket'

const { Option } = Select

const AdminUsers = (props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [usersList, setUsersList] = useState([])
  const [usersListOriginal, setUsersListOriginal] = useState([])
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})
  const [form] = Form.useForm()
  const [userform] = Form.useForm()
  const socket = useContext(SocketContext)

  useEffect(() => {
    fetchAdminUsers()
  }, [])

  useEffect(() => {
    const handleUserStatusChange = (userData) => {
      const changedUser = JSON.parse(userData)
      for (let i = 0; i < usersList.length; i++) {
        if (changedUser._id === usersList[i]._id) {
          setUsersList([...usersList.slice(0, i), changedUser, ...usersList.slice(i + 1)])
          break
        }
      }
    }
    socket.on('updateUserStatus', handleUserStatusChange)

    return () => {
      socket.off('updateUserStatus', handleUserStatusChange)
    }
  }, [socket, usersList])

  const fetchAdminUsers = async () => {
    setLoading(true)
    try {
      Service.get({
        url: '/user',
      })
        .then((response) => {
          setLoading(false)
          if (response.hasOwnProperty('error')) {
            return
          }

          const userInfo = Storage.get('auth')
          var arr = []
          if (response.length > 0) {
            //Set BG color to user profile
            response.forEach(function (user) {
              if (user._id !== userInfo._id) {
                //user.color = RandColor()
                arr.push(user)
              }
            })
          }

          setUsersList(arr)
          setUsersListOriginal(arr)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        })
    } catch (e) {
      console.log(e)
    }
  }

  function onChange(value) {
    triggerFilter()
  }

  function onBlur() {
    //console.log('blur');
  }

  function onFocus() {
    // console.log('focus');
  }

  function onSearch(val) {
    //console.log('search:', val);
  }

  const triggerFilter = (data) => {
    var users = usersListOriginal
    var frmInput = form.getFieldsValue()
    var newArr = users

    //Filter
    if (typeof frmInput.isOnline !== 'undefined') {
      newArr = newArr.filter(function (o) {
        return frmInput.isOnline
          ? o.isOnline === frmInput.isOnline && o.dnd === 0
          : o.isOnline === frmInput.isOnline || o.dnd === 1
      })
    }
    if (typeof frmInput.role !== 'undefined') {
      newArr = newArr.filter(function (o) {
        return o.role === frmInput.role
      })
    }
    if (typeof frmInput.search != 'undefined') {
      newArr = newArr.filter(function (o) {
        return o.email === frmInput.search
      })
    }
    if (typeof frmInput.surname !== 'undefined') {
      newArr = newArr.filter(function (o) {
        return o.surname === frmInput.surname
      })
    }

    //Sorting
    if (typeof frmInput.order !== 'undefined') {
      if (frmInput.order === 'nameAsc') {
        newArr = _.orderBy(newArr, ['name'], ['asc'])
      } else if (frmInput.order === 'nameDesc') {
        newArr = _.orderBy(newArr, ['name'], ['desc'])
      } else if (frmInput.order === 'dateAsc') {
        newArr = _.orderBy(newArr, ['_id'], ['desc'])
      } else if (frmInput.order === 'dateDesc') {
        newArr = _.orderBy(newArr, ['_id'], ['asc'])
      }
    }

    setUsersList(newArr)
  }

  const showEditModal = (data) => {
    userform.resetFields()
    setSelectedUser(data)
    setEditModalVisible(true)
    userform.setFieldsValue(data) //Pushing datas to react form
  }

  const handleOk = () => {
    setEditModalVisible(false)
  }

  const closeEditModal = () => {
    setEditModalVisible(false)
    userform.resetFields()
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  }

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} ' + t('errors.is required!'),
    types: {
      email: '${label} ' + t('errors.is not a valid email!'),
      number: '${label} ' + t('errors.is not a valid number!'),
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  const deleteAcademy = (academyId) => {
    var param = {
      userId: selectedUser._id,
      resourceId: academyId,
      type: 'academy',
    }
    processDisEnroll(param)
  }
  const deleteCourse = (courseId) => {
    var param = {
      userId: selectedUser._id,
      resourceId: courseId,
      type: 'course',
    }
    processDisEnroll(param)
  }

  const processDisEnroll = (params) => {
    setLoading(true)
    Service.post({
      url: '/user/disenroll',
      body: JSON.stringify(params),
    })
      .then((response) => {
        setLoading(false)
        if (response.status === 'success') {
          fetchAdminUsers()
          openNotification('success', t('content.Success'), t('content.User disenrolled successfully!'))
          closeEditModal()
        } else {
          openNotification('error', t('content.Oops!'), response.message)
        }
      })
      .catch((err) => {
        setLoading(false)
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
  }

  const submitForm = (values) => {
    setLoading(true)
    Service.patch({
      url: '/user/update/' + selectedUser._id,
      body: JSON.stringify(values),
    })
      .then((response) => {
        setLoading(false)
        if (response.status === 'error') {
          openNotification('error', t('content.Oops!'), response.message)
        } else {
          fetchAdminUsers()
          openNotification('success', t('content.Success'), t('content.User updated successfully!'))
          closeEditModal()
        }
      })
      .catch((err) => {
        setLoading(false)
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
  }

  const accountDeactivate = (values) => {
    setLoading(true)
    Service.post({
      url: '/user/deactivate',
      body: JSON.stringify({ deactivate: 'yes', profileId: selectedUser._id }),
    })
      .then((response) => {
        setLoading(false)
        openNotification('success', t('content.Success'), t('content.Profile Deactivated!'))
        fetchAdminUsers()
        closeEditModal()
      })
      .catch((err) => {
        setLoading(false)
        openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
      })
  }

  return (
    <Layout className="layout academyUsersList bgWhite">
      <Form form={form}>
        <Row>
          <Col span={3} xl={3} lg={3} xs={24} sm={24} md={12}>
            <Form.Item name="isOnline">
              <Select
                allowClear={true}
                onChange={triggerFilter}
                placeholder={t('content.Status')}
                style={{ width: '100%' }}
                bordered={false}
              >
                <Option value={true}>{t('content.Online')}</Option>
                <Option value={false}>{t('content.Offline')}</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={3} xl={3} lg={3} xs={24} sm={24} md={12}>
            <Form.Item name="role">
              <Select
                allowClear={true}
                onChange={triggerFilter}
                placeholder={t('content.Role')}
                style={{ width: '100%' }}
                bordered={false}
              >
                <Option value="admin">{t('content.Course Leader')}</Option>
                <Option value="student">{t('content.Student')}</Option>
                <Option value="module_trainer">{t('content.Module Trainer')}</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={4} xl={4} lg={4} xs={24} sm={24} md={12}>
            <Form.Item name="order">
              <Select
                allowClear={true}
                onChange={triggerFilter}
                placeholder={t('content.Order By')}
                style={{ width: '100%' }}
                bordered={false}
              >
                <Option value="nameAsc">{t('content.Name Asc')}</Option>
                <Option value="nameDesc">{t('content.Name Desc')}</Option>
                <Option value="dateAsc">{t('content.Date Added Asc')}</Option>
                <Option value="dateDesc">{t('content.Date Added Desc')}</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col
            className="searchField"
            span={4}
            xl={{ span: 4, offset: 1 }}
            lg={{ span: 4, offset: 1 }}
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 12, offset: 0 }}
          >
            <Form.Item name="surname">
              <Select
                showSearch
                style={{ width: '100%', float: 'right' }}
                placeholder={t('content.Search by family name')}
                optionFilterProp="children"
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                bordered={false}
                allowClear={true}
              >
                {usersListOriginal.map((userInfo, index) => (
                  <Option key={`key-${index}`} value={userInfo.surname}>
                    {userInfo.surname}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            className="searchField"
            span={7}
            xl={{ span: 7, offset: 2 }}
            lg={{ span: 7, offset: 2 }}
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 12, offset: 0 }}
          >
            <Form.Item name="search">
              <Select
                showSearch
                style={{ width: '100%', float: 'right' }}
                placeholder={t('content.Search by email or name')}
                optionFilterProp="children"
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                bordered={false}
                allowClear={true}
              >
                {usersListOriginal.map((userInfo, index) => (
                  <Option key={`opt-${index}`} value={userInfo.email}>
                    {userInfo.email} ({userInfo.name})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {loading ? (
        <PageLoader />
      ) : (
        <>
          {!loading && usersList.length === 0 ? <NoDataFound message={t('content.No users found')} /> : null}

          <List itemLayout="horizontal" className="users-lists" pagination={{ defaultCurrent: 1 }}>
            {usersList.map((userInfo, index) => (
              <List.Item key={`user-${index}`}>
                <div className="topProfileMenu">
                  <Tooltip title={userInfo.email}>
                    <Avatar style={{ backgroundColor: userInfo.color }}>
                      <GetInitial text={userInfo.name} />
                    </Avatar>
                    <span
                      className={`availablityIndicator ${
                        !userInfo.isOnline || userInfo.dnd === 1 ? 'inactive' : 'active'
                      }`}
                    ></span>
                  </Tooltip>
                </div>

                <List.Item.Meta
                  title={userInfo.name + ' ' + userInfo.surname}
                  description={t('content.' + GetRole(userInfo.role + ''))}
                  className="metaInfo"
                />

                <div className="meta-email" style={{ marginRight: '10px', color: '#b6d5b6' }}>
                  {userInfo.email}
                </div>

                <div className="callButton">
                  <Button
                    shape="round"
                    onClick={() => showEditModal(userInfo)}
                    className="transparentButton"
                    htmlType="button"
                  >
                    {t('buttons.Edit')} <EditOutlined />
                  </Button>
                </div>
              </List.Item>
            ))}
          </List>
        </>
      )}

      {/* Edit User modal */}
      <Modal
        title={false}
        visible={editModalVisible}
        onOk={handleOk}
        onCancel={closeEditModal}
        footer={false}
        width={400}
        className="userModal"
        maskClosable={false}
      >
        <div className="profileViewHead">
          <div className="topProfileMenu">
            <Avatar style={{ backgroundColor: selectedUser.color }}>
              <GetInitial text={selectedUser.name} />
            </Avatar>
          </div>
          <h2>{selectedUser.name + ' ' + selectedUser.surname}</h2>
          <span className="subtitle">{selectedUser.email}</span>
        </div>

        <Form
          {...layout}
          name="nest-messages"
          form={userform}
          layout="vertical"
          onFinish={submitForm}
          initialValues={{}}
          validateMessages={validateMessages}
        >
          <Form.Item name={['role']} label={t('content.Role')} rules={[{ required: true }]}>
            <Select placeholder={t('content.Role')} style={{ width: '100%' }}>
              <Option value="admin">{t('Info.Course Leader')}</Option>
              <Option value="student">{t('Info.Student')}</Option>
              <Option value="module_trainer">{t('Info.Module Trainer')}</Option>
            </Select>
          </Form.Item>

          {typeof selectedUser.academies != 'undefined' && selectedUser.academies && selectedUser.academies.length > 0 && (
            <>
              <h3>{t('content.Enrolled Academies')}</h3>
              {selectedUser.academies.map((academy) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src={Service.getImage(academy.image.filePath)} />}
                    title={academy.name}
                    description=""
                  />

                  <Popconfirm
                    onConfirm={() => deleteAcademy(academy._id)}
                    title={t('content.Are you sure you want to remove user from this academy?')}
                    okText={t('buttons.Yes')}
                    cancelText={t('buttons.No')}
                  >
                    <Button ghost type="button" icon={<DeleteOutlined />} loading={false} />
                  </Popconfirm>
                </List.Item>
              ))}
              <Divider />
            </>
          )}

          {typeof selectedUser.courses != 'undefined' && selectedUser.courses && selectedUser.courses.length > 0 && (
            <>
              <h3>{t('content.Enrolled Courses')}</h3>
              {selectedUser.courses.map((course, index) => (
                <List.Item key={index}>
                  <List.Item.Meta
                    avatar={<Avatar src={Service.getImage(course.image.filePath)} />}
                    title={course.name}
                    description=""
                  />

                  <Popconfirm
                    onConfirm={() => deleteCourse(course._id)}
                    title={t('content.Are you sure you want to remove user from this course?')}
                    okText={t('buttons.Yes')}
                    okButtonProps={{ type: 'default' }}
                    cancelText={t('buttons.No')}
                    cancelButtonProps={{ type: 'primary' }}
                  >
                    <Button ghost type="button" icon={<DeleteOutlined />} loading={false} />
                  </Popconfirm>
                </List.Item>
              ))}
              <Divider />
            </>
          )}

          <Popconfirm
            title={t('content.Are you sure to delete this profile? This action cannot be undo!')}
            onConfirm={accountDeactivate}
            okText={t('buttons.Yes')}
            okButtonProps={{ type: 'default' }}
            cancelText={t('buttons.No')}
            cancelButtonProps={{ type: 'primary' }}
          >
            <Button type="dashed" danger style={{ float: 'right', marginLeft: '15px' }}>
              {t('buttons.Delete')}
            </Button>
          </Popconfirm>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t('buttons.Submit')}
            </Button>{' '}
            &nbsp; &nbsp;
            <Button type="default" htmlType="button" onClick={closeEditModal}>
              {t('buttons.Cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  )
}

export default AdminUsers
