import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NoDataFound, GetInitial, RandColor, GetRole, openNotification } from '../../common/components/Helper'
import { Row, Col, Image, Progress, Button, List, Avatar } from 'antd'
import { Service, Storage } from '../../../services/Service'
import noImage from '../../../asset/images/no-image.png'
import QuillViewer from '../../common/components/QuillViewer'

const CourseInformation = (props) => {
  const userInfo = Storage.get('auth')
  const { t } = useTranslation()
  const [courseData, setCourseData] = useState([])
  const [teamMembers, setTeamMembers] = useState([])
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)
  const [stats, setStats] = useState({
    totalModules: 0,
    completedModules: 0,
    totalSteps: 0,
    completedSteps: 0,
    percentage: 0,
  })

  useEffect(() => {
    if (props.course) {
      setCourseData(props.course)
      var arr = []
      if (props.course.teamMembers && props.course.teamMembers.length > 0) {
        props.course.teamMembers.forEach(function (user) {
          if (user.role == 'admin' || user.role == 'module_trainer') {
            arr.push(user)
          }
        })
      }
      setTeamMembers(arr)
    }
    getUserCourseProgress()
  }, [])
  //}, [props?.course]);

  const getUserCourseProgress = () => {
    try {
      Service.get({
        url: '/progress/course/' + props.course._id,
      })
        .then((response) => {
          if (response && response.length > 0) {
            var completedModules = 0,
              completedSteps = 0
            response.forEach((a, index) => {
              if (a.status === 1) {
                completedModules++
              }
              if (a.completedSteps && a.completedSteps.length > 0) {
                completedSteps = completedSteps + a.completedSteps.length
              }
            })

            var totalSteps = 0
            if (props.course.modules)
              props.course.modules.forEach((b, index) => {
                totalSteps = totalSteps + (b.steps ? b.steps.length : 0)
              })

            setStats({
              totalModules: props.course.modules.length,
              completedModules: completedModules,
              totalSteps: totalSteps,
              completedSteps: completedSteps,
              percentage: Math.round((completedSteps / totalSteps) * 100),
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (e) {
      console.log(e)
    }
  }

  const enrollToCourse = () => {
    Service.post({
      url: '/user/courseEnroll',
      body: JSON.stringify({ courseId: courseData._id }),
    }).then((resp) => {
      if (resp) {
        openNotification(
          'success',
          t('content.Enrollment to a Course'),
          t('content.You have successfully enrolled in the course!'),
        )
        setTimeout(function () {
          window.location.reload()
        }, 2000)
      }
    })
  }

  const courseInfo =
    courseData &&
    courseData.translations &&
    (typeof courseData.translations[selectedLanguage.key] != 'undefined'
      ? courseData.translations[selectedLanguage.key].description
      : courseData.description)

  return (
    <>
      <div className="course-info">
        <div className="site-layout-content" style={{}}>
          <h2 className="content-title green">{t('content.About Course')} </h2>
        </div>

        <Row className="coursesDetail" justify="space-between">
          <Col lg={{ span: 15 }} md={{ span: 15 }} sm={{ span: 15 }} xs={{ span: 24 }}>
            <QuillViewer content={courseInfo} />
          </Col>
          <Col lg={{ span: 7 }} md={{ span: 7 }} sm={{ span: 8 }} xs={{ span: 24 }} style={{ textAlign: 'right' }}>
            {typeof courseData.image != 'undefined' ? (
              <Image fallback={noImage} src={Service.getImage(courseData.image.filePath)} />
            ) : (
              <Image fallback={noImage} src={noImage} />
            )}
          </Col>
        </Row>
      </div>

      {courseData && courseData.access === 'valid' ? (
        <div className="courseProgress">
          <div className="site-layout-content">
            <h2 className="content-title green">{t('content.Your Progress')} </h2>
          </div>

          <Row type="flex" align="middle" gutter={24}>
            <Col lg={{ span: 18 }} md={{ span: 18 }} sm={{ span: 18 }} xs={{ span: 24 }}>
              <div className="course-progress">
                <Button>
                  {stats.completedModules} / {stats.totalModules} {t('content.Modules')}
                </Button>
                <Button style={{ marginLeft: '10px' }}>
                  {stats.completedSteps} / {stats.totalSteps} {t('content.Steps')}
                </Button>
              </div>
              <Progress percent={stats.percentage} status="active" />
            </Col>
          </Row>
        </div>
      ) : null}

      <div className="courseTeam">
        <div className="site-layout-content">
          <h2 className="content-title green">{t('content.Course Team')} </h2>
        </div>

        {typeof teamMembers == 'undefined' || teamMembers.length === 0 ? (
          <NoDataFound message={t('content.No Team Member Data')} />
        ) : (
          <>
            <List itemLayout="horizontal">
              {teamMembers.map((user) => (
                <>
                  {user.role == 'admin' || user.role == 'module_trainer' ? (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Avatar style={{ backgroundColor: user.user.color ? user.user.color : '' }}>
                            <GetInitial text={user.user.name} />{' '}
                          </Avatar>
                        }
                        title={user.user.name + ' ' + user.user.surname}
                        description={t('content.' + GetRole(user.user.role) + '')}
                      />
                    </List.Item>
                  ) : null}
                </>
              ))}
            </List>
          </>
        )}
      </div>

      {courseData && courseData.access === 'invalid' ? (
        <div style={{ marginTop: '40px' }}>
          {userInfo && userInfo.role === 'admin' ? (
            <span className="view-enroll"> {t('Viewing as enrolled')} </span>
          ) : (
            <Button ghost={true} htmlType="button" onClick={() => enrollToCourse()} className="transparentButton">
              {' '}
              {t('content.Enroll Now')}{' '}
            </Button>
          )}
        </div>
      ) : null}
    </>
  )
}

export default CourseInformation
