import { useState } from 'react'
import { Service } from '../../../../services/Service'
import './styles.scss'

const StepImageViewer = ({ src }) => {
  const [showImage, setShowImage] = useState(false)
  const [imageSize, setImageSize] = useState({})

  const toggleImageViewer = (event) => {
    event.preventDefault()
    setShowImage(!showImage)
  }

  const onImageLoad = ({ target }) => {
    const { naturalWidth, naturalHeight } = target
    setImageSize({ imWidth: naturalWidth, imHeight: naturalHeight })
  }

  let scaleClass = ''
  const { imWidth, imHeight } = imageSize
  if (imHeight && imWidth) {
    const { innerHeight, innerWidth } = window
    if (innerWidth / innerHeight >= imWidth / imHeight) {
      scaleClass = 'height-scale'
    } else {
      scaleClass = 'width-scale'
    }
  }

  if (src) {
    return (
      <p className={['ql-indent step-image-view', scaleClass, showImage ? 'enable-viewer' : ''].join(' ')}>
        <a href="#/" onClick={toggleImageViewer}>
          <img src={Service.getImage(src)} alt="" onLoad={onImageLoad} />
        </a>
      </p>
    )
  }
  return null
}

export default StepImageViewer
