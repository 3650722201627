import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { NoDataFound, openNotification } from '../../common/components/Helper'
import {
  Row,
  Col,
  Image,
  Select,
  Input,
  Modal,
  Button,
  Radio,
  message,
  List,
  Upload,
  Collapse,
  Form,
  Divider,
  Menu,
  Tooltip,
  Space,
  Checkbox,
  InputNumber,
} from 'antd'
import { Service } from '../../../services/Service'
import { useParams, Link } from 'react-router-dom'
import {
  PlusOutlined,
  MoreOutlined,
  UploadOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { languageInfo } from '../../../otherLanguages'
import ImgCrop from 'antd-img-crop'
import Config from '../../../config.json'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { getCourseDetails } from '../../../redux/actions/courseDetail'
import StepContentBlock from './StepContentBlock'
import { findNewUploadedFiles } from '../../utils/deleteFile'

const { Panel } = Collapse
const { Option } = Select
const { confirm } = Modal
const { SubMenu } = Menu
const { TextArea } = Input
const DragHandle = sortableHandle(() => <span className="draggableHanle">::</span>)

const SortableItem = sortableElement(({ value }) => (
  <List.Item className="learn-sortable">
    <DragHandle />
    {value}
  </List.Item>
))

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <List size="large">
      <ul style={{ paddingInlineStart: 0 }}>{children}</ul>
    </List>
  )
})

const AdminCourseLearn = (props) => {
  const { selectedLanguage } = useSelector(({ languageReducer }) => languageReducer)
  const { t } = useTranslation()
  let { id } = useParams()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [popupMode, setPopupMode] = useState('Add')
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [editStepModalVisible, setEditStepModalVisible] = useState(false)
  const [activeLanguages, setActiveLanguages] = useState([])
  const [fileSelection, setFileSelection] = useState({})
  const [moduleList, setModuleList] = useState([])
  const [courseData, setCourseData] = useState([])
  const [editModuleInfo, setEditModuleInfo] = useState([])
  const [activeModule, setActiveModule] = useState([])
  const [editStepInfo, setEditStepInfo] = useState([])
  const [imageSelection, setImageSelection] = useState({})
  const [audioSelection, setAudioSelection] = useState([])
  const [videoSelection, setVideoSelection] = useState([])
  const [docSelection, setDocSelection] = useState([])
  const [stepLoading, setStepLoading] = useState(false)
  const [previewOption, setPreviewOption] = useState(false)
  const [stepDescriptions, setStepDescriptions] = useState({})
  const [unusedFiles, setUnusedFiles] = useState([])
  const [waitingSubmitFiles, setWaitingSubmitFiles] = useState([])

  useEffect(() => {
    if (props.course) {
      setCourseData(props.course)
      if (props.course.modules) {
        setModuleList(props.course.modules)
      }
    }
  }, [props?.course])

  const courseReload = async () => {
    dispatch(getCourseDetails(id, false))
  }

  const [form] = Form.useForm()
  const [stepForm] = Form.useForm()

  const defaultModuleObj = {
    translations: { de: { name: '' }, ar: { name: '' }, en: { name: '' } },
  }

  const defaultStepObj = {
    translations: {
      de: { name: '', description: '' },
      ar: { name: '', description: '' },
      en: { name: '', description: '' },
    },
  }

  const getAction = (module, index) => (
    <Menu mode="horizontal" className="listMenu">
      <SubMenu key="SubMenu" icon={<MoreOutlined />} title="">
        <Menu.Item onClick={() => showEditModal(module)} className="listSubMenuItems" key={`setting:1:${index}`}>
          {t('buttons.Edit')}
        </Menu.Item>

        <Menu.Item
          onClick={() => showModuleDeleteConfirm(module)}
          className="listSubMenuItems"
          key={`setting:2:${index}`}
        >
          {t('buttons.Delete')}
        </Menu.Item>

        <Menu.Item onClick={() => duplicateModule(module)} className="listSubMenuItems" key={`setting:3:${index}`}>
          {t('buttons.Duplicate')}
        </Menu.Item>
      </SubMenu>
    </Menu>
  )

  const GetSteps = (props) => {
    return (
      <SortableItem
        key={`item-${props.index}`}
        collection={props.collection}
        index={props.index}
        value={
          <div className="module-steps">
            <p>{props.step.name}</p>
            <Menu mode="horizontal" className="listMenu">
              <SubMenu key="SubMenu" icon={<MoreOutlined />} title="">
                <Menu.Item
                  onClick={() => showEditStepModal(props.module, props.step)}
                  className="listSubMenuItems"
                  key={`setting:11:${props.index}`}
                >
                  {t('buttons.Edit')}
                </Menu.Item>

                <Menu.Item
                  onClick={() => showStepDeleteConfirm(props.step)}
                  className="listSubMenuItems"
                  key={`setting:12:${props.index}`}
                >
                  {t('buttons.Delete')}
                </Menu.Item>

                <Menu.Item
                  onClick={() => duplicateStep(props.step)}
                  className="listSubMenuItems"
                  key={`setting:13:${props.index}`}
                >
                  {t('buttons.Duplicate')}
                </Menu.Item>
              </SubMenu>
            </Menu>
          </div>
        }
      />
    )
  }

  const showEditModal = (module) => {
    setPopupMode('Edit')
    form.resetFields()
    setActiveLanguages(module.language)
    setEditModalVisible(true)
    setEditModuleInfo(module)
    form.setFieldsValue(module)
  }

  const showAddModal = () => {
    form.resetFields()
    form.setFieldsValue(defaultModuleObj)
    setPopupMode('Add')
    setEditModalVisible(true)
    setActiveLanguages([])
  }

  const showAddStepModal = (module) => {
    stepForm.resetFields()
    stepForm.setFieldsValue(defaultStepObj)
    setPopupMode('Add')
    setEditStepModalVisible(true)
    setActiveLanguages([])
    setEditStepInfo([])
    setFileSelection([])
    setImageSelection({})
    setAudioSelection([])
    setVideoSelection([])
    setDocSelection([])
    setStepDescriptions({})
    setActiveModule(module)
    setStepDescriptions({})
    setUnusedFiles([])
    setWaitingSubmitFiles([])
  }

  const showEditStepModal = (module, step) => {
    setPopupMode('Edit')
    stepForm.resetFields()
    setPreviewOption(false)
    setUnusedFiles([])
    setWaitingSubmitFiles([])

    if (step.homework && typeof step.homework === 'object') {
      const homeworkCopy = { ...step.homework }
      step.homeworkMin = {}
      step.homeworkMax = {}
      step.homework = {}
      Object.keys(homeworkCopy).forEach((language) => {
        step.homeworkMin[language] = homeworkCopy[language].min
        step.homeworkMax[language] = homeworkCopy[language].max
        step.homework[language] = homeworkCopy[language].content
      })
    }

    if (step.polls && typeof step.polls === 'object') {
      step.pollQuestion = {}
      step.attempts = {}
      step.pollAnswer = {}
      Object.keys(step.polls).forEach((language) => {
        if (step.polls[language].length > 0) {
          step.pollQuestion[language] = step.polls[language][0].question
          step.attempts[language] = step.polls[language][0].attempts

          if (Array.isArray(step.polls[language][0].options))
            step.pollAnswer[language] = step.polls[language][0].options
          else step.pollAnswer[language] = []
        }

        // stepForm.setFieldsValue({
        //   pollAnswer: step.polls[0].options,
        // });
      })
    }

    setActiveLanguages(step.language)
    stepForm.setFieldsValue(step)
    setEditStepModalVisible(true)
    setActiveModule(module)
    setEditStepInfo(step)

    //Populating existing image selection
    if (step.stepImage && typeof step.stepImage.filePath != 'undefined') {
      setFileSelection([
        {
          uid: '1',
          name: step.stepImage.name,
          status: 'success',
          url: Config.BASE_URL + '/' + step.stepImage.filePath,
          rawData: { ...step.stepImage },
        },
      ])
    } else {
      setFileSelection([])
    }

    if (step.image && typeof step.image === 'object') {
      let images = {}
      Object.keys(step.image).forEach((language) => {
        images[language] = []
        ;(step.image[language] || []).forEach((img, i) => {
          if (img) {
            images[language].push({
              uid: i + 1,
              id: img._id,
              name: img.originalName || img.name,
              status: 'success',
              url: Config.BASE_URL + '/' + img.filePath,
              response: {
                id: img._id,
                name: img.name,
              },
              rawData: { ...img },
            })
          }
        })
      })

      setImageSelection(images)
    } else {
      setImageSelection({})
    }

    if (step.audio && typeof step.audio === 'object') {
      let audios = {}
      Object.keys(step.audio).map((language) => {
        audios[language] = []
        ;(step.audio[language] || []).forEach((au, j) => {
          if (au) {
            audios[language].push({
              uid: j + 1,
              id: au._id,
              name: au.originalName || au.name,
              status: 'success',
              url: Config.BASE_URL + '/' + au.filePath,
              response: {
                id: au._id,
                name: au.name,
              },
              rawData: { ...au },
            })
          }
        })
      })

      setAudioSelection(audios)
    } else {
      setAudioSelection({})
    }

    if (step.video && typeof step.video === 'object') {
      let videos = {}
      Object.keys(step.video).forEach((language) => {
        videos[language] = []
        ;(step.video[language] || []).forEach((videoFile, k) => {
          if (videoFile) {
            videos[language].push({
              uid: k + 1,
              id: videoFile._id,
              name: videoFile.originalName || videoFile.name,
              status: 'success',
              url: Config.BASE_URL + '/' + videoFile.filePath,
              response: {
                id: videoFile._id,
                name: videoFile.name,
              },
              rawData: { ...step.videoFile },
            })
          }
        })
      })

      setVideoSelection(videos)
    } else {
      setVideoSelection({})
    }

    if (step.docs && typeof step.docs === 'object') {
      let docs = {}
      Object.keys(step.docs).forEach((language) => {
        docs[language] = []
        ;(step.docs[language] || []).forEach((docFile, l) => {
          if (docFile) {
            docs[language].push({
              uid: l + 1,
              id: docFile._id,
              name: docFile.originalName || docFile.name,
              status: 'success',
              url: Config.BASE_URL + '/' + docFile.filePath,
              response: {
                id: docFile._id,
                name: docFile.name,
              },
              type: docFile.contentType,
              openType: step.configs?.docs?.[language]?.[docFile._id]?.openType,
              rawData: { ...docFile },
            })
          }
        })
      })

      setDocSelection(docs)
    } else {
      setDocSelection([])
    }

    // populate existing step content
    if (step.translations && typeof step.translations === 'object') {
      let descriptions = {}
      Object.keys(step.translations).forEach((lang) => {
        try {
          JSON.parse(step.translations[lang].description)
          descriptions[lang] = step.translations[lang].description
        } catch (err) {
          if (step.translations[lang].description) {
            descriptions[lang] = JSON.stringify([{ type: 'TEXT', value: step.translations[lang].description }])
          }
        }
      })

      setStepDescriptions(descriptions)
    }
  }

  const handleOk = () => {
    setEditModalVisible(false)
  }

  const onCloseEditModal = async () => {
    // Call API to delete waiting submit files
    if (waitingSubmitFiles.length > 0) {
      await Service.delete({
        url: '/fileUpload',
        body: JSON.stringify(waitingSubmitFiles),
      })
    }

    closeEditModal()
  }

  const closeEditModal = () => {
    setEditModalVisible(false)
    setEditStepModalVisible(false)
    setUnusedFiles([])
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    var sorted = arrayMove(moduleList, oldIndex, newIndex)
    setModuleList(sorted)
    var newArr = []

    //Create array of sorted order
    for (var i = 0; i < sorted.length; i++) {
      newArr.push({ id: sorted[i]._id, order: i })
    }

    if (newArr.length > 0)
      Service.patch({
        url: '/academy/module/order/' + courseData._id,
        body: JSON.stringify({
          modules: newArr,
        }),
      })
  }

  const onStepSortEnd = ({ oldIndex, newIndex, collection }) => {
    let moduleArr = [...moduleList]
    var sorted = arrayMove(moduleArr[collection].steps, oldIndex, newIndex)

    moduleArr[collection].steps = sorted

    setModuleList(moduleArr)

    var newArr = []

    for (var i = 0; i < sorted.length; i++) {
      newArr.push({ id: sorted[i]._id, order: i })
    }

    if (newArr.length > 0)
      Service.patch({
        url: '/academy/steps/order/' + moduleArr[collection]._id,
        body: JSON.stringify({
          steps: newArr,
        }),
      })
  }

  const validateMessages = {
    required: '${label} ' + t('errors.is required!'),
    types: {
      email: '${label} ' + t('errors.is not a valid email!'),
      number: '${label} ' + t('errors.is not a valid number!'),
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  const submitForm = (values) => {
    var params = {
      courseId: courseData._id,
      language: values.language,
      name:
        typeof values.translations[courseData.defaultLanguage] != 'undefined'
          ? values.translations[courseData.defaultLanguage].name
          : values.translations[values.language[0]].name,
      defaultLanguage:
        typeof values.translations[courseData.defaultLanguage] != 'undefined'
          ? courseData.defaultLanguage
          : values.language[0],
      translations: values.translations ? values.translations : {},
    }

    setLoading(true)

    if (popupMode == 'Edit') {
      // EDIT Api call
      Service.patch({
        url: '/academy/module/' + editModuleInfo._id,
        body: JSON.stringify(params),
      })
        .then((response) => {
          apiSuccess(apiSuccess)
        })
        .catch((err) => {
          setLoading(false)
          openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
        })
    } else {
      // Add Api Call
      Service.post({
        url: '/academy/module',
        body: JSON.stringify(params),
      })
        .then((response) => {
          apiSuccess(apiSuccess)
        })
        .catch((err) => {
          setLoading(false)
          openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
        })
    }
  }

  const apiSuccess = (response) => {
    setLoading(false)
    if (response.status === 'error') {
      openNotification('error', t('content.Oops!'), response.message)
    } else {
      courseReload()
      openNotification(
        'success',
        t('content.Success'),
        t('content.Module ' + (popupMode == 'Add' ? 'Added' : 'Updated') + ' successfully'),
      )
      closeEditModal()
    }
  }

  const submitStepForm = (values) => {
    var params = {
      moduleId: activeModule._id,
      language: values.language,
      name:
        typeof values.translations[courseData.defaultLanguage] != 'undefined'
          ? values.translations[courseData.defaultLanguage].name
          : values.translations[values.language[0]].name,
      description:
        typeof values.translations[courseData.defaultLanguage] != 'undefined'
          ? values.translations[courseData.defaultLanguage].description
          : values.translations[values.language[0]].description,
      defaultLanguage: courseData.defaultLanguage,
      embedCode: values.embedCode,
      access: values.access,
      unusedFiles,
    }

    // step main content
    let translations = {}
    if (values.translations) {
      Object.keys(values.translations).forEach((lang) => {
        translations[lang] = {
          ...values.translations[lang],
          description: stepDescriptions[lang] || '',
        }
      })
    }
    params.translations = translations

    // homework
    const homework = {}

    if (values.homework) {
      Object.keys(values.homework).forEach((language) => {
        homework[language] = {
          content: values.homework[language],
          min: values.homeworkMin[language],
          max: values.homeworkMax[language],
        }
      })

      params.homework = homework
    }
    // check if homework is there for all selected languages
    let atLeastOneHomework = false
    let homeworksCount = 0
    if (values.homework) {
      Object.keys(values.homework).forEach((language) => {
        if (values.homework[language] && values.homework[language].length > 0) {
          atLeastOneHomework = true
          homeworksCount += 1
        }
      })
    }
    if (atLeastOneHomework && homeworksCount !== activeLanguages.length) {
      openNotification('warning', t('errors.Missing Homework'), t('errors.Add all languages homework'))
      return
    }

    // questions
    if (values.pollQuestion && typeof values.pollQuestion != 'undefined') {
      let polls = {}
      Object.keys(values.pollQuestion).map((language) => {
        var PollsOptions = []
        if (
          values.pollAnswer[language] &&
          values.pollAnswer[language].length &&
          values.pollAnswer[language].length > 0
        ) {
          values.pollAnswer[language].forEach((answer) => {
            if (answer && answer.title) {
              PollsOptions.push({
                title: answer.title,
                isCorrect: answer.isCorrect,
              })
            }
          })
        }

        polls[language] = {
          question: values.pollQuestion[language],
          options: PollsOptions,
          attempts: typeof values.attempts[language] == 'undefined' ? 0 : values.attempts[language],
        }
      })

      params.polls = polls

      // check if poll is there for all languages
      let atLeastOnePoll = false
      let pollsLength = 0
      Object.keys(values.pollQuestion).map((language) => {
        if (values.pollQuestion[language] && values.pollQuestion[language].length > 0) {
          atLeastOnePoll = true
          pollsLength += 1
        }
      })

      if (atLeastOnePoll && pollsLength !== activeLanguages.length) {
        openNotification('warning', t('errors.Missing Quiz'), t('errors.Add all languages quiz'))
        return
      }
    } else {
      params.polls = [{}]
    }

    // Step Image
    if (values.stepImage && typeof values.stepImage.id == 'undefined') {
      params.stepImage = values.stepImage
    } else {
      params.stepImage = null
    }

    // Image
    let images = {}
    let atLeastOneImage = false
    Object.keys(imageSelection).forEach((language) => {
      images[language] = []
      imageSelection[language] = imageSelection[language].forEach((image) => {
        if (image.response) {
          images[language].push(image.response.id)
          atLeastOneImage = true
        }
      })
    })
    if (atLeastOneImage) {
      params.image = images
    } else {
      params.image = null
    }

    // Audio
    let audios = {}
    let atLeastOneAudio = false
    Object.keys(audioSelection).forEach((language) => {
      audios[language] = []
      audioSelection[language] = audioSelection[language].forEach((audio) => {
        if (audio.response) {
          audios[language].push(audio.response.id)
          atLeastOneAudio = true
        }
      })
    })
    if (atLeastOneAudio) {
      params.audio = audios
    } else {
      params.audio = null
    }

    // Video
    let videos = {}
    let atLeastOneVideo = false
    Object.keys(videoSelection).forEach((language) => {
      videos[language] = []
      videoSelection[language] = videoSelection[language].forEach((video) => {
        if (video.response) {
          videos[language].push(video.response.id)
          atLeastOneVideo = true
        }
      })
    })
    if (atLeastOneVideo) {
      params.video = videos
    } else {
      params.video = null
    }

    // Documents
    let docs = {}
    let atLeastOneDoc = false
    Object.keys(docSelection).forEach((language) => {
      docs[language] = []
      docSelection[language].forEach((doc) => {
        if (doc.response) {
          docs[language].push(doc.response.id)
          atLeastOneDoc = true
        }
      })
    })
    if (atLeastOneDoc) {
      params.docs = docs
    } else {
      params.docs = null
    }
    let configs = {}
    if (params.docs) {
      configs.docs = {}
      Object.keys(params.docs).forEach((language) => {
        configs.docs[language] = {}
        docSelection[language].forEach((doc) => {
          if (doc.response) {
            configs.docs[language][doc.response.id] = { openType: doc.openType }
          }
        })
      })
    }
    params.configs = configs

    setStepLoading(true)
    if (popupMode == 'Edit') {
      Service.patch({
        url: '/academy/steps/' + editStepInfo._id,
        body: JSON.stringify(params),
      })
        .then((response) => {
          apiStepSuccess(apiSuccess)
          closeEditModal()
        })
        .catch((err) => {
          setStepLoading(false)
          openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
        })
    } else {
      // Add Api Call
      Service.post({
        url: '/academy/steps',
        body: JSON.stringify(params),
      })
        .then((response) => {
          apiStepSuccess(apiSuccess)
        })
        .catch((err) => {
          setStepLoading(false)
          openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
        })
    }
  }

  const apiStepSuccess = (response) => {
    setStepLoading(false)
    if (response.status === 'error') {
      openNotification('error', t('content.Oops!'), response.message)
    } else {
      courseReload()
      openNotification(
        'success',
        t('content.Success'),
        t('content.Step ' + (popupMode == 'Add' ? 'Added' : 'Updated') + ' successfully'),
      )
      setPreviewOption(false)
      if (popupMode == 'Add') {
        closeEditModal()
      }
    }
  }

  function handleLanguageChange(value) {
    var langVal = form.getFieldValue('translations')
    if (!langVal) {
      form.setFieldsValue({
        translations: {
          ar: { title: '', description: '' },
          de: { title: '', description: '' },
        },
      })
    }
    setActiveLanguages(value)
  }

  function handleStepLanguageChange(value) {
    var langVal = stepForm.getFieldValue('translations')
    if (!langVal) {
      stepForm.setFieldsValue({
        translations: {
          ar: { title: '', description: '' },
          de: { title: '', description: '' },
        },
      })
    }
    setActiveLanguages(value)
  }

  const translate = (data) => {
    return JSON.parse(JSON.stringify(data)).map((item) => {
      if (item.translations && item.translations.hasOwnProperty(selectedLanguage.key)) {
        item.name = item.translations[selectedLanguage.key].name
        item.description = item.translations[selectedLanguage.key].description
      }
      return item
    })
  }

  //Any change on filepicker
  const onFileUploadChange = ({ fileList: newFileList }) => {
    if (typeof newFileList[0] != 'undefined' && newFileList[0].response) {
      stepForm.setFieldsValue({ stepImage: newFileList[0].response.id })
      setWaitingSubmitFiles([...waitingSubmitFiles, { id: newFileList[0].response.id }])
    } else {
      stepForm.setFieldsValue({ stepImage: null })
    }
  }
  const onImageUploadChange = (newFileList, language) => {
    const newFiles = findNewUploadedFiles(imageSelection[language], newFileList)
    setWaitingSubmitFiles([...waitingSubmitFiles, ...newFiles])
    setImageSelection({ ...imageSelection, [language]: newFileList })
  }
  const onAudioUploadChange = (newFileList, language) => {
    const newFiles = findNewUploadedFiles(audioSelection[language], newFileList)
    setWaitingSubmitFiles([...waitingSubmitFiles, ...newFiles])
    setAudioSelection({ ...audioSelection, [language]: newFileList })
  }
  const onVideoUploadChange = (newFileList, language) => {
    const newFiles = findNewUploadedFiles(videoSelection[language], newFileList)
    setWaitingSubmitFiles([...waitingSubmitFiles, ...newFiles])
    setVideoSelection({ ...videoSelection, [language]: newFileList })
  }
  const onDocUploadChange = (newFileList, language) => {
    const newFiles = findNewUploadedFiles(docSelection[language], newFileList)
    setWaitingSubmitFiles([...waitingSubmitFiles, ...newFiles])
    setDocSelection({ ...docSelection, [language]: newFileList })
  }

  const onStepDescriptionChange = (value, language) => {
    setStepDescriptions({ ...stepDescriptions, [language]: value })
  }

  const beforeUploadImage = (file) => {
    const size = file.size / 1024 / 1024 < 10
    if (!size) {
      message.error('Image must smaller than 10MB!')
      return false
    }
    return true
  }
  const beforeUploadAudio = (file) => {
    const size = file.size / 1024 / 1024 < 100
    if (!size) {
      message.error('Audio must smaller than 100MB!')
      return false
    }
    return true
  }
  const beforeUploadVideo = (file) => {
    const size = file.size / 1024 / 1024 < 500
    if (!size) {
      message.error('Video must smaller than 500MB!')
      return false
    }
    return true
  }
  const beforeUploadDocs = (file) => {
    const size = file.size / 1024 / 1024 < 50
    if (!size) {
      message.error('Document must smaller than 50MB!')
      return false
    }
    return true
  }

  // handle removing uploaded files
  const onRemoveUploadedFiles = (removedFile) => {
    if (removedFile.rawData || removedFile.response) {
      setUnusedFiles([...unusedFiles, removedFile.rawData || removedFile.response])
    }
  }

  //Preview selected image
  const onPreview = async (file) => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow.document.write(image.outerHTML)
  }

  // Delete Module
  const confirmContent = (
    <>
      {t(
        'content.If you’re sure that you want to discard this module,  all contained steps, settings and module content',
      )}
      ,&nbsp;{t('content.type the words Delete irreversibly into the field')}.<br />
      <br />
      <Input
        id="confirmText"
        required
        placeholder={t('content.Type') + ' : ' + t('content.Delete irreversibly')}
      />{' '}
      <br />
      <br />
    </>
  )

  function showModuleDeleteConfirm(module) {
    confirm({
      title:
        t('content.Delete module') + ' "' + module.name + '" ' + t('content.and all contained steps irreversibly?'),
      icon: false,
      content: confirmContent,
      okText: t('buttons.Delete'),
      okType: 'danger',
      cancelText: t('buttons.Cancel'),
      wrapClassName: 'deleteConfirmModal',
      className: 'deleteConfirmModal',
      onOk(e) {
        var inp = document.getElementById('confirmText').value
        if (inp != t('content.Delete irreversibly')) {
          openNotification('error', t('content.Oops!'), t('content.Invalid input for delete'))
          return false
        }

        //Api call
        Service.delete({
          url: '/academy/module/' + module._id,
        })
          .then((response) => {
            setLoading(false)
            if (response.status === 'error') {
              openNotification('error', t('content.Oops!'), response.message)
              return
            } else {
              openNotification('success', t('content.Success'), t('content.Deleted Successfully!'))
              closeEditModal()
              Modal.destroyAll()
              courseReload()
            }
          })
          .catch((err) => {
            setLoading(false)
            openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
          })
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  // Duplicate Modules
  const duplicateModule = (module) => {
    confirm({
      title: t('content.Do you want to duplicate this module?'),
      icon: <ExclamationCircleOutlined />,
      okText: t('buttons.Yes'),
      cancelText: t('buttons.Cancel'),
      onOk() {
        Service.post({
          url: '/duplicate/module',
          body: JSON.stringify({ moduleId: module._id }),
        })
          .then((response) => {
            if (response.status === 'success') {
              openNotification('success', t('content.Success'), t('content.Duplicated Successfully!'))
              courseReload()
            }
          })
          .catch((err) => {
            openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
          })
      },
      onCancel() {},
    })
  }

  // Duplicate Steps
  const duplicateStep = (step) => {
    confirm({
      title: t('content.Do you want to duplicate this step?'),
      icon: <ExclamationCircleOutlined />,
      okText: t('buttons.Yes'),
      cancelText: t('buttons.Cancel'),
      onOk() {
        Service.post({
          url: '/duplicate/step',
          body: JSON.stringify({ stepId: step._id }),
        })
          .then((response) => {
            if (response.status === 'success') {
              openNotification('success', t('content.Success'), t('content.Duplicated Successfully!'))
              courseReload()
            }
          })
          .catch((err) => {
            openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
          })
      },
      onCancel() {},
    })
  }

  const confirmStepContent = (
    <>
      {t('content.If you’re sure that you want to discard this step,  all contained steps, settings and step content')}
      ,&nbsp;
      {t('content.type the words Delete irreversibly into the field')}.<br />
      <br />
      <Input
        id="confirmText"
        required
        placeholder={t('content.Type') + ' : ' + t('content.Delete irreversibly')}
      />{' '}
      <br />
      <br />
    </>
  )

  function showStepDeleteConfirm(step) {
    confirm({
      title: t('content.Delete step') + ' "' + step.name + '" ' + t('content.and all contained steps irreversibly?'),
      icon: false,
      content: confirmStepContent,
      okText: t('buttons.Delete'),
      okType: 'danger',
      cancelText: t('buttons.Cancel'),
      wrapClassName: 'deleteConfirmModal',
      className: 'deleteConfirmModal',
      onOk(e) {
        var inp = document.getElementById('confirmText').value
        if (inp != t('content.Delete irreversibly')) {
          openNotification('error', t('content.Oops!'), t('content.Invalid input for delete'))
          return false
        }

        //Api call
        Service.delete({
          url: '/academy/steps/' + step._id,
        })
          .then((response) => {
            setLoading(false)
            if (response.status === 'error') {
              openNotification('error', t('content.Oops!'), response.message)
              return
            } else {
              openNotification('success', t('content.Success'), t('content.Deleted Successfully!'))
              closeEditModal()
              Modal.destroyAll()
              courseReload()
            }
          })
          .catch((err) => {
            setLoading(false)
            openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
          })
      },
      onCancel() {
        //console.log('Cancel');
      },
    })
  }

  function onChangeValue(e) {
    //console.log(`checked = ${e.target.checked}`);
  }

  const onValuesChange = (changedValues, allValues) => {
    setPreviewOption(true)
  }

  const onOpenOptionChange = (checked, language, fileId) => {
    const newFileList = docSelection[language].map((itm) => {
      if (itm.uid === fileId) {
        return { ...itm, openType: checked ? 'NEWTAB' : null }
      } else {
        return itm
      }
    })
    setDocSelection({ ...docSelection, [language]: newFileList })
  }

  return (
    <>
      <div className="admin-learn-module">
        {moduleList.length > 0 ? (
          <>
            <SortableContainer onSortEnd={onSortEnd} useDragHandle>
              {translate(moduleList).map((module, key) => (
                <SortableItem
                  key={`item-${key}`}
                  index={key}
                  value={
                    <Collapse ghost expandIconPosition="right">
                      <Panel header={module.name} key={key} extra={getAction(module, key)}>
                        {module.steps.length > 0 ? (
                          <>
                            <SortableContainer onSortEnd={onStepSortEnd} useDragHandle>
                              {translate(module.steps).map((step, index) => (
                                <GetSteps
                                  module={module}
                                  step={step}
                                  index={index}
                                  collection={key}
                                  key={`step-${key}-${index}`}
                                />
                              ))}
                            </SortableContainer>
                          </>
                        ) : (
                          <NoDataFound message={t('errors.There are no steps yet')} />
                        )}
                        <div className="add-module-btn">
                          <Divider>
                            {' '}
                            <Tooltip title={t('buttons.Add Step')}>
                              <PlusOutlined onClick={() => showAddStepModal(module)} />{' '}
                            </Tooltip>{' '}
                          </Divider>
                        </div>
                      </Panel>
                    </Collapse>
                  }
                />
              ))}
            </SortableContainer>
          </>
        ) : (
          <NoDataFound message={t('errors.There are no modules yet')} />
        )}
        <div className="add-module-btn">
          <Divider>
            {' '}
            <Tooltip title={t('buttons.Add Module')}>
              <PlusOutlined onClick={() => showAddModal()} />{' '}
            </Tooltip>{' '}
          </Divider>
        </div>
      </div>

      <Modal
        title={popupMode == 'Add' ? t('buttons.Add Module') : t('buttons.Edit Module')}
        visible={editModalVisible}
        onOk={handleOk}
        onCancel={closeEditModal}
        footer={false}
        width={500}
        maskClosable={false}
      >
        <Form
          {...layout}
          name="nest-messages"
          form={form}
          layout="vertical"
          onFinish={submitForm}
          initialValues={{}}
          validateMessages={validateMessages}
        >
          <Form.Item name={['language']} label={t('content.Module Languages')} rules={[{ required: true }]}>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder={t('content.Please select')}
              onChange={handleLanguageChange}
            >
              <Option value={'en'} key={1}>
                {t('content.English')}
              </Option>
              <Option value={'de'} key={2}>
                {t('content.German')}
              </Option>
              <Option value={'ar'} key={3}>
                {t('content.Arabic')}
              </Option>
            </Select>
          </Form.Item>

          {activeLanguages.map((language, index) => (
            <div key={index}>
              <Form.Item
                name={['translations', language, 'name']}
                label={t('content.Module Name') + ` (${t(`content.${languageInfo(language).name}`)})`}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </div>
          ))}

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t('buttons.Save')}
            </Button>{' '}
            &nbsp; &nbsp;
            <Button type="default" htmlType="button" onClick={closeEditModal}>
              {t('buttons.Cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={popupMode == 'Add' ? t('content.Add Step Information') : t('content.Edit Step Information')}
        visible={editStepModalVisible}
        onOk={handleOk}
        onCancel={onCloseEditModal}
        className="steps-form"
        footer={false}
        width={700}
        maskClosable={false}
      >
        <Form
          {...layout}
          name="nest-messages"
          form={stepForm}
          layout="vertical"
          onFinish={submitStepForm}
          onValuesChange={onValuesChange}
          initialValues={{ pollAnswer: editModuleInfo?.pollAnswer, access: '1' }}
          validateMessages={validateMessages}
        >
          <Form.Item name={['language']} label={t('content.Step Language') + '(s)'} rules={[{ required: true }]}>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder={t('content.Please select')}
              onChange={handleStepLanguageChange}
            >
              <Option value={'en'} key={1}>
                {t('content.English')}
              </Option>
              <Option value={'de'} key={2}>
                {t('content.German')}
              </Option>
              <Option value={'ar'} key={3}>
                {t('content.Arabic')}
              </Option>
            </Select>
          </Form.Item>

          <Form.Item name={['stepImage']} label={t('content.Step image')} rules={[{ required: false }]}>
            <ImgCrop rotate aspect={1 / 0.3}>
              <Upload
                action={`${Config.BASE_URL}/fileUpload `}
                listType="picture"
                onChange={onFileUploadChange}
                onPreview={onPreview}
                maxCount={1}
                defaultFileList={fileSelection}
                headers={{ 'x-access-token': localStorage.getItem('token') }}
                onRemove={onRemoveUploadedFiles}
              >
                <Button icon={<UploadOutlined />}>{t('buttons.Upload')}</Button>
                <p className="ant-form-item-optional">
                  {t('content.Image file size')}: {t('content.limit to 10MB')}
                </p>
              </Upload>
            </ImgCrop>
          </Form.Item>

          {activeLanguages.map((language, index) => (
            <div key={index}>
              <Form.Item
                name={['translations', language, 'name']}
                label={`${t('content.Step Name')} (${t('content.' + languageInfo(language).name + '')})`}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['translations', language, 'description']}
                initialValue=""
                label={`${t('content.Main Content')} (${t('content.' + languageInfo(language).name + '')})`}
              >
                <StepContentBlock
                  content={stepDescriptions[language]}
                  onChange={(value, deletedFiles, newFiles) => {
                    onStepDescriptionChange(value, language)
                    setUnusedFiles([...unusedFiles, ...(deletedFiles || [])])
                    setWaitingSubmitFiles([...waitingSubmitFiles, ...(newFiles || [])])
                  }}
                />
              </Form.Item>

              <div className="">
                <p className="form-subtitle">
                  {t('content.Choose Attachments Type')} ({t('content.' + languageInfo(language).name + '')})
                </p>
                <Collapse defaultActiveKey={['15']} ghost>
                  <Panel header={t('content.Image')} key="11">
                    <Form.Item name={['image']}>
                      <Upload
                        action={`${Config.BASE_URL}/fileUpload `}
                        listType="picture"
                        beforeUpload={beforeUploadImage}
                        onChange={({ fileList: newFileList }) => {
                          onImageUploadChange(newFileList, language)
                        }}
                        onRemove={onRemoveUploadedFiles}
                        onPreview={onPreview}
                        multiple={true}
                        defaultFileList={imageSelection[language]}
                        headers={{
                          'x-access-token': localStorage.getItem('token'),
                        }}
                      >
                        <Button icon={<UploadOutlined />}>{t('buttons.Upload Image')}</Button>
                        <p className="ant-form-item-optional">
                          {t('content.Image file size')}: {t('content.limit to 10MB')}
                        </p>
                      </Upload>
                    </Form.Item>
                  </Panel>

                  <Panel header={t('content.Video')} key="12">
                    <Form.Item name={['video']}>
                      <Upload
                        action={`${Config.BASE_URL}/fileUpload `}
                        beforeUpload={beforeUploadVideo}
                        onChange={({ fileList: newFileList }) => {
                          onVideoUploadChange(newFileList, language)
                        }}
                        multiple={true}
                        defaultFileList={videoSelection[language]}
                        headers={{
                          'x-access-token': localStorage.getItem('token'),
                        }}
                        onRemove={onRemoveUploadedFiles}
                      >
                        <Button icon={<UploadOutlined />}>{t('buttons.Upload Video')}</Button>
                        <p className="ant-form-item-optional">
                          {t('content.Video file size')}: {t('content.limit to 500MB')}
                        </p>
                      </Upload>
                    </Form.Item>
                  </Panel>

                  <Panel header={t('content.Audio')} key="13">
                    <Form.Item name={['audio']}>
                      <Upload
                        action={`${Config.BASE_URL}/fileUpload `}
                        beforeUpload={beforeUploadAudio}
                        onChange={({ fileList: newFileList }) => {
                          onAudioUploadChange(newFileList, language)
                        }}
                        multiple={true}
                        defaultFileList={audioSelection[language]}
                        headers={{
                          'x-access-token': localStorage.getItem('token'),
                        }}
                        onRemove={onRemoveUploadedFiles}
                      >
                        <Button icon={<UploadOutlined />}>{t('buttons.Upload Audio')}</Button>
                        <p className="ant-form-item-optional">
                          {t('content.Audio file size')}: {t('content.limit to 100MB')}
                        </p>
                      </Upload>
                    </Form.Item>
                  </Panel>

                  <Panel header={t('content.Documents')} key="18">
                    <Form.Item name={['docs']}>
                      <Upload
                        action={`${Config.BASE_URL}/fileUpload `}
                        beforeUpload={beforeUploadDocs}
                        onChange={({ fileList: newFileList }) => {
                          onDocUploadChange(newFileList, language)
                        }}
                        onRemove={onRemoveUploadedFiles}
                        multiple={true}
                        defaultFileList={docSelection[language]}
                        fileList={docSelection[language]}
                        headers={{
                          'x-access-token': localStorage.getItem('token'),
                        }}
                        itemRender={(originNode, file, currFileList) => (
                          <>
                            {file.status === 'error' ? (
                              <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
                            ) : (
                              <Row>
                                <Col span={18}>{originNode}</Col>
                                {file.type.includes('pdf') && (
                                  <Col style={{ paddingTop: 8 }} span={6}>
                                    {(file.status === 'success' || file.status === 'done') && (
                                      <Checkbox
                                        checked={file.openType === 'NEWTAB'}
                                        onChange={(e) => onOpenOptionChange(e.target.checked, language, file.uid)}
                                      >
                                        {t('buttons.Open in browser')}
                                      </Checkbox>
                                    )}
                                  </Col>
                                )}
                              </Row>
                            )}
                          </>
                        )}
                      >
                        <Button icon={<UploadOutlined />}>{t('buttons.Upload Document')}</Button>
                        <p className="ant-form-item-optional">
                          {t('content.Document file size')}: {t('content.limit to 50MB')}
                        </p>
                      </Upload>
                    </Form.Item>
                  </Panel>
                  <Panel header={`</> ${t('content.Embed Code')}`} key="14">
                    <Form.Item name={['embedCode', language]}>
                      <TextArea rows={4} placeholder={t('content.Only allowed services YouTube, Vimeo, Soundcloud')} />
                    </Form.Item>
                  </Panel>
                  <Panel header={t('content.Question (MC/Single choice question)')} className="mcq-block" key="15">
                    <Form.Item name={['pollQuestion', language]}>
                      <TextArea autoSize={{ minRows: 3, maxRows: 8 }} placeholder={t('content.Poll Question')} />
                    </Form.Item>

                    <Form.List name={['pollAnswer', language]}>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field) => (
                            <Row
                              key={field.key}
                              //style={{ display: "flex", marginBottom: 8 }}
                              //align="center"
                              //size="middle"
                              gutter={[8, 0]}
                              align="middle"
                            >
                              <Col xs={24} md={16}>
                                <Form.Item {...field} name={[field.name, 'title']} fieldKey={[field.fieldKey, 'title']}>
                                  <TextArea
                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                    placeholder={t('content.Poll Answer')}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={18} md={5}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'isCorrect']}
                                  fieldKey={[field.fieldKey, 'isCorrect']}
                                  valuePropName="checked"
                                >
                                  <Checkbox onChange={onChangeValue}>{t('content.Right Answer')}</Checkbox>
                                </Form.Item>
                              </Col>
                              <Col xs={6} md={3} style={{ marginTop: -24 }}>
                                <MinusCircleOutlined
                                  style={{ alignSelf: 'center' }}
                                  onClick={() => remove(field.name)}
                                />
                              </Col>
                            </Row>
                          ))}
                          <Form.Item>
                            <Button onClick={() => add()} icon={<PlusOutlined />}>
                              {t('buttons.Add Answer')}
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>

                    <Form.Item name={['attempts', language]} label={t('content.Attempts to answer question')}>
                      <Select style={{ width: '100%' }} placeholder={t('content.Please select')}>
                        <Option value={0} key={4}>
                          {t('content.Unlimited')}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Panel>
                  <Panel header={t('content.Homework')} className="homework-block" key="16">
                    <Form.Item name={['homework', language]}>
                      <TextArea rows={4} placeholder={t('content.Homework Task')} />
                    </Form.Item>

                    <Input.Group size="large">
                      <Row gutter={24}>
                        <Col span={12}>
                          <Form.Item
                            name={['homeworkMin', language]}
                            label={t('content.Minimum Words')}
                            rules={[{ type: 'number' }]}
                          >
                            <InputNumber min={1} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={['homeworkMax', language]}
                            label={t('content.Maximum Words')}
                            rules={[{ type: 'number' }]}
                          >
                            <InputNumber min={1} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Panel>
                </Collapse>
              </div>
            </div>
          ))}

          <br />
          <Form.Item
            name={['access']}
            label={t('content.How can course participants access this step?')}
            rules={[
              {
                required: true,
                message: t('content.Please select course participants access!'),
              },
            ]}
          >
            <Radio.Group>
              <Radio style={{ display: 'block', lineHeight: '30px' }} value="0">
                {t('content.Freely once they are enrolled')}
              </Radio>
              <Radio style={{ display: 'block', lineHeight: '30px' }} value="1">
                {t('content.After the previous step was completed')}
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            {stepLoading ? (
              <Button loading={true} disabled={true} htmlType="button">
                {t('buttons.Save')}
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                {t('buttons.Save')}
              </Button>
            )}
            &nbsp; &nbsp;
            {popupMode == 'Edit' ? (
              <Link to={'/admin/preview-step/' + editStepInfo._id} target="_blank">
                <Button disabled={previewOption} type="default" htmlType="button">
                  {t('buttons.Preview')}
                </Button>
              </Link>
            ) : null}
            &nbsp; &nbsp;
            <Button type="default" htmlType="button" onClick={onCloseEditModal}>
              {t('buttons.Cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AdminCourseLearn
