import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Form, Button, Modal, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { languageInfo } from '../../../otherLanguages'
import { Service } from '../../../services/Service'
import { openNotification } from '../../common/components/Helper'

const { Option } = Select

const AddGroupBtn = (props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [popupMode, setPopupMode] = useState('Add')
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [activeLanguages, setActiveLanguages] = useState([])
  const [editGroupInfo, setEditGroupInfo] = useState([])
  const [groupList, setGroupList] = useState([])

  const [form] = Form.useForm()

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  }

  const defaultGroupObj = {
    translations: { de: { name: '' }, ar: { name: '' }, en: { name: '' } },
  }

  const getGroupList = () => {
    Service.get({
      url: '/groups',
    })
      .then((response) => {
        setLoading(false)
        if (response.hasOwnProperty('error')) {
          return
        }
        setGroupList(response)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const showAddModal = () => {
    form.resetFields()
    form.setFieldsValue(defaultGroupObj)
    setPopupMode('Add')
    setEditModalVisible(true)
    setActiveLanguages([])
    setEditGroupInfo({})
  }

  const handleOk = () => {
    setEditModalVisible(false)
  }

  const closeEditModal = () => {
    setEditModalVisible(false)
  }

  const validateMessages = {
    required: '${label} ' + t('errors.is required!'),
    types: {
      email: '${label} ' + t('errors.is not a valid email!'),
      number: '${label} ' + t('errors.is not a valid number!'),
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  const submitForm = (values) => {
    var params = {
      name: values.name,
      language: values.language,
      defaultLanguage: values.defaultLanguage,
      translations: values.translations ? values.translations : {},
    }

    if (typeof values.translations[values.defaultLanguage] == 'undefined') {
      openNotification(
        'warning',
        t('content.Missing default lanugage content'),
        t('content.Please add Name in default language - ') + languageInfo(values.defaultLanguage).name,
      )
    } else {
      var defLngContent = values.translations[values.defaultLanguage]
      params.name = defLngContent.name
    }

    //console.log(params);
    //return false;

    setLoading(true)
    if (popupMode == 'Edit') {
      // EDIT Api call
      Service.patch({
        url: '/groups/' + editGroupInfo._id,
        body: JSON.stringify(params),
      })
        .then((response) => {
          apiSuccess(apiSuccess)
        })
        .catch((err) => {
          setLoading(false)
          openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
        })
    } else {
      // Add Api Call
      Service.post({
        url: '/groups',
        body: JSON.stringify(params),
      })
        .then((response) => {
          apiSuccess(apiSuccess)
        })
        .catch((err) => {
          setLoading(false)
          openNotification('error', t('content.Oops!'), t('content.Something went wrong'))
        })
    }
  }

  const apiSuccess = (response) => {
    setLoading(false)
    if (response.status === 'error') {
      openNotification('error', t('content.Oops!'), response.message)
    } else {
      openNotification(
        'success',
        t('content.Success'),
        popupMode == 'Add' ? t('content.Group Added successfully!') : t('content.Group Updated successfully!'),
      )
      props.onClick()
      getGroupList()
      closeEditModal()
    }
  }

  function handleLanguageChange(value) {
    var langVal = form.getFieldValue('translations')
    if (!langVal) {
      form.setFieldsValue({ translations: { ar: { title: '', description: '' }, de: { title: '', description: '' } } })
    }
    setActiveLanguages(value)
  }

  //Render html
  return (
    <>
      <Button
        style={{ float: 'right', margin: '14px 10px' }}
        icon={<PlusOutlined />}
        ghost={true}
        onClick={showAddModal}
        className="transparentButton"
        htmlType="button"
      >
        {t('buttons.New Group')}
      </Button>

      <Modal
        title={popupMode === 'Add' ? t('buttons.Add Group') : t('buttons.Edit Group')}
        visible={editModalVisible}
        onOk={handleOk}
        onCancel={closeEditModal}
        footer={false}
        width={500}
        maskClosable={false}
      >
        <Form
          {...layout}
          name="nest-messages"
          form={form}
          layout="vertical"
          onFinish={submitForm}
          initialValues={{}}
          validateMessages={validateMessages}
        >
          <Form.Item name={['language']} label={t('content.Add Language')} rules={[{ required: true }]}>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder={t('content.Please select')}
              onChange={handleLanguageChange}
            >
              <Option value={'en'} key={1}>
                {t('content.English')}
              </Option>
              <Option value={'de'} key={2}>
                {t('content.German')}
              </Option>
              <Option value={'ar'} key={3}>
                {t('content.Arabic')}
              </Option>
            </Select>
          </Form.Item>

          {activeLanguages.map((language, index) => (
            <div key={index}>
              <Form.Item
                name={['translations', language, 'name']}
                label={`${t('content.Group Name')} (${t('content.' + languageInfo(language).name + '')})`}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </div>
          ))}

          <Form.Item name={['defaultLanguage']} label={t('content.Default Language')} rules={[{ required: true }]}>
            <Select placeholder={t('content.Please select')}>
              <Option value="en">{t('content.English')}</Option>
              <Option value="de">{t('content.German')}</Option>
              <Option value="ar">{t('content.Arabic')}</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('buttons.Save')}
            </Button>{' '}
            &nbsp; &nbsp;
            <Button type="default" htmlType="button" onClick={closeEditModal}>
              {t('buttons.Cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AddGroupBtn
